import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import { parseJSON } from '../../../helpers'
import modalService from '../../../services/modalService'
import payPalHereService from '../../../services/payPalHereService'
import FullScreenLoader from '../../../components/FullScreenLoader/FullScreenLoader'
import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Button from '../../../components/Button'
import ButtonsContainer from '../../../components/ButtonsContainer'
import H2 from '../../../components/H2'
import P from '../../../components/P'
import Container from '../../../components/Container'
import style from './style'
// import PayByGiftCardModal from '../../../components/PayByGiftCardModal/PayByGiftCardModal'
import Dropdown from '../../../components/Fields/Dropdown'

class PayPalHere extends Component {
  state = {
    isLoading: false
  }

  _buttons = []

  componentDidMount () {
    const { paymentToken, paymentRequired } = this.props
    if (paymentRequired) {
      // this is here because...
      // FullScreenLoader HOC from withCheckoutFlow forces
      // this component to remount whenever isLoading changes.
      // which sucks because it means componentDidMount fires
      // twice during the checkout flow, which will trigger
      // this.takePayment twice, unless we include the
      // following if condition.
      if (!paymentToken) {
        this.takePayment()
      }
    }

    this._buttons = [
      // ...this.props.isVirtualConsultation
      //   ? [{ text: translations('Pay by Link'), onClick: this.payByLink }]
      //   : [],
      ...(this.props.cardPaymentAvailable)
        ? [{ text: translations('Card'), onClick: this.props.payByCard }]
        : [],
      ...(this.props.cashPaymentAvailable)
        ? [{ text: translations('Cash'), onClick: this.props.payByCash }]
        : [],
      { text: translations('PayPal Here Button Take Payment'), onClick: this.takePayment },
      { text: translations('PayPal Here Button Skip Payment'), onClick: this.handleSkipPayment },
      { text: translations('Pay by Email'), onClick: this.payByEmail },
      { text: translations('Pay By Link'), onClick: this.props.payByLink },
      { text: translations('Pay By Gift Card'), onClick: this.payByGiftCard },
    ]
  }

  payByGiftCard = () => {
    modalService.close();
    // modalService.open({
    //   component: PayByGiftCardModal,
    //   paymentDevices: this.props.paymentDevices,
    //   props: this.props,
    //   total: this.props.total,
    //   next: this.props.next,
    //   updateOrder: this.props.updateOrder
    // })
  }
  
  handleSkipPayment = () => {
    this.props.next()
  }

  onFail = (err) => {
    const { error: errorObj, correlationId } = parseJSON(err, {})

    const text = []
    if (errorObj || correlationId) {
      const { message, code, developerMessage, debugId } = errorObj || {}
      if (message) text.push(`Message: ${message}`)
      if (code) text.push(`Code: ${code}`)
      if (developerMessage) text.push(`More info: ${developerMessage}`)
      if (debugId) text.push(`Debug ID: ${debugId}`)
      if (correlationId) text.push(`Correlation ID: ${correlationId}`)
    } else {
      text.push(err)
    }

    modalService.action({
      title: translations(`Error processing payment`),
      children: text.map(line => <P value={line} />),
      actions: [
        {
          onClick: this.takePayment,
          text: translations('Try again'),
          primary: true
        },
        {
          onClick: this.props.goBack,
          text: translations('Go back')
        }
      ]

    })
  }

  onSuccess = (result) => {
    const { next, updateOrder } = this.props
    const paypalTransaction = parseJSON(result, {})
    const { transactionNumber } = paypalTransaction
    updateOrder({
      details: { paypalTransaction },
      paymentToken: transactionNumber
    })
    next()
  }

  takePayment = async () => {
    this.setState({ isLoading: true })

    const { total } = this.props
    payPalHereService
      .initializeMerchant()
      .then(() => {
        return payPalHereService.searchAndConnectToReader()
      })
      .then(() => {
        return payPalHereService.takePayment(total)
      })
      .then((result) => {
        this.onSuccess(result)
      })
      .catch(err => {
        this.onFail(err)
      })
      .then(() => {
        this.setState({ isLoading: false })
      })
  }

  payByLink = async () => {
    this.props.updateOrder({ status: 'awaiting_payment' })
    this.props.setDeferredModule('PAY_BY_LINK')
    this.props.next()
  }

  payByEmail = async () => {
    this.props.updateOrder({ status: 'awaiting_payment' })
    this.props.setDeferredModule('PAY_BY_EMAIL')
    this.props.next()
  }

  handledPrinterChange = ({ target: { value } }) => {
    this.props.setselectedPrinterId(value);
  }  

  render () {
    const { isLoading } = this.state
    const { paymentRequired, classes, selectedPrinterId, isAnonymousOrder } = this.props
    return (
      <div>
        <SubHeader
          leftContent={<BackBar />}
          centerContent={<H2 value="Payment" />}
        />
        {paymentRequired || isLoading ? (
          <FullScreenLoader />
        ) : (
          <Container withMarginTop withMarginBottom>
            <div className={classes.printerDropdownContainer}>
              <Dropdown
                value={this.props.selectedPrinterId}
                options={this.props.allPrinters.map((printer) => ({
                  label: printer.name,
                  value: printer.id,
                }))}
                onChange={this.handledPrinterChange}
                label={translations('Select printer')}
              />
            </div>
            <ButtonsContainer
              className={classes.buttonsContainer}
              innerClassName={classes.buttonsContainerInner}
            >
              {this._buttons.map(({ text, onClick }) => {
                return (
                  <Button
                    key="text"
                    buttonType="primary"
                    big
                    disabled = {!selectedPrinterId && isAnonymousOrder}
                    children={text}
                    onClick={onClick}
                  />
                );
              })}
            </ButtonsContainer>
          </Container>
        )}
      </div>
    );
  }
}

export default withStyles(style)(PayPalHere)
