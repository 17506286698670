export const steps = {
  CHECK_BALANCE: 'CHECK_BALANCE',
  SELECT_INPUT_METHOD: 'SELECT_INPUT_METHOD',
  SCAN_GIFT_CARD: 'SCAN_GIFT_CARD'
}

export const inputMethods = [
  {
    value: 'Scanned',
    label: 'Scan'
  },
  {
    value: 'Keyed',
    label: 'Manual Entry'
  },
  {
    value: 'MagStripe',
    label: 'Mag Stripe'
  }
]

export const actions = {
  UPDATE_STEP: 'UPDATE_STEP',
  UPDATE_LOADING_STATE: 'UPDATE_LOADING_STATE',
  UPDATE_SELECTED_METHOD: 'UPDATE_SELECTED_METHOD'
}
