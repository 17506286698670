import React, { useState } from 'react'
import modalService from '../../../services/modalService'
import SelectInputMethodModal from './SelectInputMethodModal'
import CheckBalanceModal from './CheckBalanceModal'
import ScanGiftCardModal from './ScanGiftCardModal'
import { steps, inputMethods } from './constants'

export const PayByGiftCardModal = ({
  success,
  paymentDevices,
  total,
  orderNumber
}) => {
  const adyenTerminals = paymentDevices.map((item) => ({ value: item.id, label: item.name }))

  const [currentStep, setCurrentStep] = useState(steps.SELECT_INPUT_METHOD)
  const [inputMethod, setInputMethod] = useState()
  const [adyenTerminal, setSelectedAdyenTerminal] = useState()
  const [giftCardDetails, setGiftCardDetails] = useState({
    value: null,
    cardNumber: null,
    currency: null
  })

  const handleCancel = () => {
    modalService.close()
  }

  const handleSelectInputMethod = (inputMethod, adyenTerminal) => {
    const selectedAdyenTerminal = paymentDevices.find(
      (terminal) => terminal.id === adyenTerminal
    )
    setInputMethod(inputMethod)
    setSelectedAdyenTerminal(selectedAdyenTerminal)
    if (inputMethod === inputMethods[0].value) {
      setCurrentStep(steps.SCAN_GIFT_CARD)
    } else {
      setCurrentStep(steps.CHECK_BALANCE)
    }
  }

  const handleScanGiftCard = (cardNumber) => {
    setGiftCardDetails({
      ...giftCardDetails,
      cardNumber
    })
    setCurrentStep(steps.CHECK_BALANCE)
  }

  if (currentStep === steps.SCAN_GIFT_CARD) {
    return (
      <ScanGiftCardModal onCancel={handleCancel} onClick={handleScanGiftCard} />
    )
  }

  if (currentStep === steps.CHECK_BALANCE) {
    return (
      <CheckBalanceModal
        total={total}
        onCancel={handleCancel}
        inputMethod={inputMethod}
        success={success}
        orderNumber={orderNumber}
        cardDetails={giftCardDetails}
        adyenTerminal={adyenTerminal}
      />
    )
  }

  return (
    <SelectInputMethodModal
      adyenTerminals={adyenTerminals}
      onCancel={handleCancel}
      onClick={handleSelectInputMethod}
    />
  )
}

export default PayByGiftCardModal
