import { get, groupBy, filter, cloneDeep, orderBy, some, map, trimStart, sortBy, countBy, partition } from 'lodash'
import { createSelector } from 'reselect'

export const getProductList = state => get(state, 'productListDetails.list', null)
export const getIsLoading = state => get(state, 'productListDetails.isLoading') || false
export const getIsUpdating = state => get(state, 'productListDetails.isUpdating') || false
export const getSortOption = state => get(state, 'productListDetails.orderBy')
export const getProductListStatus = state => get(state, 'productListDetails.list.status')
export const getProducts = state => get(state, 'productListDetails.list.products') || []
export const getSearchQuery = state => get(state, 'productListDetails.searchQuery')

const filteredProducts = (query, products) => {
  return filter(products, product => {
    const eans = product.variants[0].details.eans
    return some(eans, (ean) => ean.includes(query))
  })
}

const getInReviewProducts = createSelector([
  getProducts
], (products) => {
  return filter(products, (product) => {
    return product.flaggedForReview
  })
}
)

const getGroupedProducts = (products, groupTitle) => {
  return products.map(p => {
    return {
      ...p,
      groupTitle
    }
  })
}

export const getHasReviewProducts = createSelector([
  getProducts
], (products = []) => {
  return some(products, { flaggedForReview: true })
})

export const getIsFullyReviewed = createSelector([
  getInReviewProducts
], (products = []) => {
  return !some(products, (product) => !product.reviewed)
})

const orderProductsByLocation = (products, addProducts) => {
  const handleMultipleLocations = products.flatMap((product) => {
    const locations = product.details.locations || []
    if (locations.length > 1) {
      return locations.map((loc) => {
        const clone = cloneDeep(product)
        clone.details.locations = [loc]
        clone.details.totalLocations = locations.length
        return clone
      })
    }
    product.details.totalLocations = locations.length
    return product
  })

  const groupByDepartment = groupBy(handleMultipleLocations, 'details.locations[0].department')
  const groupedProducts = []

  for (var department in groupByDepartment) {
    const departmentProducts = groupByDepartment[department]
    const groupByLayout = groupBy(departmentProducts, 'details.locations[0].layout')
    for (var layout in groupByLayout) {
      const layoutDepartmentProducts = groupByLayout[layout]
      const mappedProducts = layoutDepartmentProducts.map(p => {
        return {
          ...p,
          groupTitle: `${trimStart(get(p.details.locations[0], 'layout', ''), 0)} ${department}`
        }
      })
      groupedProducts.push({ department, products: mappedProducts })
    }
  }

  const sortByDepartment = sortBy(groupedProducts, ['department'], ['asc'])
  const sortDepartmentByLayout = map(sortByDepartment, department => sortBy(department.products,
    [`details.locations[0].layout`],
    ['asc']
  ))

  if (addProducts !== undefined) sortDepartmentByLayout.push(addProducts)

  const orderedProducts = map(sortDepartmentByLayout, group => {
    const location = 'details.locations[0]'
    const result = sortBy(
      group,
      [`${location}.metre`, `${location}.shelf`, `${location}.sequence`],
      ['asc']
    )
    return result
  })
  return orderedProducts
}

export const getCapacityFillProducts = createSelector([
  getSortOption,
  getProducts,
  getInReviewProducts,
  getSearchQuery,
  getProductListStatus
], (sort = 'scannedTime', products = [], reviewProducts = [], searchQuery, listStatus) => {
  const statusSpecificProducts = listStatus === 'review'
    ? reviewProducts
    : products

  const partitionProducts = partition(products, p => statusSpecificProducts.includes(p))
  const otherProducts = partitionProducts[0]
  const completedProducts = partitionProducts[1]

  const returnedProducts = searchQuery
    ? filteredProducts(trimStart(searchQuery, 0), otherProducts)
    : otherProducts

  const returnedCompletedProducts = searchQuery
    ? filteredProducts(trimStart(searchQuery, 0), completedProducts)
    : completedProducts

  if (sort === 'scannedTime') {
    const sortedProducts = orderBy(returnedProducts, 'scannedTime', 'desc')
    if (listStatus === 'review') {
      const sortedCompletedProducts = orderBy(getGroupedProducts(returnedCompletedProducts, 'Completed Products'), 'scannedTime', 'desc')
      sortedProducts.push(sortedCompletedProducts)
    }
    return sortedProducts
  }

  if (sort === 'location') {
    if (listStatus === 'review') {
      return orderProductsByLocation(returnedProducts, getGroupedProducts(returnedCompletedProducts, 'Completed Products'))
    }
    return orderProductsByLocation(returnedProducts)
  }
})

export const getStockCountProducts = createSelector([
  getSortOption,
  getProducts,
  getSearchQuery
], (sort = 'scannedTime', products = [], searchQuery) => {
  const returnedProducts = searchQuery
    ? filteredProducts(trimStart(searchQuery, 0), products)
    : products

  const partitionProducts = partition(returnedProducts, p => p.details.locations.length !== 0)
  const locationProducts = partitionProducts[0]
  const noLocationProducts = partitionProducts[1]

  if (sort === 'scannedTime') {
    const sortedProducts = orderBy(locationProducts, 'scannedTime', 'desc')
    const sortedNoLocationProducts = orderBy(getGroupedProducts(noLocationProducts, 'No Location'), 'scannedTime', 'desc')
    sortedProducts.push(sortedNoLocationProducts)
    return sortedProducts
  }

  if (sort === 'location') {
    return orderProductsByLocation(
      locationProducts,
      getGroupedProducts(noLocationProducts, 'No Location')
    )
  }
})

export const getIsStockCountFullyReviewed = createSelector([
  getProducts
], (products = []) => {
  const counts = countBy(products, 'reviewed')
  return counts.true && counts.true === products.length
})

export const getStockCountInvestigationsCounts = createSelector([
  getProducts
], (products = []) => {
  const partitionProducts = partition(products, p => p.stockAdjusted)
  return {
    counts: partitionProducts[0],
    nonCounts: partitionProducts[1]
  }
})
