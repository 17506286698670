import { defaultState } from './store'
import { promiseReducer } from '../../util'
import { FAIL, PENDING, SUCCESS } from '../../middleware/redux-promise'

class AccessoryDetailsReducers {
  create = promiseReducer(
    (state, action) => {
      const { result } = action

      return {
        ...state,
        result,
      }
    }
  )
  patch = promiseReducer(
    (state, action) => {
      const { result } = action

      return {
        ...state,
        result,
      }
    }
  )
  fetchOne = promiseReducer(
    (state, action) => {
      const { result } = action

      return {
        ...state,
        result,
      }
    }
  )
}

export default new AccessoryDetailsReducers()
