import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations, userRoles } from '../../config'
import { Input } from '../Fields'
import Form from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'

import style from './style'
import currencyFormatter from '../../formatters/currencyFormatter'
import * as validators from '../../validators'

export const formId = 'count-cash-modal'
const ThisForm = Form(formId)

class CountCashModal extends Component {

  renderWarning = () => {
    const {
      total,
      setShowWarning,
      success,
    } = this.props

    let actions = []

    actions.push({
      text: translations('Back'),
      success: false,
      primary: false,
      onClick: () => setShowWarning(false)
    })

    actions.push({
      text: translations('Confirm'),
      success: true,
      primary: true
    })

    return (
      <ThisForm onSubmit={success}>
        <BasicModalOverlay
          title={translations('Are you sure?')}
          confirmButtonText={translations('Pay')}
          actions={actions}
        >
          <div>
            <p>The amount counted is significantly different to the expected amount of money in the till. Please confirm that the entered amount ({currencyFormatter.format(total)}) is correct.</p>
          </div>
        </BasicModalOverlay>
      </ThisForm>
    )
  }

  render() {
    const {
      classes,
      currencyCode,
      success,
      dismiss,
      totalFloatError,
      countCashConfig,
      onQuantityChange,
      total,
      expectedAmount,
      loggedInUserRole,
      showWarning,
    } = this.props

    const canUserViewTheExpectedAmount = 
      loggedInUserRole.name !== userRoles.STORE_MANAGER && 
      loggedInUserRole.name !== userRoles.SALES_ASSISTANT

    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })


    actions.push({
      text: translations('Confirm'),
      success: true,
      primary: true,
      disabled: totalFloatError
        ? true
        : false
    })

    return showWarning ? this.renderWarning() : (
      <ThisForm onSubmit={success} >
        <BasicModalOverlay
          title={translations(`Count Cash`)}
          confirmButtonText={translations('Pay')}
          actions={actions}
        >
          <div className={classes.modalContainer}>
            {countCashConfig.denominations.map((conf, index) => {
              return (
                <div key={index} className={classes.floatSectionContainer}>
                  <span className={classes.symbol}>{conf.denomination}</span>
                  <FormControl className={classes.quantityInput}>
                    <Input
                      type='integer'
                      name={`${conf.denomination}_quantity`}
                      label={translations(`Quantity`)}
                      validate={[validators.required]}
                      onChange={onQuantityChange}
                    />
                  </FormControl>
                  <FormControl className={classes.priceInput}>
                    <Input
                      type='price'
                      disabled
                      currencyCode={currencyCode}
                      name={`${conf.denomination}_total`}
                      label={translations(`Total`)}
                    />
                  </FormControl>
                </div>
              )
            })}
          </div>
          <div className={classes.amountContainer}>
            <h2 className={classes.amountValue}>TOTAL {currencyFormatter.format(total)}</h2>
            {canUserViewTheExpectedAmount && (
              <p className={classes.amountValue}>Expected amount: {currencyFormatter.format(expectedAmount)}</p>
            )}
          </div>
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(CountCashModal)
