import styled, { css } from 'styled-components';

export const Container = styled.div`
  li {
    width: 316px;
    margin: 0 auto;
    > div {
      border-bottom: 1px solid #e9e9e9;

      &::first-child {
        border-bottom: none;
      }
    }
  }

  ${(props) =>
    props.centerContent &&
    css`
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const NoContent = styled.div`
  color: rgba(0,0,0,0.87);
  opacity: 0.5;
  font-size: 17px;
  font-family: Roboto;
`;

export const Row = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const ChartTitle = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
`;
