export default theme => ({
  formControl: {
    width: '100%'
  },
  buttonGroup: {
    marginBottom: '30px'
  },
  formContainer: {
    margin: '0px 35px'
  },
  discountDetailText: {
    fontSize: '12px'
  },
  amountContainer: {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(0,0,0,0.1)',
    border: '1px solid gray',
    borderRadius: '3px',
    textAlign: 'center',
    margin: '20px 0px',
  },
  changeContainer: {
    textAlign: 'right',
  },
  changeDueText: {
    fontSize: '20px'
  },
  errorText: {
    fontSize: '14px',
    color: 'red',
  },
})
