import * as validators from '../../../validators'
import {
  countryPhoneCodes,
  translations
} from '../../../config'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

export default [{
//   id: 'title',
//   field: 'Dropdown',
//   props: {
//     label: translations('Title'),
//     name: 'title',
//     options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
//     validate: [smallTextLengthValidator],
//     required: true
//   }
// }, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: translations('First Name'),
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: translations('Last Name'),
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'telephone',
  field: 'PhoneNumber',
  props: {
    label: translations('Phone Number'),
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: false
  }
}]
