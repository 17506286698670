import theme, { colors } from '../../config/theme'

const styles = {
  h1: {
    color: colors.text,
    fontFamily: theme.base.fontFamily,
    fontSize: theme.h1.fontSize,
    fontWeight: theme.h1.fontWeight
  }
}

export default theme => styles
