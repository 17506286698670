import React from 'react'

import { FormBody } from '../../../components/Form'
import schema from '../../../components/Form/commonSchemas/deliveryPersonalDetails'

const layout = [
  ['firstName'],
  ['lastName'],
  ['telephone']
]

const PersonalFields = props => {
  const { formId } = props
  return (
    <FormBody
      schema={schema}
      layout={layout}
      formId={formId}
      editing
      fullWidthFields
    />
  )
}

export const personalFieldNames = schema.map(({ id, props = {} }) => props.name || id)

export default PersonalFields
