import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { apiUpdateSource } from '../../../config'
import * as constants from './constants'

class AccessoryDetailsActions {
  getLatestCashCount = ({ paymentDeviceId }) => ({
    type: constants.FETCH_CASH_PAYMENT_DEVICE_COUNT,
    promise: () => digitalStoreSdk.cashPaymentDeviceCounts.getAll({
      paymentDeviceId: paymentDeviceId,
      limit: 1,
      includes: 'countedBy,signedOffBy',
      sort: '-createdAt'
    }).then(res => res.items[0] || null)
  })

  create = (fields) => ({
    type: constants.CREATE_CASH_PAYMENT_DEVICE_COUNT,
    promise: (dispatch, getState) => {
      const currentState = getState()
      const userId = authSelectors.getActiveUserId(currentState)

      return digitalStoreSdk.cashPaymentDeviceCounts.create({
        ...fields,
        countedById: userId,
        details: {},
        updateSource: apiUpdateSource
      })
    }
  })
  patch = ({ id, ...fields }) => ({
    type: constants.PATCH_CASH_PAYMENT_DEVICE_COUNT,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.cashPaymentDeviceCounts.patch({
        id,
        fields: {
          ...fields,
          updateSource: apiUpdateSource
        }
      }).then((result) => {
        return dispatch(this.getLatestCashCount({ paymentDeviceId: result.paymentDeviceId }))
      })
    }
  })
}

export default new AccessoryDetailsActions()
