import { connect } from 'react-redux'
import { formValueSelector, isValid } from 'redux-form'
import _ from 'lodash'
import ValueAddedServicesOrderModal, { formId } from './ValueAddedServicesOrderModal'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { selectors as checkoutFlowSelectors } from '../../store/modules/checkoutFlow'
import { selectors as currentOrderSelectors } from '../../store/modules/currentOrder'
import { selectors as authSelectors } from '../../store/modules/auth'

const valueSelector = formValueSelector(formId)

const getIsServiceAlreadySelected = (state, serviceId) => {
  const products = currentOrderSelectors.getCurrentOrderProducts(state)
  const service = _.find(products, p => p.id.startsWith(serviceId)) // special case for services because they are suffixed with the corresponding product ids

  return service
    ? true
    : false
}

const mapStateToProps = (state, props) => {
  const valueAddedServices = checkoutFlowSelectors.getValueAddedServices('ORDER_SERVICES')(state)
  const currencyCode = authSelectors.getCurrencyCode(state)
  const serviceId = valueSelector(state, 'serviceId')
  const isFormValid = isValid(formId)(state)
  const selectedService = _.find(valueAddedServices, product => product.id === serviceId)
  const isServiceAlreadySelected = getIsServiceAlreadySelected(state, serviceId)
  const options = valueAddedServices.map(product => {
    return { label: product.name, value: product.id }
  })

  return {
    options,
    selectedService,
    currencyCode,
    isFormValid,
    isServiceAlreadySelected,
  }
}

export default compose(
  connect(mapStateToProps),
  withState('error', 'setError', null),

  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    onSubmit: ({ selectedService, success }) => ({ serviceId, price, notes }) => {
      return success({
        selectedService,
        price,
        notes
      })
    },
  }),
)(ValueAddedServicesOrderModal)
