import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ProductsReducers {
  searchProductsFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchProductsFreshPending(state, action)
      case SUCCESS:
        return this.searchProductsFreshSuccess(state, action)
      case FAIL:
        return this.searchProductsFreshFail(state, action)
      default:
        return state
    }
  }

  searchProductsFreshPending = (state, action) => {
    const { query, sort, category } = action
    const mergeCategory = category === undefined
      ? {}
      : { category }
    const mergeSort = sort === undefined
      ? {}
      : { sort }
    const mergeQuery = query === undefined
      ? {}
      : { query }
    return {
      ...state,
      ...mergeSort,
      ...mergeQuery,
      ...mergeCategory,
      status: PENDING,
      action: action.type
    }
  }

  searchProductsFreshSuccess = (state, action) => {
    const { result } = action
    const { total, results, query, sort, category } = result
    return {
      ...state,
      total,
      page: 1,
      // this is so we can tell from the state what
      // query, sort & category our products results
      // correspond to. because state.products.category
      // can refer to a pending search rather than the
      // results actually in state (displaying in app)
      resultsMeta: {
        query,
        sort,
        category
      },
      results,
      status: SUCCESS,
      action: undefined
    }
  }

  searchProductsFreshFail = (state, action) => {
    return {
      ...state,
      error: action.error.message,
      status: FAIL,
      action: undefined
    }
  }

  searchProductsNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchProductsNextPending(state, action)
      case SUCCESS:
        return this.searchProductsNextSuccess(state, action)
      case FAIL:
        return this.searchProductsNextFail(state, action)
      default:
        return state
    }
  }

  searchProductsNextPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }

  searchProductsNextSuccess = (state, action) => {
    const { total, results = [], query } = action.result
    const { page, results: currentResults, query: currentQuery } = state
    if (query === currentQuery) {
      const newResults = results.groups
        ? { groups: [...currentResults.groups, ...results.groups] }
        : [...currentResults, ...results]
      return {
        ...state,
        total,
        page: page + 1,
        results: newResults,
        status: SUCCESS,
        action: undefined
      }
    } else {
      return state
    }
  }

  searchProductsNextFail = (state, action) => {
    return {
      ...state,
      status: SUCCESS,
      action: undefined
    }
  }
}

export default new ProductsReducers()
