import { PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import _ from 'lodash'

import passwordPolicy from '../passwordPolicy'
import { dateFormat, timeFormat, checkoutBackdateLimit, translations } from '../config'
import currencyFormatter from '../formatters/currencyFormatter'

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? translations('Invalid email address') : undefined
}

export const password = (value) => {
  const validatePassword = passwordPolicy.validatePassword(value)
  if (validatePassword instanceof Error) {
    return validatePassword.message
  }
}

export const time = (value) => {
  if (value && !moment(value, timeFormat, true).isValid()) {
    return translations('Invalid time')
  }
}

export const timeRequired = (value) => {
  if (!moment(value, timeFormat, true).isValid()) {
    return translations('Invalid time')
  }
}

export const dateTime = (value) => {
  if (value && !moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Invalid date time')
  }
}
export const dateTimeRequired = (value) => {
  if (!moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Invalid date time')
  }
}

export const date = (value) => {
  if (value && !moment(value, dateFormat, true).isValid()) {
    return translations('Invalid date')
  }
}

export const checkoutDate = (value) => {
  if (value) {
    const formattedDate = moment(value, 'DD/MM/YYYY')
    if (formattedDate.isAfter(moment(),'day')) {
      return translations('Date cannot be in the future')
    }
    if (formattedDate.isBefore(moment(), 'day')) {
      return translations('Date cannot be in the past')
    }
    if (checkoutBackdateLimit) {
      const { value, unit } = checkoutBackdateLimit
      const maxPastDate = moment().subtract(value, unit)
      if (formattedDate.isBefore(maxPastDate)) {
        return translations('Order Date Change Checkout Error', { value, unit })
      }
    }
  }
}

export const dobMinAge = (minAge) => value => {
  const minDob = moment().subtract(minAge, 'years')
  if (moment(value, dateFormat).isAfter(minDob, 'day')) {
    return _.template(translations('Minimum age is minAge'))({ minAge })
  }
}

export const dateRequired = (value) => {
  if (!moment(value, dateFormat, true).isValid()) {
    return translations('Invalid date')
  }
}

export const passwordConfirmation = (value, form) => {
  const confirmationPassword = form.password
  if (confirmationPassword !== value) {
    return translations('Passwords do not match')
  }
}

export const required = (value) => {
  return _.isNil(value) ? translations('Field is required') : undefined
}

export const isTruthy = (value) => {
  return !value ? translations('Field is required') : undefined
}

export const min = (threshold, currencyCode = undefined) => value => {
  return value < threshold
    ? translations(`Field must be at least ${currencyCode
      ? currencyFormatter.format({ code: currencyCode, value: threshold })
      : threshold}`)
    : undefined
}

export const refundAmount = (threshold, currencyCode = undefined) => value => {
  return Number(value) !== threshold
    ? translations(`Field must be ${currencyCode
      ? currencyFormatter.format({ code: currencyCode, value: threshold })
      : threshold}`)
    : undefined
}

export const money = (value) => {
  const moneyRegex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
  return !moneyRegex.test(value) ? translations('Invalid amount') : undefined
}

export const requiredWithFieldName = (fieldName) => (value) => {
  return !value ? _.template(translations('fieldName is required'))({ fieldName }) : undefined
}

export const maxLength = (max) => value => {
  return value && value.length > max ? translations(`Must be ${max} characters maximum`) : undefined
}

export const minArrayLength = (min = 0) => value => {
  return !_.isArray(value) || value.length < min  ? translations(`Must have at least ${min} item`) : undefined
}

export const phoneNumber = value => {
  if (value) {
    // starts with +44
    // check the next character is not a 0
    // could use String.startsWith but IE support is bad :(
    const ukCode = '+44'
    // because of the space
    if ((value.substring(0, ukCode.length) === ukCode) && (value[ukCode.length] === '0' || value[ukCode.length + 1] === '0')) {
      return translations('UK phone numbers cannot begin with 0')
    }
    try {
      PhoneNumberUtil.getInstance().parse(value)
    } catch (e) {
      return translations('Invalid phone number')
    }
  }
}

export const url = value => {
  try {
    value && new window.URL(value)
  } catch (e) {
    return translations('Invalid - please ensure https is included at the start of your URL')
  }
}

export const storeRequired = value => {
  const errorText = translations('Boutique is required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value ? errorText : undefined
  }
}

export const departmentRequired = value => {
  const errorText = translations('Department is required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value ? errorText : undefined
  }
}

export const brandRequired = value => {
  const errorText = translations('Brand is required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value || _.isEmpty(value) ? errorText : undefined
  }
}

export const ean = value => {
  const errorText = translations('Invalid EAN')
  // if (value && !!parseInt(value) && value.length >= 12 && value.length <= 13) {
  if (!_.isNil(value) && !/\s/g.test(value.toString())) {
    return undefined
  } else {
    return errorText
  }
}

export const generateSchemaValidationFromConfig = (validateObj) => {
  return _.map(validateObj, (value, key) => {
    switch (key) {
      case 'maxLength':
        return maxLength(value)
      default:
        return null
    }
  })
}

export const validateRefundReason = (products) => {
  if (!Array.isArray(products)) return;

  let isValid = true;
  const productsToRefund = products.filter((product) => product.refund);
  for (const item of productsToRefund) {
    if (
      !item.returnReasonCode ||
      (item.returnReasonCode === 'WARRANTY' &&
        (!item.defectCode || !item.defectCodeCategory)) ||
      !item.isInSalableCondition
    ) {
      isValid = false
    }
  }

  return isValid;
}
