import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getImage } from '../Images'
import styled from 'styled-components'

const InputStepperWrapper = styled.div`
  text-align: center;
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
  height: 40px;
`

const InputStepperButtonWrapper = styled.div`
  box-sizing: border-box;
  height: 40px;
  width: 150px;
  border: 1px solid #979797;
  display: inline-block;
`

const InputStepperButton = styled.div`
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  border: 1px solid #979797;
  margin-top: -1px;
  &:hover { 
    cursor: pointer; 
  }
`

const ButtonImg = styled.img`
  transform: translateY(50%);
`

const TextDisplay = styled.div`
  display: inline-block;
  transform: translateY(50%);
  margin-right: 1.5em;
  @media (max-width: 360px) {
    width: min-content;
  }
`

const InputValue = styled.div`
  display: inline-block;
  transform: translateY(50%);
  margin-left: 0.5em;
`

const Input = styled.input`
  ::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
  }
  width: 50px;
  text-align: center;
  border: none;
  outline: none;
`

const InputStepper = ({
  id,
  text,
  min,
  max,
  onChange,
  value = 0,
  enableFloat = false
}) => {
  const [counter, updateCounter] = useState(value)
  const set = (newCounter) => {
    const counter = Math.min(Math.max(newCounter, min), max)
    id !== undefined ? onChange({ id, counter }) : onChange(counter)
    updateCounter(counter)
  }

  const renderCounter = ()=>{
    return enableFloat ? counter.toString() || 0
      : counter.toString().replace(/\D|^0+/g, '') || 0
  }

  const onCounterChange = (e) => {
    const parseMethod = enableFloat ? parseFloat : parseInt
    set(parseMethod(e.target.value || 0))
  }

  return (
    <InputStepperWrapper>
      {text && <TextDisplay>{text}</TextDisplay>}
      <InputStepperButtonWrapper>
        <InputStepperButton
          style={{ float: 'left', marginLeft: '-1px' }}
          onClick={() => set(counter - 1)}
        >
          <ButtonImg src={getImage('minusIcon')} />
        </InputStepperButton>
        <InputValue>
          <Input
            type="number"
            value={renderCounter()}
            onChange={(e) => onCounterChange(e) }
          />
        </InputValue>
        <InputStepperButton
          style={{ float: 'right', marginRight: '-1px' }}
          onClick={() => set(counter + 1)}
        >
          <ButtonImg src={getImage('plusIcon')}/>
        </InputStepperButton>
      </InputStepperButtonWrapper>
    </InputStepperWrapper>)
}

InputStepper.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onSubmit: PropTypes.func,
  enableFloat: PropTypes.bool
}

InputStepper.defaultProps = {
  id: undefined,
  text: null,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  enableFloat: false
}

export default InputStepper
