const passwordPolicy = {
  REQUIRED: {
    rule: 8,
    message: 'Field is required'
  },
  MIN_LENGTH: {
    rule: 8,
    message: 'Password must be at least 8 characters long'
  },
  MAX_LENGTH: {
    rule: 15,
    message: 'Password must be no more than 15 characters long'
  },
  UPPERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 uppercase letter'
  },
  LOWERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 lowercase letter'
  },
  NUMBER_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 number'
  },
// SPECIAL_CHAR_MIN_COUNT: {
//   rule: 1,
//   message: 'Password must at least 1 special character',
// },
// NON_REPEATING: {
//   message: 'Password must have no repeating characters',
// },
}

export default passwordPolicy
