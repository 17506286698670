import React, { useState } from 'react'
import Form from '../../Form'
import { translations } from '../../../config'
import BasicModalOverlay from '../../BasicModalOverlay'
import InputWithScanButton from '../../InputWithScanButton'
import toastService from '../../../services/toastService'

const FormWrapper = Form('ScanGiftCardForm')

export const ScanGiftCardModal = ({ onClick, onCancel }) => {
  const [giftCardNumber, setGiftCardNumber] = useState(null)

  const actions = [
    {
      text: translations('Cancel'),
      onClick: () => {
        onCancel()
      }
    },
    {
      disabled: !giftCardNumber,
      text: translations('Go'),
      primary: true,
      onClick: () => {
        onClick(giftCardNumber)
      }
    }
  ]

  const handleInputChange = ({ target: { value } }) => {
    setGiftCardNumber(value)
  }

  return (
    <BasicModalOverlay
      title={translations('Please enter gift card number')}
      actions={actions}
    >
      <FormWrapper>
        <InputWithScanButton
          autoFocus
          type='number'
          value={giftCardNumber}
          onChange={handleInputChange}
          onSuccess={(result) => { onClick(result) }}
          onError={() => {
            toastService.action({
              type: 'error',
              message: translations('Failed to read barcode, please try again or enter manually'),
              verticalPosition: 'top',
              horizontalPosition: 'right'
            })
          }}
          onCancel={() => {
            toastService.action({
              type: 'error',
              message: translations('Scanning cancelled'),
              verticalPosition: 'top',
              horizontalPosition: 'right'
            })
          }}
        />
      </FormWrapper>
    </BasicModalOverlay>
  )
}

export default ScanGiftCardModal
