import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { salesforceOCAPI } from '../../../config'

class CheckoutFlowActions {
  next = () => ({
    type: constants.NEXT
  })

  start = ({ checkoutType }) => ({
    type: constants.START,
    checkoutType
  })

  end = () => ({
    type: constants.END
  })

  back = () => ({
    type: constants.BACK
  })

  clear = () => ({
    type: constants.CLEAR
  })

  fail = ({ code, errorMessage }) => ({
    type: constants.FAIL,
    code,
    errorMessage
  })

  setCurrent = (module) => ({
    type: constants.SET_CURRENT,
    module
  })

  setLoading = (isLoading) => ({
    type: constants.SET_LOADING,
    isLoading
  })

  setOrderNumber = (orderNumber) => ({
    type: constants.SET_ORDER_NUMBER,
    orderNumber
  })

  setDeferredModule = module => ({
    type: constants.SET_DEFERRED_MODULE,
    module
  })

  setOrderId = (orderId) => ({
    type: constants.SET_ORDER_ID,
    orderId
  })

  fetchDeliveryOptions = (basketId) => ({
    type: constants.FETCH_DELIVERY_OPTIONS,
    promise: () => basketId && salesforceOCAPI.basketEnabled
      ? digitalStoreSdk.baskets.fetchBasketShippingMethods({ id: basketId })
      : digitalStoreSdk.deliveryOptions.fetchDeliveryOptions()
  })

  fetchValueAddedServices = () => ({
    type: constants.FETCH_VALUE_ADDED_SERVICES,
    promise: async (dispatch, getState) => {
      const currentState = getState()
      const region = currentState.region
      const catalogue = currentState.catalogue
      const results = await digitalStoreSdk.products.fetchProducts({
        // region,
        // catalogue,
        offset: 0,
        limit: 100,
        sort: '-updatedAt',
        includes: ['variants', 'category'],
        service: true
      })

      return results
    }
  })

  setPreviousPath = (previousPath) => ({
    type: constants.SET_PREVIOUS_PATH,
    previousPath
  })
}

export default new CheckoutFlowActions()
