import React, { useState } from 'react'
import { translations } from '../../config'
import BasicModalOverlay from '../BasicModalOverlay'
import LoaderContainer from '../LoaderContainer'
import ActivationModal from './ActivationModal'
import sdk from '../../digitalStoreSdk'
import modalService from '../../services/modalService';

const ActivateGiftCardModal = ({
  giftCards,
  adyenTerminals,
  paymentDevices,
  orderNumber,
  onComplete
}) => {
  const [giftCardResponse, setGiftCardResponse] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isLoading, setIsLoading] = useState()
  const [hasError, setHasError] = useState(false)

  const handleMoveToNextGiftCard = (response) => {
    setIsLoading(true)
    if (response && response.status !== 'Success') {
      setHasError(true)
    }
    const _giftCardResponse = [...giftCardResponse, response]
    setGiftCardResponse(_giftCardResponse)
    setCurrentIndex(currentIndex + 1)

    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }

  const handleUpdateOrder = async () => {
    await sdk.orders.updateOrder({
      id: orderNumber,
      details: { giftCardResponse: giftCardResponse },
      updateSource: 'RetailOS'
    })
    onComplete()
  }

  const handleCancel = async () => {
    await sdk.orders.updateOrder({
      id: orderNumber,
      details: { giftCardResponse: giftCardResponse },
      updateSource: 'RetailOS'
    })
    await modalService.close()
  }

  if (Array.isArray(giftCards) && currentIndex < giftCards.length) {
    return (
      <LoaderContainer isLoading={isLoading}>
        <ActivationModal
          giftCardToActivate={giftCards[currentIndex]}
          adyenTerminals={adyenTerminals}
          paymentDevices={paymentDevices}
          orderNumber={orderNumber}
          onCancel={handleMoveToNextGiftCard}
          onClick={handleMoveToNextGiftCard}
        />
      </LoaderContainer>
    )
  }

  return (
    <BasicModalOverlay
      actions={[!hasError
        ? {
          primary: true,
          text: translations('Complete Order'),
          onClick: () => {
            handleUpdateOrder()
          }
        } : {
          primary: true,
          text: translations('Cancel'),
          onClick: () => {
            handleCancel()
          }
        }
      ]}
    >
      {!hasError
        ? translations('All gift cards are activated')
        : translations('Fail to activate one or more gift cards. Please go to Order Management to refund the consumer for the gift cards.')}
    </BasicModalOverlay>
  )
}

export default ActivateGiftCardModal
