import { createSelector } from 'reselect'
import _ from 'lodash'
import * as constants from './constants'
import { PENDING } from '../../middleware/redux-promise'

export const getCurrentRecipients = state => _.get(state.createMessage, 'recipients', [])
export const getMessageDetails = state => state.createMessage.message || {}
export const getMessageTemplates = state => state.createMessage.templates.items || []
export const getMessageTemplateDropdownOptions = state => _.chain(state)
  .get('createMessage.templates.items', [])
  .map(tpl => ({ label: tpl.name, value: tpl.id }))
  .value()
export const getPreviousPath = state => state.createMessage.previousPath
export const getMessageTemplatesAreLoading = state => {
  return state.createMessage.state === 'PENDING'
}
export const getIsMessageSending = state => {
  const messageDetailsAction = _.get(state, 'createMessage.action')
  const messageDetailsStatus = _.get(state, 'createMessage.status')
  return messageDetailsAction === constants.SEND_MESSAGE && messageDetailsStatus === PENDING
}

export const getSingleRecipientMarketingChannels = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.details.customerMarketingChannels')
      ? _.get(recipients, '0.details.customerMarketingChannels', [])
      : _.get(recipients, '0.customerMarketingChannels', [])
  }
  return []
})

export const getSingleRecipientMarketingPreferences = createSelector([
  getCurrentRecipients
], recipients => {
  if (recipients.length) {
    return _.get(recipients, '0.details.customerMarketingPreferences')
      ? _.get(recipients, '0.details.customerMarketingPreferences', [])
      : _.get(recipients, '0.customerMarketingPreferences', [])
  }
  return []
})

export const getMessageCommunicationType = createSelector([
  getMessageDetails
], details => {
  return _.get(details, 'communicationType')
})

export const getMessageContent = createSelector([
  getMessageDetails
], message => {
  return _.get(message, 'content', [])
})
