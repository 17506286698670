import React from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../../store/modules/promotions'
import { translations } from '../../../config'
import { get } from 'lodash'
import LoaderContainer from '../../../components/LoaderContainer'
import PromotionConditionList from './PromotionConditionList'
import BackBar from '../../../components/BackBar/BackBar'
import SubHeader from '../../../components/SubHeader'
import H2 from '../../../components/H2'

import {
  CardContainer,
  Header,
  Row,
  Column,
  ColumnHeader,
  ColumnContent,
  ContentContainer,
  Divider
} from './PromotionDetails.styles'
import { transitions } from 'polished'
import { goBack } from 'connected-react-router'

const PromotionDetails = ({ match }) => {
  const promotionId = match.params.id
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const promotions = selectors.getAllPromotions(state)
  const isLoading = selectors.getIsPromotionLoading(state)

  const getPromotionData = () => {
    if (Array.isArray(promotions)) {
      const promotion = promotions.find((item) => item.id === promotionId)
      const _startDate = moment(promotion.startDate).format('DD/MM/YYYY')
      const _endDate = moment(promotion.endDate).format('DD/MM/YYYY')

      const promotionMapped = [
        [
          { label: 'ID', value: promotion.externalPromotionId },
          { label: translations('Description'), value: promotion.description }
        ],
        [
          {
            label: translations('Promotion Group'),
            value: promotion.promotionGroup
          },
          {
            label: translations('Promotion Group Priority'),
            value: promotion.promotionGroupPriority
          }
        ],
        [
          { label: translations('Start Date'), value: _startDate },
          { label: translations('End Date'), value: _endDate }
        ],
        [
          {
            label: translations('Enabled'),
            value: promotion.enabled
              ? translations('True')
              : transitions('False')
          },
          {
            label: translations('Discount Type'),
            value: promotion.discountType
          }
        ],
        [
          {
            label: translations('Promotion Type'),
            value: translations('Promotion')
          },
          {
            label: translations('Discount Value'),
            value: promotion.discountValue
          }
        ],
        [
          {
            label: translations('Extra Product Variants'),
            value: get(promotion, 'extraProductVariants', []).length
              ? promotion.extraProductVariants
                  .map((item) => item.name)
                  .join(', ')
              : 'N/A'
          },
          { label: translations('Apply To'), value: promotion.applyTo }
        ]
      ]

      return (
        <>
          <CardContainer>
            <Header>{translations('Conditions')}</Header>
            <PromotionConditionList
              promotionConditions={get(promotion,'promotionConditions', [])}
            />
          </CardContainer>
          <CardContainer>
            <Header>Promotion</Header>
            <ContentContainer>
              {promotionMapped.map((row, index) => (
                <Row key={index}>
                  {row.map((col, _index) => (
                    <Column key={`${col.label}-${col.value}-${_index}`}>
                      <ColumnHeader>{col.label}</ColumnHeader>
                      <ColumnContent>{col.value}</ColumnContent>
                    </Column>
                  ))}
                </Row>
              ))}
            </ContentContainer>
          </CardContainer>
        </>
      )
    }
  }

  return (
    <LoaderContainer isLoading={isLoading}>
      <SubHeader
        leftContent={<BackBar onClick={() => dispatch(goBack())} />}
        centerContent={<H2 value={translations('Promotion Details')} />}
      />
      {getPromotionData()}
    </LoaderContainer>
  )
}

export default PromotionDetails
