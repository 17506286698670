import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  lastSeq: null,
  offlineOrderCount: 0,
}

export default (state = defaultState, action) => {
  const newState = {
    ...defaultState,
    ...state
  }

  switch (action.type) {
    case constants.SYNC:
      return reducers.sync(newState, action)
    case constants.SET_OFFLINE_ORDER_COUNT:
      return reducers.setOfflineOrderCount(newState, action)
    default:
      return state
  }
}
