import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import qs from 'qs'
import { history } from '../../../../store'

import OrderHeader from './OrderHeader'
import OrderProductContent from './OrderProductContent'
import OrderActions from './OrderActions'
import OrderProducts from './OrderProducts.new'
import OrderHeaderNew from './OrderHeader.new'
import OrderNotes from './OrderNotes'
import OrderFooter from './OrderFooter'

import Heading from '../../../../components/Heading'
import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import Container from '../../../../components/Container'
import Form from '../../../../components/Form'
import PaymentCollapsible from '../../../../components/PaymentDevices/PaymentCollapsible'
import GiftCardActivationCollapsible from '../../../../components/PaymentDevices/GiftCardActivationCollapsible'

import FullScreenProgress from '../../../../components/FullScreenLoader'

import {
  translations,
  orderNotesEnabled,
  newOrderDetailsScreenEnabled,
  getAppConfigForRoute,
  kanban as defaultKanbanConfig,
  salesforceOCAPI
} from '../../../../config'

import style from './style'
import formId from './formId'

const OrderForm = Form(formId)

const refundStatusArray = ['awaiting_refund', 'partial_refund', 'full_refund']

const OrderProductsComp = newOrderDetailsScreenEnabled
  ? OrderProducts
  : OrderProductContent

const OrderHeaderComp = newOrderDetailsScreenEnabled
  ? OrderHeaderNew
  : OrderHeader

const OrderDetail = (props) => {
  const { order, failedToLoad, isPartialRefundOrder, classes, goToOrders } = props

  if (!order) {
    return (
      <div className={classes.orderNotFound}>
        <Heading component={'h1'} uppercase>
          {translations('Order not found')}
        </Heading>
      </div>
    )
  }

  // use the 'actionsFor' param to determine if standard order actions should be replaced for an order
  // based on where the user came from. Used by kanban boards
  const params = qs.parse(history.location.search.slice(1))
  const actionsFor = params.actionsFor

  // Get the app config for the route and iterate through config.actions to create each button
  const config = actionsFor
    ? { ...defaultKanbanConfig, ...getAppConfigForRoute(actionsFor) }
    : {}

  const hasRefunds = _.some(order.payments, payment => _.get(payment, 'isRefund', false))
  const giftCardActivations = _.get(order, `details.giftCardResponse`, []).filter(response =>
    response.status === 'Success' &&
      _.get(response, 'SaleToPOIResponse.StoredValueResponse.StoredValueResult.[0].StoredValueTransactionType', '') === 'Activate')
  const hasGiftCardActivations = giftCardActivations.length > 0

  return (
    <OrderForm onSubmit={_.noop}>
      <SubHeader
        leftContent={<BackBar onClick={goToOrders} />}
        centerContent={
          <Heading component={'h1'} uppercase>
            {translations('Order Details')}
          </Heading>
        }
      />
      {failedToLoad ? (
        <Container
          className={classes.errorMessage}
          withMarginTop={!newOrderDetailsScreenEnabled}
        >
          <Heading component={'h2'} uppercase>
            {translations('Load Order Error')}
          </Heading>
        </Container>
      ) : (
        <Container
          withMarginTop={!newOrderDetailsScreenEnabled}
          withMarginBottom={!newOrderDetailsScreenEnabled}
        >
          <OrderHeaderComp {...order} />
          <OrderProductsComp
            refund={isPartialRefundOrder}
            products={order.groupedProducts}
            order={order}
            config={config}
          />
          {orderNotesEnabled ? <OrderNotes order={order} /> : null}
          {newOrderDetailsScreenEnabled ? <OrderFooter /> : null}
          <div className={classes.paymentsContainer}>
            <PaymentCollapsible
              defaultOpen
              payments={ _.filter(order.payments, (payment) => !payment.isRefund)}
            />
          </div>
          {hasRefunds &&
                <div className={classes.paymentsContainer}>
                  <PaymentCollapsible
                    title={translations('Refunds')}
                    defaultOpen
                    payments={ _.filter(order.payments, (payment) => payment.isRefund)}
                  />
                </div>}

          {hasGiftCardActivations &&
                <div className={classes.paymentsContainer}>
                  <GiftCardActivationCollapsible
                    title={translations('Gift Card Activations')}
                    defaultOpen
                    giftCardResponses ={ giftCardActivations}
                  />
                </div>}

          {!salesforceOCAPI.basketEnabled && <OrderActions config={config} />}
        </Container>
      )}
    </OrderForm>
  )
}

const LoaderOrderDetail = FullScreenProgress(OrderDetail)

export default withStyles(style)(LoaderOrderDetail)
