import { push, LOCATION_CHANGE, go } from 'connected-react-router'
import _ from 'lodash'

import modalService from '../../../services/modalService'
import { translations } from '../../../config'

import * as messageConstants from './constants'
import { history } from '../.././../store'
import { matchPath } from 'react-router'
import { actions as createMessageActions, selectors as createMessageSelectors } from './index'
import { actions as storeDetailsActions } from '../storeDetails'
import analyticsService from '../../../services/analyticsService'
import { selectors as authSelectors } from '../auth'

class CreateMessageMiddleware {
  getRecipientMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === messageConstants.UPDATE_RECIPIENT) {
      const previousPath = history.location.pathname
      if (!(previousPath.indexOf('/messages/new') === 0)) {
        dispatch(createMessageActions.setPreviousPath(previousPath))
        dispatch(push(`/messages/new`))
      }
    }
    next(action)
  }

  addProductToMessageMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === messageConstants.ADD_CONTENT_TO_MESSAGE) {
      dispatch(push('/messages/new'))
    }
    next(action)
  }

  navigateToMessageMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const messageResult = matchPath(action.payload.location.pathname, { path: '/messages/new', exact: false })
      const recipients = createMessageSelectors.getCurrentRecipients(getState())
      const messageContent = createMessageSelectors.getMessageContent(getState())
      if (messageResult) {
        const storeId = authSelectors.getUserSelectedStoreId(getState())

        dispatch(createMessageActions.fetchMessageTemplates())
        dispatch(storeDetailsActions.fetchStore({ id: storeId }))
      }
      // clear the store when navigating away
      // and recipients have a length of greater than 0
      if (!messageResult && (recipients.length || messageContent.length)) {
        dispatch(createMessageActions.resetStore())
      }
    }
  }

  handleActions = message => {
    const communicationType = _.get(message, 'communicationType')
    const products = _.get(message, 'details.products', [])
    const templateName = _.get(message, 'details.template.name')
    analyticsService.sendCustomEvent({ type: 'sendMessage', communicationType, productCount: products.length, templateName })
  }

  sendMessageMiddleware = ({ dispatch, getState }) => next => (action) => {
    const isMessageSentSuccess = action.type === messageConstants.SEND_MESSAGE && action.status === 'SUCCESS'
    const isMessageSentFailed = action.type === messageConstants.SEND_MESSAGE && action.status === 'FAIL'
    const state = getState()
    const message = createMessageSelectors.getMessageDetails(state)
    if (isMessageSentSuccess) {
      const redirect = () => dispatch(go(-1))
      modalService.continue({
        success: redirect,
        title: translations('Communication Send Success Modal Title'),
        text: translations('Communication Send Success Modal Text'),
        confirmButtonText: translations('Continue')
      })
      this.handleActions(message)
      return next(action)
    }
    if (isMessageSentFailed) {
      const retryToSendMessage = () => {
        const recipients = createMessageSelectors.getCurrentRecipients(state)
        const storeId = authSelectors.getUserSelectedStoreId(state)
        const data = {
          message,
          recipients,
          storeId
        }
        dispatch(createMessageActions.sendMessage(data))
      }
      const modalText = (
        action.error && action.error.code && action.error.code === 'RA-24-07'
          ? translations('Communication Send Fail Modal Text Customer Opt-Out')
          : translations('Communication Send Fail Modal Text')
      )
      modalService.action({
        title: translations('Communication Send Fail Modal Title'),
        text: modalText,
        actions: [
          {
            success: true,
            text: translations('Communication Send Fail Modal Retry Button'),
            onClick: retryToSendMessage,
            primary: true
          },
          {
            text: translations('Close'),
            primary: true
          }
        ]
      })
      return next(action)
    }
    next(action)
  }
}

export default new CreateMessageMiddleware()
