import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import InfiniteScroll from 'react-infinite-scroller'

import { translations } from '../../config'

import { getImage } from '../Images'

import FullScreenProgress from '../FullScreenLoader'
import { Table, TableHeader, TableRow, TableCell } from '../Table'

import style from './style'

const trashIcon = getImage('trashIcon')

class Waitlist extends PureComponent {
  renderTableRows () {
    const { waitlist = [], classes, onClickDelete, onClickSendMessage } = this.props

    return waitlist.map((waitlistItem, index) => {
      const { id, customer, createdAt, product } = waitlistItem
      return (
        <TableRow key={id}>
          <TableCell xs={5} sm={5}>
            <div
              className={classes.nameContainer}
            >
              <div
                className={classes.nameWrapper}
              >
                {`${customer.firstName} ${customer.lastName}`}
              </div>
            </div>
          </TableCell>
          <TableCell xs={4} sm={4}>{moment(createdAt).format('DD/MM/YYYY')}</TableCell>
          <TableCell xs={3} sm={3} justify={'flex-end'} direction={'row'}>
              <IconButton
                aria-label='Remove from waitlist'
                onClick={() => onClickDelete(id)}
              >
                <img alt="" src={trashIcon} className={classes.trashIconImg} />
              </IconButton>
              <IconButton
                aria-label='Send message'
                onClick={() => onClickSendMessage({customer, product})}
                className={classes.messageIconButton}
              >
                <img alt="" src={getImage('messageDarkIcon')} className={classes.messageIcon} />
              </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }
  _renderTable () {
    return (
      <Table
        header={(
          <TableHeader>
            <TableCell xs={5} sm={5}>{translations('Customer Name')}</TableCell>
            <TableCell xs={4} sm={4}>{translations('Added')}</TableCell>
          </TableHeader>
        )}
        rows={this.renderTableRows()}
        emptyMessage={translations('No results found')}
      />
    )
  }
  render () {
    const { classes, loadMore, hasMore } = this.props
    return (
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        initialLoad={false}
      >
        {this._renderTable()}
      </InfiniteScroll>
    )
  }
}

Waitlist.propTypes = {
  getProductWaitlist: PropTypes.func.isRequired
}

export default FullScreenProgress(
  withStyles(style)(Waitlist)
)
