import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { apiUpdateSource } from '../../../config'
import * as constants from './constants'

class AccessoryDetailsActions {
  fetchPaymentDevice = ({ id, ...query }) => ({
    type: constants.FETCH_PAYMENT_DEVICE,
    promise: () => digitalStoreSdk.paymentDevices.fetchPaymentDevice({ id, ...query })
  })
  createPaymentDevice = (fields) => ({
    type: constants.CREATE_PAYMENT_DEVICE,
    promise: (dispatch, getState) => {
      const currentState = getState()
      const storeId = authSelectors.getUserSelectedStoreId(currentState)

      return digitalStoreSdk.paymentDevices.createPaymentDevice({
        ...fields,
        storeId,
        enabled: true,
        updateSource: apiUpdateSource
      })
    }
  })
  updatePaymentDevice = ({ id, fields }) => ({
    type: constants.UPDATE_PAYMENT_DEVICE,
    promise: () => digitalStoreSdk.paymentDevices.patchPaymentDevice({
      id,
      fields: { ...fields, updateSource: apiUpdateSource
    }})
  })
  clear = () => ({
    type: constants.CLEAR
  })
}

export default new AccessoryDetailsActions()
