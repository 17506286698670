import _ from 'lodash'
import { selectors as authSelectors } from '../auth'

export const getIsLoading = (state) =>
  state.checkoutFlow.isLoading || state.checkoutFlow.status === 'PENDING'
export const getAll = (state) => ({
  ...state.checkoutFlow,
  isLoading: getIsLoading(state)
})
export const getDeliveryOptions = (state) => _.get(state, 'checkoutFlow.deliveryOptions') || []
export const getIsInOrderSummary = state => _.get(state, 'checkoutFlow.current') === 'ORDER_SUMMARY'
export const getCurrentStep = state => _.get(state, 'checkoutFlow.current')
export const getCheckoutFlowType = state => _.get(state, 'checkoutFlow.checkoutType', '')
export const getOrderNumber = state => _.get(state, 'checkoutFlow.orderNumber')
export const getOrderId = state => _.get(state, 'checkoutFlow.orderId')
export const getValueAddedServices = categoryId => state => _.chain(state)
  .get('checkoutFlow.valueAddedServices.items')
  .filter(({ category, catalogue }) => category.externalCategoryId === categoryId && catalogue === authSelectors.getUserSelectedStoreCatalogue(state))
  .value()
