import styled from 'styled-components'

export const NoResults = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 19px;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`
export const Container = styled.div`
  margin-top: 20px;
`;