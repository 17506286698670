import { INITIAL } from '../../middleware/redux-promise'
import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  filters: {},
  total: null,
  results: [],
  status: INITIAL,
  action: null,
  error: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_FILTERS:
      return reducers.setFilters(state, action)
    case constants.FETCH_ALL_FRESH:
      return reducers.fetchAllFresh(state, action)
    case constants.FETCH_ALL_NEXT:
      return reducers.fetchAllNext(state, action)
    default:
      return state
  }
}
