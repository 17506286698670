import React, { useState } from 'react';
import { FormControl, withStyles } from '@material-ui/core';

import { history } from '../../../../store';
import { translations } from '../../../../config';
import { Input } from '../../../../components/Fields';
import Button from '../../../../components/Button/Button';
import Form from '../../../../components/Form';
import style from './style';

const PromoCodeForm = Form('promo-code');

const PromoCodeSection = ({
  promoCode = '',
  classes,
  setPromoCode,
}) => {
  const [_promoCode, _setPromoCode] = useState(promoCode);

  const handleChange = (event) => {
    _setPromoCode(event.target.value);
  };

  const handleApplyPromoCode = () => {
    setPromoCode(_promoCode);
  };

  if (history.location.pathname === '/checkout/delivery') {
    return (
      <div className={classes.promoCodeContainer}>
        <PromoCodeForm>
          <div className={classes.promoCodeInnerContainer}>
            <FormControl className={classes.promoCodeInputContainer}>
              <Input
                label={translations('Promo code')}
                className={classes.promoCodeInput}
                value={_promoCode}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              className={classes.promoCodeButton}
              disabled={!_promoCode}
              onClick={handleApplyPromoCode}
            >
              {translations('Apply')}
            </Button>
          </div>
        </PromoCodeForm>
      </div>
    );
  }

  return null;
};

export default withStyles(style)(PromoCodeSection);
