import { connect } from 'react-redux'
import {
  actions as currentOrderActions,
  selectors as currentOrderSelectors,
} from '../../../store/modules/currentOrder'
import { selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { actions as checkoutFlowActions, selectors as checkoutFlowSelectors } from '../../../store/modules/checkoutFlow'

import BasketItem from './BasketItem'
import { compose, withHandlers, withProps } from 'recompose'
import modalService from '../../../services/modalService'
import ValueAddedServicesModal from '../../../components/ValueAddedServicesModal'
import * as currentOrderCombinedSelectors from "../../../store/modules/combinedSelectors/currentOrderCombinedSelectors";

const mapStateToProps = (state, props) => {
  const discountType = currentOrderSelectors.getDiscountType(state)
  const currencyCode = authSelectors.getCurrencyCode(state)
  let hasRefundPermission = authSelectors.getHasRefund(state)
  const consultationMode = !!currentAppointmentSelectors.getAppointmentCustomerId(state)
  const serviceForProduct = currentOrderSelectors.getProductForService(props.serviceForProductId)(state)
  const isInOrderSummary = checkoutFlowSelectors.getIsInOrderSummary(state)
  const basketType = currentOrderCombinedSelectors.getBasketType(state)
  hasRefundPermission = hasRefundPermission && basketType !== 'vintage purchase'
  return {
    currencyCode,
    discountType,
    isInOrderSummary,
    hasRefundPermission,
    consultationMode,
    serviceForProduct
  }
}
const mapDispatchToProps = dispatch => ({
  changeQuantity: params => dispatch(currentOrderActions.changeProductQuantity(params)),
  onChangeProductField: params => dispatch(currentOrderActions.onChangeProductField(params)),
  removeProduct: params => dispatch(currentOrderActions.removeProduct({ ...params })),
  addProduct: params => dispatch(currentOrderActions.addProduct({ ...params })),
  leaveCheckout: () => dispatch(checkoutFlowActions.end()),
  addManualDiscount: params => dispatch(currentOrderActions.addManualDiscountToProduct(params)),
  changeProductPrice: params => dispatch(currentOrderActions.changeProductPrice(params)),
  removeManualOrderDiscount: () => dispatch(currentOrderActions.removeManualDiscountFromOrder()),
  removeManualItemDiscount: params =>
    dispatch(currentOrderActions.removeManualDiscountFromProduct(params)),
  toggleRefundItem: params => dispatch(currentOrderActions.toggleRefundItem(params)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ itemIndex, selectedLines = {} }) => {
    return { checked: selectedLines[itemIndex] === true }
  }),
  withHandlers({
    toggleChecked: ({ checked, addSelection, removeSelection, itemIndex }) => () => {
      if (checked) {
        removeSelection(itemIndex)
      } else {
        addSelection(itemIndex)
      }
    },
    addDiscount: props => () => {
      // why isn't the product info props.product?
      const product = JSON.parse(JSON.stringify(props))

      props.addDiscountItems(product)
    },
    valueAddedServices: ({ addProduct, ...props }) => () => {
      // why isn't the product info props.product?
      const product = JSON.parse(JSON.stringify(props))
      const onSuccess = (params) => {
        addProduct({
          product: {
            ...params.selectedService,
            service: params.selectedService.service,
            id: params.selectedService.id + '__' + product.id,
            name: params.selectedService.name,
            categoryId: params.selectedService.categoryId,
            brand: params.selectedService.brand,
            link: params.selectedService.link,
            price: {
              code: params.selectedService.price.code,
              value: params.price
            },
            discount: params.selectedService.discount,
            images: params.selectedService.images,
            externalProductId: params.selectedService.externalProductId + '__' + product.externalProductId,
            preview: params.selectedService.preview,
            service: params.selectedService.service,
            clientId: params.selectedService.clientId,
            serviceForProductId: product.id,
            lineItemNotes: params.notes || null,
            vatPercent: params.selectedService.vatPercent,
            variant: params.selectedService.variants[0],
          },
          quantity: product.quantity,
          silent: false
        })
        modalService.close()
      }

      const onDismiss = () => null

      return modalService.open({
        component: ValueAddedServicesModal,
        success: onSuccess,
        dismiss: onDismiss,
        product
      });
    },
  })
)(BasketItem)
