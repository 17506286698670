export const notificationObject = ({ formData = {}, media = {}, users = {}, storeIds = {}, departmentIds = {}, scheduledDate }) => ({
    message: formData.text,
    media: {
      id: media.id,
      type: media.type,
      name: media.name || media.title
    },
    targetUser: {
      id: users.id,
      name: users.fullName
    },
    storeIds,
    departmentIds,
    scheduledDate: scheduledDate.toDate(),
    isActionable: formData.type === 'task'
});