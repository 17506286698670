import React from 'react'
import Editable from '../Editable'
import { Dropdown } from '../../../components/Fields'
import P from '../../P'

export default Editable({
  input: (props) => (
    <Dropdown {...props} /> 
  ),
  display: (props) => {
    const { label, value, options = [] } = props
    let modifiedValue = value instanceof Array ? value.join(', ') : value

    for (let strOrObj of options) {
      if (typeof strOrObj === 'object') {
        if (strOrObj.value === value) {
          modifiedValue = strOrObj.label
        }
      }
    }

    return (
      <div>
        <P value={label} type='textButton' />
        <P value={modifiedValue} />
      </div>
    )
  }
})
