import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.CREATE_CASH_PAYMENT_DEVICE_COUNT:
      return reducers.create(state, action)
    case constants.PATCH_CASH_PAYMENT_DEVICE_COUNT:
      return reducers.patch(state, action)
    case constants.FETCH_CASH_PAYMENT_DEVICE_COUNT:
      return reducers.fetchOne(state, action)
    default:
      return state
  }
}
