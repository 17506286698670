import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../config'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import MobileSelect from '../MobileSelect'
import ProductListCard from './ProductListCard'
import ErrorMessage from './ErrorMessage'
import EmptyListMessage from './EmptyListMessage'

const Container = styled.div`
  margin: 10px 3%;
`

const NoListLabelTitle = styled.p`
  text-align: center;
  margin-top: 50%;
  font-weight: bold;
  font-size: 16px;
`

const CardListWrapper = styled.li`
  list-style: none;
  margin: 0;
`

const NoListLabel = styled.p`
  text-align: center;
  font-size: 14px;
`

const LoaderWrapper = styled.div`
  text-align: center;
  margin-top: 50%;
`

const ProductListMobile = ({
  config,
  lists,
  isLoading,
  getProductLists,
  error,
  onListItemClick,
  mobileSelectedColumn,
  setMobileSelectedColumn
}) => {
  const getUpdatedLists = (listStatus) => {
    setMobileSelectedColumn(listStatus)
    getProductLists({ status: listStatus })
  }

  const { columns } = config
  const selectOptions = columns.map((column) => {
    const { title, status } = column
    return { label: title, value: status }
  })

  const displayLists = lists[mobileSelectedColumn] && lists[mobileSelectedColumn].length > 0
  const title = selectOptions.find(status => status.value === mobileSelectedColumn).label

  return (
    <Container>
      <MobileSelect value={mobileSelectedColumn} options={selectOptions} onChange={(e) => getUpdatedLists(e.target.value)} />
      {error[mobileSelectedColumn] && <ErrorMessage content={translations('503 Error')} />}
      {isLoading[mobileSelectedColumn] ? (
        <LoaderWrapper>
          <CircularProgress size={50} color='secondary' />
        </LoaderWrapper>
      ) : (
        <>
          {displayLists ? (
            lists[mobileSelectedColumn].map(
              ({ id, title, subTitle, info, type }) => (
                <CardListWrapper key={id}>
                  <ProductListCard
                    id={id}
                    title={title}
                    type={type}
                    subTitle={subTitle}
                    info={info}
                    onClick={onListItemClick}
                  />
                </CardListWrapper>
              )
            )
          ) : (
            <EmptyListMessage status={title} />
          )}
        </>
      )}
    </Container>
  )
}

ProductListMobile.propTypes = {
  config: PropTypes.shape({}).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.object,
  getProductLists: PropTypes.func.isRequired,
  error: PropTypes.bool,
  onListItemClick: PropTypes.func,
  mobileSelectedColumn: PropTypes.string.isRequired,
  setMobileSelectedColumn: PropTypes.func.isRequired
}

ProductListMobile.defaultProps = {
  lists: [],
  isLoading: false,
  error: false
}

export default ProductListMobile
