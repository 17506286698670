import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { push } from 'connected-react-router'
import * as constants from './constants'

export const fetchProductsList = ({ id, ...params }) => ({
  type: constants.FETCH_PRODUCTLIST,
  promise: (dispatch, getState) => {
    return digitalStoreSdk.productLists.fetchProductList({
      id,
      includes: ['user'],
      ...params
    })
  }
})

export const fetchProductLists = ({ ...params }) => ({
  type: constants.FETCH_PRODUCTLISTS,
  promise: () => digitalStoreSdk.productLists.fetchProductLists({ ...params })
})

export const createProductList = ({ ...fields }) => ({
  type: constants.CREATE_PRODUCTLIST,
  promise: (dispatch, getState) => {
    const loggedInStoreId = authSelectors.getUserSelectedStoreId(getState())
    const userId = authSelectors.getActiveUserId(getState())

    dispatch(setProductListDetailsLoading(true))
    dispatch(setProductListsError(false))

    return digitalStoreSdk.productLists.createProductList({
      userId,
      storeId: loggedInStoreId,
      ...fields
    }).then((res) => {
      const { listType, id } = res
      dispatch(setProductListDetailsLoading(false))
      dispatch(push(`/${listType}/${id}`))
    }).catch(() => {
      dispatch(setProductListDetailsLoading(false))
      dispatch(setProductListsError(true))
    })
  }
})

export const updateProductList = ({ id, ...fields }) => ({
  type: constants.UPDATE_PRODUCTLIST,
  promise: (dispatch, getState) => {
    const userId = authSelectors.getActiveUserId(getState())

    return digitalStoreSdk.productLists.updateProductList({
      id,
      userId,
      ...fields
    })
  }
})

export const putProductList = ({ id, ...fields }) => ({
  type: constants.PUT_PRODUCTLIST,
  promise: (dispatch, getState) => {
    const userId = authSelectors.getActiveUserId(getState())

    return digitalStoreSdk.productLists.putProductList({
      id,
      userId,
      ...fields
    })
  }
})

export const deleteProductList = ({ id }) => ({
  type: constants.DELETE_PRODUCTLIST,
  promise: () => digitalStoreSdk.productLists.deleteProductList({ id })
})

export const setProductListDetailsLoading = (isLoading) => ({
  type: constants.SET_PRODUCTLIST_DETAILS_LOADING,
  isLoading
})

export const setProductListDetailsUpdating = (isUpdating) => ({
  type: constants.SET_PRODUCT_LIST_UPDATING,
  isUpdating
})

export const setOrderBy = (orderBy) => ({
  type: constants.SET_ORDER_BY,
  orderBy
})

export const setSearchQuery = (searchQuery) => ({
  type: constants.SET_SEARCH_QUERY,
  searchQuery
})

export const setProductListsError = (error) => ({
  type: constants.SET_PRODUCT_LIST_ERROR,
  error
})
