import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import { actions as productWaitlistActions, selectors as productWaitlistSelectors } from '../../../store/modules/productWaitlist'
import { actions as createMessageActions } from '../../../store/modules/createMessage'

import ProductWaitlistScreen from './ProductWaitlistScreen'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

const mapStateToProps = state => {
  return {
    waitlist: productWaitlistSelectors.getProductWaitlist(state),
    isLoading: productWaitlistSelectors.getIsLoading(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onClickDelete: ({ dispatch }) => productId => {
      modalService.action({
        title: translations(`Delete waitlist entry`),
        text: translations(`Remove this entry from the waitlist?`),
        actions: [
          {
            success: true,
            text: translations('Delete'),
            onClick: () => dispatch(productWaitlistActions.deleteEntryFromWaitlist(productId)),
            primary: true
          },
          {
            text: translations('Cancel'),
            primary: false
          }
        ]
      })
    },
    onClickSendMessage: ({ dispatch }) => ({customer, product}) => {
      dispatch(createMessageActions.updateReceipient(customer))
      dispatch(createMessageActions.addContentToMessage({ content: [{ type: 'product', ...product }] }))
    },
    loadMore: ({ dispatch }) => () => {
      dispatch(productWaitlistActions.fetchProductWaitlistNext())
    }
  })
)(ProductWaitlistScreen)
