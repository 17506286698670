import offlineOrdersService from '../../../services/offlineOrdersService'
import actions from './actions'

export const updateOfflineOrderCount = ({ getState, dispatch }) => {
  const changes = offlineOrdersService.dataStore.changes({
    live: true,
    since: 'now'
  })

  offlineOrdersService.count()
    .then(count => {
      dispatch(actions.setOfflineOrderCount({ count }))
    })
    .catch(error => {
      console.log({ error })
    })

  changes.on('change', async (change) => {
    offlineOrdersService.count()
      .then(count => {
        dispatch(actions.setOfflineOrderCount({ count }))
      })
      .catch(error => {
        console.log({ error })
      })
  })
}
