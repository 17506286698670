import React, { useState } from 'react'
import { translations } from '../../../config'
import BasicModalOverlay from '../../BasicModalOverlay'
import Dropdown from '../../Fields/Dropdown'
import { inputMethods } from './constants'

export const SelectInputMethod = ({ onClick, onCancel, adyenTerminals }) => {
  const [selectedInputMethod, setInputMethod] = useState(inputMethods[0].value)
  const [adyenTerminal, setAdyenTerminals] = useState(null)

  const actions = [
    {
      text: translations('Cancel'),
      onClick: () => {
        onCancel()
      }
    },
    {
      disabled: !adyenTerminal,
      text: translations('Go'),
      primary: true,
      onClick: () => {
        onClick(selectedInputMethod, adyenTerminal)
      }
    }
  ]

  const handleChange = ({ target: { value } }) => {
    setInputMethod(value)
  }

  const handleTerminalChange = ({ target: { value } }) => {
    setAdyenTerminals(value)
  }

  return (
    <BasicModalOverlay
      title={translations('Select Input Method')}
      actions={actions}
    >
      <Dropdown
        value={selectedInputMethod}
        label='Input method'
        options={inputMethods}
        onChange={handleChange}
      />
      <Dropdown
        value={adyenTerminal}
        label='Payment Terminal'
        options={adyenTerminals}
        onChange={handleTerminalChange}
      />
    </BasicModalOverlay>
  )
}

export default SelectInputMethod
