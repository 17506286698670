import React, { useState } from 'react'
import ReceiptTypeModal from './ReceiptTypeModal'
import PrintReceiptModal from './PrintReceiptModal'
import { SELECT_RECEIPT_TYPE, PRINT_RECEIPT } from './constants'

const ReprintReceipt = ({ order, printers, onCancel }) => {
  const [currentStep, setCurrentStep] = useState(
    order.customer ? SELECT_RECEIPT_TYPE : PRINT_RECEIPT
  )

  const onTypeClick = (type) => {
    setCurrentStep(type)
  }

  if (currentStep === PRINT_RECEIPT) {
    return (
      <PrintReceiptModal
        order={order}
        printers={printers}
        onCancel={onCancel}
      />
    )
  }

  return <ReceiptTypeModal onTypeClick={onTypeClick} onCancel={onCancel} />
}

export default ReprintReceipt
