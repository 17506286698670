class OfflineReducers {
  sync = (state, action) => {
    return {
      ...state,
      lastSeq: _.get(action, 'result.last_seq')
    }
  }
  setOfflineOrderCount = (state, action) => {
    return {
      ...state,
      offlineOrderCount: _.get(action, 'payload.count')
    }
  }
}

export default new OfflineReducers()
