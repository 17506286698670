import React from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'

import Button from '../../Button'

import styles from './style'
import { translations } from '../../../config'

const SelectStoreField = ({
  value: storeValue,
  meta: { error, touched } = {},
  label,
  buttonLabel,
  noErrorTextLabel,
  classes,
  onSearchClick,
  onRemoveStoreClick
}) => {
  return (
    <div className={classes.container}>
      {label
        ? <div>
            <div className={classes.label}>{`${label} ${error === 'Field is required' ? '*' : ''}`}</div>
          </div>
        : null
      }
      <div className={classes.searchContainer}>
        <div className={classes.storeValue}>
          {storeValue
            ? storeValue.name
            : translations('Please select a store')}
        </div>
        <div className={classes.buttonContainer}>
          {storeValue && <DeleteIcon className={classes.deleteIcon} onClick={onRemoveStoreClick} />}
          <Button
            type='button'
            color='primary'
            className={classes.searchButton}
            onClick={onSearchClick}
          >
            {buttonLabel || translations('Search')}
          </Button>
        </div>
      </div>
      {noErrorTextLabel
        ? null
        : <FormHelperText className={classes.errorStyle}>
          {touched && error}
        </FormHelperText>}
    </div>
  )
}

SelectStoreField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    // can be an empty string if it is removed
    PropTypes.string
  ]),
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  noErrorTextLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onRemoveStoreClick: PropTypes.func.isRequired
}

export default withStyles(styles)(SelectStoreField)
