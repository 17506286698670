import _ from 'lodash'

import { checkoutModules, referralModules, storeroomRequestModules, consultationModules } from '../config'

export const modulesForCheckoutType = {
  referral: referralModules,
  storeroom: storeroomRequestModules,
  checkout: checkoutModules,
  consultation: consultationModules
}

export const pathForCheckoutType = {
  referral: 'referral',
  storeroom: 'storeroom-request',
  checkout: 'checkout',
  consultation: 'checkout'
}

export const getModulesFromCheckoutType = (checkoutType) => {
  const modules = modulesForCheckoutType[checkoutType]
  if (modules) {
    return modules
  } else {
    throw new Error(`cannot get modules for checkoutType '${checkoutType}'`)
  }
}

export const getPathFromCheckoutType = (checkoutType) => {
  const path = pathForCheckoutType[checkoutType]
  if (path) {
    return path
  } else {
    throw new Error(`cannot get path for checkoutType '${checkoutType}'`)
  }
}

const _getModulesHashmap = (modules) => {
  const modulesHashmap = _.reduce(modules, (result, someModule, index) => {
    const { name, ...rest } = someModule
    result[someModule.name] = { index, ...rest }
    return result
  }, {})
  return modulesHashmap
}
const _modulesHashmapsForCheckoutType = _.mapValues(modulesForCheckoutType, _getModulesHashmap)

export const getCheckoutModuleOptions = (moduleName, checkoutType) => {
  const options = _.get(_modulesHashmapsForCheckoutType, [checkoutType, moduleName], {})
  return options
}

// these are all checkout related screens, including email basket
// (referral), and storeroom request flow.
// why does this need to exist? why can't we just lowercase the
// module name to get the path? I'll tell you why. it's because
// a module can have multiple paths :O
export const pathsForCheckoutModule = {
  ORDER_SUMMARY: [
    'order-summary'
  ],
  SELECT_CUSTOMER: [
    'select-customer',
    'register-customer'
  ],
  DELIVERY: [
    'delivery'
  ],
  PAYMENT_DUMMY: [
    'payment-dummy'
  ],
  COUPON_CODE: [
    'coupon-code'
  ],
  EDIT_BASKET: [
    'edit-basket'
  ],
  PAYPAL_HERE: [
    'paypal-here'
  ],
  MULTI_PAYMENT: [
    'multi-payment'
  ],
  PAYMENT_SALESFORCE: [
    'payment'
  ],
  DEFERRED_MODULE: [
    'deferred'
  ]
}

const _checkoutModulePathsInverse = _.transform(pathsForCheckoutModule,
  (acc, paths, moduleName) => {
    paths.forEach(path => { acc[path] = moduleName })
  }, {}
)
export const getCheckoutModuleNameFromPath = (path) => {
  return _checkoutModulePathsInverse[path]
}
