import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import * as receivedGoodsReceived from '../../../store/modules/receivedGoodsReceived'
import * as receivedGoodsChecked from '../../../store/modules/receivedGoodsChecked'
import ReceiveGoodsScreen from './ReceiveGoodsScreen'
import { push } from 'connected-react-router'
import moment from 'moment'

const config = {
  "columns": [
    {
      "status": "all",
      "title": "All Lists",
      "orderBy": "createdAt",
      "sortOrder": "desc"
    },
    {
      "status": "received",
      "title": "Pending",
      "orderBy": "createdAt",
      "sortOrder": "desc"
    },
    {
      "status": "checked",
      "title": "Checked",
      "orderBy": "createdAt",
      "sortOrder": "desc"
    },
  ]
}

const mapProductListToUI = obj => ({
  id: obj.id,
  title: `Delivered ${moment(obj.details.goodsIssueDate).format('DD MMMM YYYY HH:mm:ss')}`,
  // subTitle: `Shipment ${obj.details.deliveryNumber}`,
  // info: obj.products.map(product => `${product.name} - ${product.variant.name}`), // productList card lines underneath split
  info: [
    `Shipment ${obj.details.deliveryNumber}`,
    `Received from: ${obj.details.receivedFrom || 'unknown'}`
  ], // productList card lines underneath split
  // type: obj.details.sapMaterialNumber // productList card top right
})

const mapStateToProps = (state) => {
  const filters = receivedGoodsReceived.selectors.filters(state)

  const dateRange = {
    to: _.get(filters, 'dateRange.to', null),
    from: _.get(filters, 'dateRange.to', null)
  }

  let lists = {
    all: [],
    ['received']: receivedGoodsReceived.selectors.results(state).map(mapProductListToUI),
    ['checked']: receivedGoodsChecked.selectors.results(state).map(mapProductListToUI),
  }
  const isLoading = {
    ['received']: receivedGoodsReceived.selectors.loading(state),
    ['checked']: receivedGoodsChecked.selectors.loading(state),
  }
  const error = {
    ['received']: receivedGoodsReceived.selectors.error(state),
    ['checked']: receivedGoodsChecked.selectors.error(state),
  }

  return {
    config,
    dateRange,
    lists,
    error,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductLists: (params) => {
    dispatch(receivedGoodsReceived.actions.fetchAllFresh(params))
    dispatch(receivedGoodsChecked.actions.fetchAllFresh(params))
  },
  getList: (id) => {
    dispatch(push(`/receive-goods/${id}`))
  }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('mobileSelectedColumn', 'setMobileSelectedColumn', 'all'),
)(ReceiveGoodsScreen)
