import { get } from 'lodash'

export const getAllPromotions = (state) =>
  get(state, 'promotions.promotions', [])
export const getRemovePromotionFromOrder = (state) =>
  get(state, 'promotions.setShouldRemovePromotionFromOrder')
export const getIsPromotionLoading = (state) =>
  get(state, 'promotions.isLoading')
export const getHasPromoCode = (state) => get(state, 'promotions.hasPromoCode')
export const getPromoCode = (state) => get(state, 'promotions.promoCode')
export const getHasValidPromoCode = (state) => get(state, 'promotions.hasValidPromoCode')
