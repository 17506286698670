import offlineOrdersService from "../../../services/offlineOrdersService"
import { NETWORK_CONNECTION_ON } from "./constants"

class NetworkMiddleware {

  uploadOrdersOnOnlineMiddleware = ({ dispatch }) => next => action => {
    if (action.type === NETWORK_CONNECTION_ON) {
      offlineOrdersService.upload()
    }
    next(action)
  }

}

export default new NetworkMiddleware()
