import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import _ from 'lodash'

import scanner from '../../services/barcodeScannerService'
import modalService from '../../services/modalService'
import digitalStoreSdk from '../../digitalStoreSdk'
import { translations } from '../../config'
import ScanButton from './ScanButton'
import EnterEANModal from './EnterEANModal'
import analyticsService from '../../services/analyticsService'
import { selectors as authSelectors } from '../../store/modules/auth'

const searchProduct = (outputFunc, userRegionId, catalogue) => ({ ean, onError = _.noop, onSuccess = _.noop }) => {
  return digitalStoreSdk.variants.fetchVariants({
    ean,
    catalogue,
    regionId: userRegionId,
    includes: ['product']
  })
    .then(response => {
      const product = {
        ..._.get(response, 'items.0.product', {}),
        variant: _.chain(response)
          .get('items.0')
          .omit('products')
          .value()
      }

      onSuccess({ ean, id: product.id })
      if (product.id) {
        outputFunc({ product, ean })
      }
    })
    .catch(error => {
      onError({ error, ean })
    })
}

// `isLoading` here is about whether the scanner plugin is loading
export default compose(
  connect((state) => ({
    userRegionId: authSelectors.getUserSelectedRegionId(state),
    catalogue: _.get(authSelectors.getCurrentUser(state), 'catalogue', ''),
  })),
  withState('isLoading', 'setIsLoading', false),
  withHandlers({
    onClick: ({ setIsLoading, onClick: onClickFromProps, userRegionId, catalogue }) => () => {
      const onConfirm = searchProduct(onClickFromProps, userRegionId, catalogue)

      if (window.cordova) {
        setIsLoading(true)
        scanner.scan(
          result => {
            analyticsService.sendCustomEvent({ type: 'eanProductSearch' })
            const { cancelled, text } = result
            if (!cancelled) {
              onConfirm({
                ean: text,
                onSuccess: () => {
                  setIsLoading(false)
                },
                onError: () => {
                  setIsLoading(false)
                  modalService.open({
                    component: EnterEANModal,
                    title: translations('Enter EAN No Results Title'),
                    onConfirm,
                    actions: [
                      {
                        success: true,
                        primary: true,
                        text: translations('Enter EAN Submit')
                      },
                      {
                        text: translations('Cancel'),
                        onClick: _.noop
                      }
                    ]
                  })
                }
              })
            } else {
              setIsLoading(false)
              modalService.open({
                component: EnterEANModal,
                title: translations('Enter EAN Title'),
                onConfirm,
                actions: [
                  {
                    success: true,
                    primary: true,
                    text: translations('Enter EAN Submit')
                  },
                  {
                    text: translations('Cancel'),
                    onClick: _.noop
                  }
                ]
              })
            }
          },
          () => {
            setIsLoading(false)
            modalService.open({
              component: EnterEANModal,
              title: translations('Enter EAN Scan Error Title'),
              onConfirm,
              actions: [
                {
                  success: true,
                  primary: true,
                  text: translations('Enter EAN Submit')
                },
                {
                  text: translations('Cancel'),
                  onClick: _.noop
                }
              ]
            })
          }
        )
      } else {
        modalService.open({
          component: EnterEANModal,
          title: translations('Enter EAN Title'),
          onConfirm,
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Enter EAN Submit')
            },
            {
              text: translations('Cancel'),
              onClick: _.noop
            }
          ]
        })
      }
    }
  })
)(ScanButton)
