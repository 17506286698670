import React, { useState } from 'react'
import styled from 'styled-components'
import { translations } from '../../config'
import AdyenGiftCardPaymentDevice from '../../services/paymentDevicesService/adyenGiftCardPaymentDevice'
import BasicModalOverlay from '../BasicModalOverlay'
import Dropdown from '../Fields/Dropdown'
import Form from '../Form'
import { inputMethods } from '../PaymentDevices/PayByGiftCardModal/constants'
import _ from 'lodash'
import InputWithScanButton from '../../components/InputWithScanButton'
import toastService from "../../services/toastService/toastService";

const Error = styled.p`
  color: red;
`

const FormWrapper = Form('ActivateGiftCardForm')

const ActivationModal = ({
  giftCardToActivate,
  adyenTerminals,
  orderNumber,
  paymentDevices,
  onCancel,
  onClick
}) => {
  const [inputMethod, setInputMethod] = useState(inputMethods[0].value)
  const [giftCardCode, setGiftCardCode] = useState()
  const [adyenTerminalId, setAdyenTerminalId] = useState()
  const [isActivationPending, setIsActivationPending] = useState()
  const [error, setError] = useState({
    errorMessage: null,
    error: null,
    hasError: false
  })

  const value = giftCardToActivate.manualDiscount
    ? Number(giftCardToActivate.price.value) +
      -1 * giftCardToActivate.manualDiscount.value
    : Number(giftCardToActivate.price.value)

  const actions = [
    {
      disabled: isActivationPending,
      text: translations('Skip'),
      onClick: () => {
        onCancel(error)
      }
    },
    {
      primary: true,
      disabled: !inputMethod || isActivationPending,
      text: translations('Activate'),
      onClick: () => {
        handleActivateGiftCard()
      }
    }
  ]

  const handleChange = ({ target: { value } }) => {
    setInputMethod(value)
  }

  const handleAdyenTerminalChange = ({ target: { value } }) => {
    setAdyenTerminalId(value)
  }

  const handleGiftCardCodeChange = ({ target: { value } }) => {
    setGiftCardCode(value)
  }

  const handleActivateGiftCard = async () => {
    setIsActivationPending(true)
    setError({ hasError: false, error: null, errorMessage: null })

    try {
      const adyenTerminal = paymentDevices.find(
        (item) => item.id === adyenTerminalId
      )

      const result = await AdyenGiftCardPaymentDevice.implementation.activateCard(
        `${adyenTerminal.config.modelNumber}-${adyenTerminal.config.serialNumber}`,
        {
          cardNumber: giftCardCode,
          value: value,
          currency: giftCardToActivate.price.code,
          ean: _.get(giftCardToActivate, 'variant.ean')
        },
        orderNumber,
        inputMethod,
        adyenTerminal.id
      )

      if (result.status === 'Success') {
        onClick(result)
        return
      }
      setError({
        error: result,
        hasError: true,
        errorMessage: result.errorMessage
      })
    } catch (error) {
      setError({
        error: error,
        hasError: true,
        errorMessage: 'Unexpected Error'
      })
    }
    setIsActivationPending(false)
  }

  return (
    <BasicModalOverlay actions={actions} title={`Activate Gift Card`}>
      <FormWrapper style={{ width: '100%' }}>
        <div>
          <Dropdown
            style={{ width: '100%', minWidth: '300px' }}
            value={inputMethod}
            label='Input method'
            options={inputMethods}
            onChange={handleChange}
          />
        </div>
        <div>
          <Dropdown
            style={{ width: '100%', minWidth: '300px' }}
            autoWidth
            value={adyenTerminalId}
            label='Adyen Terminal'
            options={adyenTerminals}
            onChange={handleAdyenTerminalChange}
          />
        </div>
        {inputMethod === inputMethods[0].value && (
          <InputWithScanButton
            autoFocus
            label='Gift card number'
            value={giftCardCode}
            type='number'
            onChange={handleGiftCardCodeChange}
            onError={(error)=> {
              toastService.action({
                type: 'error',
                message: translations('Failed to read barcode, please try again or enter manually'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            }}
            onCancel={() => {
              toastService.action({
                type: 'error',
                message: translations('Scanning cancelled'),
                verticalPosition: 'top',
                horizontalPosition: 'right'
              })
            }}
          />
        )}
      </FormWrapper>
      <div>Value: {value}</div>
      {error.hasError && <Error>{error.errorMessage}</Error>}
    </BasicModalOverlay>
  )
}

export default ActivationModal
