export default theme => ({
  formControl: {
    width: '100%'
  },
  buttonGroup: {
    marginBottom: '30px'
  },
  floatSectionContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px 35px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  discountDetailText: {
    fontSize: '12px'
  },
  amountContainer: {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(0,0,0,0.1)',
    border: '1px solid gray',
    borderRadius: '3px',
    textAlign: 'center',
    margin: '20px 0px',
  },
  changeContainer: {
    textAlign: 'right',
  },
  changeDueText: {
    fontSize: '20px'
  },
  errorText: {
    fontSize: '14px',
    color: 'red',
  },
  symbol: {
    background: 'rgba(0,0,0,0.1)',
    width: '60px',
    height: '40px',
    padding: '11px',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '15px',
    textAlign: 'center',
  },
  amountContainer: {
    fontSize: '14px',
    backgroundColor: 'rgba(0,0,0,0.1)',
    border: '1px solid gray',
    borderRadius: '3px',
    textAlign: 'center',
    margin: '20px 0px',
  },
  quantityInput: {
    flex: 1,
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      maxWidth: '120px',
    }
  },
  priceInput: {
    flex: 1,
    padding: '0 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      maxWidth: '120px',
    }
  },
  amountValue: {
    margin: '5px'
  },
  modalContainer: {
    overflow: 'scroll',
    maxHeight: '500px'
  },
})
