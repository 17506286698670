import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import SubHeader from '../../../components/SubHeader'
import Button from '../../../components/Button'
import ButtonsContainer from '../../../components/ButtonsContainer'
import H2 from '../../../components/H2'
import Container from '../../../components/Container'
import style from './style'
import Dropdown from '../../../components/Fields/Dropdown'
import currencyFormatter from '../../../formatters/currencyFormatter'
import LoaderContainer from '../../../components/LoaderContainer'
import PaymentCollapsible from '../../../components/PaymentDevices/PaymentCollapsible'
import { required } from '../../../validators'

const MultiPayment = ({
  classes,
  selectedPrinterId,
  printerOptions,
  supportedPaymentDeviceTypes,
  amountToPay,
  currentOrder,
  payments,
  setselectedPrinterId,
  isLoading,
  onPaymentOptionClick,
  onCancelClick,
  onCompleteClick,
  onGiftReceiptClick,
  isVintagePurchase
}) => {
  const handledPrinterChange = ({ target: { value } }) => {
    setselectedPrinterId(value)
  }
  const products = currentOrder.products
  const isProductAGiftCard = item => _.get(item, 'details.isProductAGiftCard')
  const isNotAGiftCardAndExtaItem = item => !_.get(item, 'details.isProductAGiftCard') && !_.get(item, 'isPromotionExtraItem')
  const giftCards = products.filter(isProductAGiftCard)
  const productsWithoutGiftCards = products.filter(isNotAGiftCardAndExtaItem)

  return (
    <LoaderContainer isLoading={isLoading}>
      <SubHeader centerContent={<H2 value="Payment" />} />
      <Container withMarginTop withMarginBottom>
        <div className={classes.actionContainer}>
          <div className={classes.cancelContainer}>
            <Button
              key="text"
              buttonType="white"
              big
              children={'Cancel'}
              onClick={() => onCancelClick()}
            />
          </div>
          <div className={classes.completeContainer}>
            <Button
              key="text"
              buttonType="primary"
              big
              children={'Complete'}
              disabled={ !isVintagePurchase && (amountToPay.value !== 0 || selectedPrinterId === null)}
              onClick={() => onCompleteClick()}
            />
          </div>
        </div>
        <div className={classes.printerDropdownContainer}>
          <Dropdown
            value={selectedPrinterId}
            options={printerOptions}
            validate={required}
            onChange={handledPrinterChange}
            label={translations('Select printer')}
          />
        </div>
        <div className={classes.amountContainer}>
          <div className={classes.total}>
            <span>Total: </span><span>{currencyFormatter.format(currentOrder.total)}</span>
          </div>
          <div className={classes.outstanding}>
            <span>Outstanding: </span><span>{currencyFormatter.format(amountToPay)}</span>
          </div>
        </div>
        <div className={classes.paymentsContainer}>
          <PaymentCollapsible payments={payments} />
        </div>
        <ButtonsContainer innerClassName={classes.buttonsContainerInner}>
          { !isVintagePurchase && supportedPaymentDeviceTypes.map(({ ctaText, deviceType }) => {
            return (
              <Button
                key="text"
                buttonType="primary"
                big
                disabled={amountToPay.value === 0 || productsWithoutGiftCards.length === 0 && giftCards.length && deviceType.startsWith('gift')}
                children={ctaText}
                onClick={onPaymentOptionClick(deviceType)}
              />
            )
          })}
          <Button
            key="text"
            big
            buttonType="primary"
            children={'Get a gift receipt'}
            disabled={ !isVintagePurchase && (amountToPay.value !== 0 || selectedPrinterId === null)}
            onClick={() => onGiftReceiptClick()}
          />
        </ButtonsContainer>
      </Container>
    </LoaderContainer>
  )
}

export default withStyles(style)(MultiPayment)
