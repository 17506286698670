import sdk from '../../../../digitalStoreSdk'

export async function makeRequest(mappedRequest, path = 'sync') {
  try {
    const response = await handleAdyenRequest(mappedRequest, path)

    return response
  } catch (error) {
    console.log(error)
    console.log({ error })
    throw error
  }
}

async function handleAdyenRequest({ SaleToPOIRequest }, path) {
  try {
    const request = await sdk.proxyEndpoints.adyenProxyRequest({
      SaleToPOIRequest,
      path
    })

    return Promise.resolve(request)
  } catch (error) {
    if (error.message.includes('Failed to fetch')) {
      return Promise.reject({
        ...error,
        message:
          'Failed connecting to Adyen Cloud Terminal API server: ' +
          error.message
      })
    }
    return Promise.reject(error)
  }
}
