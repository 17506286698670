import { compose, withHandlers, withState } from 'recompose'

import scanner from '../../services/barcodeScannerService'
import InputWithScanButton from './InputWithScanButton'

// `isLoading` here is about whether the scanner plugin is loading
export default compose(
  withState('isLoading', 'setIsLoading', false),
  withState('scanned', 'setScanned', ({ value }) => value),
  withHandlers({
    onClick: ({ setIsLoading, setScanned, ...props }) => () => {
      if (window.cordova) {
        setIsLoading(true)
        scanner.scan(
          result => {
            const { cancelled, text } = result
            if (!cancelled) {
              setIsLoading(false)
              setScanned(text)
              props.onSuccess && props.onSuccess(text)
              props.onChange && props.onChange({ target: { value: text } })
            } else {
              setIsLoading(false)
              props.onCancel && props.onCancel()
            }
          },
          (error) => {
            setIsLoading(false)
            props.onError && props.onError(error)
          })
      } else {
        props.onError('NOT_CORDOVA')
      }
    }
  })
)(InputWithScanButton)
