import { colors } from '../../config/theme'

export default {
  root: {
    width: '100%'
  },
  generalError: {
    textAlign: 'left',
    textTransform: 'capitalize',
    color: colors.error,
    border: '1px solid',
    backgroundColor: colors.errorLight,
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '14px'
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  field: {
    width: '100%'
  },
  centerButtonContainerFixed: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 7,
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1299,
    backgroundColor: colors.lightGrey,
    width: '100%'
  },
  centerButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 7,
    fontWeight: 600
  },
  rightButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginTop: '30px'
  },
  leftButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '30px',
    marginTop: '30px'
  },
  rightButton: {
    width: '330px',
    fontSize: '14px',
    padding: '16px'
  },
  productButton: {
    width: '250px',
    fontSize: '14px',
    weight: '600'
  },
  dateOfBirthField: {
    width: '10%'
  },
  quantityDropdown: {
    width: '37%'
  },
  borderButton: {
    border: '1px solid black',
    background: 'white',
    // override hover style
    '&:hover': {
      background: 'white'
    }
  },
  productBorderButton: {
    border: '1px solid black',
    background: 'white',
    minWidth: '38px',
    textAlign: 'center'
  },
  fullWidthButton: {
    width: '100%'
  },
  productField: {
    padding: '5px'
  },

  // USER REGISTRATION FORM STYLES
  requiredFieldsText: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '20px',
    margin: '25px 0'
  },
  contentContainer: {
    padding: '0 7.5%'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh - 144px)',
    flexDirection: 'column'
  },
  userButton: {
    width: '330px',
    height: '50px',
    lineHeight: '50px',
    display: 'block',
    textAlign: 'center',
    backgroundColor: colors.primary,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    margin: '0 auto',
    fontWeight: 600
  },
  buttonContainer: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    height: '70px',
    background: 'white',
    display: 'flex',
    alignItems: 'center'
  }

}
