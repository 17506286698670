import qs from 'qs'

export const mapReferencedRefundResponse = adyenTerminalApiResponse => {
  const response = _.get(adyenTerminalApiResponse, 'SaleToPOIResponse.ReversalResponse.Response')
  const shouldCheckPaymentStatus = checkShouldCheckPaymentStatus(response)

  if (shouldCheckPaymentStatus) {
    return null
  }

  const error = checkError(response)

  if (error) {
    return {
      success: false,
      message: error.message,
      error: error,
      data: adyenTerminalApiResponse
    }
  }

  return {
    success: true,
    error: null,
    message: 'Successfully processed payment.',
    data: adyenTerminalApiResponse
  }
}

const checkError = response => {
  console.debug('AdyenPaymentService.checkError() ', { response })
  if (response.Result === 'Success') {
    return null
  }

  /**
   * Adyen's api returns Base64 encoded information
   * or a key/value pair as additionalResponse...
   */
  let result = response.AdditionalResponse
  try {
    result = atob(result)
    result = JSON.parse(result)
  } catch (error) {
    result = qs.parse(response.AdditionalResponse)
  }

  /**
   * https://docs.adyen.com/point-of-sale/refusal-reasons-pos
   * Building custom error messages:
   * result.message.includes('Shopper cancelled tx')
   */

  // will have a "message" property in it
  return result
}

const checkShouldCheckPaymentStatus = response => {
  // https://docs.adyen.com/point-of-sale/error-scenarios#no-result-received
  if (!['Success', 'Failure'].includes(response.Result)) {
    return true
  }

  return false
}
