import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from 'connected-react-router'
import { selectors } from '../../../store/modules/promotions'
import { translations } from '../../../config'
import { NoResults, Container } from './PromotionList.styles'

import LoaderContainer from '../../../components/LoaderContainer'
import PromotionCard from '../PromotionCard'
import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar/BackBar'
import H2 from '../../../components/H2'

const PromotionList = ({ history }) => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const promotions = selectors.getAllPromotions(state)
  const isLoading = selectors.getIsPromotionLoading(state)

  const handlePromotionClick = (id) => {
    history.push(`/promotions/${id}`)
  }

  return (
    <LoaderContainer isLoading={isLoading}>
      <SubHeader
        leftContent={<BackBar onClick={() => dispatch(goBack())} />}
        centerContent={<H2 value={translations('Promotions')} />}
      />
      <Container>
        {Array.isArray(promotions) && promotions.length ? (
          promotions.map((promotionItem) => (
            <PromotionCard
              key={promotionItem.id}
              promotion={promotionItem}
              onClick={handlePromotionClick}
            />
          ))
        ) : (
          <NoResults>{translations('No promotions')}</NoResults>
        )}
      </Container>
    </LoaderContainer>
  )
}

export default PromotionList
