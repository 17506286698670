import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const FormSubmit = props => {
  const {
    big,
    buttonType,
    ContainerComponent,
    disabled,
    buttonClass,
    containerClass,
    invalid,
    label,
    name,
    submitting,
    value,
    onClick,
    noContainer,
    children,
    flexGrow
  } = props

  const buttonContent = (
    submitting
      ? <CircularProgress size={23} thickness={2} />
      : label || children
  )

  const Container = ContainerComponent || 'div'

  const button = (
    <Button
      big={big}
      buttonType={buttonType}
      className={buttonClass}
      color='primary'
      disabled={invalid || submitting || disabled}
      name={name}
      type='submit'
      value={value}
      onClick={onClick}
      flexGrow={flexGrow}
    >
      {buttonContent}
    </Button>
  )

  return (
    noContainer
      ? button
      : <Container className={containerClass}>
        {button}
      </Container>
  )
}

FormSubmit.propTypes = {
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  label: PropTypes.string
}

export default FormSubmit
