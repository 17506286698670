import { useSelector } from 'react-redux'

import { getAccessoryDetails, getError, getIsFetchPaymentDeviceLoading } from '../store/modules/accessoryDetails/selectors'

/**
 * @description returns accessory details
 * @returns
 */
export const useResults = () => {
  return useSelector(getAccessoryDetails)
}

/**
 * @description returns regions error
 * @returns
 */
export const useError = () => {
  return useSelector(getError)
}

/**
 * @description returns if the accessory is currently loading
 * @returns
 */
 export const useIsFetchPaymentDeviceLoading = () => {
  return useSelector(getIsFetchPaymentDeviceLoading)
}
