import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ResourceReducers {

  /**
   * Set the filter options in the store so the
   * subsequent fetchAll requests correctly filter results
   */
  setFilters = (state, action) => ({
    ...state,
    filters: action.filters
  })

  /**
   * Fetch all fresh - Replaces results in the store with
   * the API response. Used on inital load or a changing filters
   */
  fetchAllFresh = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllFreshPending(state, action)
      case SUCCESS:
        return this.fetchAllFreshSuccess(state, action)
      case FAIL:
        return this.fetchAllFreshFail(state, action)
      default:
        return state
    }
  }

  fetchAllFreshPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  fetchAllFreshSuccess = (state, action) => ({
    ...state,
    total: action.result.total,
    results: action.result.items,
    status: SUCCESS,
    action: undefined,
    error: null
  })

  fetchAllFreshFail = (state, action) => ({
    ...state,
    status: FAIL,
    action: undefined,
    error: action.error.message
  })

  /**
   * Fetch all next - Appends results to the store.
   * Used for pagination, load more, infinite scrolling etc.
   */
  fetchAllNext = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllNextPending(state, action)
      case SUCCESS:
        return this.fetchAllNextSuccess(state, action)
      case FAIL:
        return this.fetchAllNextFail(state, action)
      default:
        return state
    }
  }

  fetchAllNextPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  fetchAllNextSuccess = (state, action) => ({
    ...state,
    total: action.result.total,
    results: [
      ...state.results,
      ...action.result.items
    ],
    status: SUCCESS,
    action: undefined,
    error: null
  })

  fetchAllNextFail = (state, action) => ({
    ...state,
    status: FAIL,
    action: undefined,
    error: action.error.message
  })

}

export default new ResourceReducers()
