import { push, LOCATION_CHANGE, replace } from 'connected-react-router'
import _ from 'lodash'
import { matchPath } from 'react-router'
import uuid from 'uuid/v4'

import { translations, getAppConfig } from '../../../config'
import modalService from '../../../services/modalService'
import {
  pathForCheckoutType,
  getModulesFromCheckoutType,
  getPathFromCheckoutType,
  pathsForCheckoutModule,
  getCheckoutModuleNameFromPath
} from '../../../helpers'
import uploadOrder, {
  generateOrderNumber
} from '../../../containers/Checkout/uploadOrder'
import {
  constants as currentOrderConstants,
  actions as currentOrderActions
} from '../currentOrder'
import { actions as orderDetailsActions } from '../orderDetails'
import { constants as customerDetailsConstants } from '../customerDetails'
import {
  actions as currentAppointmentActions,
  selectors as currentAppointmentSelectors
} from '../currentAppointment'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'
import * as currentOrderCombinedSelectors from '../combinedSelectors/currentOrderCombinedSelectors'
import { history } from '../../../store'
import actions from './actions'
import * as constants from './constants'
import analyticsService from '../../../services/analyticsService'
import FormModal from '../../../components/FormModal'

// some notes:
// changing state.current to the correct module and handling the
// next module (routing to a component or any other function) are
// done separately so that the app can cope with back functionality
// (both via browser back button and our back button in subheader)
const handleNext = ({ dispatch, getState }) => ({
  checkoutType,
  checkoutModuleName,
  moduleConfig
}) => {
  const state = getState()
  const serviceRequired = getAppConfig('CONSULTATIONS', 'serviceRequired')
  const paths = pathsForCheckoutModule[checkoutModuleName]
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  const isOnline = networkSelectors.isConnected(state)

  // const setAnonymousCustomer = () => {
  //   dispatch(currentOrderActions.anonymousCustomer({ anonymousReason: 'Offline' }))
  //   dispatch(actions.next())
  // }

  const _doNext = () => {
    if (
      serviceRequired &&
      checkoutType === 'consultation' &&
      !_.get(order, 'groupedProducts', []).find((product) => product.service)
    ) {
      modalService.continue({
        title: translations('Service Required Title'),
        text: translations('Service Required Text')
      })
      // return out of function without moving forward
      return
    }
    if (paths) {
      dispatch(push(`/${getPathFromCheckoutType(checkoutType)}/${paths[0]}`))

      // const anonymousOfflineCustomer = (!isOnline && checkoutType === 'checkout' && paths[0] === 'select-customer')

      // if (anonymousOfflineCustomer) {
      //   setTimeout(() => {
      //     dispatch(currentOrderActions.anonymousCustomer({ anonymousReason: 'Offline' }))
      //     dispatch(actions.next())
      //   }, 100)
      // }
    } else {
      const currentOrder = currentOrderCombinedSelectors.currentOrderSelector(
        state
      )
      switch (checkoutModuleName) {
        case 'COMPLETE_ORDER':
          dispatch(actions.setLoading(true))
          const customerId = _.get(currentOrder, 'customer.id')
          const currentOrderStatus = _.get(currentOrder, 'status')
          const isRefund = (_.get(currentOrder, 'products') || []).some(product => product.refund)

          let orderType
          switch (true) {
            case checkoutType === 'referral':
              orderType = checkoutType
              break
            case checkoutType === 'storeroom':
              orderType = checkoutType
              break
            case checkoutType === 'consultation':
              orderType = 'consultation'
              break
            default:
              orderType = 'standard'
              break
          }
          let status
          switch (checkoutType) {
            case 'storeroom':
              status = 'awaiting_picking'
              break
            default:
              // status = 'complete'
              status = isRefund ? 'awaiting_refund' : 'awaiting_payment'
              break
          }

          if (!currentOrder.orderNumber) {
            currentOrder.orderNumber = generateOrderNumber()
          }

          const params = {
            ...currentOrder,
            customerId,
            orderType,
            storeId: authSelectors.getUserSelectedStoreId(state),
            userId: authSelectors.getActiveUserId(state),
            status: currentOrderStatus || status
          }

          uploadOrder({ params, isOnline })
            .then(async (resp) => {
              dispatch(
                orderDetailsActions.fetchOrder({
                  orderNumber: resp.orderNumber
                })
              )

              dispatch(actions.setLoading(false))
              const orderId = _.get(resp, 'id') ? _.get(resp, 'id') : uuid()
              if (orderId) {
                dispatch(actions.setOrderId(orderId))
              }
              dispatch(actions.setOrderNumber(resp.orderNumber))
              if (checkoutType === 'referral') {
                analyticsService.sendCustomEvent({ type: 'referralSent' })
              } else if (checkoutType === 'storeroom') {
                analyticsService.sendCustomEvent({
                  type: 'storeroomRequestSubmitted'
                })
              } else {
                analyticsService.sendCustomEvent({ type: 'eReceiptSent' })
              }
              if (isRefund) {
                // redirect to refund screen
                dispatch(push(`/orders/${resp.orderNumber}/refund`))
              } else {
                dispatch(actions.next())
              }
            })
            .catch(async (err) => {
              dispatch(actions.setLoading(false))
              dispatch(
                actions.fail({ code: err.code, errorMessage: err.message })
              )
              // NOTE: what are these integration codes?
              const integrationErrorCodes = [
                'RA-29-05',
                'RA-29-06',
                'RA-29-07'
              ]
              if (integrationErrorCodes.includes(err.code)) {
                // The order was still saved on the server, so clear the basket.
                dispatch(currentOrderActions.clearOrder({}))
              }
            })
          break
        case 'CONFIRMATION_MODAL':
          const orderNumber = _.get(state, 'checkoutFlow.orderNumber')
          const customerEmail = _.get(state, 'currentOrder.customer.email')
          // close the consultation review modal
          modalService.close({ modalIndex: 1 })
          const modalServiceContinueArguments = {
            title: getOrderSuccessTitle(),
            actions: [
              {
                text: translations('Exit')
              }
            ]
          }
          const currentAppointmentId = currentAppointmentSelectors.getAppointmentId(
            state
          )
          const consultationMode = currentAppointmentSelectors.getIsAppointmentActive(
            state
          )
          let modalText
          if (consultationMode) {
            modalText = translations('Consultation Successful Text')
          } else {
            modalText = isOnline
              ? translations('Order Successful Text')
              : translations('Order Successful Text - Offline')
          }
          modalServiceContinueArguments.text = _.template(modalText)({
            orderNumber,
            customerEmail
          })

          if (orderNumber) {
            modalServiceContinueArguments.actions.push({
              primary: true,
              text: translations('View order'),
              onClick: () => dispatch(push(`/orders/${orderNumber}`))
            })
          }
          // modalService.action(modalServiceContinueArguments)
          // dispatch(actions.end())
          modalService.continue(modalServiceContinueArguments)
          if (currentAppointmentId) {
            const orderId = _.get(state, 'checkoutFlow.orderId')
            const currentStage = currentAppointmentSelectors.getAppointmentStage(
              getState()
            )
            if (currentStage === 'PAYMENT_PENDING') {
              dispatch(
                currentAppointmentActions.updateCurrentAppointment({
                  id: currentAppointmentId,
                  orderId,
                  status: 'COMPLETE'
                })
              ).then(() => {
                const previousPath = getState().checkoutFlow.previousPath
                dispatch(push(previousPath || '/'))
              })
            } else {
              dispatch(
                currentAppointmentActions.setStage({ stage: 'COMPLETE' })
              )
              dispatch(
                currentAppointmentActions.updateCurrentAppointment({
                  id: currentAppointmentId,
                  orderId,
                  status: 'COMPLETE'
                })
              ).then(() => {
                dispatch(actions.end())
              })
            }
          } else {
            dispatch(actions.end())
          }
          break
        case 'CONFIRM_START_CHECKOUT_MODAL':
          modalService.action({
            title: translations('Confirm Start Checkout Modal Title'),
            text: translations('Confirm Start Checkout Modal Text'),
            actions: [
              {
                text: translations('Yes'),
                success: true,
                primary: true,
                onClick: () => dispatch(actions.next())
              },
              {
                text: translations('Cancel')
              }
            ]
          })
          break
        case 'NOTES_MODAL':
          // Default notes schema, can be overriden by brand config
          let schema = [
            {
              id: 'notes',
              field: 'Input',
              props: {
                label: translations('Notes'),
                name: 'notes',
                multiline: true,
                rows: 1,
                rowsMax: 10
              }
            }
          ]

          if (moduleConfig.fields) {
            schema = moduleConfig.fields.map(
              ({ id, component, label, props }) => ({
                id,
                field: component,
                props: { label: translations(label), name: id, ...props }
              })
            )
          }
          modalService.open({
            component: FormModal,
            formId: 'notes',
            title: translations(moduleConfig.title || 'Add Notes'),
            onSubmit: ({ notes }) => {
              dispatch(currentOrderActions.updateOrder({ details: { notes } }))
              dispatch(actions.next())
              modalService.close()
            },
            schema
          })
          break
        default:
          break
      }
    }

    function getOrderSuccessTitle () {
      if (checkoutType === 'referral') {
        return translations('Referral Successful')
      } else if (checkoutType === 'storeroom') {
        return translations('Storeroom Request Submitted')
      } else {
        return translations('Order Successful')
      }
    }
  }

  /**
   * FISKARS: Check if Value adding services quantities make sense
   */
  const path = history.location.pathname
  const isCheckoutDelivery = matchPath(path, {
    path: '/checkout/delivery',
    exact: true
  })
  if (isCheckoutDelivery) {
    const ungroupedProducts = currentOrderCombinedSelectors.getCurrentOrderProducts(
      state
    ) // get q's from here.
    const groupedProducts = _.chain(ungroupedProducts)
      .groupBy('externalProductId')
      .map((items, externalProductId) => {
        return { ...items[0], quantity: items.length }
      })
      .value()
    const services = _.filter(
      groupedProducts,
      (product) => product.service === true
    )
    const areThereMoreVASThanProducts = _.some(services, (obj) => {
      if (!obj.serviceForProductId) {
        return false
      }
      const product = _.find(
        groupedProducts,
        (product) => product.id === obj.serviceForProductId
      )
      return obj.quantity > product.quantity
    })
    if (areThereMoreVASThanProducts) {
      modalService.action({
        title: translations(
          'Warning - Value Adding Services Quantity Mismatch'
        ),
        text: translations(
          'The number of Value Adding Services does not match the number of products. Would you like to continue?'
        ),
        actions: [
          {
            success: true,
            text: translations('Continue'),
            onClick: () => _doNext(),
            primary: true
          },
          {
            text: translations('Back'),
            primary: false
          }
        ]
      })
    } else {
      _doNext()
    }
  } else {
    _doNext()
  }
}

class CheckoutFlowMiddleware {
  nextMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === constants.START) {
      const state = getState()
      const checkoutType = action.checkoutType
      const existingCheckoutType = _.get(state, 'checkoutFlow.checkoutType')
      if (existingCheckoutType && checkoutType !== existingCheckoutType) {
        dispatch(currentOrderActions.clearOrderExceptBasket())
      }
      if (
        action.checkoutType !== 'referral' &&
        currentOrderCombinedSelectors.getCurrentOrderHasPreviewProducts(
          getState()
        )
      ) {
        const previewProducts = currentOrderCombinedSelectors.getPreviewProducts(
          getState()
        )
        const previewProductsString = previewProducts.reduce(
          (str, product, index) => {
            const { name } = product
            if (str.includes(name)) return str
            return index === 0 ? (str += name) : (str += `, ${name}`)
          },
          ''
        )

        modalService.continue({
          title: translations('There are preview products in your basket'),
          text: translations('Please remove preview products', {
            previewProductsString
          }),
          success: () => dispatch(actions.clear())
        })
      } else if (
        _.get(getState(), 'currentOrder.customerWasAnonymised', false)
      ) {
        modalService.action({
          title: translations(
            'The customer associated to this order was anonymised'
          ),
          text: translations('Please chose from actions below:'),
          actions: [
            {
              text: translations('Close'),
              onClick: () => dispatch(actions.clear())
            },
            {
              text: translations('Clear basket'),
              onClick: () => dispatch(currentOrderActions.clearOrder({}))
            },
            {
              primary: true,
              text: translations('Re-assign'),
              onClick: () => {
                dispatch(currentOrderActions.resetCustomerWasAnonymised())
                dispatch(actions.start({ checkoutType: action.checkoutType }))
              }
            }
          ]
        })
      } else {
        // START
        const pathname = history.location.pathname
        if (
          !_.values(pathForCheckoutType).find((path) =>
            pathname.startsWith(path)
          )
        ) {
          dispatch(actions.setPreviousPath(pathname))
        }
        const startCheckoutFromBeginning = () => {
          const moduleConfig = _.get(
            getModulesFromCheckoutType(checkoutType),
            '0'
          )
          const firstModuleName = _.get(moduleConfig, 'name')
          dispatch(currentOrderActions.clearOrderExceptBasket())
          dispatch(actions.setCurrent(firstModuleName))
          dispatch(actions.fetchValueAddedServices())
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: firstModuleName,
            moduleConfig
          })
        }

        const resumeCheckoutWithCustomer = () => {
          const modules = getModulesFromCheckoutType(checkoutType)
          const customerModuleIndex = _.findIndex(
            modules,
            (m) => m.name === 'SELECT_CUSTOMER'
          )
          const nextModuleIndex =
            customerModuleIndex > -1 ? customerModuleIndex + 1 : 0
          const moduleConfig = _.get(modules, nextModuleIndex)
          const nextModuleName = _.get(moduleConfig, 'name')
          dispatch(actions.setCurrent(nextModuleName))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: nextModuleName,
            moduleConfig
          })
        }

        const currentOrder = getState().currentOrder

        if (
          checkoutType === 'storeroom' ||
          currentOrder.startCheckoutWithCustomer
        ) {
          resumeCheckoutWithCustomer()
        } else {
          if (currentOrder.customer) {
            resumeCheckoutWithCustomer()
          } else {
            startCheckoutFromBeginning()
          }
        }
        // when checkout starts, if there is a current appointment set the stage to 'CHECKOUT'
        const currentAppointmentId = currentAppointmentSelectors.getAppointmentId(
          state
        )
        if (currentAppointmentId) {
          dispatch(currentAppointmentActions.setStage({ stage: 'CHECKOUT' }))
        }
      }
    } else if (action.type === constants.NEXT) {
      // NEXT
      // workout what next module is and do it
      const state = getState()
      const currentModuleName = _.get(state, 'checkoutFlow.current')
      const checkoutType = _.get(state, 'checkoutFlow.checkoutType')
      const moduleSteps = getModulesFromCheckoutType(checkoutType)
      if (currentModuleName) {
        const n = _.findIndex(moduleSteps, (m) => m.name === currentModuleName)
        if (n < 0) {
          dispatch(actions.start({ checkoutType }))
        } else if (n >= moduleSteps.length - 1) {
          dispatch(actions.end())
        } else {
          const moduleConfig = moduleSteps[n + 1]
          dispatch(actions.setCurrent(moduleConfig.name))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: moduleConfig.name,
            moduleConfig
          })
        }
      } else {
        dispatch(actions.start({ checkoutType }))
      }
    } else if (action.type === constants.END) {
      // END
      // go back to home screen
      const previousPath = getState().checkoutFlow.previousPath
      const isVirtualConsultation = currentAppointmentSelectors.getIsAppointmentVirtual(
        getState()
      )
      if (!isVirtualConsultation) {
        dispatch(currentOrderActions.clearOrder({ clearAppointment: true }))
      }
      dispatch(push(previousPath || '/'))
    } else if (action.type === constants.FAIL) {
      // FAIL
      // go back to home screen and show modal saying failed
      const previousPath = getState().checkoutFlow.previousPath
      const errorText = action.errorMessage || translations('Order Fail Text')
      dispatch(push(previousPath || '/'))
      modalService.continue({
        title: translations('Error') + (action.code ? ` ${action.code}` : ''),
        text: errorText,
        success: () => {
          dispatch(currentOrderActions.clearOrder({}))
          modalService.close({ modalIndex: 1 })
        }
      })
    } else if (action.type === constants.BACK) {
      // BACK
      // workout what previous module is and do it
      const state = getState()
      const currentModuleName = _.get(state, 'checkoutFlow.current')
      const checkoutType = _.get(state, 'checkoutFlow.checkoutType')
      const moduleSteps = getModulesFromCheckoutType(checkoutType)
      const isVirtualConsultation = currentAppointmentSelectors.getIsAppointmentVirtual(
        getState()
      )
      if (currentModuleName) {
        const n = _.findIndex(moduleSteps, (m) => m.name === currentModuleName)
        const minimumCheckoutStep = isVirtualConsultation ? 2 : 0
        if (n <= minimumCheckoutStep) {
          // close checkoutflow
          modalService.close()
          const previousPath = getState().checkoutFlow.previousPath
          dispatch(push(previousPath || '/'))
        } else if (n >= minimumCheckoutStep + 1) {
          const moduleConfig = moduleSteps[n - 1]
          dispatch(actions.setCurrent(moduleConfig.name))
          handleNext({ dispatch, getState })({
            checkoutType,
            checkoutModuleName: moduleConfig.name,
            moduleConfig
          })
        }
      }
    }

    next(action)
  };

  handleRouteChangeMiddleware = ({ dispatch, getState }) => (next) => (
    action
  ) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const currentModuleName = _.get(getState(), 'checkoutFlow.current')
      const checkoutType = _.get(getState(), 'checkoutFlow.checkoutType')
      const basketId = _.get(
        getState(),
        'currentOrderSalesforce.basket.basketId'
      )

      if (currentModuleName) {
        const string = path.replace(`/${checkoutType}/`, '')
        const checkoutModule = getCheckoutModuleNameFromPath(string)
        if (checkoutModule) {
          dispatch(actions.setCurrent(checkoutModule))
        } else {
          // nb: this clears the checkoutFlow store, aka what is current module
          // but not the currentOrder store, aka customer, delivery, etc
          // dispatch(actions.clear())
        }
      }

      const isCheckoutDelivery = matchPath(path, {
        path: `/${checkoutType}/${pathsForCheckoutModule.DELIVERY[0]}`,
        exact: true
      })
      if (isCheckoutDelivery) {
        dispatch(actions.fetchDeliveryOptions(basketId))
      }
    }
  };

  createCustomerSuccessMiddleware = ({ dispatch, getState }) => (next) => (
    action
  ) => {
    if (
      action.type === currentOrderConstants.CREATE_CUSTOMER_FOR_ORDER &&
      action.status === 'SUCCESS'
    ) {
      dispatch(actions.next())
    } else if (
      action.type === customerDetailsConstants.CREATE_CUSTOMER &&
      action.status === 'SUCCESS'
    ) {
      const consultationMode = currentAppointmentSelectors.getIsAppointmentActive(
        getState()
      )
      const customerId = _.get(action, 'result.id')
      if (consultationMode) {
        dispatch(currentOrderActions.addCustomer({ id: customerId }))
        dispatch(replace('/consultations'))
      }
    }
    next(action)
  };
}

export default new CheckoutFlowMiddleware()
