import _ from 'lodash'

import digitalStoreSdk from '../../../digitalStoreSdk'
import { apiUpdateSource } from '../../../config'
import { constants, selectors } from '../currentOrder'
import { selectors as orderDetailsSelectors } from '../orderDetails'
import uploadOrder from '../../../containers/Checkout/uploadOrder'

import { selectors as authSelectors } from '../auth'
import * as currentAppointmentCombinedSelectors from '../combinedSelectors/currentAppointmentCombinedSelectors'

class CurrentOrderActions {
  addProduct = input => ({
    type: constants.ADD_PRODUCT_TO_ORDER,
    ..._.pick(input, ['product', 'quantity', 'silent'])
  })

  updateOrderWithPromotions = order => ({
    type: constants.UPDATE_ORDER_WITH_PROMOTION,
    order
  })

  removeProduct = input => ({
    type: constants.REMOVE_PRODUCT_FROM_ORDER,
    ..._.pick(input, ['product', 'quantity', 'silent', 'removeFromAppointment'])
  })

  changeProductQuantity = input => ({
    type: constants.CHANGE_PRODUCT_QUANTITY_IN_ORDER,
    ..._.pick(input, ['quantity', 'product'])
  })

  changeProductPrice = input => ({
    type: constants.CHANGE_PRODUCT_PRICE_IN_ORDER,
    ..._.pick(input, ['editedPriceValue', 'product'])
  })

  onChangeProductField = (input) => ({
    type: constants.CHANGE_PRODUCT_FIELD,
    ..._.pick(input, ['product', 'field', 'value'])
  })

  addManualDiscountToProduct = (input) => ({
    type: constants.ADD_MANUAL_DISCOUNT_TO_PRODUCT,
    ..._.pick(input, ['discount', 'product', 'applyTo'])
  })

  applyPromotionToOrder = input => ({
    type: constants.APPLY_PROMOTION_TO_ORDER
  })

  removeManualDiscountFromProduct = input => {
    return {
      type: constants.REMOVE_MANUAL_DISCOUNT_FROM_PRODUCT,
      product: _.get(input, 'product'),
      applyTo: _.get(input, 'applyTo')
    }
  }

  // NOTE: update order function
  updateOrder = params => ({
    test: '1',
    type: constants.UPDATE_ORDER,
    ...params,
  })

  createCustomer = params => ({
    type: constants.CREATE_CUSTOMER_FOR_ORDER,
    promise: (dispatch, getState) => {
      const territoryId = authSelectors.getCurrentUser(getState()).currentTerritoryId
      return digitalStoreSdk.customers
        .createCustomer({ ...params, territoryId, updateSource: apiUpdateSource })
    }
  })

  addCustomer = ({ id }) => ({
    type: constants.ADD_CUSTOMER_TO_ORDER,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.customers.fetchCustomerDetails({ id })
    }
  })

  clearOrder = ({ doNotRemoveEditMode = undefined, showToast = false, clearAppointment = true, isVirtualConsultation } = {}) => ({
    type: constants.CLEAR_ORDER,
    doNotRemoveEditMode,
    showToast,
    clearAppointment,
    isVirtualConsultation,
    promise: (dispatch, getState) => {
      const loggedInStoreId = authSelectors.getUserSelectedStoreId(getState())
      return digitalStoreSdk.users
        .setCurrentOrder({ currentOrder: {}, loggedInStoreId })
    }
  })

  clearOrderExceptBasket = () => ({
    type: constants.CLEAR_ORDER_EXCEPT_BASKET
  })

  restoreOrder = ({ storeId: loggedInStoreId }) => ({
    type: constants.RESTORE_ORDER,
    promise: (dispatch) => {
      return digitalStoreSdk.users
        .getCurrentOrder({ loggedInStoreId })
    }
  })

  editOrder = ({ editType = constants.EDIT_ORDER_TYPES.DEFAULT, order } = {}) => (dispatch, getState) => {
    const state = getState()
    // Use the passed order, or get raw order from state
    const newBasketOrder = order || orderDetailsSelectors.getOrderRaw(state)
    const action = {
      type: constants.EDIT_ORDER,
      payload: { order: newBasketOrder, editType }
    }
    return dispatch(action)
  }

  stopEditingOrder = () => dispatch => {
    dispatch(this.clearOrder({}))
    dispatch({
      type: constants.STOP_EDITING_ORDER
    })
  }

  anonymousCustomer = ({ customerWasAnonymised = false, anonymousReason }) => {
    return ({
      type: constants.ANONYMOUS_CUSTOMER,
      customerWasAnonymised,
      anonymousReason
    })
  }

  setCurrentOrderDiscount = ({ discount }) => {
    return ({
      type: constants.SET_DISCOUNT,
      discount: discount
    })
  }

  resetCustomerWasAnonymised = () => {
    return ({
      type: constants.RESET_CUSTOMER_WAS_ANONYMISED
    })
  }

  populateOrder = (params) => {
    return ({
      type: constants.POPULATE_ORDER,
      ...params
    })
  }

  toggleRefundItem = ({ id, refund }) => {
    return {
      type: constants.TOGGLE_REFUND_ITEM,
      id,
      refund
    }
  }

  saveToProfile = () => ({
    type: constants.SAVE_TO_PROFILE,
    promise: (dispatch, getState) => {
      const currentOrder = selectors.getCurrentOrderRaw(getState())
      const params = {
        storeId: authSelectors.getUserSelectedStoreId(getState()),
        orderType: currentOrder.consultationMode ? 'consultation' : 'standard',
        status: 'pending',
        customerId: _.get(currentOrder, 'customer.id'),
        userId: authSelectors.getActiveUserId(getState()),
        products: currentOrder.products,
        details: currentOrder.details
      }

      return uploadOrder({ params })
    }
  })

  saveConsultationOrderToProfile = ({ ...params }) => ({
    type: constants.SAVE_TO_PROFILE,
    promise: (dispatch, getState) => {
      const orderFields = currentAppointmentCombinedSelectors.getRecomposedConsultationOrderForCheckout(getState())
      const params = {
        ...orderFields,
        ...params,
        status: 'pending',
        orderType: 'consultation'
      }

      return uploadOrder({ params })
    }
  })

  addRequestItem = ({ product, notes }) => ({
    type: constants.ADD_REQUEST_ITEM,
    promise: (dispatch, getState) => {
      const params = {
        storeId: authSelectors.getUserSelectedStoreId(getState()),
        orderType: 'storeroom',
        status: 'awaiting_picking',
        userId: authSelectors.getActiveUserId(getState()),
        products: [product],
        details: {
          notes: notes
        }
      }

      return uploadOrder({ params })
    }
  })

  toggleLove = ({ product }) => {
    return {
      type: constants.TOGGLE_LOVE,
      product
    }
  }

  calculateBasketDiscount = () => {
    return ({
      type: constants.CALCULATE_BASKET_DISCOUNT
    })
  }

  manageCustomerReviewBasketStatus = ({ seen }) => {
    return {
      type: constants.TOGGLE_CUSTOMER_BASKET_REVIEW_STATUS,
      seen
    }
  }

  addConsultationServiceToCurrentOrder = ({ product, servicesCategory, quickTricksCategory }) => {
    return {
      type: constants.ADD_CONSULTATION_SERVICE_TO_CURRENT_ORDER,
      product,
      servicesCategory,
      quickTricksCategory
    }
  }

  fetchBasketConfiguration = ({ force = false }) => {
    return {
      type: constants.FETCH_BASKET_CONFIGURATION,
      force,
      promise: () => digitalStoreSdk.basket.fetchConfiguration()
    }
  }

  /**
   * @description action to change the content attachment mode
   * @param {boolean} toggle
   * @memberof CurrentOrderActions
   */
  toggleAttachContentMode = ({ toggle }) => {
    return {
      type: constants.TOGGLE_ATTACH_CONTENT_MODE,
      toggle
    }
  }

  /**
   * @description used to override current order object from client in virtual consultation
   * @param {object} { currentOrder }
   * @memberof CurrentOrderActions
   */
  setCurrentOrder = ({ currentOrder, source }) => ({
    type: constants.SET_CURRENT_ORDER,
    currentOrder,
    source
  })

  payByEmail = ({ orderNumber }) => ({
    type: constants.PAY_BY_EMAIL,
    promise: (dispatch, getState) => {
      return digitalStoreSdk.orders.payByEmail({ orderNumber })
    }
  })

  setStripePaymentSuccess = ({ success }) => ({
    type: constants.SET_STRIPE_PAYMENT_SUCCESS,
    success
  })
  setCurrentOrderDiscount = ({ discount }) => {
    return ({
      type: constants.SET_DISCOUNT,
      discount: discount
    })
  }

  removeManualDiscountFromOrder = () => {
    return ({
      type: constants.REMOVE_MANUAL_DISCOUNT_FROM_ORDER
    })
  }

  setPaymentDetails = (payment) => ({
    type: constants.SET_PAYMENT_DETAILS,
    payment
  })
}

export default new CurrentOrderActions()
