import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { translations } from '../../config'

const NoListLabelTitle = styled.p`
  text-align: center;
  margin-top: 50%;
  font-weight: bold;
  font-size: 16px;
`

const NoListLabel = styled.p`
  text-align: center;
  font-size: 14px;
`

const EmptyListMessage = ({ status }) => (
  <>
    <NoListLabelTitle>
      {translations('Product lists - no lists title', { status })}
    </NoListLabelTitle>
    <NoListLabel>
      {translations('Product lists - no lists', { status })}
    </NoListLabel>
  </>
)

EmptyListMessage.propTypes = {
  status: PropTypes.string.isRequired
}

export default EmptyListMessage
