import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'

class FollowingActions {
  fetchFollowing = () => ({
    type: constants.FETCH_FOLLOWING,
    promise: (dispatch, getState) => {
      const state = getState()
      const currenUserId = authSelectors.getCurrentUser(state).id
      return digitalStoreSdk.users.fetchCustomerRelationshipsForUser({
        userId: currenUserId,
        relationshipType: 'follow',
        includes: 'customer'
      })
    }
  })

  addToFollowing = ({ id, customer }) => ({
    type: constants.ADD_TO_FOLLOWING,
    id: id || (customer && customer.id),
    customer
  })

  removeFromFollowing = ({ id, customer }) => ({
    type: constants.REMOVE_FROM_FOLLOWING,
    id: id || (customer && customer.id)
  })
}

export default new FollowingActions()

