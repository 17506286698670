import { paymentRequest } from './paymentRequest'
import { unrefrerencedRefundRequest } from './unreferencedRefundRequest'
import { refrerencedRefundRequest } from './referencedRefundRequest'
import { connectedTerminalsRequest } from './connectedTerminalsRequest'

export class AdyenCloudPaymentService {

  async makePaymentRequest(paymentDevice, total, orderNumber) {
    return paymentRequest(paymentDevice, total, orderNumber)
  }

  async makeUnreferencedRefundRequest(paymentDevice, total, orderNumber) {
    return unrefrerencedRefundRequest(paymentDevice, total, orderNumber)
  }

  async makeReferencedRefundRequest(paymentDevice, originalTransactionId, originalTransactionTimestamp, total = undefined, orderNumber) {
    return refrerencedRefundRequest(paymentDevice, originalTransactionId, originalTransactionTimestamp, total, orderNumber)
  }

  async makeConnectedTerminalsRequest() {
    return connectedTerminalsRequest()
  }

}
