import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroller'
import Grid from '@material-ui/core/Grid'

import ProductsGridItem from './ProductsGridItem'
import style from './style'
import LoadMoreButton from '../../../../../components/LoadMoreButton'

const defaultProductMapper = product => product

class ProductsGrid extends PureComponent {
  renderGridItems = () => {
    const {
      products,
      onProductClick,
      onButtonClick,
      buttonValue,
      productMapper = defaultProductMapper,
      multiSelect,
      modalIndex,
      disablePrice,
      push,
      goBack
    } = this.props

    return (products || [])
      .map((product, index) => {
        return (
          <ProductsGridItem
            modalIndex={modalIndex}
            component={'li'}
            key={index}
            product={product}
            onProductClick={onProductClick}
            onButtonClick={onButtonClick}
            buttonValue={buttonValue}
            multiSelect={multiSelect}
            disablePrice={disablePrice}
            push={push}
            goBack={goBack}
          />
        )
      })
  }

  render () {
    const { handleLoadMore, hasMore, classes, isLoading, products: results } = this.props

    return (
      <>
        <div
          className={classes.container}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          initialLoad={false}
          // useWindow={false}
        >
          <Grid className={classes.listContainer} container spacing={0} component={'ul'}>
            {this.renderGridItems()}
          </Grid>
        </div>
        {results && results.length > 0 && <div className={classes.loadMoreContainer}>
          <LoadMoreButton
            hasMore={hasMore}
            isLoading={isLoading}
            loadNext={handleLoadMore}
          />
        </div>}
      </>
    )
  }
}

export default withStyles(style)(ProductsGrid)
