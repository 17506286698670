import React, { useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Collapse } from '@material-ui/core'

import style from './style'
import currencyFormatter from '../../../formatters/currencyFormatter'

const GiftCardActivationCollapsible = ({ classes, defaultOpen = false, giftCardResponses = [], title = 'Gift Cards' }) => {
  const numPayments = _.size(giftCardResponses)
  const [expanded, setExpanded] = useState(!!((defaultOpen && numPayments)))

  const toggle = () => {
    if (numPayments) {
      setExpanded(!expanded)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.listItemName}>{title} ({numPayments})</div>
        <div className={classes.listItemValue} onClick={toggle}>
          <ExpandMoreIcon
            className={clsx(classes.expandIcon, { [classes.expandOpen]: expanded })}
            aria-expanded={expanded}
            aria-label="show more"
          />
        </div>
      </div>
      <div className={classes.listContainer}>
        <Collapse timeout='auto' unmountOnExit in={expanded}>
          {_.map(giftCardResponses, response => {
            const serial = _.get(response, 'SaleToPOIResponse.StoredValueResponse.StoredValueResult[0].StoredValueAccountStatus.StoredValueAccountID.StoredValueID', null)
            const name = _.isEmpty(serial) ? 'Gift Card' : `Gift Card - ${serial.slice(-9)}`
            const itemAmount = _.get(response, 'SaleToPOIResponse.StoredValueResponse.StoredValueResult[0].ItemAmount', null)
            const currency = _.get(response, 'SaleToPOIResponse.StoredValueResponse.StoredValueResult[0].Currency', null)
            const amount = currencyFormatter.format({
              code: currency,
              value: itemAmount
            })
            return (
              <div className={classes.listItem}>
                <div className={classes.listItemName}>{name}</div>
                <div className={classes.listItemValue}>{amount}</div>
              </div>
            )
          })}
        </Collapse>
      </div>
    </div>
  )
}

GiftCardActivationCollapsible.propTypes = {
  defaultOpen: PropTypes.bool
}

export default withStyles(style)(GiftCardActivationCollapsible)
