import React, { useEffect, useMemo } from 'react'
import _, { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { goBack } from 'connected-react-router'

import toastService from '../../../services/toastService/toastService'
import Container from '../../../components/Container'
import SubHeader from '../../../components/SubHeader'
import LoaderContainer from '../../../components/LoaderContainer'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import StoreDetailsForm from '../StoreDetailsForm'
import { translations } from '../../../config'

import { actions as productCataloguesActions } from '../../../store/modules/productCatalogues'
import { actions as storesActions } from '../../../store/modules/stores'
import { actions as storeDetailsActions } from '../../../store/modules/storeDetails'
import { actions as regionActions } from '../../../store/modules/regions'
import { actions as calendarsActions } from '../../../store/modules/calendars'
import { useResults as useRegionResults, useIsLoading as useIsRegionLoading } from '../../../hooks/regionsHooks'
import { useResults as useStoreResults, useIsFetchStoreLoading } from '../../../hooks/storeDetailsHooks'
import { useResults as useProductCatalogueResults, useIsLoading as useIsProductCataloguesLoading } from '../../../hooks/productCataloguesHooks'
import { useResults as useCalendarsResults, useIsLoading as useIsCalendarsLoading } from '../../../hooks/calendarsHooks'
import { getCurrentUser } from '../../../store/modules/auth/selectors'

const StoreDetailsScreen = ({ match }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const storeId = _.get(match, 'params.id')
  const regions = useRegionResults()
  const calendars = useCalendarsResults()
  const storeDetails = useStoreResults()
  const productCatalogues = useProductCatalogueResults()
  const isRegionLoading = useIsRegionLoading()
  const isStoreLoading = useIsFetchStoreLoading()
  const isCalendarsLoading = useIsCalendarsLoading()
  const isProductCataloguesLoading = useIsProductCataloguesLoading()
  const isDependenciesLoading = isRegionLoading || isProductCataloguesLoading || isCalendarsLoading
  const isLoading = storeId ? isStoreLoading || isDependenciesLoading : isDependenciesLoading
  const formMode = storeId ? 'edit' : 'create'
  const title = storeId ? _.get(storeDetails, 'name') : translations('Create store')
  const currentUser = getCurrentUser(state)

  const pickStoreDetailsValues = (values) => {
    return _.pick(values, [
      'name',
      'contactEmail',
      'externalStoreId',
      'regionId',
      'catalogue'
    ])
  }

  const getStoreDetailValues = (values) => ({
    ...pickStoreDetailsValues(values),
    details: {
      phoneNumber: values.phoneNumber,
      address: {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        county: values.county,
        postCode: values.postCode,
        country: values.country
      },
      storeLogo: values.storeLogo,
      latLng: values.latLng,
      termsAndCondition: values.termsAndCondition,
      companyName: values.companyName,
      companyVAT: values.companyVAT,
      receiptLanguage: values.receiptLanguage,
      tacMarkdown: values.tacMarkdown,
      storeBrand: values.storeBrand
    }
  })

  const getStoreDetails = () => ({
    address1: get(storeDetails, 'details.address.address1', ''),
    address2: get(storeDetails, 'details.address.address2', ''),
    city: get(storeDetails, 'details.address.city', ''),
    county: get(storeDetails, 'details.address.county', ''),
    postCode: get(storeDetails, 'details.address.postCode', ''),
    country: get(storeDetails, 'details.address.country', ''),
    phoneNumber: get(storeDetails, 'details.phoneNumber', ''),
    storeLogo: get(storeDetails, 'details.storeLogo', ''),
    latLng: get(storeDetails, 'details.latLng', ''),
    companyName: get(storeDetails, 'details.companyName', ''),
    companyVAT: get(storeDetails, 'details.companyVAT', ''),
    receiptLanguage: get(storeDetails, 'details.receiptLanguage', ''),
    tacMarkdown: get(storeDetails, 'details.tacMarkdown', ''),
    storeBrand: get(storeDetails, 'details.storeBrand', ''),
    termsAndCondition: get(storeDetails, 'details.termsAndCondition', '')
  })

  const regionOptions = useMemo(() => {
    return regions.map(region => ({
      value: region.id,
      label: region.name
    }))
  }, [regions])

  const calendarOptions = useMemo(() => {
    return calendars.map(calendar => ({
      value: calendar.id,
      label: calendar.name
    }))
  }, [calendars])

  const initialValues = useMemo(() => ({
    ...pickStoreDetailsValues(storeDetails),
    ...getStoreDetails(),
    calendarIds: [
      ..._.map(_.get(storeDetails, 'calendars', []), calendar => {
        return calendar.id
      })
    ]
  }), [storeDetails])

  const submitCreate = (values) => {
    const detailsValues = getStoreDetailValues(values)
    const calendarIds = _.get(values, 'calendarIds')
    return dispatch(storeDetailsActions.createStore(detailsValues))
      .then((res) => {
        if (calendarIds) {
          return dispatch(
            storeDetailsActions.setStoreCalendars({
              id: res.id,
              calendarIds
            })
          )
        } else {
          return res
        }
      })
      .then(() => {
        dispatch(storesActions.fetchAllStores({ includes: ['region'], sort: 'name' }))
        dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Store successfully created'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const submitEdit = (values) => {
    const detailsValues = getStoreDetailValues(values)
    const calendarIds = _.get(values, 'calendarIds')
    return dispatch(storeDetailsActions.updateStore({ id: storeId, fields: detailsValues }))
      .then((res) => {
        if (calendarIds) {
          return dispatch(storeDetailsActions.setStoreCalendars({
            id: storeId,
            calendarIds
          }))
        } else {
          return res
        }
      })
      .then(() => {
        dispatch(storesActions.fetchAllStores({ includes: ['region'], sort: 'name' }))
        dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Store successfully updated'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  useEffect(() => {
    if (storeId) {
      dispatch(storeDetailsActions.fetchStore({ id: storeId, includes: 'calendars' }))

      return () => {
        dispatch(storeDetailsActions.clear())
      }
    }
  }, [storeId])

  useEffect(() => {
    dispatch(regionActions.fetchAllRegions())
    dispatch(productCataloguesActions.fetchProductCatalogues())
    dispatch(calendarsActions.fetchCalendarsFresh())
  }, [])

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(title)} />
        )}
      />
      <Container>
        <LoaderContainer isLoading={isLoading}>
          <p>{translations('required fields')}</p>
          <StoreDetailsForm
            currentUser={currentUser}
            regions={regionOptions}
            catalogues={productCatalogues}
            calendars={calendarOptions}
            initialValues={initialValues}
            mode={formMode}
            onSubmit={storeId ? submitEdit : submitCreate}
          />
        </LoaderContainer>
      </Container>
    </div>
  )
}

export default StoreDetailsScreen
