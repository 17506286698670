import _ from 'lodash'
import { submit, reset, isValid as rfIsValid, getFormError, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import FormContentBox from './FormContentBox'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { formId, editable } = ownProps
  const submitForm = () => dispatch(submit(formId))
  const formError = getFormError(formId)(state)
  const isValid = rfIsValid(formId)(state)
  const isSubmitting = _.get(state, `form.${formId}.submitting`, false)
  const resetForm = () => dispatch(reset(formId))
  return {
    ...ownProps,
    isValid,
    isSubmitting,
    submitForm,
    formError,
    editable,
    resetForm
  }
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FormContentBox)

export default formId => reduxForm({
  form: formId
})(connectedComponent)
