import React from 'react'
import styled from 'styled-components'
import { getImage } from '../../../components/Images'
import modalService from '../../../services/modalService'

export const ProductInformation = styled.div`
  text-align: left;
  margin: 5% 10% 5% 10%;
  font-size: 0.9em;
`

export const StepperWrapper = styled.div`
  border-top: 1px solid #B9B9B9;
  font-size: 0.9em;
  padding-top: 1.3em;
`

const Close = styled.img`
  &:hover { cursor: pointer; }
`

const WarningMessage = styled.div`
  font-weight: bold;
  padding: 1em 1em 0.5em 1em;
  background-color: #F8F8F8;
`

export const CloseButton = (
  <div style={{ textAlign: 'right' }}>
    <Close src={getImage('closeIconBlack')} onClick={() => modalService.close()}/>
  </div>
)

export const WarningLabel = (text) => (
  <WarningMessage>
    <img style={{ float: 'left' }} src={getImage('iconExclamationTriangle')}/>
    <div style={{ marginLeft: '1.8em', textAlign: 'left' }}>
      {text}
    </div>
  </WarningMessage>
)