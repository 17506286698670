import { connect } from 'react-redux'
import { change } from 'redux-form'

import StoresSearchScreen from '../../../containers/Stores/StoresSearchScreen'
import SelectStore from './SelectStore'
import modalService from '../../../services/modalService'
import { actions as rolesActions, selectors as rolesSelectors } from '../../../store/modules/roles'

const onBackClick = () => modalService.close({ modalIndex: 2 })

const handleRemoveStoreClick = (ownProps, dispatch) => () => dispatch(change(ownProps.formName, ownProps.name, null))

const handleStoreClick = (ownProps, dispatch) => (cell) => {
  const store = cell.row.original
  const { id, name, externalStoreId } = store
  const value = { id, name, externalStoreId }

  modalService.close({ modalIndex: 2 })
  dispatch(change(ownProps.formName, ownProps.name, value))
}

const handleSearchClick = ({ ownProps, state, dispatch }) => () => {
  const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(state)
  const rolesStoreHasError = rolesSelectors.getHasError(state)
  const onStoreClick = handleStoreClick(ownProps, dispatch)

  if (rolesStoreIsNotLoaded || rolesStoreHasError) {
    dispatch(rolesActions.fetchAllRoles())
  }

  modalService.open({
    modalIndex: 2,
    component: StoresSearchScreen,
    fullScreen: true,
    onStoreClick,
    onBackClick
  })
}

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const onSearchClick = handleSearchClick({ ownProps, state, dispatch })
  const onRemoveStoreClick = handleRemoveStoreClick(ownProps, dispatch)

  return {
    ...ownProps,
    onSearchClick,
    onRemoveStoreClick
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectStore)
