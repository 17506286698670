import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  promotions: [],
  error: null,
  isLoading: true,
  removePromotionFromOrder: false,
  hasPromoCode: false,
  hasValidPromoCode: false,
  promoCode: ''
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PROMOTIONS:
      return reducers.fetchPromotionsAndPromotionConditions(state, action)
    case constants.SET_REMOVE_PROMOTION_FROM_ORDER:
      return reducers.setRemovePromotionFromOrder(state, action)
    case constants.SET_HAS_PROMO_CODE:
      return reducers.setHasPromoCode(state, action)
    case constants.SET_PROMO_CODE:
      return reducers.setPromoCode(state, action)
    case constants.SET_HAS_VALID_PROMO_CODE:
        return reducers.setHasValidPromoCode(state, action)
    case constants.RESET_PROMOTIONS_STATE:
        return reducers.resetPromotionState(state, action)
    default:
      return state
  }
}
