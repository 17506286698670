import { connect } from 'react-redux'
import _ from 'lodash'
import CashFloatModal from './CashFloatModal'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { getFormValues } from 'redux-form'
import { formId } from './CashFloatModal'
import { addPrices, subtractPrices } from '@redant/digital-store-prices-fiskars/dist/handlePrices'
import { selectors as authSelectors } from '../../store/modules/auth'
import { translations } from '../../config'

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(formId)(state)
  const currencyCode = authSelectors.getCurrencyCode(state)

  return {
    currencyCode,
    formValues: formValues || {},
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['formValues', 'currentFloat'], ({ formValues, currentFloat: _currentFloat, removeFloat, currencyCode }) => {
    let currentFloat = _currentFloat
    if (!currentFloat) {
      currentFloat = { code: currencyCode, value: 0 }
    }

    const fn = removeFloat
      ? subtractPrices
      : addPrices
    const totalFloat = fn(currentFloat, { code: currencyCode, value: formValues.float })

    const totalFloatError = totalFloat.value < 0
      ? translations('Total float must not be a negative value.')
      : undefined

    return { totalFloat, totalFloatError, currentFloat }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
    },
    success: ({ currentFloat, totalFloat, formValues, onSubmit, currencyCode }) => () => {
      return onSubmit({
        currentFloat: currentFloat,
        totalFloat: totalFloat,
        addedFloat: { 
          code: currencyCode, 
          value: formValues.float, 
        },
        floatComment: formValues.floatComment 
      })
    }
  }),
)(CashFloatModal)
