import {
  FETCH_PRODUCTLIST,
  FETCH_PRODUCTLISTS,
  CREATE_PRODUCTLIST,
  UPDATE_PRODUCTLIST,
  PUT_PRODUCTLIST,
  DELETE_PRODUCTLIST,
  SET_PRODUCTLIST_DETAILS_LOADING,
  SET_ORDER_BY,
  SET_SEARCH_QUERY,
  SET_PRODUCT_LIST_ERROR,
  SET_PRODUCT_LIST_UPDATING
} from './constants'
import {
  fetchProductList,
  fetchProductLists,
  createProductList,
  updateProductList,
  putProductList,
  deleteProductList,
  setProductListDetailsLoading,
  setOrderBy,
  setSearchQuery,
  setProductListsError,
  setProductListDetailsUpdating
} from './reducers'

export const defaultState = {
  list: {},
  user: {},
  products: [],
  isLoading: false,
  isUpdating: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTLIST:
      return fetchProductList(state, action)
    case FETCH_PRODUCTLISTS:
      return fetchProductLists(state, action)
    case CREATE_PRODUCTLIST:
      return createProductList(state, action)
    case UPDATE_PRODUCTLIST:
      return updateProductList(state, action)
    case PUT_PRODUCTLIST:
      return putProductList(state, action)
    case DELETE_PRODUCTLIST:
      return deleteProductList(state, action)
    case SET_PRODUCTLIST_DETAILS_LOADING:
      return setProductListDetailsLoading(state, action)
    case SET_ORDER_BY:
      return setOrderBy(state, action)
    case SET_SEARCH_QUERY:
      return setSearchQuery(state, action)
    case SET_PRODUCT_LIST_UPDATING:
      return setProductListDetailsUpdating(state, action)
    case SET_PRODUCT_LIST_ERROR:
      return setProductListsError(state, action)
    default:
      return state
  }
}
