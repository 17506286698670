import { AdyenCloudPaymentService } from '../paymentDevicesService/adyenCloudPaymentDevice/adyenCloud'
// import { StarWebPRNTPaymentService } from './starWebPRNT'

/**
 * These should match the database paymentTerminals[].deviceType field
 */
export const ADYEN_CLOUD_PAYMENT_DEVICE = 'card:adyen-cloud'
export const STAR_WEBPRNT_PAYMENT_DEVICE = 'cash:star-webprnt'
export const STAR_CLOUD_PRNT_DEVICE = 'cash:star-cloudprnt'
export const PAY_BY_LINK = 'internal:pay-by-link'
export const PAY_BY_EMAIL= 'internal:pay-by-email'
export const PAY_BY_NATIONAL_VOUCHER= 'internal:navo'
export const PAY_BY_WOLT= 'internal:wolt'
export const ADYEN_CLOUD_GIFT_CARD_PAYMENT_DEVICE = 'gift-card:adyen-cloud'

export const UnsupportedPaymentDevicesForRefund = [ADYEN_CLOUD_GIFT_CARD_PAYMENT_DEVICE]

const paymentDevices = {
  [ADYEN_CLOUD_PAYMENT_DEVICE]: new AdyenCloudPaymentService()
  // [STAR_WEBPRNT_PAYMENT_DEVICE]: new StarWebPRNTPaymentService(),
}


export const getPaymentDeviceType = (paymentDeviceOrDeviceType) => {
  if (typeof paymentDeviceOrDeviceType === 'object') {
    if (paymentDeviceOrDeviceType.deviceType.includes('cash')) {
      return 'cash'
    }
    if (paymentDeviceOrDeviceType.deviceType.includes('card')) {
      return 'card'
    }
    return null
  }

  if (typeof paymentDeviceOrDeviceType === 'string') {
    if (paymentDeviceOrDeviceType.includes('cash')) {
      return 'cash'
    }
    if (paymentDeviceOrDeviceType.includes('card')) {
      return 'card'
    }
  }

  return null
}

export default paymentDevices
