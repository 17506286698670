import digitalStoreSdk from '../../../digitalStoreSdk'
import couchbaseService from '../../../services/couchbaseService'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'
import { logger } from '../../../helpers'
import { groupedProductsEnabled } from '../../../config'

class ProductsActions {
  searchProductsFresh = ({ query, sort, category }) => {
    logger.trace(`searchProductsFresh`)
    logger.debug({ query, sort, category })
    return ({
      type: constants.SEARCH_PRODUCTS_FRESH,
      sort,
      query,
      category,
      promise: (dispatch, getState) => {
        const state = getState()
        const isConnected = networkSelectors.isConnected(state)
        const region = authSelectors.getUserSelectedRegionId(getState())
        const catalogue = authSelectors.getUserSelectedStoreCatalogue(state)
        const searchProductsCategory = category === undefined ? selectors.getCategory(state) : category
        const searchProductsSort = sort === undefined ? selectors.getSort(state) : sort
        const searchProductsQuery = query === undefined ? selectors.getQuery(state) : query
        const size = selectors.getSize(state)

        if (isConnected) {
          return digitalStoreSdk.products
            .searchProducts({
              region,
              catalogue: catalogue || undefined,
              grouped: groupedProductsEnabled,
              query: searchProductsQuery || null,
              sort: searchProductsSort,
              category: searchProductsCategory ? searchProductsCategory.id : undefined,
              page: 1,
              size
            })
            .then(result => ({
              query: searchProductsQuery,
              sort: searchProductsSort,
              category: searchProductsCategory,
              ...result
            }))
        }

        // offline mode flow
        return couchbaseService.searchProducts({
          catalogue: catalogue || undefined,
          region,
          query: searchProductsQuery,
          sort: searchProductsSort,
          category: searchProductsCategory ? searchProductsCategory.id : undefined,
          page: 1,
          size
        }).then(result => ({
            query: searchProductsQuery,
            sort: searchProductsSort,
            category: searchProductsCategory,
            ...result
        }))
      }
    })
  }

  searchProductsNext = () => ({
    type: constants.SEARCH_PRODUCTS_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const { products: { page, query, size, sort, category } } = state
      const isConnected = networkSelectors.isConnected(state)
      const region = authSelectors.getUserSelectedRegionId(state)
      const catalogue = authSelectors.getUserSelectedStoreCatalogue(state)
        if (isConnected) {
            return digitalStoreSdk.products
                .searchProducts({
                    grouped: groupedProductsEnabled,
                    region,
                    query,
                    sort,
                    page: page + 1,
                    size,
                    category: category ? category.id : undefined,
                    catalogue: catalogue || undefined,
                })
                .then(result => ({
                    query,
                    sort,
                    category,
                    ...result
                }))
        }

        // offline mode flow
        return couchbaseService.searchProducts({
            grouped: groupedProductsEnabled,
            region,
            query,
            sort,
            page: page + 1,
            size,
            category: category ? category.id : undefined,
            catalogue: catalogue || undefined,
        }).then(result => ({
            query,
            sort,
            category,
            ...result
        }))
    }
  })

  syncProductsForCatalogues = () => ({
    type: constants.SYNC_PRODUCTS_FOR_CATALOGUES
  })

  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new ProductsActions()
