import _ from 'lodash';
import PouchDB from 'pouchdb'
import uuid from 'uuid';

PouchDB.plugin(require('pouchdb-find').default)

class OfflinePaymentDevicesService {
  constructor() {
    this.dataStore = new PouchDB(`offline_payment_devices`)
    window.offlinePaymentDevicesDB = this.dataStore
  }

  async create({ paymentDevices }) {
    const { total_rows } = await this.dataStore.allDocs()
    if(total_rows) {
      await this.dataStore.destroy()
      this.dataStore = new PouchDB(`offline_payment_devices`)
    }
    await this.dataStore.put({ _id: uuid(), paymentDevices })
  }

  async getPaymentDevices() {
    const { rows } = await this.dataStore.allDocs({ include_docs: true })
    return rows.reduce((acc, item) => _.unionWith(acc, _.get(item, 'doc.paymentDevices', []), _.isEqual), [])
  }
}

export default new OfflinePaymentDevicesService()
