import { createSelector } from 'reselect'
import _ from 'lodash'
import { FETCH_ALL_CATEGORIES } from './constants'
import { PENDING } from '../../middleware/redux-promise'

export const getStatus = state => state.categories.status
export const getAction = state => state.categories.action
export const getIsInitial = state => !state.categories.status
export const getCategories = state => _.get(state, 'categories.categories', [])
export const getRootCategories = state => _.chain(state)
  .get('categories.categories', [])
  .reject(category => category.parentId)
  .reject(category => category.externalCategoryId === 'drinks')
  .reject(category => _.get(category, 'details.isExcludedInCategoryDetailsPage', false))
  .sortBy('order')
  .value()
export const getChildCategories = (state, props) => {
  const { category: parentCategory } = props || {}
  return _.chain(state)
    .get('categories.categories', [])
    .filter(category => category.parentId === _.get(parentCategory, 'id'))
    .value()
}
export const getHasChildCategories = (state, props) => {
  return _.chain(getChildCategories(state, props))
    .thru(childCategories => !_.isEmpty(childCategories))
    .value()
}
export const getParentCategory = (state, props) => {
  const { category: childCategory } = props || {}
  return _.chain(state)
    .get('categories.categories', [])
    .find(category => category.id === _.get(childCategory, 'parentId'))
    .value()
}
export const getIsLoading = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === FETCH_ALL_CATEGORIES)
))

// hopefully as this is memoized the hashmap will be created literally once
// (until you refresh the page, ofc)
export const getCategoryHashmap = createSelector(
  [getCategories],
  (categories) => {
    return (categories || []).reduce(
      (hashmap, category) => {
        return {
          ...hashmap,
          [category.id]: category
        }
      }, {}
    )
  }
)

export const getCategoryById = (state, id) => getCategoryHashmap(state)[id]

export const getChildCategoriesById = (state, id) => {
  return _.chain(getCategories(state))
    .filter(category => category.parentId === id)
    .sortBy('order')
    .value()
}

export const getHasChildCategoriesById = (state, id) => {
  return !!getChildCategoriesById(state, id).length
}

export const getParentCategoryById = (state, id) => {
  const parentId = _.get(getCategoryById(state, id), 'parentId')
  return getCategoryById(state, parentId)
}
