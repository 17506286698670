import React from 'react';
import PrivateRoute from '../../components/PrivateRoute';
import TimedLogoutComponent from '../../components/TimedLogout';
import VisitorConnectionCountScreen from './VisitorConnectionCountScreen';

export default [
  <PrivateRoute
    exact
    key="/visitor-connection-count"
    path="/visitor-connection-count"
    component={TimedLogoutComponent(VisitorConnectionCountScreen)}
  />,
];
