import { translations, countries, countryPhoneCodes, stateOptions } from '../../../config'
import CustomerPhoneNumberField from '../../../containers/Customer/CustomerEditableFields/PhoneNumber'
import * as validators from '../../../validators'

const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

export default [{
  id: 'address1',
  field: 'Input',
  props: {
    label: translations('Address Line One'),
    name: `address.address1`,
    required: false,
    shrink: true
  }
}, {
  id: 'address2',
  field: 'Input',
  props: {
    label: translations('Address Line Two'),
    name: `address.address2`,
    required: false,
    shrink: true
  }
}, {
  id: 'city',
  field: 'Input',
  props: {
    label: translations('City'),
    name: `address.city`,
    required: false,
    shrink: true
  }
}, {
  id: 'county',
  field: 'Dropdown',
  props: {
    label: translations('State'),
    name: `address.county`,
    required: false,
    options: stateOptions,
    shrink: true
  }
}, {
  id: 'country',
  field: 'Dropdown',
  props: {
    label: translations('Country'),
    name: `address.country`,
    required: true,
    options: countries,
    shrink: true
  }
}, {
  id: 'postCode',
  field: 'Input',
  props: {
    label: translations('Postal Code'),
    name: `address.postCode`,
    required: false,
    shrink: true
  }
// }, {
//   id: 'homePhoneNumber',
//   component: CustomerPhoneNumberField,
//   props: {
//     label: translations('Home Phone Number'),
//     name: 'details.homePhoneNumber',
//     options: countryPhoneCodes,
//     validate: [telephoneLengthValidator, validators.phoneNumber],
//     showSmsButton: true
//   }
}]
