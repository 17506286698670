import React from 'react'
import { isArray } from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getImage } from '../../Images'
import { colors } from '../../../config/theme'

const CardWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
  font-size: 14px;
  & + & {
    margin-top: 15px;
  }
`

const LeftIcon = styled.div`
  text-align: center;
  padding: 10px 0 10px 10px;
  display: inline-block;
`

const ProductInformation = styled.div`
  padding: 10px;
  display: inline-block;
  flex-grow: 1;
`

const Info = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin: 0 7px 0 0;
`

const InfoIcon = styled.img`
  width: 17px;
  margin-right: 7px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
`

const RightButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: ${({ hasBottomRight, hasTopRight }) => {
    if (hasBottomRight && hasTopRight) return 'space-between'
    if (hasTopRight) return 'flex-start'
    if (hasBottomRight) return 'flex-end'
  }};
  padding: 5px;
`

const RightButton = styled.button`
  border: 0;
  background: transparent;
  display: flex;
  flex-direction: row;
`

const RightButtonIcon = styled.img`
  display: inline-block;
  width: 16px;
`

const RightButtonText = styled.span`
  margin-left: 5px;
`
const Title = styled.p`
  margin: 0;
  font-weight: bold;
`

const SubTitle = styled.p`
  margin: 5px 0 0 0;
`

const ExtraInfoContainer = styled.div`
  border-top: 1px solid ${colors.lightGrey};
  display: flex;
  flex-direction: row;
`

const ExtraInfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 5px 10px 10px 10px;
`

const ExtraInfoButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SelectButton = styled.button`
  background: transparent;
  border: 0;
  padding: 10px;
  margin: 0;
`

const SelectButtonImg = styled.img`
  width: 25px;
  height: 25px;
  display: block;
`

const ProductListDetailsCard = ({
  title,
  subTitle,
  leftIcon,
  topRightButton,
  bottomRightButton,
  info,
  extraInfoStyle,
  extraInfoContainerStyle,
  extraInfo,
  extraInfoRightButton,
  isSelectable,
  isSelected,
  onSelect,
  disabled
}) => {
  const hasLeftIcon = !!leftIcon
  const hasRightButtons = !!topRightButton || !!bottomRightButton
  const hasInfo = info.length > 0
  const hasExtraInfo = extraInfo.length > 0
  const hasExtraInfoButton = !!extraInfoRightButton

  const renderInfo = (info) => {
    const renderText = () => {
      if (info.weight === 'bold') {
        return <span><b>{info.text}</b></span>
      } else {
        return <span>{info.text}</span>
      }
    }

    return <>
      {info.icon && <InfoIcon src={getImage(info.icon)} />}
      {renderText()}
      {info.label}
    </>
  }

  return (
    <CardWrapper>
      <div>
        <div style={{ display: 'flex' }}>
          {hasLeftIcon && <LeftIcon>
            <img src={getImage(leftIcon.icon)}/>
            {leftIcon.text && <div style={{ fontSize: '12px' }}>{leftIcon.text}</div>}
          </LeftIcon>}
          <ProductInformation>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
            {hasInfo && (
              <InfoWrapper>
                {info.map(i =>
                  <Info>
                    {isArray(i)
                      ? i.map(renderInfo)
                      : renderInfo(i)
                    }
                  </Info>
                )}
              </InfoWrapper>
            )}
          </ProductInformation>
          {hasRightButtons && (
            <RightButtons hasTopRight={!!topRightButton} hasBottomRight={!!bottomRightButton}>
              {topRightButton && <RightButton style={{cursor:'pointer'}} disabled={disabled} onClick={topRightButton.onClick}>
                <RightButtonIcon src={getImage(topRightButton.icon)} />
                {topRightButton.text && <RightButtonText>{topRightButton.text}</RightButtonText>}
              </RightButton>}
              {bottomRightButton && <RightButton style={{cursor:'pointer'}} disabled={disabled} onClick={bottomRightButton.onClick}>
                <RightButtonIcon src={getImage(bottomRightButton.icon)} />
                {bottomRightButton.text && <RightButtonText>{bottomRightButton.text}</RightButtonText>}
              </RightButton>}
            </RightButtons>
          )}
          {isSelectable && (
            <SelectWrapper>
              <SelectButton disabled={disabled} onClick={onSelect}>
                <SelectButtonImg src={getImage(isSelected ? 'checkFilledGreen' : 'checkUnfilled')} />
              </SelectButton>
            </SelectWrapper>
          )}
        </div>
        {hasExtraInfo && (
          <ExtraInfoContainer style={extraInfoContainerStyle || {}}>
            <ExtraInfoWrapper>
              <InfoWrapper style={extraInfoStyle || {}}>
                {extraInfo.map(i =>
                  <Info style={{cursor: i.onClick ? "pointer" : "inherit"}} onClick={i.onClick || _.noop}>
                    {isArray(i)
                      ? i.map(renderInfo)
                      : renderInfo(i)
                    }
                  </Info>
                )}
              </InfoWrapper>
            </ExtraInfoWrapper>
            {hasExtraInfoButton && (
              <ExtraInfoButtonWrapper>
                {extraInfoRightButton && <RightButton style={{cursor:'pointer'}} disabled={disabled} onClick={extraInfoRightButton.onClick}>
                  <RightButtonIcon src={getImage(extraInfoRightButton.icon)} />
                  {extraInfoRightButton.text && <RightButtonText>{extraInfoRightButton.text}</RightButtonText>}
                </RightButton>}
              </ExtraInfoButtonWrapper>
            )}
          </ExtraInfoContainer>
        )}
      </div>
    </CardWrapper>
  )
}

const iconButtonType = PropTypes.shape({
  icon: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string
})

const infoTextType = PropTypes.shape({
  icon: PropTypes.string,
  text: PropTypes.string,
  weight: PropTypes.string
})

const infoType = PropTypes.arrayOf(
  PropTypes.oneOfType([
    infoTextType,
    PropTypes.arrayOf(infoTextType)
  ])
)

ProductListDetailsCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  leftIcon: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    text: PropTypes.string
  }),
  topRightButton: iconButtonType,
  bottomRightButton: iconButtonType,
  extraInfoRightButton: iconButtonType,
  info: infoType,
  extraInfo: infoType,
  extraInfoContainerStyle: PropTypes.object,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool
}

ProductListDetailsCard.defaultProps = {
  info: [],
  extraInfo: [],
  disabled: false
}

export default ProductListDetailsCard
