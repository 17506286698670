import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import { getFormValues } from 'redux-form'
import { formNames } from '../../../../../../config'
import { actions as variantsStockActions } from '../../../../../../store/modules/variantsStock'
import * as variantsCombinedSelectors from '../../../../../../store/modules/combinedSelectors/variantsCombinedSelectors'
import StoreList from './StoreList'
import { selectors as ProductDetailSelectors } from '../../../../../../store/modules/productDetails'

const mapStateToProps = state => {
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')
  const variantsStock = variantsCombinedSelectors.matchStockWithStore(state)

  const product = ProductDetailSelectors.getProduct(state)
  const variant = _.find(product.variants, (o) => o.id === selectedVariantId)
  const selectedVariantStock = _.get(variant, 'stock')

  return {
    variant,
    selectedVariantId,
    variantsStock,
    selectedVariantStock
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    getVariantStock: ({ dispatch }) => ({ selectedVariantId, variant }) => {
      dispatch(variantsStockActions.fetchVariantStoreStocks({ variantId: selectedVariantId, regionId: variant.regionId }))
    }
  })
)(StoreList)
