import _ from 'lodash'


export const mapTransactionStatusRequest = (paymentDevice, serviceId, saleId) => {
  const paymentDeviceId = makePaymentDeviceId(paymentDevice)

  const result = {
    SaleToPOIRequest: {
      MessageHeader: {
        ProtocolVersion: '3.0',
        MessageClass: 'Service',
        MessageCategory: 'TransactionStatus',
        MessageType: 'Request',
        SaleID: saleId,
        ServiceID: '010' + getRandomInt(100000, 10000000),
        POIID: paymentDeviceId
      },
      TransactionStatusRequest: {
        MessageReference: {
          SaleID: saleId,
          ServiceID: serviceId,
          MessageCategory: 'Payment'
        }
      }
    }
  }

  return result
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const makePaymentDeviceId = paymentDevice => `${paymentDevice.config.modelNumber}-${paymentDevice.config.serialNumber}`
