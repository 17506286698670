import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BasicModalOverlay from '../BasicModalOverlay'
import { Checkbox, Input } from '../Fields'
import Form from '../Form'

const Overlay = styled.div`
  text-align: center;
`

const Title = styled.p`
  font-weight: bold;
`

const Row = styled.div`
  display: flex;
  margin: 0.5em;
  @media (max-width: 361px) {
    margin: 0.5em 0;
    font-size: 14px;
  }
`

const ColL = styled.div`
  font-weight: bold;
  flex: 1em;
  text-align: right;
  margin-right: 1em;
  transform: translateY(20%);
`

const ColR1 = styled.div`
  margin-right: 0.5em;
  transform: translateY(20%);
`

const ColR2 = styled.div`
  flex: 1;
  margin-right: 1em;
`

const Filter = ({
  formName,
  filters,
  title,
  type,
  text,
  actions
}) => {
  const FilterForm = Form(formName, {
    destroyOnUnmount: false
  })

  const initialValues = () => {
    const values = {}

    switch (type) {
      case 'checkbox':
        filters.forEach(f => {
          values[f.name] = true
        })
        break
    }
    return values
  }

  const renderType = (f) => {
    switch (type) {
      case 'checkbox':
        return <Checkbox
          key={f.name}
          name={f.name}
          defaultChecked={f.defaultChecked}
          label={f.label}
          noErrorTextLabel
        />
      case 'input':
        return <Row>
          <ColL>
            {f.name}
          </ColL>
          {f.input.map(i =>
            <>
              <ColR1>{i.label}</ColR1>
              <ColR2>
                <Input
                  type={i.type}
                  key={i.name}
                  name={i.name}
                />
              </ColR2>
            </>
          )}
        </Row>
    }
  }

  return (
    <BasicModalOverlay
      actions={actions || []}
    >
      <Overlay>
        {title && <Title>{title}</Title>}
        {text && <p>{text}</p>}
        <FilterForm initialValues={initialValues()}>
          {filters.length > 0 && filters.map((f) => <div key={f.label}>
            {renderType(f)}
          </div>)}
        </FilterForm>
      </Overlay>
    </BasicModalOverlay>)
}

Filter.propTypes = {
  filters: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  actions: PropTypes.array
}

Filter.defaultProps = {
  filters: []
}

export default Filter
