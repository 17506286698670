export const FETCH_PRODUCTLIST = 'digitalStore/productListDetails/FETCH_PRODUCTLIST'
export const FETCH_PRODUCTLISTS = 'digitalStore/productListDetails/FETCH_PRODUCTLISTS'
export const CREATE_PRODUCTLIST = 'digitalStore/productListDetails/CREATE_PRODUCTLIST'
export const UPDATE_PRODUCTLIST = 'digitalStore/productListDetails/UPDATE_PRODUCTLIST'
export const PUT_PRODUCTLIST = 'digitalStore/productListDetails/PUT_PRODUCTLIST'
export const DELETE_PRODUCTLIST = 'digitalStore/productListDetails/DELETE_PRODUCTLIST'
export const SET_PRODUCTLIST_DETAILS_LOADING = 'digitalStore/productListDetails/SET_PRODUCTLIST_DETAILS_LOADING'
export const SET_ORDER_BY = 'digitalStore/productListDetails/SET_ORDER_BY'
export const SET_SEARCH_QUERY = 'digitalStore/productListDetails/SET_SEARCH_QUERY'
export const FILTER_PRODUCTS = 'digitalStore/productListDetails/FILTER_PRODUCTS'
export const SET_PRODUCT_LIST_UPDATING = 'digitalStore/productListDetails/SET_PRODUCT_LIST_UPDATING'
export const SET_PRODUCT_LIST_ERROR = 'digitalStore/productListDetails/SET_PRODUCT_LIST_ERROR'
