import formStyle from '../style'
import textContentStyles from '../../../../../components/Form/TextContent/style'

const styles = (theme) => ({
  storesContainer: {
    width: '100%',
    marginTop: '-20px',
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    }
  },
  fullWidth: {
    width: '100%'
  }
})

export default (theme) => ({
  ...formStyle,
  ...styles(theme),
  ...textContentStyles
})
