import { connect } from 'react-redux'
import NetworkNotification from './NetworkNotification'
import { selectors as networkSelectors } from '../../store/modules/network'
import { selectors as offlineSelectors } from '../../store/modules/offline'

const mapStateToProps = state => {
  const isOffline = !networkSelectors.isConnected(state)
  const offlineOrderCount = offlineSelectors.getOfflineOrderCount(state)

  return {
    isOffline,
    offlineOrderCount
  }
}

export default connect(mapStateToProps)(NetworkNotification)
