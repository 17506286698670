import { FormControl } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { translations } from '../../../config'
import currencyFormatter from '../../../formatters/currencyFormatter'
import AdyenGiftCardPaymentDevice from '../../../services/paymentDevicesService/adyenGiftCardPaymentDevice'
import * as validators from '../../../validators'
import BasicModalOverlay from '../../BasicModalOverlay'
import { Input } from '../../Fields'
import Form from '../../Form'
import LoaderContainer from '../../LoaderContainer'
import { Container, Error, Paragraph } from './CheckBalanceModal.style'

const FormWrapper = Form('CheckBalanceModal')

export const CheckBalanceModal = ({
  onCancel,
  inputMethod,
  cardDetails,
  total,
  success,
  orderNumber,
  adyenTerminal,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [giftCardDetails, setGiftCardDetails] = useState(cardDetails)
  const [isPaymentBeingProcessed, setIsPaymentBeingProcessed] = useState()
  const [error, setError] = useState(null)
  const [amount, setAmount] = useState(total.value)

  const handleInputChange = ({ target: { value } }) => {
    setAmount(value)
  }

  const validateAmount = amount => {
    const leftToPay = total.value
    if (leftToPay < parseFloat(amount)) {
      return `Amount must not be more than ${leftToPay}.`
    }

    return validators.required(amount)
  }

  const actions = [
    {
      text: translations('Cancel'),
      onClick: () => {
        onCancel()
      }
    },
    {
      disabled: !giftCardDetails.value || error || isPaymentBeingProcessed,
      text: translations('Pay by gift card'),
      primary: true,
      onClick: () => {
        handlePayByGiftCard()
      }
    }
  ]

  const handlePayByGiftCard = async () => {
    setIsPaymentBeingProcessed(true)
    const paymentResult = await AdyenGiftCardPaymentDevice.implementation.makeAPayment(
      `${adyenTerminal.config.modelNumber}-${adyenTerminal.config.serialNumber}`,
      giftCardDetails,
      { value: amount, currency: total.code },
      inputMethod,
      orderNumber,
      adyenTerminal.id
    )

    if (paymentResult.status !== 'Success') {
      setError(paymentResult.errorMessage)
      return
    }

    success(adyenTerminal, { ...paymentResult }) //TODO: why were we sending amount:0?
    setIsPaymentBeingProcessed(false)
  }

  useEffect(() => {
    const getGiftCardBalance = async () => {
      setError(null)
      const data = await AdyenGiftCardPaymentDevice.implementation.checkBalance(
        `${adyenTerminal.config.modelNumber}-${adyenTerminal.config.serialNumber}`,
        cardDetails,
        inputMethod,
        adyenTerminal.id
      )

      if (data.status === 'Success') {
        setGiftCardDetails({
          ...giftCardDetails,
          currency: data.currency,
          value: data.currentBalance
        })
      } else {
        setError(data.errorMessage)
      }

      setIsLoading(false)
    }

    getGiftCardBalance()
  }, [])

  return (
    <BasicModalOverlay
      title={translations('Gift card balance')}
      actions={actions}
    >
      <LoaderContainer isLoading={isLoading}>
        <FormWrapper>
          <Container>
            <Paragraph>
              Card Number:{' '}
              {cardDetails.cardNumber ? cardDetails.cardNumber : 'N/A'}
            </Paragraph>
            <Paragraph>
              Balance:{' '}
              {giftCardDetails.value
                ? currencyFormatter.format({
                  value: giftCardDetails.value,
                  code: giftCardDetails.currency
                })
                : 'N/A'}
            </Paragraph>
            <FormControl>
              <Input
                currencyCode={total.code}
                label={translations('Amount to Pay')}
                name='amount'
                type='price'
                validate={validateAmount(amount)}
                value={amount}
                onChange={handleInputChange}
              />
            </FormControl>
            {error && <Error>{error}</Error>}
          </Container>
        </FormWrapper>
      </LoaderContainer>
    </BasicModalOverlay>
  )
}

export default CheckBalanceModal
