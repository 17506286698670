import { connect } from 'react-redux'
import { compose, withState, withPropsOnChange, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import ViewCustomerActivityScreen from './ViewCustomerActivityScreen'
import { selectors as customerDetailSelectors } from '../../../../store/modules/customerDetails'
import { selectors as activitiesSelectors, actions as activitiesActions } from '../../../../store/modules/activities'

const mapStateToProps = (state) => ({
  customer: customerDetailSelectors.getCustomer(state),
  activity: activitiesSelectors.getActivitiesByCustomer(state),
  isPending: activitiesSelectors.getActivitiesStatus(state) === 'PENDING'
})

export default compose(
  connect(mapStateToProps),
  withState('page', 'setPage', 1),
  withState('hasMore', 'setHasMore', true),
  withPropsOnChange(['isPending', 'activity'], ({ isPending, activity }) => ({
    isLoading: isPending && !activity.length
  })),
  withHandlers({
    fetchNextNotifications: ({ dispatch, setPage, setHasMore, page, isPending, customer }) => () => {
      if (isPending) {
        return
      }

      const nextPage = page + 1
      setPage(nextPage)
      setHasMore(false)
      dispatch(activitiesActions.fetchActivities({
        customerId: customer.id,
        page: nextPage,
        limit: 20
      })).then(newActivities => {
        const isMoreItems = !!(newActivities && newActivities.length >= 20)
        setHasMore(isMoreItems)
      })
    }
  })
)(ViewCustomerActivityScreen)
