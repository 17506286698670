import { countryPhoneCodes, translations } from '../../../config'
import * as validators from '../../../validators'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')
const dobAgeValidator = validators.dobMinAge(16)

export default [{
//   id: 'title',
//   field: 'Dropdown',
//   props: {
//     label: translations('Title'),
//     name: 'title',
//     options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
//     validate: [smallTextLengthValidator],
//     required: false
//   }
// }, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: translations('First Name'),
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: translations('Last Name'),
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'email',
  field: 'Email',
  props: {
    label: translations('Email'),
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: true,
    showEmailButton: true
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: translations('Date Of Birth'),
    name: 'dob',
    validate: [validators.date, dobAgeValidator]
  }
}, {
  id: 'telephone',
  field: 'PhoneNumber',
  props: {
    label: translations('Phone Number'),
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: true
  }
}]
