import React, { Fragment, useEffect, useState } from 'react'
import { translations } from '../../config'
import BasicModalOverlay from '../BasicModalOverlay'
import LoaderContainer from '../LoaderContainer'
import sdk from '../../digitalStoreSdk'
import modalService from '../../services/modalService'
import P from '../P'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import style from './style'
import { withStyles } from '@material-ui/core/styles'
import { getGroupedProducts } from '@redant/digital-store-prices-fiskars/dist/handleProducts'
import Dropdown from '../Fields/Dropdown'
import StarCloudPRNTService from '../../services/paymentDeviceService/starCloudPRNTService'

const GiftReceiptModal = ({
  currentUser,
  currentOrder,
  selectedPrinter,
  classes
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [gifts, setGifts] = useState([])

  useEffect(() => {
    setGifts(getGroupedProducts(currentOrder.products).map(product => ({ id: product.id, quantity: 0 })))
  }, [currentOrder])

  const handleChange = (productId, quantity) => {
    setGifts(gifts.map(gift => gift.id !== productId ? gift : _.set(gift, 'quantity', quantity)))
  }

  const handlePrint = async () => {
    setIsLoading(true)
    const content = StarCloudPRNTService.generateContentToPrint(
      {
        order: currentOrder,
        user: currentUser,
        gifts: gifts.filter(gift => gift.quantity > 0)
      })
    await StarCloudPRNTService.print(selectedPrinter.config.modelNumber, content, 'text/html')
    setIsLoading(false)
    await modalService.close()
  }

  return (
    <BasicModalOverlay title={'Select Gift Items'}
      actions={[{
        text: translations('Print'),
        onClick: handlePrint,
        primary: true
      },
      {
        text: translations('Cancel'),
        onClick: () => { modalService.close() }
      }
      ]}
    >
      <LoaderContainer isLoading={isLoading}>
        <div className={classes.contentWrapper}>
          {getGroupedProducts(currentOrder.products).map(product => {
            return <Grid container justifyContent="space-between" spacing={0}>
              <Grid item xs={9}><P className={classes.productName} type='bold' value={product.name} /></Grid>
              <Grid item xs={3}>
                <Dropdown
                  className={classes.dropdown}
                  value={_.get(gifts.find(gift => gift.id === product.id), 'quantity', 0)}
                  noNullOption
                  options={_.range(product.quantity + 1).map(quantity => ({ label: quantity, value: quantity }))}
                  onChange={(e) => handleChange(product.id, e.target.value)}
                />
              </Grid>
            </Grid>
          })
          }
        </div>
      </LoaderContainer>
    </BasicModalOverlay>
  )
}

export default withStyles(style)(GiftReceiptModal)
