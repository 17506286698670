import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { translations } from '../../config'
import P from '../P'
import style from './style'
import { colors, theme } from '../../config/theme'

const OfflineBannerWrapper = styled.aside`
  width: 100%;
  height: ${theme.offlineBannerHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.error};
  z-index: 101;
`

const OfflineText = styled(P)`
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  margin: 0;
`

const NetworkNotification = ({ isOffline, offlineOrderCount, classes }) => {
  if (isOffline && offlineOrderCount) {
    return (
      <OfflineBannerWrapper role='alert' aria-hidden={!isOffline}>
        <OfflineText type='large' value={translations('Offline notification with orders', { offlineOrderCount })} />
      </OfflineBannerWrapper>
    )
  }

  if (isOffline && !offlineOrderCount) {
    return (
      <OfflineBannerWrapper role='alert' aria-hidden={!isOffline}>
        <OfflineText type='large' value={translations('Offline notification')} />
      </OfflineBannerWrapper>
    )
  }

  if (offlineOrderCount) {
    return (
      <OfflineBannerWrapper role='alert' aria-hidden={!isOffline}>
        <OfflineText type='large' value={translations('Back online notification with orders', { offlineOrderCount })} />
      </OfflineBannerWrapper>
    )
  }

  return null
}

NetworkNotification.propTypes = {
  isOffline: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired
}

NetworkNotification.defaultProps = {
  isOffline: false
}

export default withStyles(style)(NetworkNotification)
