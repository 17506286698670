export default theme => ({
  container: {
    overflowX: 'auto',
    marginRight: '7.5%',
    marginLeft: '7.5%'
  },
  inner: {
    // minWidth: 1200
  },
  noResultsMessage: {
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  loadMoreContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0 15px 0'
  }
})
