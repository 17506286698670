const __RESOURCE_NAME = 'receivedGoodsReceived'


import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const results = state => _.get(state, `${__RESOURCE_NAME}.results`, [])
export const filters = state => _.get(state, `${__RESOURCE_NAME}.filters`, {})
export const hasMore = state => {
  const numResults = _.get(state, `${__RESOURCE_NAME}.results.length`, 0)
  const totalResults = _.get(state, `${__RESOURCE_NAME}.total`, 0)

  return totalResults > numResults
}
export const error = state => _.get(state, `${__RESOURCE_NAME}.error`)
export const loading = state => _.get(state, `${__RESOURCE_NAME}.status`) === PENDING

export const getAllAsOptions = createSelector([
  results
], (items) => {
  return items.map(obj => ({ value: obj.id, label: obj.name }))
})

