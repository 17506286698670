import AdyenCloudPaymentDevice from "./adyenCloudPaymentDevice"
import CashStarCloudPRNTPaymentDevice from "./cashStarCloudPRNTPaymentDevice"
import EmailPaymentDevice from './emailPaymentDevice'
import PayByLinkPaymentDevice from './payByLinkPaymentDevice'
import AdyenGiftCardPaymentDevice from './adyenGiftCardPaymentDevice'
import StarCloudPRNTReceiptPrinter from './starCloudPRNTReceiptPrinter'
import NationalVoucherPaymentDevice from './nationalVoucherPaymentDevice'
import WoltPaymentDevice from './woltPaymentDevice'


const supportedPaymentDevices = [
    AdyenCloudPaymentDevice,
    CashStarCloudPRNTPaymentDevice,
    //EmailPaymentDevice,
    PayByLinkPaymentDevice,
    AdyenGiftCardPaymentDevice,
    NationalVoucherPaymentDevice,
    WoltPaymentDevice
]

const supportedReceiptPrinters = [
    StarCloudPRNTReceiptPrinter
]

export const getSupportedPaymentDevices = () => {
    return supportedPaymentDevices
}

export const getSupportedPaymentDeviceNameForReceipt = (deviceType) => {
    return supportedPaymentDevices.find(x=> x.deviceType === deviceType).nameForReceipt || 'UNKNOWN_PAYMENT_METHOD'
}

export const getSupportedReceiptPrinters = () => {
    return supportedReceiptPrinters
}

// Used on create accessory screen
export const getPaymentDeviceOptions = () => {
    return supportedPaymentDevices.map(obj => {
        return {
            label: obj.deviceName,
            value: obj.deviceType
        }
    })
}
