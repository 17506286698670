import * as constants from './constants'
import reducers from './reducers'
import { INITIAL } from '../../middleware/redux-promise'

export const defaultSchema = {
  status: INITIAL,
  stage: null,
  type: null,
  customerId: null,
  contents: [],
  order: null
}
export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.START_APPOINTMENT:
      return reducers.startAppointment(state, action)
    case constants.CLEAR_APPOINTMENT:
      return reducers.clearAppointment(state, action)
    case constants.ADD_CONTENT:
      return reducers.addContent(state, action)
    case constants.REMOVE_CONTENT:
      return reducers.removeContent(state, action)
    case constants.TOGGLE_CONTENT_IS_SHOWING:
      return reducers.toggleContentIsShowing(state, action)
    case constants.TOGGLE_CONTENT_IS_LOVED:
      return reducers.toggleContentIsLoved(state, action)
    case constants.CUSTOMER_TOGGLE_CONTENT_IS_LOVED:
      return reducers.toggleContentIsLoved(state, action)
    case constants.SET_STAGE:
      return reducers.setStage(state, action)
    case constants.SET_ORDER_NUMBER:
      return reducers.setOrderNumber(state, action)
    case constants.SET_CUSTOMER:
      return reducers.setCustomer(state, action)
    case constants.UPDATE_CURRENT_APPOINTMENT:
      return reducers.updateCurrentAppointment(state, action)
    case constants.SET_CURRENT_APPOINTMENT:
      return reducers.setCurrentAppointment(state, action)
    case constants.SET_CONSULTATION_START_TIME:
      return reducers.setConsultationStartTime(state, action)
    case constants.END_APPOINTMENT:
      return reducers.endAppointment(state, action)
    default:
      return state
  }
}
