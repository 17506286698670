import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import * as authSelectors from '../auth/selectors'
import resourceActions from './actions'
import {FETCH_ALL} from "./constants";
import offlinePaymentDevicesService from '../../../services/oflinePaymentDevicesService'

class ResourceMiddleware {
  fetchResourceMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const path = action.payload.location.pathname
      const { paymentDevices } = getState()
      if (
        authSelectors.getIsLoggedIn(getState()) && paymentDevices.status !== "SUCCESS" &&
        (
          matchPath(path, { path: `/checkout` })
          ||   matchPath(path, { path: `/` })
          || matchPath(path, { path: `/orders/:orderNumber`, exact: true })
          || matchPath(path, { path: `/orders/:orderNumber/refund`, exact: true })
        )
      ) {
        dispatch(resourceActions.fetchAll({ enabled: true }))
      }
    }
  }

  createOfflinePaymentDevicesMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FETCH_ALL && action.status === "SUCCESS" && _.get(action,'result.items',[]).length > 0){
       offlinePaymentDevicesService.create({paymentDevices: action.result.items})
    }
  }
}

export default new ResourceMiddleware()
