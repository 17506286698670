import { connect } from 'react-redux'
import _ from 'lodash'
import CashReviewCountModal from './CashReviewCountModal'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { getFormValues } from 'redux-form'
import { formId } from './CashReviewCountModal'
import { addPrices, subtractPrices } from '@redant/digital-store-prices-fiskars/dist/handlePrices'
import { selectors as authSelectors } from '../../store/modules/auth'
import { translations } from '../../config'

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(formId)(state)
  const currencyCode = authSelectors.getCurrencyCode(state)
  const userId = authSelectors.getActiveUserId(state)
  const userRole = authSelectors.getLoggedInUserRole(state)

  return {
    userId,
    userRole,
    currencyCode,
    formValues: formValues || {},
  }
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(['formValues', 'currentFloat'], ({ formValues, currentFloat, removeFloat, currencyCode }) => {
    const fn = removeFloat
      ? subtractPrices
      : addPrices
    const totalFloat = fn(currentFloat, { code: currencyCode, value: formValues.float })

    const totalFloatError = totalFloat.value < 0
      ? translations('Total float must not be a negative value.')
      : undefined

    return { totalFloat, totalFloatError }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
    },
    success: ({ onSubmit, userId }) => () => {
      return onSubmit({ userId })
    }
  }),
)(CashReviewCountModal)
