import * as validators from '../../../validators'
import personalSchema from '../../../components/Form/commonSchemas/customerPersonalDetails'
import {
  translations,
  getAppConfig
} from '../../../config'

const smallTextLengthValidator = validators.maxLength(50)

export const personal = {
  schema: personalSchema,
  layout: [
    ['firstName', 'lastName'],
    ['email', 'telephone'],
    ['address1', 'address2'],
    ['city', 'county'],
    ['country', 'postCode'],
    // ['homePhoneNumber']
  ]
}

export const loyalty = {
  schema: [{
    id: 'loyaltyTier',
    field: 'Input',
    props: {
      label: translations('Loyalty Tier'),
      name: 'loyaltyTier',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'lifetimeSpend',
    field: 'Input',
    props: {
      label: translations('Lifetime Spend'),
      name: 'lifetimeSpend',
      editable: false
    }
  }],
  layout: [
    ['lifetimeSpend', 'loyaltyTier']
  ]
}

const customerFieldsConfig = getAppConfig('CUSTOMER_MANAGEMENT', 'detailsFields')

const customerSchema = (customerFieldsConfig) && customerFieldsConfig.map(item => {
  const { id, field, validate, ...rest } = item
  return {
    id,
    field,
    props: {
      name: id,
      disabled: ['brands'].includes(id),
      validate: validators.generateSchemaValidationFromConfig(validate),
      ...rest
    }
  }
})

export const customer = {
  schema: customerSchema,
  layout: []
}

export const sizing = {
  schema: [{
    id: 'coatAndJackets',
    field: 'Input',
    props: {
      label: 'Coats / Jackets',
      name: 'coatAndJackets',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'blousesAndShirts',
    field: 'Input',
    props: {
      label: 'Blouses / Shirts',
      name: 'blousesAndShirts',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'trousers',
    field: 'Input',
    props: {
      label: translations('Trousers'),
      name: 'trousers',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'dress',
    field: 'Input',
    props: {
      label: translations('Dress'),
      name: 'dress',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'skirt',
    field: 'Input',
    props: {
      label: translations('Skirt'),
      name: 'skirt',
      validate: [smallTextLengthValidator]
    }
  }, {
    id: 'shoe',
    field: 'Input',
    props: {
      label: translations('Shoe'),
      name: 'shoe',
      validate: [smallTextLengthValidator]
    }
  }],
  layout: [
    ['coatAndJackets', 'blousesAndShirts'],
    ['trousers', 'dress'],
    ['skirt', 'shoe']
  ]
}
