import * as constants from './constants'
import sdk from '../../../digitalStoreSdk'
import promotionService from '../../../services/promotionService'
import offlinePromotionService from '../../../services/oflinePromotionService'
import { selectors as networkSelectors } from '../network'
import { translations } from '../../../config'
import { get } from 'lodash'

class PromotionsActions {
  getAllPromotions = () => ({
    type: constants.FETCH_PROMOTIONS,
    promise: async (_, getState) => {
      const state = getState()
      const isConnected = networkSelectors.isConnected(state)

      if (isConnected) {
        const { items } = await sdk.promotions.getAllPromotions()

        const promotionWithPromotionConditionRequest = items.map(
          async (promotion) => {
            const {
              items: promotionConditions
            } = await sdk.promotions.getPromotionConditionRelationshipsByPromotionId(
              {
                id: promotion.id
              }
            )

            try {
              const productsPromise = get(promotion, 'extraProductVariants', []).map(
                async (id) => {
                  try {
                    const product = await sdk.products.fetchProduct({ id })
                    return { ...product, variant: product.variants[0] }
                  } catch (error) {
                    return {
                      error: translations('Product not found')
                    }
                  }
                }
              )
              const productsResolved = await Promise.all(productsPromise)
              return {
                ...promotion,
                promotionConditions,
                extraProductVariants: productsResolved
              }
            } catch (error) {
              return {
                ...promotion,
                promotionConditions
              }
            }
          }
        )

        const promotionWithPromotionConditionResolved = await Promise.all(
          promotionWithPromotionConditionRequest
        )

        const activePromotions = promotionService.getAllActivePromotions(
          promotionWithPromotionConditionResolved
        )

        await offlinePromotionService.create({
          promotions: activePromotions
        })
        return activePromotions
      }

      const promotions = await offlinePromotionService.getPromotions()
      return promotions
    }
  })

  setRemovePromotionFromOrder = (removePromotionFromOrder) => ({
    type: constants.SET_REMOVE_PROMOTION_FROM_ORDER,
    removePromotionFromOrder
  })

  setHasValidPromoCode = (hasValidPromoCode) => ({
    type: constants.SET_HAS_VALID_PROMO_CODE,
    hasValidPromoCode
  })

  setHasPromoCode = (hasPromoCode) => ({
    type: constants.SET_HAS_PROMO_CODE,
    hasPromoCode
  })

  setPromoCode = (promoCode) => ({
    type: constants.SET_PROMO_CODE,
    promoCode
  })

  resetPromotionState = () => ({
    type: constants.RESET_PROMOTIONS_STATE
  })
}

export default new PromotionsActions()
