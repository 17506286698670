import _ from 'lodash'
import currencyFormatter from '../../../formatters/currencyFormatter'

const getLowestPriceFromProducts = (products = []) => {
  const defaultPrice = {
    code: _.get(products, '0.price.code', 'GBP'),
    value: _.get(products, '0.price.value', '0')
  }

  const result = products.reduce((currentPrice, product) => {
    const productPrice = _.get(product, 'price', defaultPrice)

    if (currentPrice.value === '0' || parseInt(productPrice.value) < parseInt(currentPrice.value)) {
      currentPrice.value = productPrice.value
    }

    return currentPrice
  }, defaultPrice)
  return result
}

const getHighestPriceFromProducts = (products = []) => {
  const defaultPrice = {
    code: _.get(products, '0.price.code', 'GBP'),
    value: _.get(products, '0.price.value', '0')
  }
  const result = products.reduce((currentPrice, product) => {
    const productPrice = _.get(product, 'price', defaultPrice)

    if (parseInt(productPrice.value) > parseInt(currentPrice.value)) {
      currentPrice.value = productPrice.value
    }

    return currentPrice
  }, defaultPrice)
  return result
}

export const getAllVariantsFromProducts = (products = []) => {
  const result = products.reduce((allVariants, product) => {
    const variantsWithProductInfo = _.get(product, 'variants', [])
      .map(variant => {
        return {
          ...variant,
          productId: _.get(product, 'id', null),
          price: _.get(product, 'price', { code: 'unknown', value: 'unknown' }),
          discount: _.get(product, 'discount', { code: 'unknown', value: 'unknown' }),
          images: _.get(product, 'images', [])
        }
      })

    return [...allVariants, ...variantsWithProductInfo]
  }, [])
  return result
}

const getGroupBaseProduct = (group) => {
  // use the most expensive product as the base
  const result = _.chain(group)
    .get('children')
    .reduce((baseProduct, child) => {
      const baseProductPriceValue = parseInt(_.get(baseProduct, 'price.value')) || null
      const childProductPriceValue = parseInt(_.get(child, 'price.value', '0'))

      if (baseProductPriceValue === null || childProductPriceValue >= baseProductPriceValue) {
        return child
      }
      return baseProduct
    }, {})
    .value()
  return result
}

/**
 * @param {Object} result Product search endpoint results grouped by productGroup
 * @returns {Object} Container project object with all variants in order to drive the PLP
 * @example
 * // returns [{...group1Child1, variants: group1AllChildrenVariantsWithProductInfo[]}]
 * parseProductGroups({
 *  groups: [
 *   {
 *     productGroup: 'some product group',
 *     children: [
 *       {
 *         id: 'product-1',
 *         variants: [
 *          {
 *            id: 'variant-1'
 *          }
 *         ]
 *       }
 *     ]
 *   }
 *  ]
 * });
 */
export default function parseProductGroups (result) {
  const groups = _.get(result, 'groups', [])

  const results = groups.map((group, index) => {
    const products = _.get(group, 'children', [])
    const base = getGroupBaseProduct(group)

    const priceFrom = getLowestPriceFromProducts(products)
    const priceTo = getHighestPriceFromProducts(products)

    const displayPriceFrom = currencyFormatter.format(priceFrom.value, priceFrom.code)
    const displayPriceTo = currencyFormatter.format(priceTo.value, priceTo.code)

    // use the first child's data as the "container" group product with the overrides below:
    return {
      ...base,
      productGroupProducts: products,
      name: group.productGroup || group.groupName,
      displayPriceFrom: displayPriceFrom,
      displayPriceTo: displayPriceTo,
      variants: getAllVariantsFromProducts(products),

      _isProductGroup: true,
      _isPriceRange: (displayPriceFrom !== displayPriceTo)
    }
  }, [])

  return results
}

export const convertToProductGroups = (products) => {
  const groups = _.reduce(products, (acc, product) => {
    const productGroupName = _.get(product, 'productGroup') || _.get(product, 'name')
    const [matchedProductGroups, otherProductGroups] = _.partition(acc, (group) => _.get(group, 'groupName') === productGroupName)
    const matchedProductGroup = _.first(matchedProductGroups) || {}

    return [
      ...otherProductGroups,
      {
        groupName: productGroupName,
        children: [
          ..._.get(matchedProductGroup, 'children', []),
          {
            ...product
          }
        ]
      }
    ]
  }, [])

  return {
    groups
  }
}
