import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'

import ViewUserScreen from './ViewUserScreen'
import MessageForwardingScreen from '../MessageForwardingScreen'
import { selectors as userDetailsSelectors, actions as userDetailsActions } from '../../../store/modules/userDetails'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { authCombinedSelectors, userDetailCombinedSelector } from '../../../store/modules/combinedSelectors'
import modalService from '../../../services/modalService'

import { translations } from '../../../config'

const mapStateToProps = state => {
  const isLoading = userDetailCombinedSelector.getUserDetailsAndRolesIsLoading(state)
  const initialValues = userDetailsSelectors.getUserWithModifiedRoleAndStores(state)

  const selectedUser = userDetailsSelectors.getSelectedUser(state)
  const currentUser = authSelectors.getCurrentUser(state)
  const isCurrentUserSelectedUser = selectedUser && currentUser && selectedUser.id === currentUser.id

  const isPersonalDetailsEditable = authCombinedSelectors.isCurrentUserAbleToEditPersonalDetails(state)
  const isRolesAndStoresEditable = authCombinedSelectors.isCurrentUserAbleToEditRoleAndStores(state)
  const isPasswordEditable = authCombinedSelectors.isCurrentUserAbleToEditPassword(state)

  const isCurrentUserAbleToDeactivateUser = authCombinedSelectors.isCurrentUserAbleToDeactivateUser(state)
  const isSelectedUserDeactivated = userDetailsSelectors.getSelectedUserIsDeactivated(state)
  const isSelectedUserLocked = userDetailsSelectors.getSelectedUserIsLocked(state)

  return {
    isLoading,
    initialValues,
    selectedUser,
    isCurrentUserSelectedUser,
    isPersonalDetailsEditable,
    isRolesAndStoresEditable,
    isPasswordEditable,
    isCurrentUserAbleToDeactivateUser,
    isSelectedUserDeactivated,
    isSelectedUserLocked,
    userAvailability: selectedUser ? selectedUser.availability : null,
    messageForwardingUser: userDetailsSelectors.getSelectedUserForwardingUser(state)
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, selectedUser, isCurrentUserSelectedUser }) => (params) => {
      if (params.password) {
        const newParams = _.omit(params, 'confirmPassword')
        newParams.id = isCurrentUserSelectedUser
        ? '@me'
        : selectedUser.id
        // different actions to make middleware easier
        // different modals show depending on whether it is the current users password which is being changed
        if (isCurrentUserSelectedUser) {
          return dispatch(userDetailsActions.changeCurrentUserPassword(newParams))
        } else {
          return dispatch(userDetailsActions.changeUserPassword(newParams))
        }
      } else {
        if (params.storeIds) {
          params.storeIds = _.flatten([params.storeIds])
        }
        return dispatch(userDetailsActions.updateUser(_.omit(params, 'userNumber')))
      }
    },
    showModal: ({ dispatch, isSelectedUserDeactivated, selectedUser }) => () => {
      const toggleDeactivateAccount = () => dispatch(userDetailsActions.setDeactivated(selectedUser.id, !isSelectedUserDeactivated))
      const title = isSelectedUserDeactivated
        ? translations('Confirm Re-Activation')
        : translations('Confirm De-Activation')
      const text = isSelectedUserDeactivated
        ? translations('Re-Activation Copy')
        : translations('De-Activation Copy')

      modalService.action({
        title,
        text,
        actions: [
          {
            success: true,
            text: translations('Confirm'),
            onClick: () => toggleDeactivateAccount(),
            primary: true
          },
          {
            text: translations('Cancel'),
          }
        ]
      })
    },
    onAvailabilityClick: ({ dispatch, selectedUser = {}, userAvailability }) => () => {
      let newAvailability = userAvailability === 'available' ? 'unavailable' : 'available'
      let userUpdate = {
        id: selectedUser.id,
        availability: newAvailability,
        messageForwardUserId: null
      }

      const showErrorModal = () => {
        modalService.continue({
          title: translations('Error'),
          text: translations('Set User Availability Error')
        })
      }

      const setUserAvailability = userId => {
        dispatch(userDetailsActions.updateUser({
          ...userUpdate,
          messageForwardUserId: userId || null
        }))
        .catch(err => showErrorModal())
      }

      if (newAvailability === 'unavailable') {
        modalService.open({
          component: MessageForwardingScreen,
          onContinue: (user) => {
            modalService.close()
            setUserAvailability(user)
          },
          buttonValue: translations('Select Inspiration'),
          inModal: true
        })
      } else {
        setUserAvailability()
      }
      
    }
  })
)(ViewUserScreen)
