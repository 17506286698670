import React from 'react'
import { DatePicker } from '../../components/Fields'
import Form from '../../components/Form'
import FormBody from '../../components/Form/FormBody'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import { dateFormat } from '../../config'
import BasicModalOverlay from '../../components/BasicModalOverlay'
import { translations } from '../../config'
import modalService from '../../services/modalService/index'

const DatePickerWrapper = styled.div`
  margin: 1em;
  justify-content: center;
`

const DateFilterForm = Form('product-list-date-filter')

export const ProductListDateRangePicker = ({ setDateFrom, setDateTo, toLabel, fromLabel, dateRange }) => {
  const currentDate = new Date() // Initial date

  const dateFrom = dateRange.from ? moment(dateRange.from).format(dateFormat) : null
  const dateTo = dateRange.to ? moment(dateRange.to).subtract(1, 'days').format(dateFormat) : null

  const setDateOffset = (days) => new Date(new Date().setDate(new Date().getDate() + days))
  const formatDate = (date) => moment(date, dateFormat).format('YYYY-MM-DD')

  const [updatedDateRange, setDateRange] = React.useState({ 
    from: dateFrom || moment(currentDate).format(dateFormat), 
    to: dateTo || moment(currentDate).format(dateFormat)
  })

  const maxDate = moment(setDateOffset(7)).format('YYYY-MM-DD')
  const minDate = moment(setDateOffset(-21)).format('YYYY-MM-DD')
  const currentFormattedDate = currentDate.toLocaleDateString()

  const setDateRangeField = (direction) => (value) => {
    let rangeDate = currentDate

    if (value && value.length) {
      const updatedDate = new Date(formatDate(value))
      rangeDate = new Date(updatedDate.setDate(updatedDate.getDate()))
    }

    const updateObject = direction === 'from'
      ? { from: rangeDate }
      : { to: rangeDate }

    setDateRange({
      ...updatedDateRange, ...updateObject
    })
  }


  const actions = [{
    primary: true,
    success: true,
    text: translations('Confirm'),
    onClick: () => {
      const updatedDateFrom = formatDate(updatedDateRange.from)
      const updatedDateTo = formatDate(moment(updatedDateRange.to).add(1, 'days'))

      setDateFrom(updatedDateFrom)
      setDateTo(updatedDateTo)
      modalService.close({ modalIndex: 1 })
    }
  }, {
    text: translations('Close'),
    onClick: () => {
      modalService.close({ modalIndex: 1 })
    }
  }]

  return <BasicModalOverlay actions={actions}>
    <DateFilterForm>
      <FormBody>
        <DatePickerWrapper>
          <DatePicker
            id='from'
            label={fromLabel}
            onChange={setDateRangeField('from')}
            defaultValue={dateFrom || currentFormattedDate}
            minDate={minDate}
            maxDate={updatedDateRange.to}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePicker
            id='to'
            label={toLabel}
            onChange={setDateRangeField('to')}
            defaultValue={dateTo || currentFormattedDate}
            minDate={updatedDateRange.from}
            maxDate={maxDate}
          />
        </DatePickerWrapper>
      </FormBody>
    </DateFilterForm>
  </BasicModalOverlay>
}

ProductListDateRangePicker.propTypes = {
  toLabel: PropTypes.string,
  fromLabel: PropTypes.string,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  dateRange: PropTypes.object
}

export default ProductListDateRangePicker
