import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../config/theme'
import { getImage } from '../Images'

const exclamationIcon = getImage('exclamationIcon')

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: #fdecea;
  min-height: 32px;
  border: none;
  margin: 10px 0;
  padding: 6px 10px;
  border-radius: 5px;
`

const Image = styled.img`
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 10px;
`

const ContentText = styled.span`
  font-size: 13px;
  color: ${colors.primary};
`

const ErrorMessage = ({ content }) => (
  <ErrorWrapper>
    <Image src={exclamationIcon} alt='error' />
    <ContentText>{content}</ContentText>
  </ErrorWrapper>
)

ErrorMessage.propTypes = {
  content: PropTypes.string.isRequired
}

export default ErrorMessage
