import React from 'react'
import PropTypes from 'prop-types'
import Counter from './Counter'

class CounterContainer extends React.Component {
  onChange = (direction) => {
    if (this.props.allowNegative) {
      const currentValue = parseInt(this.props.value)
      const newValue = direction === '+' ? currentValue + 1 : currentValue - 1
      this.props.onChange(newValue)
      return
    }

    const currentValue = parseInt(this.props.value) || 1
    const newValue = direction === '+' ? currentValue + 1 : Math.max(1, currentValue - 1)
    this.props.onChange(newValue)
  }

  _generateProps = () => ({
    ...this.props,
    onChange: this.onChange
  })

  render () {
    const props = this._generateProps()
    return <Counter {...props} />
  }
}

CounterContainer.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default CounterContainer
