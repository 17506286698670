import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import paymentDevices, { ADYEN_CLOUD_PAYMENT_DEVICE } from '../../../services/paymentDeviceService'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'
import offlinePaymentDevicesService from "../../../services/oflinePaymentDevicesService";

const adyenCloudService = paymentDevices[ADYEN_CLOUD_PAYMENT_DEVICE]
class ResourceActions {

  fetchAll = (params) => ({
    type: constants.FETCH_ALL,
    promise: (dispatch, getState) => {
      const currentState = getState()
      const SelectedStoreId = authSelectors.getUserSelectedStoreId(currentState)
      const isConnected = networkSelectors.isConnected(currentState)
      const SelectedStoreIds = ['null',SelectedStoreId].join(',')  // null introduced to give ability to generate a single device for all stores

      if (isConnected){
        return digitalStoreSdk.paymentDevices.fetchAll({
          ...params,
          storeId: { $in:SelectedStoreIds },
          limit: 500,
        })
      }

      //offline flow
      return offlinePaymentDevicesService.getPaymentDevices()
    }
  })

  fetchConnectedTerminals = () => ({
    type: constants.FETCH_CONNECTED_TERMINALS,
    promise: (dispatch) => {
      return adyenCloudService.makeConnectedTerminalsRequest()
    }
  })

}

export default new ResourceActions()
