import React, { useEffect, useState } from 'react';
import { has } from 'lodash';

import {
  Container,
  ChartTitle,
  NoContent,
  ButtonContainer,
} from './SalesPerPerson.styles';

import BackBar from '../../../../components/BackBar/BackBar';
import Button from '../../../../components/Button';
import ChartTile from '../../../../components/Charts/ChartTile/ChartTile';
import H2 from '../../../../components/H2/H2';
import { Link } from 'react-router-dom';
import List from './List';
import NewListChart from '../../../../components/Charts/ListChartNew';
import SubHeader from '../../../../components/SubHeader';
import sdk from '../../../../digitalStoreSdk';
import currencyFormatter from '../../../../formatters/currencyFormatter';

const header = [
  { id: 'user', label: 'User' },
  { id: 'sales', label: 'Sales', align: 'center' },
  { id: 'salesValue', label: 'Sales Value', align: 'center' },
];

const mapData = (item, timePeriod) => {
  let salesValue = 'N/A',
    sales = 'N/A';

  if (has(item, `reporting.${timePeriod}.profit`)) {
    sales = item.reporting[timePeriod].profit.orderCount;
    salesValue = currencyFormatter.format(
      item.reporting[timePeriod].profit.totalSpend
    );
  }

  return {
    sales,
    salesValue,
    user: `${item.firstName} ${item.lastName}`,
  };
};

export const SalesByPerson = ({
  storeId,
  reportSchema,
  timePeriod,
  ...props
}) => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { params } = props.match || {};

  useEffect(() => {
    const fetchUserReporting = async () => {
      const { items } = await sdk.users.fetchAll({
        currentStoreId: storeId ? storeId : params.storeId,
        fields: ['id', 'reporting', 'firstName', 'lastName'],
      });
      setIsLoading(false);
      const mappedData = items.map((item) => mapData(item, timePeriod ? timePeriod : params.timePeriod));
      setColumns(mappedData);
    };

    fetchUserReporting();
  }, [storeId, timePeriod]);

  if (isLoading) return null;

  if (!storeId) {
    return (
      <>
        <SubHeader
          leftContent={
            <BackBar onClick={() => props.history.push('/retail-analytics')} />
          }
          centerContent={<H2 value="Sales Per Person" />}
        />
        <Container style={{ margin: '20px 7.5% 0' }}>
          <NewListChart
            columns={columns}
            header={header}
            timePeriod={params.timePeriod}
          />
        </Container>
      </>
    );
  }

  const link = `/retail-analytics/store/${storeId}/${timePeriod}/sales-per-person`;

  return (
    <ChartTile>
      <ChartTitle>{reportSchema.title}</ChartTitle>
      <Container style={{ padding: 9 }} centerContent={!columns.length}>
        {columns.length ? (
          <List
            data={columns.slice(0, 2)}
            timePeriod={timePeriod}
            isFullList={false}
          />
        ) : (
          <NoContent>Not enough data to show results.</NoContent>
        )}
        {columns.length > 2 && (
          <ButtonContainer>
            <Link to={link}>
              <Button>See All</Button>
            </Link>
          </ButtonContainer>
        )}
      </Container>
    </ChartTile>
  );
};

export default SalesByPerson;
