import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../config'
import { Dropdown, Input } from '../Fields'
import Form from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'

import style from './style'
import * as validators from '../../validators'
import currencyFormatter from '../../formatters/currencyFormatter'

export const formId = 'value-added-services-modal'
const ThisForm = Form(formId)

class ValueAddedServicesModal extends Component {

  render() {
    const {
      classes,
      onSubmit,
      options,
      dismiss,
      currencyCode,
      product,
      initialValues,
      isFormValid,
      isServiceAlreadySelected
    } = this.props

    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })


    actions.push({
      text: translations('Submit'),
      success: true,
      primary: true,
      disabled: !isFormValid || isServiceAlreadySelected,
    })

    return (
      <ThisForm onSubmit={onSubmit} initialValues={initialValues}>
        <BasicModalOverlay
          title={translations('Value Adding Services')}
          confirmButtonText={translations('Confirm')}
          actions={actions}
        >
          <div className={classes.formContainer}>
            <FormControl className={classes.formControl}>
              <Dropdown
                required
                name='serviceId'
                label={translations('Service *')}
                options={options}
                validate={validators.required}
              />
            </FormControl>
            <div className={classes.serviceDetails}>
              <FormControl className={classes.formControl}>
                <Input
                  currencyCode={currencyCode}
                  name='price'
                  label={translations('Price *')}
                  type='price'
                  validate={validators.required}
                  shrink
                />
              </FormControl>
              <div className={classes.fieldTopSpacer}>
                <FormControl className={classes.formControl}>
                  <Input
                    name='notes'
                    label={translations('Notes')}
                    type='text'
                    shrink
                  />
                </FormControl>
              </div>
            </div>
            {!true && (
              <p className={classes.errorText}>No payment devices enabled for the current store.</p>
            )}
            {isServiceAlreadySelected && (
              <p className={classes.errorText}>This service is already in the basket for {product.name}</p>
            )}
          </div>
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(ValueAddedServicesModal)
