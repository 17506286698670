import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import _ from 'lodash';
import LoaderContainer from '../../../components/LoaderContainer';
import PaymentCollapsible from '../../../components/PaymentDevices/PaymentCollapsible';
import SubHeader from '../../../components/SubHeader';
import Container from '../../../components/Container';
import Dropdown from '../../../components/Fields/Dropdown';
import H2 from '../../../components/H2';
import Button from '../../../components/Button';
import ButtonsContainer from '../../../components/ButtonsContainer';
import { translations } from '../../../config';
import currencyFormatter from '../../../formatters/currencyFormatter';
import { required } from '../../../validators';

const RefundOrder = ({
  classes,
  selectedPrinterId,
  printerOptions,
  paymentOutstanding,
  setSelectedPrinterId,
  isLoading,
  onPaymentOptionClick,
  onCancelClick,
  onCompleteClick,
  supportedPaymentDevices,
  refundPayments,
  originalPayments,
  paymentTotal
}) => {

  const handledPrinterChange = ({ target: { value } }) => {
    setSelectedPrinterId(value);
  };

  const amountToPay = _.get(paymentOutstanding, 'value');

  return (
    <LoaderContainer isLoading={isLoading}>
      <SubHeader centerContent={<H2 value='Refund' />} />
      <Container withMarginTop withMarginBottom>
        <div className={classes.actionContainer}>
          <div className={classes.cancelContainer}>
            <Button
              key='text'
              buttonType='white'
              big
              children={'Cancel'}
              onClick={() => onCancelClick()}
            />
          </div>
          <div className={classes.completeContainer}>
            <Button
              key='text'
              buttonType='primary'
              big
              children={'Complete'}
              disabled={amountToPay !== 0 || selectedPrinterId === null}
              onClick={() => onCompleteClick()}
            />
          </div>
        </div>

        <div className={classes.printerDropdownContainer}>
          <Dropdown
            value={selectedPrinterId}
            options={printerOptions}
            validate={required}
            onChange={handledPrinterChange}
            label={translations('Select printer')}
          />
        </div>

        <div className={classes.paymentsContainer}>
          <PaymentCollapsible payments={originalPayments} defaultOpen={true} />
        </div>
        <div className={classes.amountContainer}>
          <div className={classes.total}>
            <span>Total: </span>
            <span>{currencyFormatter.format(paymentTotal)}</span>
          </div>
          <div className={classes.outstanding}>
            <span>Outstanding: </span>
            <span>{currencyFormatter.format(paymentOutstanding)}</span>
          </div>
        </div>
        <div className={classes.paymentsContainer}>
          <PaymentCollapsible
            payments={refundPayments}
            title='Refunds'
            defaultOpen={true}
          />
        </div>
        <ButtonsContainer innerClassName={classes.buttonsContainerInner}>
          {supportedPaymentDevices.map(
            ({ ctaText, deviceType, hasBeenRefunded }) => (
              <Button
                key='text'
                buttonType='primary'
                big
                disabled={amountToPay === 0 || hasBeenRefunded}
                children={ctaText}
                onClick={onPaymentOptionClick(deviceType)}
              />
            )
          )}
        </ButtonsContainer>
      </Container>
    </LoaderContainer>
  );
};

export default withStyles(style)(RefundOrder);
