import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class StoresReducers {
  fetchAllStores = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllStoresPending(state, action)
      case SUCCESS:
        return this.fetchAllStoresSuccess(state, action)
      case FAIL:
        return this.fetchAllStoresFail(state, action)
      default:
        return state
    }
  }

  fetchUserStores = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchAllStoresPending(state, action)
      case SUCCESS:
        return this.fetchUserStoresSuccess(state, action)
      case FAIL:
        return this.fetchAllStoresFail(state, action)
      default:
        return state
    }
  }

  fetchUserStoresSuccess = (state, action) => {
    return {
      ...state,
      userStores: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }

  fetchAllStoresPending = (state, action) => {
    return {
      ...state,
      status: PENDING,
      action: action.type
    }
  }
  fetchAllStoresSuccess = (state, action) => {
    return {
      ...state,
      result: action.result,
      status: SUCCESS,
      action: undefined,
      error: undefined
    }
  }
  fetchAllStoresFail = (state, action) => {
    return {
      ...state,
      status: FAIL,
      action: undefined,
      error: action.error.message
    }
  }
}

export default new StoresReducers()
