import { defaultState } from './store'
import { promiseReducer } from '../../util'
import { FAIL, PENDING, SUCCESS } from '../../middleware/redux-promise'

class AccessoryDetailsReducers {
  fetchPaymentDevice = promiseReducer(
    (state, action) => {
      const { result } = action

      return {
        ...state,
        result
      }
    }
  )

  createPaymentDevice = promiseReducer(
    (state, action) => {
      console.log(action)
      return {
        ...state
      }
    }
  )
  clear = (state, action) => {
    return defaultState
  }


  /**
   * Fetch all fresh - Replaces results in the store with
   * the API response. Used on inital load or a changing filters
   */
  updateOne = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateOnePending(state, action)
      case SUCCESS:
        return this.updateOneSuccess(state, action)
      case FAIL:
        return this.updateOneFail(state, action)
      default:
        return state
    }
  }

  updateOnePending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  updateOneSuccess = (state, action) => ({
    ...state,
    result: action.result,
    status: SUCCESS,
    action: undefined,
    error: null
  })

  updateOneFail = (state, action) => ({
    ...state,
    status: FAIL,
    action: undefined,
    error: action.error.message
  })
}

export default new AccessoryDetailsReducers()
