import { connect } from 'react-redux'

import { actions as customersActions, selectors as customersSelectors } from '../../../store/modules/customers'
import CustomerSearch from './CustomerSearch'

const mapStateToProps = state => {
  return {
    query: customersSelectors.getCustomerListQuery(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchCustomer: (query) => {
      dispatch(customersActions.searchCustomerFresh(query))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSearch)
