import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import ReceiveGoodsScreen from './ReceiveGoodsScreen'
import ReceiveGoodsDetailsScreen from './ReceiveGoodsDetailsScreen'

export default [
  <PrivateRoute
    key='/receive-goods'
    path='/receive-goods'
    exact
    component={TimedLogoutComponent(ReceiveGoodsScreen)}
  />,
  <PrivateRoute
    key='/receive-goods/:id'
    path='/receive-goods/:id'
    exact
    component={TimedLogoutComponent(ReceiveGoodsDetailsScreen)}
  />
]
