import React from 'react'
import ProductListDetailsCard from '../../../components/ProductLists/ProductListDetails/ProductListDetailsCardGeneric'
import { translations } from '../../../config'

import PropTypes from 'prop-types'

const ProductCard = ({
  index,
  list,
  product,
  hasReviewPermissions,
  activeOrderByOption,
  openReceiveProductsModal,
  openScanAgainModal,
  onSelect
}) => {
  const info = [
    // { text: product.externalProductId }
  ]
  let extraInfo = []
  const details = product.details || {}
  const location = details.locations && details.locations.length
    ? details.locations[0]
    : undefined

  info.push({
    text: `SKU: ${product.variant.ean}`
  })

  info.push({
    weight: 'bold',
    text: `Expected Qty: ${product.quantity}`
  })

  const isCountCorrect = product.receivedQuantity === product.quantity

  if (product.receivedQuantity && product.quantity && isCountCorrect) {
    extraInfo.push({
      text: `Received ${product.receivedQuantity} items.`,
      weight: 'bold',
      icon: 'greenTickIcon'
    })
  } else {
    extraInfo.push({
      text: `Quantity mismatch. Expected ${product.quantity} items, received ${product.receivedQuantity} items.`,
      weight: 'bold',
      icon: 'orangeCheckIcon'
    })
  }


  const extraInfoStyle = product.returned ? { justifyContent: 'center' } : {}

  const bottomRightButton = () => {
    return { icon: 'threeDotsIcon', onClick: () => openReceiveProductsModal({ product, list, index }) }
  }

  return <ProductListDetailsCard
    title={product.name}
    leftIcon={activeOrderByOption === 'location' && !!location
      ? { icon: 'locationIcon', text: `${location.metre}:${location.shelf}:${location.sequence}` }
      : undefined}
    topRightButton={undefined}
    bottomRightButton={bottomRightButton()}
    info={info}
    extraInfoStyle={extraInfoStyle}
    extraInfo={extraInfo}
    isSelectable={hasReviewPermissions && list.status === 'review' && product.groupTitle !== 'Completed Products'}
    isSelected={!!product.reviewed}
    onSelect={() => onSelect(product, index)}
  />
}

ProductCard.propTypes = {
  index: PropTypes.number,
  list: PropTypes.object,
  product: PropTypes.object,
  onClickDelete: PropTypes.func,
  activeOrderByOption: PropTypes.string,
  openReceiveProductsModal: PropTypes.func,
  openScanAgainModal: PropTypes.func,
  hasReviewPermissions: PropTypes.bool
}

export default ProductCard
