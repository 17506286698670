import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  products: [],
  details: null,
  customer: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.ADD_PRODUCT_TO_ORDER:
      return reducers.addProduct(state, action)
    case constants.REMOVE_PRODUCT_FROM_ORDER:
      return reducers.removeProduct(state, action)
    case constants.CHANGE_PRODUCT_QUANTITY_IN_ORDER:
      return reducers.changeProductQuantity(state, action)
    case constants.CHANGE_PRODUCT_PRICE_IN_ORDER:
      return reducers.changeProductPrice(state, action)
    case constants.CHANGE_PRODUCT_FIELD:
      return reducers.changeProductField(state, action)
    case constants.UPDATE_ORDER:
      return reducers.updateOrder(state, action)
    case constants.CREATE_CUSTOMER_FOR_ORDER:
      return reducers.createCustomer(state, action)
    case constants.CLEAR_ORDER:
      return reducers.clearOrder(state, action)
    case constants.RESTORE_ORDER:
      return reducers.restoreOrder(state, action)
    case constants.CLEAR_ORDER_EXCEPT_BASKET:
      return reducers.clearOrderExceptBasket(state, action)
    case constants.ADD_MANUAL_DISCOUNT_TO_PRODUCT:
      return reducers.addManualDiscountToProduct(state, action)
    case constants.UPDATE_ORDER_WITH_PROMOTION:
      return reducers.updateOrderWithPromotions(state, action)
    case constants.REMOVE_MANUAL_DISCOUNT_FROM_PRODUCT:
      return reducers.removeManualDiscountFromProduct(state, action)
    case constants.REMOVE_MANUAL_DISCOUNT_FROM_ORDER:
      return reducers.removeManualDiscountFromOrder(state, action)
    case constants.EDIT_ORDER:
      return reducers.editOrder(state, action)
    case constants.STOP_EDITING_ORDER:
      return reducers.stopEditingOrder(state, action)
    case constants.ANONYMOUS_CUSTOMER:
      return reducers.anonymousCustomer(state, action)
    case constants.SET_DISCOUNT:
      return reducers.setDiscount(state, action)
    case constants.RESET_CUSTOMER_WAS_ANONYMISED:
      return reducers.resetCustomerWasAnonymised(state, action)
    case constants.POPULATE_ORDER:
      return reducers.populateOrder(state, action)
    case constants.TOGGLE_REFUND_ITEM:
      return reducers.toggleRefundItem(state, action)
    case constants.TOGGLE_CUSTOMER_BASKET_REVIEW_STATUS:
      return reducers.manageCustomerReviewBasketStatus(state, action)
    case constants.ADD_CUSTOMER_TO_ORDER:
      return reducers.addCustomer(state, action)
    case constants.ADD_CONSULTATION_SERVICE_TO_CURRENT_ORDER:
      return reducers.addConsultationServiceToCurrentOrder(state, action)
    case constants.FETCH_BASKET_CONFIGURATION:
      return reducers.fetchBasketConfiguration(state, action)
    case constants.TOGGLE_ATTACH_CONTENT_MODE:
      return reducers.toggleAttachContentMode(state, action)
    case constants.SET_CURRENT_ORDER:
      return reducers.setCurrentOrder(state, action)
    case constants.SET_STRIPE_PAYMENT_SUCCESS:
      return reducers.setStripePaymentSuccess(state, action)
    case constants.SET_DISCOUNT:
      return reducers.setDiscount(state, action)
    case constants.SET_PAYMENT_DETAILS:
      return reducers.setPaymentDetails(state, action)
    default:
      return state
  }
}
