export const currencyThreshold = [
  {
    currencyCode: 'EUR',
    regions: [
      'Belgium', 
      'Finland', 
      'Germany', 
      'Netherlands'
    ],
    value: 0.05
  },
  {
    currencyCode: 'DKK',
    regions: [
      'Denmark'
    ],
    value: 2
  },
  {
    currencyCode: 'SEK',
    regions: [
      'Sweden'
    ],
    value: 30
  }
]