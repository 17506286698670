import _ from 'lodash'
import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { apiUpdateSource } from '../../../config'
import { formatNullValues } from '../../../helpers'

class CustomerDetailsActions {
  fetchFullCustomer = (id) => ({
    type: constants.FETCH_FULL_CUSTOMER,
    promise: (dispatch, getState) => {
      const userId = authSelectors.getCurrentUser(getState()).id
      return digitalStoreSdk.customers.fetchCustomerDetails({ id })
      .then(customerObj => {
        return Promise.all([
          digitalStoreSdk.customers.fetchCustomerStoreMarketingForId(customerObj.id),
          digitalStoreSdk.users.fetchCustomerRelationshipsForUser({ userId, customerId: customerObj.id }),
          digitalStoreSdk.orders.fetchOrders({ customerId: customerObj.id, sort: '-orderDate', limit: 50 }),
          digitalStoreSdk.messages.fetchMessages({ customerId: customerObj.id, sort: '-createdAt', limit: 1, includes: 'customer,user' })
        ])
          .then(([marketingStoreIds, customerUserRelationships, orders, messages = { items: [] }]) => {
            return {
              customer: { ...customerObj, orders: orders.items, latestMessage: messages.items[0] },
              marketingStoreIds: marketingStoreIds.items,
              customerUserRelationships
            }
          })
      })
    }
  })

  createCustomer = ({ noPush, ...params }) => ({
    type: constants.CREATE_CUSTOMER,
    noPush,
    promise: (dispatch, getState) => {
      const territoryId = authSelectors.getCurrentUser(getState()).currentTerritoryId
      const newCustomer = {
        ...formatNullValues(_.omit(params, ['marketingStoreIds'])),
        updateSource: apiUpdateSource,
        territoryId
      }
      return digitalStoreSdk.customers
        .createCustomer(newCustomer)
        .then((customerCreateRes) => {
          if (!params.marketingStoreIds || !params.marketingStoreIds.length) return customerCreateRes
          return digitalStoreSdk.customers
            .setCustomerStoreMarketingForId(customerCreateRes.id, params.marketingStoreIds)
            .then(marketingUpdateRes => {
              return {
                ...customerCreateRes,
                marketingStoreIds: _.map(marketingUpdateRes, 'storeId')
              }
            })
        })
    }
  })

  updateCustomer = (params) => ({
    type: constants.UPDATE_CUSTOMER,
    promise: (dispatch) => {
      const update = {
        ...formatNullValues(_.omit(params, ['marketingStoreIds'])),
        updateSource: apiUpdateSource
      }
      return digitalStoreSdk.customers
        .updateCustomerDetails(update)
        .then((customerUpdateRes) => {
          if (!params.marketingStoreIds) return customerUpdateRes
          return digitalStoreSdk.customers
            .setCustomerStoreMarketingForId(params.id, params.marketingStoreIds)
            .then(marketingUpdateRes => {
              return {
                ...customerUpdateRes,
                marketingStoreIds: _.map(marketingUpdateRes, 'storeId')
              }
            })
        })
    }
  })

  updateCustomerWishlist = (params) => ({
    type: constants.UPDATE_CUSTOMER_WISHLIST,
    promise: (dispatch) => {
      return digitalStoreSdk.customers
        .updateCustomerDetails({ ...params, updateSource: apiUpdateSource })
    }
  })

  updateCustomerNotes = (params) => ({
    type: constants.UPDATE_CUSTOMER_NOTES,
    promise: (dispatch) => {
      return digitalStoreSdk.customers
        .updateCustomerDetails({ ...params, updateSource: apiUpdateSource })
    }
  })

  deleteCustomerNote = params => ({
    type: constants.DELETE_CUSTOMER_NOTE,
    promise: (dispatch) => {
      return digitalStoreSdk.customers
        .updateCustomerDetails({ ...params, updateSource: apiUpdateSource })
    }
  })

  editCustomerNote = params => ({
    type: constants.EDIT_CUSTOMER_NOTE,
    promise: (dispatch) => {
      return digitalStoreSdk.customers
        .updateCustomerDetails({ ...params, updateSource: apiUpdateSource })
    }
  })

  followCustomer = params => ({
    ...params,
    type: constants.FOLLOW_CUSTOMER,
    promise: (dispatch, getState) => {
      const state = getState()
      const currentUserId = authSelectors.getCurrentUser(state).id
      return digitalStoreSdk.users
        .setCustomerRelationshipsForUser({
          userId: currentUserId,
          customerId: params.id,
          relationshipType: 'follow'
        })
    }
  })

  unfollowCustomer = params => ({
    ...params,
    type: constants.UNFOLLOW_CUSTOMER,
    promise: (dispatch) => {
      return digitalStoreSdk.customerUserRelationships
        .deleteCustomerUserRelationship(params)
    }
  })

  anonymiseCustomer = customerId => ({
    type: constants.ANONYMISE_CUSTOMER,
    promise: () => {
      return digitalStoreSdk.customers
        .anonymiseCustomer({ customerId })
    }
  })
}

export default new CustomerDetailsActions()
