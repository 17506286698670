import { get } from 'lodash';
import PouchDB from 'pouchdb'
import uuid from 'uuid';

PouchDB.plugin(require('pouchdb-find').default)

class OfflinePromotionsService {
  constructor() {
    this.dataStore = new PouchDB(`offline_promotions`)
    window.offlinePromotionsDB = this.dataStore
  }

  async create({ promotions }) {
    const { total_rows } = await this.dataStore.allDocs()
    if(total_rows) {
      await this.dataStore.destroy()
      this.dataStore = new PouchDB(`offline_promotions`)
    }
    await this.dataStore.put({ _id: uuid(), promotions })
  }

  async getPromotions() {
    const { rows } = await this.dataStore.allDocs({ include_docs: true })

    return rows.map(item => get(item, 'doc.promotions[0]', []))
  }
}

export default new OfflinePromotionsService()
