import React from 'react';
import PrivateRoute from '../../../../components/PrivateRoute';
import StoreFullList from './StoreFullList';
import { slugify } from '../../../../helpers';
import { storeReportingSchema as schema } from '../../../../config';
import SalesByPerson from '../SalesByPerson';

// this only needs to be done once so it can be outside a render function
// because schema is static
const StoreFullListScreens = schema
  .map((report, i) => {
    if (report.title === 'Sales per Person') {
      const path = `/retail-analytics/store/:storeId/:timePeriod/${slugify(
        report.title
      )}`;
      return (
        <PrivateRoute
          key={path}
          path={path}
          component={(props) => (
            <SalesByPerson {...props} reportSchema={report} />
          )}
          exact
        />
      );
    }

    const path = `/retail-analytics/store/:storeId/:timePeriod/${i}-${slugify(
      report.title
    )}`;

    return report.fullList ? (
      <PrivateRoute
        key={path}
        path={path}
        component={(props) => (
          <StoreFullList {...props} reportSchema={report} />
        )}
        exact
      />
    ) : null;
  })
  .filter(Boolean);

export default StoreFullListScreens;
