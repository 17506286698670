import ActivateGiftCardModal from '../../components/ActivateGiftCardModal'
import { STAR_CLOUD_PRNT_DEVICE, UnsupportedPaymentDevicesForRefund } from '../../services/paymentDeviceService'
import modalService from '../../services/modalService'
import _ from 'lodash'

export const handleActivateGiftCards = (
  adyenTerminals,
  giftCards,
  orderNumber,
  paymentDevices
) =>
  new Promise((resolve) => {
    modalService.open({
      component: ActivateGiftCardModal,
      adyenTerminals,
      giftCards,
      orderNumber,
      paymentDevices,
      onComplete: (response) => {
        resolve(response)
      }
    })
  })

export const asDropdownOptions = (array, label, value) =>
  array.map((obj) => ({
    label: _.get(obj, label),
    value: _.get(obj, value)
  }))

export const getRefundablePaymentsWithLeftToRefundForOrder = (order) => {
  const allPayments = (_.get(order, 'payments') || [])
  const successfulPayments = allPayments.filter(payment => payment.success && !payment.isRefund)
  const successfulRefunds = allPayments.filter(payment => payment.success && payment.isRefund)
  return successfulPayments.map(payment => {
    const refundSum = _.sumBy(successfulRefunds, x => payment.id === _.get(x, 'result.selectedPaymentToRefund.id') ? x.amountPaid : 0)
    const leftOver = Number(payment.amountPaid - refundSum).toFixed(2)
    return {
      ...payment,
      leftToRefund: Number(leftOver)
    }
  })
}

export const getLeftToPayGroupedByDeviceType = (order) => {
  const allPayments = (_.get(order, 'payments') || [])
  const successfulPayments = allPayments.filter(payment => payment.success && !payment.isRefund)
  const successfulRefunds = allPayments.filter(payment => payment.success && payment.isRefund)
  const previousRefundPayments = _.get(order, 'details.previousRefundPayments', [])
  const successfulPaymentAmountPerDeviceType = _(successfulPayments)
    .groupBy('deviceType')
    .map((payment, deviceType) => ({
      deviceType: deviceType,
      amount: _.sumBy(payment, 'amountPaid')
    }))
    .value()
  const successfulRefundAmountPerDeviceType = _(_.unionBy(successfulRefunds, previousRefundPayments, 'id'))
    .groupBy('deviceType')
    .map((payment, deviceType) => ({
      deviceType: deviceType,
      amount: _.sumBy(payment, 'amountPaid')
    }))
    .value()
  const leftToPayGroupedByDeviceType = successfulPaymentAmountPerDeviceType.map(paymentsSum => {
    const leftOver = Number((paymentsSum.amount - _.get(successfulRefundAmountPerDeviceType.find(x => x.deviceType === paymentsSum.deviceType), 'amount', 0)).toFixed(2))
    return {
      ...paymentsSum,
      amount: leftOver
    }
  })

  return leftToPayGroupedByDeviceType
}

const mapPaymentDeviceArrayForRefunds = (arr) => {
  return arr
    .filter(paymentDevice => !UnsupportedPaymentDevicesForRefund.includes(paymentDevice.deviceType))
    .map((item) => {
      const { ctaText, deviceName, deviceType, hasBeenRefunded } = item
      return {
        ctaText: ctaText.replace('Pay by', 'Refund to'),
        deviceName,
        deviceType,
        ...(hasBeenRefunded ? { hasBeenRefunded } : {})
      }
    })
}

export const createSupportedDevices = (currentOrder, paymentDevices) => {
  // TODO: if we have a vintage purchase process we should consider to be extended this to activate a new gift card
  const isVintagePurchase = false // TODO: extend later
  if (isVintagePurchase) { return [] }

  // if orderTotal is negative that means an unreferenced refund and we do not have original payments and we should return all options except gift card
  const isRefund = (_.get(currentOrder, 'products') || []).some(products => products.refund)
  const isUnreferencedRefundOrder = isRefund && (_.get(currentOrder, 'payments') || [])
    .filter(payment => payment.success && !payment.isRefund).length === 0 && !isVintagePurchase
  if (isUnreferencedRefundOrder) {
    return mapPaymentDeviceArrayForRefunds(paymentDevices)
  }

  // on a refund order we are filtering out unused payment methods and gift card but keep cash on all cases
  const isRefundOrder = (_.get(currentOrder, 'products') || []).some(product => product.refund)
  if (isRefundOrder) {
    const refundableDeviceTypesForCurrentOrder = getLeftToPayGroupedByDeviceType(currentOrder).filter(x => x.amount > 0)
    const filteredSupportedPaymentDevices = paymentDevices
      .filter(
        ({ deviceType: supportedDeviceType }) =>
          refundableDeviceTypesForCurrentOrder
            .some(({ deviceType: paymentDeviceType }) => paymentDeviceType === supportedDeviceType) ||
            supportedDeviceType === STAR_CLOUD_PRNT_DEVICE
      )
    return mapPaymentDeviceArrayForRefunds(filteredSupportedPaymentDevices)
  }

  // return all deviceTypes in a regular order
  return paymentDevices
}
