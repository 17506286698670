import { customAlphabet } from 'nanoid'
import _ from 'lodash';

export const generateUniqueServiceId = () => {
  const nanoid = customAlphabet('1234567890', 10)
  return nanoid()
}

export const getTimeInUTCFormat = () => new Date().toISOString()

export const getErrorMessageFromAString = (string) => {
  const decodedString = decodeURIComponent(string)
  const errorMessage = decodedString.match(/&message[\s\S]*?&/g)

  if (Array.isArray(errorMessage)) {
    return errorMessage[0].replace(/^&message=?|&?$/g, '')
  }

  const refusalReason = decodeURIComponent(decodedString).match(
    /refusalReason[\s\S]*?&/g
  )

  return Array.isArray(refusalReason)
    ? refusalReason[0].replace(/^refusalReason=?|&?$/g, '')
    : 'Unexpected Error'
}

export const makeSaleToAcquirerData = (additionalParts = []) => {
  // https://docs.adyen.com/point-of-sale/add-data/pass-application-information?tab=option_2_key_value_pairs_2

  const versionNo = window.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION

  const ADYEN_APPLICATION_NAME = 'RetailOS'
  const APPLICATION_VERSION = `${versionNo}`
  const INTEGRATOR_COMPANY_NAME = 'RedAnt'
  const PLATFORM_NAME = 'AdyenLocalTerminalApiIntegration'
  const PLATFORM_VERSION = '0.1.0'
  const OS_NAME = _.get(window, 'device.platform',
    _.get(window, 'navigator.platform',
      'Unknown'
    )
  )
  const OS_VERSION = _.get(window, 'device.version',
    'Unknown'
  )
  const DEVICE_REFERENCE = 'DEVICE_REFERENCE'

  const parts = [
    `tenderOption=ReceiptHandler`,
    `applicationInfo.merchantApplication.name=${ADYEN_APPLICATION_NAME}`,
    `applicationInfo.merchantApplication.version=${APPLICATION_VERSION}`,
    `applicationInfo.externalPlatform.integrator=${INTEGRATOR_COMPANY_NAME}`,
    `applicationInfo.externalPlatform.name=${PLATFORM_NAME}`,
    `applicationInfo.externalPlatform.version=${PLATFORM_VERSION}`,
    `applicationInfo.merchantDevice.os=${OS_NAME}`,
    `applicationInfo.merchantDevice.osVersion=${OS_VERSION}`,
    `applicationInfo.merchantDevice.reference=${DEVICE_REFERENCE}`,
    ...additionalParts
  ]

  const keyValuePairString = parts.join('&')

  return keyValuePairString
}