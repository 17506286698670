import SelectPayByLinkPaymentDeviceModal from '../../../components/PaymentDevices/SelectPayByLinkPaymentDeviceModal'
import modalService from '../../modalService'
import uuid from 'uuid/v4'

class PayByLinkPaymentDevice {
  // Constants
  deviceType = 'internal:pay-by-link';
  deviceName = 'Pay by Link';
  ctaText = 'Pay by Link';
  nameForReceipt = 'PAY_BY_LINK'

  async initalizeForCheckoutSession (order) {
    // init Adyen here
    return order
  }

  async processPayment (paymentDevices, leftToPay, order, paymentsToRefund) {
    return new Promise((resolve, reject) => {
      const success = (selectedPaymentDevice, result) => {
        resolve({
          id: uuid(),
          timestamp: new Date().toISOString(),
          paymentDeviceId: selectedPaymentDevice.id,
          amountPaid: parseFloat(result.amount), // the currency input field returns a string,
          success: true,
          name: selectedPaymentDevice.name,
          deviceType: this.deviceType,
          result,
          ...(paymentsToRefund ? { isRefund: true } : {})
        })
        return modalService.close()
      }

      const dismiss = () => {
        resolve(null)
        return modalService.close()
      }

      modalService.open({
        component: SelectPayByLinkPaymentDeviceModal,
        success,
        dismiss,
        leftToPay,
        orderTotal: order.total,
        paymentsToRefund,
        paymentDevices: paymentDevices.filter(
          (obj) => obj.deviceType === this.deviceType
        ),
        disableBackdropClick: true,
        disableEscapeKeyDown: true
      })
    })
  }
}

export default new PayByLinkPaymentDevice()
