import _ from 'lodash'
import modalService from '../../../services/modalService'
import AddDiscountModal, { discountTypes } from '../../Basket/BasketItem/AddDiscountModal'
import { translations } from '../../../config'
import { multiplyPrice } from '@redant/digital-store-prices-fiskars/dist/handlePrices'
import currencyFormatter from '../../../formatters/currencyFormatter'

export const calculateTotalPrice = ({ products, currencyCode }) => {
  return _.chain(products)
    .map('price.value')
    .map(num => parseInt(num))
    .reduce((a, b) => a + b)
    .thru(total => ({ code: currencyCode, value: `${total}` }))
    .value()
}

export const calculateTotalDiscount = ({ products, currencyCode }) => {
  return _.chain(products)
    .map('manualDiscount.value')
    .map(num => parseInt(num))
    .reduce((a, b) => a + b)
    .thru(total => ({ code: currencyCode, value: `${total}` }))
    .value()
}

export const calculateDiscount = ({
  products: productsUnsanitized,
  success,
  currencyCode,
  orderDiscount = false
}) => {
  const products = _.filter(productsUnsanitized, (product) => {
    return !product.service
  })
  const sanitizeRefundProducts = _.filter(productsUnsanitized, (product) => {
    return !product.service && !product.refund
  })

  const handler = (result) => {
    const { type, amount, reason } = result

    if (type === discountTypes.percent) { // WILL NEED TO DO THIS FOR EACH PRODUCT
      const withPercentageDiscount = products.map(product => {
        const nowPrice = _.get(product, 'nowPrice')
        let discount = {
          ...multiplyPrice(nowPrice, amount / 100),
          percent: amount,
          type,
          reason
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withPercentageDiscount, category: type, value: amount })
    }

    if (type === discountTypes.amount && orderDiscount) {
      // If discount is applied at order level
      // devide the total discount amount by the total number of prducts in the basket
      // and apply to each product in the basket
      const discountAmount =  amount / sanitizeRefundProducts.length
      const formattedAmount = currencyFormatter.truncateValue(discountAmount)
      const withAmountDiscount = sanitizeRefundProducts.map((product) => {
        const discount = {
          value: formattedAmount,
          code: currencyCode,
          type,
          reason
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withAmountDiscount, category: type, value: amount })
    } else if (type === discountTypes.amount) {
      const withAmountDiscount = products.map(product => {
        const nowPrice = _.get(product, 'nowPrice')
        const discount = {
          value: parseFloat(amount).toFixed(2),
          code: nowPrice.code,
          type,
          reason
        }

        return { product, discount, applyTo: 1 }
      })

      return success({ discountedProducts: withAmountDiscount, category: type, value: amount })
    }
  }

  return handler
}

export const openAddDiscountModal = ({
  products = [],
  success,
  currencyCode,
  orderDiscount = false
}) => {
  const validProducts = _.filter(products, (product) => {
    return !product.service && !product.refund
  })
  const totalPrice = calculateTotalPrice({ products: validProducts, currencyCode })
  const handler = calculateDiscount({
    products,
    success,
    currencyCode,
    orderDiscount
  })

  return modalService.open({
    component: AddDiscountModal,
    products: products,
    orderDiscount: orderDiscount,
    nowPrice: totalPrice,
    success: handler,
    actions: [
      {
        text: translations('Done'),
        success: true,
        primary: true
      }
    ]
  })
}
