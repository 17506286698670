import * as constants from './constants'

class OfflineActions {
  sync = ({  }) => ({
    type: constants.SYNC,
  })
  setOfflineOrderCount = ({ count }) => ({
    type: constants.SET_OFFLINE_ORDER_COUNT,
    payload: { count }
  })
}

export default new OfflineActions()