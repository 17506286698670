import * as constants from './constants'
import couchbaseService from '../../../services/couchbaseService'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { selectors as authSelectors } from '../auth'
import { selectors as networkSelectors } from '../network'

class CategoriesActions {
  fetchAllCategories = () => ({
    type: constants.FETCH_ALL_CATEGORIES,
    promise: (dispatch, getState) => {
      const state = getState()
      const isConnected = networkSelectors.isConnected(state)
      const region = authSelectors.getUserSelectedRegionId(state)
      const catalogue = authSelectors.getUserSelectedStoreCatalogue(state)

      if (isConnected) {
        return digitalStoreSdk.categories.fetchCategories({ regionId: region, catalogue })
      }
      // offline mode
      return couchbaseService.fetchCategories({ region, catalogue })
    }
  })
}

export default new CategoriesActions()
