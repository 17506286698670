import React from 'react'
import _ from 'lodash'

import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import { checkoutModules, referralModules, storeroomRequestModules } from '../../config'
import { pathsForCheckoutModule } from '../../helpers'

import OrderSummary from './OrderSummary'
import SelectCustomer from './SelectCustomer'
import RegisterCustomer from './RegisterCustomer'
import Delivery from './Delivery'
import PaymentDummy from './Payment/PaymentDummy'
import CouponCode from './CouponCode'
import EditBasket from './EditBasket'
import PayPalHere from './PayPalHere'
import MultiPayment from './MultiPayment'
import PaymentSalesforce from './PaymentSalesforce'
import Deferred from './Deferred'

const componentForPath = {
  'order-summary': OrderSummary,
  'select-customer': SelectCustomer,
  'register-customer': RegisterCustomer,
  delivery: Delivery,
  'payment-dummy': PaymentDummy,
  'coupon-code': CouponCode,
  'edit-basket': EditBasket,
  'paypal-here': PayPalHere,
  'multi-payment': MultiPayment,
  'payment': PaymentSalesforce,
  deferred: Deferred
}

const getRoutesForModules = (modules, pathPrefix) => {
  return _.chain(modules)
    .map(someModule => pathsForCheckoutModule[someModule.name])
    .compact()
    .flatten()
    .map((path) => {
      return <PrivateRoute
        key={path}
        path={`/${pathPrefix}/${path}`}
        component={TimedLogoutComponent(componentForPath[path])}
        exact
        allowOffline
      />
    })
    .value()
}

const routes = [
  ...getRoutesForModules(checkoutModules, 'checkout'),
  ...getRoutesForModules(referralModules, 'referral'),
  ...getRoutesForModules(storeroomRequestModules, 'storeroom-request')
]

export default routes
