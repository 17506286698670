import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

import couchbaseService from '../../../services/couchbaseService'
import productsActions from './actions'
import { SUCCESS } from '../../middleware/redux-promise'
import { selectors as authSelectors, constants as authConstants } from '../auth'
import * as productsSelectors from './selectors'
import * as productsConstants from './constants'

class ProductsMiddleware {
  loadInitialProductsMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const isRoutingToProducts = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/products'
    const productsIsInitial = productsSelectors.getIsInitial(getState())
    const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    if (isRoutingToProducts && productsIsInitial && isLoggedIn) {
      dispatch(productsActions.searchProductsFresh({}))
    }
  }
  resetStoreOnUserLogout = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.LOGOUT && action.status === SUCCESS) {
      dispatch(productsActions.resetStore())
    }
    next(action)
  }

  clearProductSearch = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const pathBefore = _.get(getState(), 'router.location.pathname')
      const isRoutingFromPlp = !!matchPath(pathBefore, { path: '/products', exact: true })
      const isRoutingFromPdp = !!matchPath(pathBefore, { path: '/product', exact: false })
      next(action)
      const pathAfter = _.get(getState(), 'router.location.pathname')
      const isRoutingToPlp = !!matchPath(pathAfter, { path: '/products', exact: true })
      const isRoutingToPdp = !!matchPath(pathAfter, { path: '/product', exact: false })
      const isRoutingToProductSubCategories = !!matchPath(pathAfter, { path: '/products/cat', exact: false })
      const fromPLPToPDP = isRoutingFromPlp && isRoutingToPdp && !isRoutingToPlp
      const fromPDPToPLP = isRoutingFromPdp && !isRoutingToPdp && isRoutingToPlp
      const fromPDPToCategory = isRoutingFromPdp && isRoutingToProductSubCategories
      // clear search filters only when in root products page.
      // keep search filters when navigating to PDP
      if ((isRoutingToPlp && !fromPDPToPLP && !fromPDPToCategory) || (isRoutingFromPlp && !fromPLPToPDP)) {
        dispatch(productsActions.searchProductsFresh({ category: null, query: null, sort: null }))
      }
    }
  }

  syncProductsForRecentUsersCatalogues = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === productsConstants.SYNC_PRODUCTS_FOR_CATALOGUES) {
      const { auth } = getState()
      if (!auth) {
        return
      }
      // console.log('syncProductsForRecentUsersCatalogues RECENT USERS => ', auth.recentUsers)
      const catalogues = _.chain(auth)
        .get('recentUsers')
        .map(user => {
          return _.get(user, 'lastLoggedInStore.catalogue')
        })
        .value()

      couchbaseService.syncProductsForCatalogues(catalogues)
    }

    next(action)
  }

  resetProductsOnProductPageLoad = ({ dispatch, getState }) => next => action => {
    // const isRoutingToProducts = action.type === LOCATION_CHANGE && action.payload.location.pathname === '/products'
    // const isLoggedIn = authSelectors.getIsLoggedIn(getState())
    // if (isRoutingToProducts && isLoggedIn) {
    //   dispatch(productsActions.resetStore())
    // }
    next(action)
  }
}

export default new ProductsMiddleware()
