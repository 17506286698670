import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'

class StoresActions {
  fetchAllStores = (params) => ({
    type: constants.FETCH_ALL_STORES,
    promise: () => digitalStoreSdk.stores.fetchStores({ ...params, limit: 500 })
  })

  fetchUserStores = (stores, params) => {
    return {
      type: constants.FETCH_USER_STORES,
      promise: async () => {
        const request = stores.map((store) =>
          digitalStoreSdk.stores.fetchStore({ ...params, id: store.id })
        )

        const response = await Promise.all(request);

        return {
          total: response.length,
          items: response
        }
      }
    }
  }
}

export default new StoresActions()
