import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import { Dropdown, Input } from '../../Fields'
import Form from '../../Form'
import BasicModalOverlay from '../../BasicModalOverlay'

import style from './style'
import LoaderContainer from '../../LoaderContainer'
import * as validators from '../../../validators'

export const formId = 'checkout-email-payment-modal'
const ThisForm = Form(formId)

class SelectEmailPaymentDeviceModal extends Component {
  validateAmount = amount => {
    const { leftToPay, isNegativeAmount } = this.props

    if (!isNegativeAmount && leftToPay < parseFloat(amount)) {
      return `Amount must not be more than ${leftToPay}.`
    }

    if (isNegativeAmount && leftToPay > parseFloat(amount)) {
      return `Amount must not be less more ${leftToPay}.`
    }

    return validators.required(amount)
  }

  render () {
    const {
      classes,
      paymentDeviceOptions,
      processPayment,
      pending,
      currencyCode,
      error,
      dismiss,
      initialValues,
      onlyPaymentDeviceId,
      isNegativeAmount,
      isRefund
    } = this.props

    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      disabled: pending,
      primary: false,
      onClick: () => dismiss(true)
    })

    actions.push({
      text: translations('Submit'),
      success: true,
      disabled: !paymentDeviceOptions.length,
      primary: true
    })

    return (
      <ThisForm onSubmit={processPayment} initialValues={initialValues}>
        <BasicModalOverlay
          title={isRefund ? translations('Refund by Email') : translations('Pay by Email')}
          confirmButtonText={isRefund ? translations('Refund') : translations('Pay')}
          actions={actions}
        >
          <LoaderContainer isLoading={pending}>
            <div className={classes.formContainer}>
              <FormControl className={classes.formControl}>
                <>
                  <Dropdown
                    required
                    name='paymentDeviceId'
                    label={translations('Payment Device')}
                    options={paymentDeviceOptions}
                    validate={validators.required}
                    disabled={onlyPaymentDeviceId}
                  />
                  <FormControl className={classes.formControl}>
                    <Input
                      currencyCode={currencyCode}
                      name='amount'
                      label={isRefund ? translations('Amount to Refund') : translations('Amount to Pay')}
                      type='price'
                      validate={this.validateAmount}
                      negative={isNegativeAmount}
                    />
                  </FormControl>
                </>
              </FormControl>
              {!paymentDeviceOptions.length && (
                <p className={classes.errorText}>
                  No payment devices enabled for the current store.
                </p>
              )}
              {error && <p className={classes.errorText}>{error.message}</p>}
            </div>
          </LoaderContainer>
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(SelectEmailPaymentDeviceModal)
