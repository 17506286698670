import React from 'react'
import { Provider, ReactReduxContext } from 'react-redux'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import TagManager from 'react-gtm-module'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { store, persistor, history } from './store'
import { apps, theme } from './config'
import Root from './containers/Root'
import FullScreenLoader from './components/FullScreenLoader/FullScreenLoader'
import digitalStoreSdk from './digitalStoreSdk'

const routes = []

routes.push(require('./containers/Home').routes)
routes.push(require('./containers/Webview').routes)
routes.push(require('./containers/ExampleForm').routes)
routes.push(require('./containers/Login').routes)
routes.push(require('./containers/SelectStore').routes)
routes.push(require('./containers/Feedback').routes)
routes.push(require('./containers/SelectDepartment').routes)
routes.push(require('./containers/ForgotPassword').routes)
routes.push(require('./containers/Stores').routes)
if (apps.CATALOG) {
  routes.push(require('./containers/Products').routes)
  routes.push(require('./containers/ProductDetail').routes)
  routes.push(require('./containers/Checkout').routes)
}
if (apps.CUSTOMER_MANAGEMENT) {
  routes.push(require('./containers/Customer').routes)
}
if (apps.USER_MANAGEMENT) {
  routes.push(require('./containers/Users').routes)
}
if (apps.MESSAGING) {
  routes.push(require('./containers/Message').routes)
}
if (apps.NOTIFICATIONS) {
  routes.push(require('./containers/Notifications').routes)
}
if (apps.RETAIL_ANALYTICS) {
  routes.push(require('./containers/RetailAnalytics').routes)
}
if (apps.RESOURCES) {
  routes.push(require('./containers/Resources').routes)
}
if (apps.ACCESSORY_MANAGEMENT) {
  routes.push(require('./containers/Accessories').routes)
}
if (apps.ORDER_MANAGEMENT) {
  routes.push(require('./containers/Orders').routes)
}
if (apps.POLLS) {
  routes.push(require('./containers/Polls').routes)
}
if (apps.STOREROOM_KANBAN) {
  routes.push(require('./containers/StoreroomKanban').routes)
}
if (apps.INSPIRATIONS) {
  routes.push(require('./containers/Inspirations').routes)
}
if (apps.WAITLIST) {
  routes.push(require('./containers/Waitlist').routes)
}
if (apps.EVENTS) {
  routes.push(require('./containers/Events').routes)
}
if (apps.CONSULTATIONS) {
  routes.push(require('./containers/ConsultationsV2').routes)
}
if (apps.RECEIVE_GOODS) {
  routes.push(require('./containers/ReceiveGoods').routes)
}
if (apps.ACCESSORY_MANAGEMENT) {
  routes.push(require('./containers/Accessories').routes)
}

if(apps.VISITOR_CONNECTION_COUNT){
  routes.push(require('./containers/VisitorConnectionCount').routes)
}

if(apps.PROMOTIONS){
  routes.push(require('./containers/Promotions').routes)
}

routes.push(require('./extensions').routes)
routes.push(require('./containers/NotFound').routes)

const delay = ms => new Promise(resolve => {
  setTimeout(resolve, ms)
})

const onBeforeLift = async function () {
  const { auth } = store.getState()
  if (auth && auth.user) {
    await digitalStoreSdk.auth.setCredentials(auth.user)
    await digitalStoreSdk.auth.getFreshToken()
  }
  await delay(0)
  return true
}

const getGTMId = () => {
  if (window.cordova) {
    return window.env.REACT_APP_DS_GTM_ID_MOBILE || process.env.REACT_APP_DS_GTM_ID_MOBILE
  }
  return window.env.REACT_APP_DS_GTM_ID_BROWSER || process.env.REACT_APP_DS_GTM_ID_BROWSER
}

export default class Routes extends React.Component {
  componentDidMount () {
    const gtmId = getGTMId()
    if (gtmId) {
      TagManager.initialize({ gtmId })
    }
  }

  render () {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <PersistGate
          loading={<FullScreenLoader />}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <MuiThemeProvider theme={createMuiTheme({ ...theme })}>
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <Root>
                <Switch>
                  {routes}
                </Switch>
              </Root>
            </ConnectedRouter>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}
