import { useSelector } from 'react-redux'

import { getAll, getError, getIsLoading, getAllAsOptions, getConnectedTerminals } from '../store/modules/paymentDevices/selectors'

/**
 * @description returns devices
 * @returns
 */
export const useResults = () => {
  return useSelector(getAll)
}

/**
 * @description returns payment devices error
 * @returns
 */
export const useError = () => {
  return useSelector(getError)
}

/**
 * @description returns if the payment device is currently loading more reports
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns payment devices options for dropdown
 * @returns
 */
export const useOptions = () => {
  return useSelector(getAllAsOptions)
}

/**
 * @description returns connected payment devices
 * @returns
 */
export const useConnectedTerminals = () => {
  return useSelector(getConnectedTerminals)
}
