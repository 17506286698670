import React, { useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse } from '@material-ui/core'
import { selectors as authSelectors } from '../../../store/modules/auth'

import style from './style'
import { useSelector } from 'react-redux';
import currencyFormatter from '../../../formatters/currencyFormatter';

const PaymentCollapsible = ({ classes, defaultOpen = false, payments = [], title = 'Payments' }) => {
  const numPayments = _.size(payments)
  const [expanded, setExpanded] = useState((defaultOpen && numPayments) ? true : false)
  const currencyCode = useSelector(authSelectors.getCurrencyCode)

  const toggle = () => {
    if (numPayments) {
      setExpanded(!expanded)
    }
  }

  const getPaymentNameType = (type, result, name) => {
    const deviceName = {
      'card:adyen-cloud': () => {
        const lastDigits = _.get(result, 'apiResponse.data.SaleToPOIResponse.PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.MaskedPan') ||
            _.get(result, 'selectedPaymentToRefund.result.apiResponse.data.SaleToPOIResponse.PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.MaskedPan')
        return lastDigits ? `Card ${lastDigits.slice(-4)}` : 'Card'
      },
      'cash:star-cloudprnt': () => 'Cash',
      'gift-card:adyen-cloud': () => {
        const serial = _.get(result, 'SaleToPOIResponse.PaymentResponse.PaymentResult.PaymentInstrumentData.StoredValueAccountID.StoredValueID', null)
        return _.isEmpty(serial) ? 'Gift Card' : `Gift Card - ${serial.slice(-9)}`
      },
      'internal:pay-by-link': () => {
        const reference = _.get(result, 'payByLinkReferenceNumber', null)
        return _.isEmpty(reference) ? `Pay By Link` : `Pay By Link - ${reference}`
      },
      'default:': () => name
    }

    return deviceName[type] ? deviceName[type]() : deviceName['default:']();
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.listItemName}>{title} ({numPayments})</div>
        <div className={classes.listItemValue} onClick={toggle}>
          <ExpandMoreIcon
            className={clsx(classes.expandIcon, { [classes.expandOpen]: expanded })}
            aria-expanded={expanded}
            aria-label="show more"
          />
        </div>
      </div>
      <div className={classes.listContainer}>
        <Collapse timeout='auto' unmountOnExit in={expanded}>
          {_.map(payments, ({ name, amountPaid, hasBeenRefunded, result, deviceType }) => {

            return (
              <div className={classes.listItem} style={{ textDecoration: hasBeenRefunded ? 'line-through' : 'none' }} >
                <div className={classes.listItemName}>{getPaymentNameType(deviceType, result, name)}</div>
                <div className={classes.listItemValue}>{currencyFormatter.format({
                  code: currencyCode,
                  value: amountPaid
                })}</div>
              </div>
            )
          })}
        </Collapse>
      </div>
    </div>
  )
}

PaymentCollapsible.propTypes = {
  defaultOpen: PropTypes.bool
}

export default withStyles(style)(PaymentCollapsible)
