export default (theme) => ({
  buttonsContainerInner: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  actionContainer: {
    minWidth: '300px',
    display: 'flex',
    margin: '10px 0px'
  },
  cancelContainer: {
    textAlign: 'left',
    flex: 1,
    padding: '0px 10px'
  },
  completeContainer: {
    textAlign: 'right',
    flex: 1,
    padding: '0px 10px'
  },
  printerDropdownContainer: {
    maxWidth: 380,
    margin: '0 auto',
    marginBottom: 20
  },
  table: {
    minWidth: 250
  },
  tableContainer: {
    margin: '10px 0px'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  amountContainer: {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: 'rgba(0,0,0,0.1)',
    border: '1px solid gray',
    borderRadius: '3px',
    textAlign: 'center',
    margin: '20px 0px',
    padding: '12px'
  },
  total: {
    fontSize: '15px'
    // fontWeight: 'normal',
  },
  outstanding: {
    fontSize: '10px',
    fontWeight: 'normal'
  },
  paymentsContainer: {
    margin: '10px 0px'
  }
})
