import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import styled from 'styled-components'
import InputStepper from '../../../../components/InputStepper'
import { translations } from '../../../../config'
import { ProductInformation, StepperWrapper, WarningLabel } from '../style'
import { getImage } from '../../../../components/Images'
import Dropdown from '../../../../components/Fields/Dropdown'
import FormControl from '@material-ui/core/FormControl'
import BasicModalOverlay from '../../../../components/BasicModalOverlay'
import modalService from '../../../../services/modalService'

const Overlay = styled.div`
  text-align: center;
`
const LocationInformation = styled.div`
  font-weight: normal;
  font-size: 0.9em;
  margin: 0.5em 0 0.5em 0;
`

const Row = styled.div`
  display: flex;
  margin: 1em 0 1em 0;
`

const ColText = styled.div`
  flex: 1;
  transform: translateY(20%);
  @media (min-width: 361px) {
    text-align: right;
    margin-right: 1em;
  }
`

const ColStepper = styled.div`
  flex: 1.3;
  text-align: left;
  margin-left: 1em;
`

const ReceiveProducts = ({
  index,
  onSubmit,
  onClose,
  product,
  status
}) => {
  const [inputQuantity, updateReturnValue] = useState(0)
  const actions = [
    {
      success: true,
      text: translations('Submit'),
      primary: true,
      disabled: status === 'checked',
      onClick: () => {
        onSubmit(inputQuantity, index)
      }
    },
    {
      text: translations('Close'),
      onClick: onClose
    }
  ]

  return (
    <BasicModalOverlay
      actions={actions}
      title={translations('Receive goods - Receive modal title')}
    >
      <Overlay>
        <ProductInformation>
          <p>{product.name}</p>
          <p>{product.variant.ean}</p>
        </ProductInformation>
        {status === 'received' && (
          <StepperWrapper>
            <Row>
              <ColText>
                {translations('Receive goods - accept quantity')}
              </ColText>
              <ColStepper>
                <InputStepper
                  value={inputQuantity}
                  onChange={(value) => updateReturnValue(value)}
                  enableFloat
                />
              </ColStepper>
            </Row>
          </StepperWrapper>
        )}
      </Overlay>
    </BasicModalOverlay>
  )
}

ReceiveProducts.propTypes = {
  index: PropTypes.number,
  onSetReturned: PropTypes.func,
  onClose: PropTypes.func,
  product: PropTypes.object,
  location: PropTypes.object,
  currentShelfCapacity: PropTypes.string,
  originalShelfCapacity: PropTypes.number
}

export default ReceiveProducts
