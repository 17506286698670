import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

export const fetchProductLists = (state, action) => ({
  ...state,
  list: action.result
})

export const fetchProductList = (state, action) => {
  switch (action.status) {
    case PENDING:
      return fetchProductListPending(state, action)
    case SUCCESS:
      return fetchProductListSuccess(state, action)
    case FAIL:
      return fetchProductListFail(state, action)
    default:
      return state
  }
}

const fetchProductListPending = (state, action) => {
  return {
    ...state,
    status: PENDING,
    isLoading: true,
    action: action.type
  }
}

const fetchProductListSuccess = (state, action) => {
  return {
    ...state,
    list: action.result,
    user: action.result.user,
    isLoading: false,
    status: SUCCESS,
    action: undefined,
    error: undefined
  }
}

const fetchProductListFail = (state, action) => {
  return {
    ...state,
    status: FAIL,
    isLoading: false,
    action: undefined,
    error: action.error.message
  }
}

export const createProductList = (state, action) => ({
  ...state,
  list: action.result
})

export const updateProductList = (state, action) => {
  switch (action.status) {
    case PENDING:
      return updateProductListPending(state, action)
    case SUCCESS:
      return updateProductListSuccess(state, action)
    case FAIL:
      return updateProductListFail(state, action)
    default:
      return state
  }
}

const updateProductListPending = (state, action) => {
  return {
    ...state,
    status: PENDING,
    isUpdating: true,
    action: action.type
  }
}

const updateProductListSuccess = (state, action) => {
  return {
    ...state,
    list: action.result,
    isUpdating: false,
    status: SUCCESS,
    action: undefined,
    error: undefined
  }
}

const updateProductListFail = (state, action) => {
  return {
    ...state,
    status: FAIL,
    isUpdating: false,
    action: undefined,
    error: action.error.message
  }
}

export const putProductList = (state, action) => ({
  ...state,
  list: action.result
})

export const deleteProductList = (state, action) => ({
  ...state,
  list: action.result
})

export const setProductListDetailsLoading = (state, action) => ({
  ...state,
  isLoading: action.isLoading
})

export const setProductListDetailsUpdating = (state, action) => ({
  ...state,
  isUpdating: action.isUpdating
})

export const setOrderBy = (state, action) => ({
  ...state,
  orderBy: action.orderBy
})

export const setSearchQuery = (state, action) => ({
  ...state,
  searchQuery: action.searchQuery
})

export const setProductListsError = (state, action) => ({
  ...state,
  error: action.error
})
