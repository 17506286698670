import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import modalService from '../../services/modalService'
import style from './style'

class Modal extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      open: false,
      component: undefined,
      props: undefined,
      fullScreen: false,
      smallModal: false,
      wideModal: false,
      zoomModal: false,
      disableBackdropClick: false,
      disableEscapeKeyDown: false,
    }
    this.state = this.initialState
  }

  open = ({ component, props, fullScreen = false, smallModal = false, wideModal = false, zoomModal = false, disableBackdropClick = false, disableEscapeKeyDown = false }) => {
    this.setState({ open: true, component, props, fullScreen, smallModal, wideModal, zoomModal, disableBackdropClick, disableEscapeKeyDown })
  }

  close = () => {
    this.setState(this.initialState)
  }

  componentDidMount () {
    modalService.connect({ open: this.open, close: this.close, modalIndex: this.props.index })
  }

  componentWillUnmount () {
    modalService.disconnect({ modalIndex: this.props.index })
  }

  render () {
    const { open, component: Component, props = {}, fullScreen, smallModal, wideModal, zoomModal, disableBackdropClick, disableEscapeKeyDown } = this.state
    const { classes } = this.props
    const paperClasses = classNames({
      [classes.container]: !fullScreen,
      [classes.fullScreenContainer]: fullScreen,
      [classes.smallModal]: smallModal && !fullScreen,
      [classes.wideModal]: wideModal && !fullScreen,
      [classes.zoomModal]: zoomModal && !fullScreen,
      [classes.noPadding]: props.noPadding
    })
    const dialogClasses = {
      paper: paperClasses,
      root: classes.root
    }
    return (
      Component
      ? (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onBackdropClick={props.onBackdropClick}
          onClose={props.dismiss || this.close}
          classes={dialogClasses}
          disableBackdropClick={disableBackdropClick}
          disableEscapeKeyDown={disableEscapeKeyDown}
        >
          <Component {...props} />
        </Dialog>
      )
      : null
    )
  }
}

export default withStyles(style)(Modal)
