import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Form from '../../../components/Form'
import FormBody from '../../../components/Form/FormBody'
import FormError from '../../../components/Form/FormError'
import FormSubmit from '../../../components/Form/FormSubmit'
import BottomBar from '../../../components/BottomBar'
import ContentBox from '../../../components/ContentBox'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../components/Form/TextContent'
import H2 from '../../../components/H2'

import { commonSchemas, paymentTerminalSchemas, cashDrawerSchemas } from './accessoryDetailsSchema'
import { useIsValid, useIsPristine } from '../../../hooks/formHooks'
import { translations } from '../../../config'
import { ADYEN_CLOUD_PAYMENT_DEVICE, STAR_CLOUD_PRNT_DEVICE } from '../../../services/paymentDeviceService'

export const formId = 'accessory-details'

const AccessoryDetailsFormWrapper = Form(formId)

const AccessoryDetailsForm = ({
  onSubmit,
  initialValues,
  mode
}) => {
  const [schema, setSchema] = useState(commonSchemas)
  const isValid = useIsValid(formId)
  const isPristine = useIsPristine(formId)

  const getSubmitButtonText = () => {
    switch (mode) {
      case 'edit':
        return translations('Save Device')
      case 'create':
        return translations('Add')
      default:
        return translations('Save')
    }
  }

  const handleChange = (data) => {
    if(data.deviceType === STAR_CLOUD_PRNT_DEVICE) {
      setSchema([...commonSchemas, ...cashDrawerSchemas])
    }else if (data.deviceType === ADYEN_CLOUD_PAYMENT_DEVICE) {
      setSchema([...commonSchemas, ...paymentTerminalSchemas])
    }
  }


  return (
    <div>
      <AccessoryDetailsFormWrapper
        editing
        onSubmit={onSubmit}
        initialValues={initialValues}
        onChange = {handleChange}
        >
        <ContentBox>
          <ContentBoxHeader>
            <TextContent><H2 value={translations('Details')} /></TextContent>
          </ContentBoxHeader>
          <ContentBoxBody>
            <FormBody
              editing
              schema={schema}
            />
          </ContentBoxBody>
        </ContentBox>
        <FormError />
        <FormSubmit
          ContainerComponent={BottomBar}
          label={getSubmitButtonText()}
          big
          disabled={isPristine || !isValid}
        />
      </AccessoryDetailsFormWrapper>
    </div>
  )
}

AccessoryDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  isFormValid: PropTypes.bool
}

export default AccessoryDetailsForm