import React, { Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import _ from 'lodash'

import SubHeader from '../../../../components/SubHeader'
import BackBar from '../../../../components/BackBar'
import Dropdown from '../../../../components/Fields/Dropdown'
import H2 from '../../../../components/H2'
import MobileSelect from '../../../../components/MobileSelect'

import { translations, retailAnalytics } from '../../../../config'

import style from './style'

const RetailAnalyticsFilters = props => {
  const {
    classes,

    storeOptions,
    storeValue,
    onChangeStore,

    viewOptions,
    viewValue,
    onChangeView
  } = props

  const dropdownProps = [
    {
      options: storeOptions,
      value: storeValue,
      onChange: onChangeStore,
      name: 'select-store',
      autocomplete: true
    },
    {
      options: viewOptions,
      value: viewValue,
      onChange: onChangeView,
      name: 'select-view'
    }
  ]

  return (
    <Fragment>
      {/* desktop */}
      <Hidden xsDown>
        <SubHeader
          leftContent={<BackBar />}
          centerContent={
            <div>
              {
                dropdownProps.map((someProps, i) => (
                  <FormControl
                    key={`formControl_${i}`}
                  >
                    <div className={classes.dropdown}>
                      <Dropdown
                        key={someProps.name}
                        {...someProps}
                        givenClasses={classes}
                        noNullOption
                        listWidth={retailAnalytics.storesListWidth}
                      />
                    </div>
                  </FormControl>
                ))
              }
            </div>
          }
        />
      </Hidden>

      {/* mobile */}
      <Hidden smUp>
        <Fragment>
          <SubHeader
            leftContent={<BackBar />}
            centerContent={<H2 value={_.toUpper(translations('Retail Analytics'))} />}
          />
          <div className={classes.mobileDropdownsContainer}>
            {
              dropdownProps.map(someProps => (
                <MobileSelect
                  key={someProps.name}
                  {...someProps}
                />
              ))
            }
          </div>
        </Fragment>
      </Hidden>
    </Fragment>
  )
}

export default withStyles(style)(RetailAnalyticsFilters)
