import { get } from 'lodash'
import { matchPath } from 'react-router'
import { LOCATION_CHANGE } from 'connected-react-router'

import * as authSelectors from '../auth/selectors'
import * as promotionsSelectors from './selectors'
import toastService from '../../../services/toastService'
import promotionsActions from './actions'
import promotionService from '../../../services/promotionService'
import { SET_PROMO_CODE, SET_REMOVE_PROMOTION_FROM_ORDER } from './constants'
import { actions as currentOrderActions } from '../../modules/currentOrder'
import { selectors as checkoutFlowSelectors } from '../../modules/checkoutFlow'
import { START } from '../checkoutFlow/constants'
import { getCurrentOrderRaw } from '../currentOrder/selectors'
import { getAllPromotions } from './selectors'
import { getDeliveryOptions } from '../checkoutFlow/selectors'
import { SELECT_STORE } from '../auth/constants'
import { translations } from '../../../config'
import { CLEAR_ORDER } from '../currentOrder/constants'

class PromotionMiddleware {
  loadPromotionMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action)

    if (
      (authSelectors.getIsLoggedIn(getState()) &&
        action.type === LOCATION_CHANGE &&
        (matchPath(action.payload.location.pathname, { path: '/products' }) ||
          matchPath(action.payload.location.pathname, {
            path: '/promotions'
          }))) ||
      (action.type === SELECT_STORE && !action.silent)
    ) {
      dispatch(promotionsActions.getAllPromotions())
    }
  }

  applyPromotionsToOrderMiddleware = ({ dispatch, getState }) => (next) => (
    action
  ) => {
    next(action)
    const state = getState()
    const currentCheckoutStep = checkoutFlowSelectors.getCurrentStep(state)

    if (action.type === START || action.type === SET_PROMO_CODE) {
      const currentOrder = getCurrentOrderRaw(state)
      const promotions = getAllPromotions(state)
      const currentStore = authSelectors.getSelectedStore(state)
      const promoCode = promotionsSelectors.getPromoCode(state)

      const {
        order: orderWithPromotions,
        canPromotionBeApplied,
        hasPromoCode,
        hasIncorrectPromoCode
      } = promotionService.addPromotionsToOrder(
        promotions,
        currentOrder,
        currentStore.id,
        promoCode
      )

      if (hasPromoCode.find(item => item === true)) {
        dispatch(promotionsActions.setHasPromoCode(hasPromoCode))
      }

      if (hasIncorrectPromoCode.find(item => item === true)) {
        if (currentCheckoutStep === 'DELIVERY') {
          toastService.action({
            type: 'error',
            message: translations(
              'Promo code has expired or is invalid, please try again'
            ),
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          })
        }

        if (!canPromotionBeApplied) {
          dispatch(promotionsActions.setRemovePromotionFromOrder(true))
        }
        dispatch(promotionsActions.setHasValidPromoCode(false))
      }

      if (canPromotionBeApplied) {
        const orderWithPromotionsApplied = promotionService.applyPromotionsToOrder(
          orderWithPromotions
        )

        if (hasPromoCode) {
          dispatch(promotionsActions.setHasValidPromoCode(true))
        }

        dispatch(
          currentOrderActions.updateOrderWithPromotions(
            orderWithPromotionsApplied
          )
        )
        dispatch(promotionsActions.setRemovePromotionFromOrder(false))
      }
    }
  }

  resetPromotionState = ({ dispatch }) => (next) => (action) => {
    next(action)

    if (action.type === CLEAR_ORDER) {
      dispatch(promotionsActions.resetPromotionState())
    }
  }

  removePromotionFromOrder = ({ dispatch, getState }) => (next) => (action) => {
    next(action)

    if (
      action.type === SET_REMOVE_PROMOTION_FROM_ORDER &&
      action.removePromotionFromOrder
    ) {
      const state = getState()
      const currentOrder = getCurrentOrderRaw(state)

      const deliveryOptions = getDeliveryOptions(state)
      const selectedDeliveryOption = deliveryOptions.find(
        (option) => option.id === get(currentOrder, 'deliveryOption.id')
      )
      const orderWithoutPromotion = promotionService.removePromotionFromOrder(
        currentOrder,
        selectedDeliveryOption
      )

      dispatch(
        currentOrderActions.updateOrderWithPromotions(orderWithoutPromotion)
      )
    }
  }
}

export default new PromotionMiddleware()
