import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import P from '../../../../../components/P'
import { getImage } from '../../../../../components/Images'

import style from './style'

const emailIcon = getImage('emailIcon')

const EmailDisplay = ({ label, value, classes, onActivityClick, showEmailButton, customer, isMessagingEnabled }) => {
  return (
    <div className={classes.displayContainer}>
      <div className={classes.textContainer}>
        <P value={label} type='textButton' />
        <P value={value} />
      </div>
      {
        isMessagingEnabled
        ? <div className={classes.iconContainer}>
          <IconButton
            onClick={onActivityClick}
            aria-label='Send email'
            classes={{ root: classes.buttonContainer }}
          >
            <img src={emailIcon} className={classes.emailIconPosition} />
          </IconButton>
        </div>
        : null
      }
    </div>
  )
}
export default withStyles(style)(EmailDisplay)
