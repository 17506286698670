import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { compose } from 'recompose'

import SubHeader from '../../../components/SubHeader'
import FormContentBox from '../../../components/FormContentBox'
import H2 from '../../../components/H2'
import BackBar from '../../../components/BackBar'
import styles from './style'
import * as schema from './schema'
import FullScreenProgress from '../../../components/FullScreenLoader'

import WrappedFormContentBox from './WrappedFormContentBox'
import LatestNote from './LatestNote'
import LatestMessage from './LatestMessage'
import RecommendedProducts from './RecommendedProducts'
import Loyalty from './Loyalty/OrderList'
import CommunicationOptions from './CommunicationOptions'
import Wishlist from './Wishlist'
import CustomerWaitlist from './CustomerWaitlist'

import AppointmentsList from '../../ConsultationsV2/Consultation/AppointmentsDashboardScreen/AppointmentsListing/AppointmentsList'

import { translations, getAppConfig } from '../../../config'
import TopRightButton from '../../../components/TopRightButton'
import { getCustomerFullName } from '../../../helpers'
import RequiredTextMessage from '../../../components/RequiredTextMessage'

const PersonalDetailsForm = FormContentBox('personal-details')
const CustomerDetailsForm = FormContentBox('customer-details')

const ViewCustomerFormWrapper = ({
  classes,
  initialValues = {},
  isLoadingInitialData,
  onSubmit,
  onSubmitWishlist,
  onSubmitRecommendedProducts,
  onCustomerLoyaltySubmit,
  onCommunicationSubmit,
  onSubmitAppointments,
  isCustomerEditable,
  onCustomerPreferencesSubmit,
  waitlistInitialValues,
  onSubmitWaitlist,
  customerFirstName,
  onActionsClick,
  exit,
  appointments,
  ...props
}) => {
  const { firstName, lastName, anonymised } = initialValues || {}
  const fullName = getCustomerFullName({ firstName, lastName, anonymised })
  const layout = getAppConfig('CUSTOMER_MANAGEMENT', 'viewCustomerScreenLayout')
  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar onClick={exit} />
        )}
        centerContent={(
          <H2 value={_.toUpper(fullName)} />
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredFollowButtonContainer}>
          <div className={classes.buttonsContainer}>
            <TopRightButton onClick={onActionsClick}>{translations('Actions')}</TopRightButton>
          </div>
          <RequiredTextMessage />
        </div>

        {layout.map(item => {
          if (!item.enabled) return null

          switch (item.type) {
            case 'PERSONAL_DETAILS':
              return (<WrappedFormContentBox
                component={PersonalDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onSubmit}
                schema={schema.personal.schema}
                layout={schema.personal.layout}
                boxName='Personal details'
                formId='personal-details'
                editable={isCustomerEditable}
              />)
            case 'CUSTOMER_PREFERENCES':
              return (schema.customer.schema) && (<WrappedFormContentBox
                component={CustomerDetailsForm}
                initialValues={initialValues}
                classes={classes}
                onSubmit={onCustomerPreferencesSubmit}
                schema={schema.customer.schema}
                layout={schema.customer.layout}
                boxName={translations(item.heading)}
                formId='customer-details'
                editable={isCustomerEditable}
              />)
            case 'NOTES':
              return (<LatestNote customer={initialValues} editable={isCustomerEditable} />)
            case 'APPOINTMENTS_LISTING':
              return (<AppointmentsList appointments={appointments} noResultsText='No appointments' />)
            case 'LOYALTY':
              return (<Loyalty onSubmit={onCustomerLoyaltySubmit} initialValues={initialValues} editable={isCustomerEditable} />)
            case 'COMMS_OPTIONS':
              return (<CommunicationOptions
                boxName={translations('Communication Options')}
                formId='communicationOptions'
                initialValues={initialValues}
                onSubmit={onCommunicationSubmit}
                editable={isCustomerEditable}
              />)
            case 'MESSAGES':
              return (<LatestMessage customer={initialValues} editable={isCustomerEditable} />)
            case 'RECOMMENDED_PRODUCTS':
              return (<RecommendedProducts
                boxName={translations(item.heading, { customerFirstName })}
                formId='recommendedProducts'
                initialValues={initialValues}
                onSubmit={onSubmitRecommendedProducts}
                editable={isCustomerEditable}
              />)
            case 'WISHLIST':
              return (<Wishlist
                boxName='Wishlist'
                formId='wishlist'
                initialValues={initialValues}
                onSubmit={onSubmitWishlist}
                editable={isCustomerEditable}
              />)
            case 'WAITLIST':
              return (<CustomerWaitlist
                boxName='Waitlist'
                formId='waitlist'
                initialValues={waitlistInitialValues}
                onSubmit={onSubmitWaitlist}
                editable={isCustomerEditable}
              />)
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

ViewCustomerFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default compose(
  FullScreenProgress,
  withStyles(styles)
)(ViewCustomerFormWrapper)
