import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import Form from '../../../../../components/Form'
import FormBody from '../../../../../components/Form/FormBody'
import ButtonsContainer from '../../../../../components/ButtonsContainer'
import { DateTimePicker, Dropdown, SelectUser, SelectCustomer, Input } from '../../../../../components/Fields'
import H2 from '../../../../../components/H2'
import Button from '../../../../../components/Button'

import CustomerRegistrationScreen from '../../../../Customer/CustomerRegistrationScreen/CustomerRegistrationForm'
import { useAppointmentsFilters } from '../../../../../hooks/appointmentsHooks'
import { useStoreROSCalendarOptions } from '../../../../../hooks/storeDetailsHooks'

import toastService from '../../../../../services/toastService/toastService'

import { actions as appointmentsActions } from '../../../../../store/modules/appointments'

import { translations, longDateFormat } from '../../../../../config'
import { required, dateTimeRequired } from '../../../../../validators'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const ContentWrapper = styled.div`
  margin: 18px 0;
`

const CustomFormBody = styled(FormBody)`
  display: flex;
  flex-direction: column;
`
const CustomFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 18px;
`

const ThematicBreakLine = styled.hr`
  margin: 16px 0;
  border: 1px solid #D3D3D3;
`

const getCustomerIsNew = (customerCreatedDate) => {
  const daysFromToday = moment().diff(customerCreatedDate, 'days')
  return daysFromToday === 0
}

const NewAppointmentForm = Form('new-appointment')

const NewAppointmentScreen = ({ onSave, onCancelClick, calendarId }) => {
  const formEl = React.useRef(null)
  const dispatch = useDispatch()
  const storeCalendarOptions = useStoreROSCalendarOptions()
  const appointmentFilters = useAppointmentsFilters()

  const initialValues = {}
  if (calendarId) {
    initialValues.calendarId = calendarId
  }

  const handleSubmit = (formData) => {
    const customerCreatedDate = _.get(formData, 'customer.createdAt')
    const customerIsNew = getCustomerIsNew(customerCreatedDate)

    const details = {}
    Object.assign(details, { duration: _.get(formData, 'duration') })
    if (customerIsNew) {
      Object.assign(details, { customerIsNew })
    }

    return dispatch(appointmentsActions.createAppointment({
      type: _.get(formData, 'type'),
      startDateTime: moment(_.get(formData, 'startDateTime'), longDateFormat).toISOString(),
      customerId: _.get(formData, 'customer.id'),
      userId: _.get(formData, 'user.id'),
      calendarId: _.get(formData, 'calendarId'),
      notes: _.get(formData, 'notes'),
      service: _.get(formData, 'service'),
      details
    }))
      .then(() => {
        toastService.action({
          type: 'success',
          message: translations('Appointment created'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
        if (onSave) {
          onSave()
        }
      })
      .catch((error) => {
        toastService.action({
          type: 'error',
          message: translations('Failed to create appointment'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  return (
    <Container>
      <H2 value={_.toUpper(translations('New appointment'))} />
      <ContentWrapper>
        <NewAppointmentForm initialValues={initialValues} ref={formEl} onSubmit={handleSubmit}>
          <CustomFormBody>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <CustomFormControl required>
                  <Dropdown
                    label={translations('Calendar')}
                    name='calendarId'
                    validate={required}
                    options={storeCalendarOptions}
                  />
                </CustomFormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <CustomFormControl required>
                  <Dropdown
                    label={translations('Appointment Type')}
                    name='type'
                    validate={required}
                    options={[
                      { label: translations('Virtual consultation'), value: 'VIRTUAL_CONSULTATION' },
                      { label: translations('Consultation'), value: 'CONSULTATION' }
                    ]}
                  />
                </CustomFormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <CustomFormControl >
                  <DateTimePicker
                    label={translations('Date')}
                    name='startDateTime'
                    validate={dateTimeRequired}
                  />
                </CustomFormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomFormControl required>
                  <Dropdown
                    label={translations('Duration')}
                    name='duration'
                    validate={required}
                    options={[{ label: `${translations('30 mins')}`, value: '30' }, { label: `${translations('60 mins')}`, value: '60' }]}
                  />
                </CustomFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <SelectCustomer
                  name='customer'
                  validate={required}
                  label={`${translations('Appointment for')}:`}
                  buttonLabel={`${translations('Search customer')}`}
                  customerRegistrationComponent={CustomerRegistrationScreen}
                />
              </Grid>
            </Grid>
            <ThematicBreakLine />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <SelectUser
                  name='user'
                  validate={required}
                  label={`${translations('Appointment with')}:`}
                  buttonLabel={`${translations('Search user')}`} />
              </Grid>
            </Grid>
            <ThematicBreakLine />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <CustomFormControl>
                  <Input
                    label={'Service'}
                    name='service' />
                </CustomFormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <CustomFormControl>
                  <Input
                    label={'Appointment notes'}
                    name='notes'
                    multiline
                    rows={1}
                    rowsMax={10} />
                </CustomFormControl>
              </Grid>
            </Grid>
          </CustomFormBody>
          <ButtonsContainer align='right'>
            <Button
              color='primary'
              buttonType='primary'
              onClick={() => formEl.current.submit()}>
              {`${translations('Save Appointment')}`}
            </Button>
            <Button
              buttonType='primary'
              onClick={onCancelClick}>
              {`${translations('Cancel')}`}
            </Button>
          </ButtonsContainer>
        </NewAppointmentForm>
      </ContentWrapper>
    </Container>
  )
}

NewAppointmentScreen.propTypes = {
  onSave: PropTypes.func,
  onCancelClick: PropTypes.func,
  calendarId: PropTypes.string
}

export default NewAppointmentScreen
