import _ from 'lodash'
import TagManager from 'react-gtm-module'
import { LOCATION_CHANGE } from 'connected-react-router'
import { calculateProductsSubTotal } from '@redant/digital-store-prices-fiskars'

import * as authSelectors from '../auth/selectors'
import * as currentOrderSelectors from '../currentOrder/selectors'
import * as routingSelectors from '../routing/selectors'
import { LOGIN, SELECT_STORE, SELECT_DEPARTMENT, SET_AUTH_CONTEXT } from '../auth/constants'
import { CREATE_CUSTOMER } from '../customerDetails/constants'
import { TOGGLE_CUSTOMER_MODE } from '../app/constants'
import { SET_ORDER_NUMBER } from '../checkoutFlow/constants'
import { CLEAR_ORDER, ADD_PRODUCT_TO_ORDER } from '../currentOrder/constants'
import { FLAG_MESSAGE_INAPPROPRIATE } from '../messageDetails/constants'
import { SEND_MESSAGE } from '../createMessage/constants'
import { env } from '../../../config'
import { selectors as currentAppointmentSelectors } from '../currentAppointment'

/**
 * @description used to trigger updates to the window.dataLayer
 * if and 'event' is included it fires an event to gtm
 * @param {*} {
 *   getState,
 *   event,
 *   ...customDimensions
 * }
 */
const sendCustomEvent = ({
  getState,
  event,
  ...customDimensions
}) => {
  const {
    userId,
    roleId,
    departmentId,
    storeId
  } = authSelectors.getGtmVariables(getState())

  TagManager.dataLayer({
    dataLayer: {
      ...event && { event },
      ...userId && { userId },
      ...roleId && { roleId },
      ...departmentId && { departmentId },
      ...storeId && { storeId },
      ...customDimensions,
      environment: env,
      dateTime: new Date().toISOString()
    }
  })
}

class GAMiddleware {
  /**
   * @description TNot triggering custom event. Here we just patch the ga session custom
   * dimensions to the GTM datalayer so it will be packaged with our passive Page View event.
   * @memberof GAMiddleware
   */
  pageViewMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      sendCustomEvent({ getState })
    }
  }

  /**
   * @description Trigger login event type on status SUCCESS or FAIL.
   * Update analytics to include role capture upon login - RET-4719
   * @memberof GAMiddleware
   */
  loginEventMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOGIN) {
      if (action.status === 'SUCCESS') {
        sendCustomEvent({
          getState,
          roleId: _.get(action, 'result.role.id'),
          event: 'login-success'
        })
      } else if (action.status === 'FAIL') {
        sendCustomEvent({
          getState,
          event: 'login-failure'
        })
      }
    }
  }

  /**
   * @description Analytics to include storeId on login
   * @memberof GAMiddleware
   */
  selectStoreMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const { type } = action
    const { storeId } = authSelectors.getGtmVariables(getState())
    if (type === SELECT_STORE && storeId) {
      sendCustomEvent({
        getState,
        storeId
      })
    }
  }

  /**
   * @description Update analytics to include department capture upon login - RET-4718
   * @memberof GAMiddleware
   */
  selectDepartmentMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const { type } = action
    const { departmentId } = authSelectors.getGtmVariables(getState())
    if (type === SELECT_DEPARTMENT && departmentId) {
      sendCustomEvent({
        getState,
        departmentId
      })
    }
  }

  /**
   * @description Update analytics to include department capture upon login - RET-4718
   * @memberof GAMiddleware
   */
  selectRoleMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    const { type, status } = action
    const { roleId } = authSelectors.getGtmVariables(getState())
    if (type === SET_AUTH_CONTEXT && status === 'SUCCESS' && roleId) {
      sendCustomEvent({
        getState,
        roleId
      })
    }
  }

  /**
   * @description GTM - 'Create Customer' event in RetailOS - RET-4800
   * @memberof GAMiddleware
   */
  createCustomerMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === CREATE_CUSTOMER && action.status === 'SUCCESS') {
      sendCustomEvent({
        getState,
        event: 'customer-create'
      })
    }
  }

  /**
   * @description GTM - 'Opened Customer Mode' event in RetailOS - RET-4801
   * @memberof GAMiddleware
   */
  customerModeMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === TOGGLE_CUSTOMER_MODE && action.isOn) {
      sendCustomEvent({
        getState,
        event: 'customer-mode'
      })
    }
  }

  /**
   * @description GTM - 'Order Complete' event in RetailOS- RET-4802
   * @memberof GAMiddleware
   */
  orderCompleteMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === SET_ORDER_NUMBER && action.orderNumber) {
      sendCustomEvent({
        getState,
        event: 'order-complete',
        orderValue: JSON.stringify(calculateProductsSubTotal(currentOrderSelectors.getCurrentOrderProducts(getState()))),
        orderType: currentAppointmentSelectors.getIsAppointmentActive(getState()) ? 'consultation' : 'standard'
      })
    }
  }

  /**
   * @description GTM - 'Order Abandoned' event in RetailOS - RET-4803
   * @memberof GAMiddleware
   */
  orderAbandonedMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === CLEAR_ORDER && action.status === 'SUCCESS' && action.showToast) {
      sendCustomEvent({
        getState,
        event: 'order-abandoned'
      })
    }
  }

  /**
   * @description GTM - 'Add to Basket via PLP' event in RetailOS - RET-4804
   * GTM - 'Add to Basket via PDP' event in RetailOS - RET-4805
   * @memberof GAMiddleware
   */
  addProductToBasketMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === ADD_PRODUCT_TO_ORDER && action.product) {
      const pathname = routingSelectors.getLocationPathname(getState())
      sendCustomEvent({
        getState,
        event: _.includes(pathname, '/cat/') ? 'basket-add-plp' : 'basket-add-pdp',
        productId: _.get(action, 'product.id')
      })
    }
  }

  /**
   * @description GTM - 'Create message - Email' event in RetailOS - RET-4808
   * GTM - 'Create message - SMS' event in RetailOS - RET-4812
   * @memberof GAMiddleware
   */
  messageSentMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === SEND_MESSAGE && action.status === 'SUCCESS') {
      const communicationType = _.get(action, 'result.communicationType')
      sendCustomEvent({
        getState,
        event: `sent-${communicationType}`
      })
    }
  }

  /**
   * @description GTM - 'Reported Messages' event in RetailOS - RET-4813
   * @memberof GAMiddleware
   */
  messageReportedMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === FLAG_MESSAGE_INAPPROPRIATE && action.status === 'SUCCESS') {
      sendCustomEvent({
        getState,
        event: 'message-reported'
      })
    }
  }
}

export default new GAMiddleware()
