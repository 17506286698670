import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { translations } from '../../config'
import Button from '../Button'
import FullScreenLoader from '../../components/FullScreenLoader/FullScreenLoader'

import style from './style'
import { Input } from '../Fields'
import Grid from '@material-ui/core/Grid'

const InputWithScanButton = props => {
  const { autoFocus, label, onClick, onChange, scanned, classes, type, isLoading } = props
  return (
    <Fragment>
      {
        isLoading
          ? <FullScreenLoader />
          : null
      }
      <div className={classes.scanButtonContainer}>
        <div>
          <Input
            className={classes.scanInput}
            autoFocus = {autoFocus}
            label={label}
            value={scanned}
            type= {type}
            onChange={onChange}
          />
        </div>
        <div>
          <Button
            onClick={onClick}
            buttonType='primary'
            className={classes.scanButton}
            iconImage={'scanIcon'}>
            {useMediaQuery(theme => theme.breakpoints.down('xs')) ? null : translations('Scan')}
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

export default withStyles(style)(InputWithScanButton)
