import { createSelector } from 'reselect'
import _ from 'lodash'
import { LOGIN } from './constants'
import { PENDING } from '../../middleware/redux-promise'
import { apps } from '../../../config'

export const getIsUsingRecentUser = state => _.get(state, 'auth.isUsingRecentUser', false)
export const getRecentUsers = state => state.auth.recentUsers || []
export const getErrorMessage = state => (state.auth.error || {}).message
export const getStatus = state => state.auth.status
export const getAction = state => state.auth.action
export const getCurrentUser = state => (state.auth || {}).user
export const getIsLoggedIn = state => {
  let isLoggedIn = false
  if (apps.DEPARTMENTS) {
    isLoggedIn = (
      state.auth.user &&
      _.get(state.auth.user, 'selectedStoreId'),
      _.get(state.auth.user, 'selectedRegionId'),
      _.get(state.auth.user, 'selectedDepartmentId', !apps.DEPARTMENTS)
    )
  } else {
    isLoggedIn = (
      state.auth.user &&
      _.get(state.auth.user, 'selectedStoreId'),
      _.get(state.auth.user, 'selectedRegionId')
    )
  }
  return !!isLoggedIn
}
export const getIsLoggedInBase = state => {
  return state.auth.user
}
export const getAccountIsLocked = state => _.chain(state)
  .get('auth.error.code')
  .eq('RA-02-02')
  .value()
export const getUserSelectedStoreId = state => (state.auth.user || {}).selectedStoreId
export const getUserSelectedExternalStoreId = state => {
  const storeId = _.get(state, 'auth.user.selectedStoreId')

  const externalStoreId = _.chain(state)
    .get('auth.user.stores')
    .find(store => store.id === storeId)
    .get('externalStoreId')
    .defaultTo(storeId)
    .value()

  return externalStoreId
}
export const getCurrentStoreBrands = state => {
  const storeId = _.get(state, 'auth.user.selectedStoreId')
  const brands = _.chain(state)
    .get('auth.user.stores')
    .find(store => store.id === storeId)
    .get('details.brands')
    .value() || []

  const storeBrand = _.chain(state)
    .get('auth.user.stores')
    .find(store => store.id === storeId)
    .get('details.storeBrand')
    .value()

  return _.compact([...brands, storeBrand])
}
export const getUserSelectedRegionId = state => (state.auth.user || {}).selectedRegionId
export const getUserSelectedStoreCatalogue = state => _.get(state, 'auth.user.catalogue')
export const getUserSelectedDepartmentId = state => (state.auth.user || {}).selectedDepartmentId
export const getLoggedInUserRole = state => (state.auth.user || {}).role

export const getIsUserAdmin = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'isAdmin'))

export const getCurrencyCode = createSelector([
  getCurrentUser
], (user) => {
  return _.get(user, 'currencyCode')
})

export const getHasUserManagement = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.userManagement'))

export const getHasRefund = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.refund'))

export const getHasEditOrder = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.editOrder'))

export const getHasExchange = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.exchangeOrder'))

export const getHasSaveBasket = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.saveBasket'))

export const getHasReassignOrder = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.reassignOrder'))

export const getHasViewPollResults = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewPollResults'))

export const getHasCancelOrder = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.cancelOrder'))

export const getHasDeleteInspiration = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.deleteInspiration'))

export const getHasDeleteEvent = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.deleteEvent'))

export const getHasViewOtherUsersNotifications = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.viewOtherUsersNotifications'))

export const getAppsAllowedForRole = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.allowedApps', {}))

export const getBroadcastPermissions = createSelector([
  getLoggedInUserRole
], (role) => _.get(role, 'permissions.broadcast'))

export const getIsMessagingAllowedForRole = createSelector([
  getAppsAllowedForRole
], (appsMap) => appsMap.MESSAGING)

export const getIsLoggingIn = createSelector([
  getAction, getStatus
], (action, status) => (
  (status === PENDING && action === LOGIN)
))

export const getUserStoresOptions = createSelector([
  getCurrentUser
], user => (
  user ? user.stores.map(store => ({
    label: store.name,
    value: store.id
  })) : []
))
export const getUserDepartmentsOptions = createSelector([
  getCurrentUser
], user => (
  user ? _.get(user, 'departments', []).map(d => ({
    label: d.name,
    value: d.id
  })) : []
))
export const getUserStoreRegionOptions = createSelector([
  getCurrentUser
], user => {
  return user ? user.stores.map(store => ({
    label: store.name,
    value: `${store.id}_${store.regionId}`
  })) : []
})
export const getUserStores = createSelector([
  getCurrentUser
], user => (
  user ? user.stores : []
))
export const getUserDepartments = createSelector([
  getCurrentUser
], user => (
  user ? user.departments : []
))

export const getUserStoreNames = createSelector([
  getUserStores
], (userStores) => userStores.map(store => store.name))

export const getUserStoreIds = createSelector([
  getUserStores
], (userStores) => userStores.map(store => store.id))

export const getSelectedStore = createSelector([
  getCurrentUser
], (user) => {
  return _.get(user, 'stores', []).find(store => store.id === user.selectedStoreId)
})

export const getSelectedDepartment = createSelector([
  getCurrentUser
], (user) => {
  return _.get(user, 'departments', []).find(d => d.id === user.selectedDepartmentId)
})

const getStoresHashmap = createSelector(
  [getUserStores],
  stores => {
    return (stores || []).reduce(
      (hashmap, store) => {
        return {
          ...hashmap,
          [store.id]: store
        }
      }, {})
  }
)

export const getUserStoreFromId = (state, id) => {
  const storesHashMap = getStoresHashmap(state)
  return storesHashMap[id] || {}
}

export const getActiveUsername = createSelector([
  getCurrentUser
], user => {
  return user && user.username
})

export const getActiveUserValue = createSelector([
  getCurrentUser
], currentUser => {
  return currentUser && currentUser.role.value
})

export const getActiveUserId = createSelector([
  getCurrentUser
], user => {
  return user && user.id
})

export const getActiveUserNumber = createSelector([
  getCurrentUser
], user => {
  return user && user.userNumber
})

export const getActiveUserExternalId = createSelector([
  getCurrentUser
], user => {
  return _.get(user, 'externalUserId') || _.get(user, 'id')
})

export const getActiveUserFullName = createSelector([
  getCurrentUser
], user => {
  return user && `${user.firstName} ${user.lastName}`
})

/**
 * @description default values to be added to the gtm datalayer each time a custom event is triggered
 * @param {*} state
 * @returns {Object} {userId,roleId,departmentId,storeId}
 */
export const getGtmVariables = (state) => {
  const currentUser = getCurrentUser(state)

  const store = _.find(_.get(currentUser, 'stores', []), (s) => {
    return s.id === _.get(currentUser, 'selectedStoreId')
  })
  const department = _.find(_.get(currentUser, 'departments', []), (d) => {
    return d.id === _.get(currentUser, 'selectedDepartmentId')
  })

  const gtmVariables = {
    userId: _.get(currentUser, 'id'),
    roleId: _.get(currentUser, 'role.name'),
    departmentId: _.get(department, 'name'),
    storeId: _.get(store, 'name')
  }
  return _.pickBy(gtmVariables, _.identity)
}
