import React, { Fragment } from 'react'
import FlagIcon from '@material-ui/icons/Flag'
import TickIcon from '@material-ui/icons/Done'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import _ from 'lodash'

import { timeFormat, longDateFormat } from '../../config'
import P from '../P'
import CircleButton from '../CircleButton'
import ButtonLink from '../ButtonLink'
import { translations } from '../../config'
import { colors } from '../../config/theme'
import style from './style'
import { getImage } from '../Images'

const tickIcon = getImage('greenTickIcon')

const Notification = ({
  notificationText,
  renderText,
  iconSource,
  createdAt,
  classes,
  onToggleStatus,
  isFlagged,
  isRead,
  isActioned,
  isActionable,
  isActionedByCurrentUser,
  isReceipt,
  onToggleActioned,
  currentUserSelected,
  hideFlag,
  meta,
  onClick
}) => {
  const isDisabled = (!isActionable) || (isActioned && !isActionedByCurrentUser)
  const userFullName = _.get(meta, 'userFullName')
  const userTimestamp = _.get(meta, 'userTimestamp')
  const userTimestampFormatted = moment(userTimestamp).format(longDateFormat)
  return (
    <div className={cx(classes.container, { [classes.isReadState]: isRead })}>
      <div className={classes.imageContainer}>
        {isReceipt && (
          <img alt={""} className={classes.receipt} src={tickIcon} />
        )}
        <img alt={""} className={classes.image} src={iconSource} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.notificationContainer}>
          {_.isFunction(renderText)
            ? renderText()
            : <ButtonLink onClick={onClick} className={classes.notificationText}>{notificationText}</ButtonLink>}
          <P value={moment(createdAt).format(timeFormat)} className={`${classes.dateTime} ${classes.hideMediumUp}`} />
          {
            isActioned
              ? (
                <div className={`${classes.userTextContainer} ${classes.hideMediumUp}`}>
                  <P type='greenText' value={userFullName} className={classes.notificationTextStyle} />
                  <P type='greenText' value={userTimestampFormatted} />
                </div>
              )
              : null
          }
        </div>
        <div className={classes.togglesContainer}>
          {
            isActioned
              ? (
                <div className={`${classes.userTextContainer} ${classes.hideSmall}`}>
                  <P type='greenText' value={userFullName} className={classes.notificationTextStyle} />
                  <P type='greenText' value={userTimestampFormatted} />
                </div>
              )
              : null
          }

          <div className={classes.toggles}>

            {
              isActionable
                ? (
                  <Fragment>
                    <CircleButton
                      label={translations('Action')}
                      className={classes.circleButtonStyle}
                      Icon={TickIcon}
                      color={
                        isActioned
                          ? (
                            isActionedByCurrentUser
                              ? colors.actionedGreen
                              : colors.text
                          )
                          : colors.mediumGrey
                      }
                      onClick={e => {
                        e.stopPropagation()
                        if (!isDisabled) {
                          onToggleActioned({ isActioned: !isActioned })
                        }
                      }}
                      isDisabled={isDisabled}
                    />
                  </Fragment>
                )
                : null
            }
            {
              currentUserSelected && !hideFlag
                ? (
                  <CircleButton
                    Icon={FlagIcon}
                    label={translations('Flag')}
                    color={isFlagged ? colors.primary : colors.mediumGrey}
                    onClick={(e) => {
                      e.stopPropagation()
                      onToggleStatus({ isFlagged: !isFlagged })
                    }}
                  />
                ) : null
            }

          </div>
        </div>
        <P value={moment(createdAt).format(timeFormat)} className={`${classes.dateTime} ${classes.hideSmall}`} />
      </div>
    </div>
  )
}

Notification.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  notificationText: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  iconSource: PropTypes.node.isRequired,
  onToggleStatus: PropTypes.func.isRequired,
  onToggleActioned: PropTypes.func.isRequired,
  isFlagged: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  isActioned: PropTypes.bool.isRequired,
  isActionedByCurrentUser: PropTypes.bool,
  isActionable: PropTypes.bool.isRequired,
  isReceipt: PropTypes.bool.isRequired, // Adds a tick to the top right corner of icon
  meta: PropTypes.shape({}),
  hideFlag: PropTypes.bool,
  currentUserSelected: PropTypes.bool
}

export default withStyles(style)(Notification)
