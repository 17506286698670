import { constants as authConstants, actions as authActions, selectors as authSelectors } from '../auth'
import { constants as userDetailsConstants } from '../userDetails'
import { selectors as appSelectors } from '../app'
import cacheService from '../../../services/cacheService'
import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { SUCCESS, FAIL } from '../../middleware/redux-promise'
import analyticsService from '../../../services/analyticsService'
import digitalStoreSdk from '../../../digitalStoreSdk'

class AuthMiddleware {
  loginSuccessPreselectStoreMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const { isCustomerMode, isUsingRecentUser = false } = action

    if (isUsingRecentUser && action.status === SUCCESS && action.type === authConstants.LOGIN && !isCustomerMode) {
      const userId = action.result.id
      const storeId = cacheService.getItem(`${userId}-selected-store-id`)
      const regionId = cacheService.getItem(`${userId}-selected-region-id`)
      const departmentId = cacheService.getItem(`${userId}-selected-department-id`)
      if (storeId && regionId) {
        dispatch(authActions.selectStore({ storeId, regionId, silent: !isUsingRecentUser }))
        if (departmentId) {
          dispatch(authActions.selectDepartment({ departmentId, silent: !isUsingRecentUser }))
        }
      }
    }
  }

  loginUsingRecentUserMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === '@@redux-form/CHANGE') {
      const form = _.get(action, 'meta.form')
      const field = _.get(action, 'meta.field')
      const isUsingRecentUser = authSelectors.getIsUsingRecentUser(getState())
      if (isUsingRecentUser && form === 'login' && field === 'username') {
        dispatch(authActions.setIsUsingRecentUser(false))
      }
    }
    next(action)
  }

  setAuthContextWhenSelectingStoreOrDepartment = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    if (action.type === authConstants.SELECT_STORE || action.type === authConstants.SELECT_DEPARTMENT) {
      const { storeId, departmentId } = action
      const authContext = {}
      if (storeId) _.set(authContext, 'currentStoreId', storeId)
      if (departmentId) _.set(authContext, 'currentDepartmentId', departmentId)
      dispatch(authActions.setAuthContext(authContext))
    }
  }

  selectStoreCacheSelectedStoreMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.SELECT_STORE) {
      const { auth: { user: { id: userId } } } = getState()
      cacheService.setItem(`${userId}-selected-store-id`, action.storeId)
      cacheService.setItem(`${userId}-selected-region-id`, action.regionId)
    }
    next(action)
  }

  selectDepartmentCacheSelectedStoreMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === authConstants.SELECT_DEPARTMENT) {
      const { auth: { user: { id: userId } } } = getState()
      cacheService.setItem(`${userId}-selected-department-id`, action.departmentId)
    }
    next(action)
  }

  updateAuthUserOnUserProfileUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === userDetailsConstants.UPDATE_USER && action.status === SUCCESS) {
      const currentUser = authSelectors.getCurrentUser(getState())
      const user = action.result
      if (currentUser.id === user.id) {
        dispatch(authActions.refreshAuthContext())
      }
    }
    next(action)
  }

  accountLockedMiddleware = ({ dispatch, getState }) => next => (action) => {
    const isRoutingToSelectStore = action.type === authConstants.LOGIN && action.status === FAIL && action.error.code === 'RA-02-02'
    const customerMode = appSelectors.getCustomerModeStatus(getState())
    if (isRoutingToSelectStore && !customerMode) {
      const pushToForgotPassword = () => dispatch(push(`/forgot-password`))
      modalService.action({
        title: translations('Account Locked'),
        text: translations('Too many unsuccessful login attempts. Please reset your password to unlock your account'),
        actions: [
          {
            success: true,
            text: translations('Reset Password'),
            onClick: () => pushToForgotPassword(),
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
      next(action)
    } else {
      next(action)
    }
  }

  closeModalsOnLogoutMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.status === SUCCESS && action.type === authConstants.LOGOUT) {
      modalService.close()
    }
    next(action)
  }

  resetPassword = ({ dispatch, getState }) => next => action => {
    if (action.status === SUCCESS && action.type === authConstants.resetPassword) {
      analyticsService.sendCustomEvent({ type: 'resetPasswordRequest' })
    }
    next(action)
  }

  ssoLoginSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE) {
      const res = matchPath(action.payload.pathname, {
        path: '/sso/success/:token',
        exact: true
      })
      if (res && res.params.token) {
        digitalStoreSdk.auth.setToken(res.params.token)
        dispatch(authActions.getSSOAuthContext())
      }
    }
    next(action)
  }

  ssoLoginErrorMiddleware = ({ dispatch, getState }) => next => action => {
    if (action.type === LOCATION_CHANGE && matchPath(action.payload.pathname, {
      path: '/sso/error',
      exact: true
    })) {
      dispatch(push(`/login`))
      modalService.action({
        title: translations('SSO Login Failed'),
        text: translations('There was an error authenticating your account with Digital Store. Try again later.'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
    }
    next(action)
  }
}

export default new AuthMiddleware()
