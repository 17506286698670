import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  store: {},
  view: { type: 'store' },
  timePeriod: 'thisWeek'
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_STORE:
      return reducers.fetchStore(state, action)
    case constants.SET_VIEW:
      return reducers.setView(state, action)
    case constants.SET_TIME_PERIOD:
      return reducers.setTimePeriod(state, action)
    case constants.CREATE_STORE:
      return reducers.createStore(state, action)
    case constants.CLEAR:
      return reducers.clear(state, action)
    default:
      return state
  }
}
