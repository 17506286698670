import React from 'react'
import styled from 'styled-components'
import SubHeader from '../../components/SubHeader'
import BackBar from '../../components/BackBar'
import SearchInput from '../../components/SearchInput'
import { translations } from '../../config'
import BottomBar from '../../components/BottomBar'
import ButtonsContainer from '../../components/ButtonsContainer'
import ProductListDetailsAdd from '../../components/ProductLists/ProductListDetails/ProductListDetailsAddGeneric'
import ProductListDetailsDropdown from '../../components/ProductLists/ProductListDetails/ProductListDetailsDropdownGeneric'
import ProductListDetailsFilter from '../../components/Filter'
import modalService from '../../services/modalService'
import { getImage } from '../../components/Images'
import { colors } from '../../config/theme'
import ErrorMessage from '../../components/ProductLists/ErrorMessage'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import { isArray, padStart, flatMap } from 'lodash'

const ListDetails = styled.div`
  font-size: 14px;
  border-bottom: 0.5px solid #B9B9B9;
  padding: 15px 0;
`

const DetailsTitle = styled.h1`
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px 0;
  display: inline-block;
`
const DetailsSubTitle = styled.p`
  margin: 0;
`

const CenterContent = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px 10px;
  margin-left: auto;
  margin-right: auto;
`
const ListCreatedWrapper = styled.div`
  background-color: #1C8449;
  text-align: center;
`

const ListCreatedLabel = styled.p`
  font-weight: bold;
  vertical-align: middle;
  color: white;
  font-size: 18px;
`
const StatusWrapper = styled.div`
  margin-left: 0.6em;
  background-color: #EEA200;
  height: 1.4em;
  padding: 0 0.8em;
  border-radius: 0.8em;
  text-align: center;
  display: inline-block;
`

const StatusLabel = styled.span`
  font-weight: bold;
  font-size: 11px;
  color: white;
`

const FooterButton = styled.button`
  width: 100%;
  max-width: 380px;
  height: 45px;
  background-color: ${colors.primary};
  border: none;
  border-radius: 2px;
  color: ${colors.whiteText};
  font-weight: bold;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-family: 'Tahoma';

  &:disabled {
    background-color: ${colors.mediumGrey};
    cursor: default;
  }
`

const Filter = styled.button`
  float: right;
  transform: translateY(-25%);
  width: 100%;
  max-width: 100px;
  height: 30px;
  background-color: ${colors.primary};
  border: none;
  border-radius: 2px;
  color: ${colors.whiteText};
  font-weight: bold;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-family: 'Tahoma';
`

const FilterList = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  text-decoration: underline;
`

const FilterWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`

const DetailsButtonTopRight = styled.img`
  float: right;
  &:hover { cursor: pointer; }
  width: 17px;
`

const LoaderWrapper = styled.div`
  text-align: center;
  margin-top: 40%;
`

const OrderByWrapper = styled.div`
  text-align: left;
  margin: 10px 8px;
  font-size: 14px;
`

const OrderBy = styled.img`
  margin-left: 0.5em;
  &:hover {
    cursor: pointer;
  }
`

const ProductList = styled.div`
  padding-bottom: 20px;
`

const EmptyMessage = styled.p`
  margin: 150px 0 0 0;
  text-align: center;
`

const ProductListDetailsScreen = ({
  list,
  error,
  isLoading,
  isUpdating,
  onQuerySubmit,
  searchQuery,
  searchFocus,
  updateQuery,
  searchInputPlaceholder,
  searchResults,
  footerButtons,
  headerMessage,
  detailsTitle,
  detailsSubTitle,
  detailsStatus,
  detailsButtonTopRight,
  orderByTitle,
  orderByOptions,
  activeOrderByOption,
  setOrderByOption,
  products,
  onClickAdd,
  renderProduct,
  emptyListMessage,
  isSearchLoading,
  productToast,
  searchDisabled,
  print,
  filters
}) => {
  const renderGroupedProducts = (grouped) => {
    if (!grouped.length) return null

    const title = grouped[0].groupTitle
    const groupSubtitle = grouped[0].groupSubTitle

    return (
      <ProductListDetailsDropdown
        title={title}
        subtitle={groupSubtitle}
        openByDefault={!(groupSubtitle && groupSubtitle.length)}
        total={grouped.length}
      >
        {grouped.length > 0 && grouped.map((p, i) => {
          return renderProduct(p, i)
        })}
      </ProductListDetailsDropdown>
    )
  }

  return (
    <>
      <SubHeader
        leftContent={<BackBar />}
        isLoading={isUpdating || isSearchLoading}
        centerContent={
          updateQuery && <SearchInput
            key={detailsTitle}
            value={searchQuery}
            placeholder={searchInputPlaceholder}
            autoFocus={searchFocus}
            onChange={(e) => updateQuery(e.target.value)}
            onClear={() => updateQuery('')}
            onSubmit={onQuerySubmit}
            disabled={searchDisabled}
            submitText={translations('Product lists - add')}
          />
        }
      />
      {error && <ErrorMessage content={translations('503 Error')} />}
      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress size={50} color='secondary' />
        </LoaderWrapper>
      ) : (

        <>
          {headerMessage &&
            <ListCreatedWrapper>
              <ListCreatedLabel>
                {headerMessage.icon && <img src={getImage(headerMessage.icon)}/>}{` ${headerMessage.message}`}
              </ListCreatedLabel>
            </ListCreatedWrapper>}
            <ListDetails>
              <CenterContent>
                {detailsTitle && <DetailsTitle>{detailsTitle}</DetailsTitle>}
                {detailsStatus && <StatusWrapper>
                  <StatusLabel>{detailsStatus}</StatusLabel>
                </StatusWrapper>}
                {print && print}
                {detailsButtonTopRight && <DetailsButtonTopRight
                  src={getImage(detailsButtonTopRight.icon)}
                  onClick={() => detailsButtonTopRight.onClick(list)}
                />}
                {
                  detailsSubTitle && Array.isArray(detailsSubTitle)
                    ? detailsSubTitle.map((item, index) => {
                      // So we can render JSX as subtitles...
                      if (typeof item === 'function') return item()
                      return <DetailsSubTitle key={index}>{item}</DetailsSubTitle>
                    })
                    : <DetailsSubTitle>{detailsSubTitle}</DetailsSubTitle>
                }
              </CenterContent>
            </ListDetails>
            {productToast && productToast}
            <CenterContent>
              {orderByOptions && orderByOptions.length > 0 && (
                <CenterContent>
                  <OrderByWrapper>
                    {orderByTitle}
                    {orderByOptions.map((filter, index) => (
                      <OrderBy
                        key={index}
                        src={getImage(activeOrderByOption === filter.name ? filter.activeIcon : filter.inactiveIcon)}
                        onClick={() => setOrderByOption(filter.name)}
                      />
                    ))}
                  </OrderByWrapper>
                </CenterContent>
              )}
              {filters && filters.filterList.length > 0 &&
                <FilterWrapper>
                  <Filter onClick={() => {
                    return modalService.open({
                      component: ProductListDetailsFilter,
                      modalIndex: 1,
                      formName: filters.formName,
                      filters: filters.filterList,
                      title: filters.title,
                      type: filters.type,
                      text: filters.text,
                      actions: filters.actions
                    })
                  }}>
                    {translations('Product lists - filter', { amount: filters.amount })}
                  </Filter>
                  {filters.list && <FilterList>{filters.list}</FilterList>}
                </FilterWrapper>
              }
            </CenterContent>
            <CenterContent>
              {isLoading ? (
                <LoaderWrapper>
                  <CircularProgress size={50} color='secondary' />
                </LoaderWrapper>
              )
                : <ProductList>
                  {(products && flatMap(products).length === 0) && (searchResults && searchResults.length === 0) && (
                    <EmptyMessage>{emptyListMessage}</EmptyMessage>
                  )}
                  {(products && products.length > 0) && products.map((p, i) => {
                    if (isArray(p)) {
                      return renderGroupedProducts(p)
                    } else {
                      return renderProduct(p, i)
                    }
                  })}
                  {(products && products.length === 0) && (searchResults.length > 0) && (
                    <ProductListDetailsDropdown title={'Items not in this list'} total={searchResults.length}>
                      {isSearchLoading ? (
                        <LoaderWrapper>
                          <CircularProgress size={50} color='secondary' />
                        </LoaderWrapper>
                      ) : (
                        searchResults.map((result, index) =>
                          <ProductListDetailsAdd
                            key={index}
                            label={`${padStart(result.externalProductId, 7, 0)} ${result.name}`}
                            onClick={() => onClickAdd({ product: result })}
                          />
                        )
                      )}
                    </ProductListDetailsDropdown>
                  )}
                </ProductList>
              }
            </CenterContent>
          </>
      )}

      {(footerButtons && footerButtons.length > 0) && (
        <BottomBar>
          <ButtonsContainer align='center'>
            {footerButtons.map((footerButton, index) => {
              return (
                <FooterButton
                  key={index}
                  disabled={footerButton.disabled}
                  onClick={() => footerButton.onClick()}
                >
                  {footerButton.label}
                </FooterButton>
              )
            })}
          </ButtonsContainer>
        </BottomBar>
      )}
    </>
  )
}

ProductListDetailsScreen.propTypes = {
  list: PropTypes.object,
  emptyListMessage: PropTypes.string,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  searchQuery: PropTypes.string,
  onQuerySubmit: PropTypes.func,
  updateQuery: PropTypes.func,
  searchFocus: PropTypes.bool,
  detailsTitle: PropTypes.string,
  detailsSubTitle: PropTypes.string,
  detailsStatus: PropTypes.string,
  detailsButtonTopRight: PropTypes.objectOf({
    icon: PropTypes.string,
    onClick: PropTypes.func
  }),
  footerButtons: PropTypes.arrayOf(PropTypes.objectOf({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  })),
  headerMessage: PropTypes.objectOf({
    message: PropTypes.string.isRequired,
    icon: PropTypes.string
  }),
  orderByTitle: PropTypes.string,
  orderByOptions: PropTypes.arrayOf(PropTypes.objectOf({
    name: PropTypes.string,
    activeIcon: PropTypes.string,
    inactiveIcon: PropTypes.string
  })),
  setOrderByOption: PropTypes.func,
  activeOrderByOption: PropTypes.string,
  productToast: PropTypes.obj,
  searchDisabled: PropTypes.bool,
  print: PropTypes.elementType,
  filters: PropTypes.objectOf({
    formName: PropTypes.string.isRequired,
    filterList: PropTypes.array.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    actions: PropTypes.array,
    list: PropTypes.string,
    amount: PropTypes.number.isRequired
  })
}

ProductListDetailsScreen.defaultProps = {
  list: {},
  error: false,
  footerButtons: [],
  filters: {
    filterList: []
  },
  searchResults: [],
  searchFocus: false,
  productToast: null
}

export default ProductListDetailsScreen
