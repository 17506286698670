import _ from 'lodash';

import digitalStoreSdk from '../../../digitalStoreSdk';
import { apiUpdateSource } from '../../../config';

import * as constants from './constants';

import { selectors as authSelectors } from '../auth';
import { generateOrderNumber } from '../../../containers/Checkout/uploadOrder';
import offlineOrdersService from '../../../services/offlineOrdersService';

class OrderDetailsActions {
  fetchOrder = ({ orderNumber }) => ({
    type: constants.FETCH_ORDER,
    promise: (dispatch, getState) => {
      const { connected: isOnline } = getState().network
      return isOnline ? digitalStoreSdk.orders.fetchOrder({
        id: orderNumber,
        includes: ['customer', 'user']
      }) : offlineOrdersService.fetchOrder({ orderNumber })
    }
  });
  reassignUser = ({ id, userId }) => ({
    type: constants.REASSIGN_USER_FOR_ORDER,
    promise: () =>
      digitalStoreSdk.orders
        .reassignOrder({ id, userId, updateSource: apiUpdateSource })
        .then((res) =>
          digitalStoreSdk.orders.fetchOrder({
            id: res.orderNumber,
            includes: ['customer', 'user']
          })
        )
  });
  reassignCustomer = ({ id, customer, anonymousReason } = {}) => {
    const params = { id };
    if (customer) {
      params.customerId = _.get(customer, 'id');
    } else {
      params.anonymous = true;
      params.details = {
        ...(params.details || {}),
        anonymousReason
      };
    }
    return {
      type: constants.REASSIGN_CUSTOMER_FOR_ORDER,
      promise: () =>
        digitalStoreSdk.orders
          .reassignOrder({ ...params, updateSource: apiUpdateSource })
          .then((res) =>
            digitalStoreSdk.orders.fetchOrder({
              id: res.orderNumber,
              includes: ['customer', 'user']
            })
          )
    };
  };
  beginExchangeOrder = () => ({
    type: constants.BEGIN_EXCHANGE_ORDER
  });
  cancelExchangeOrder = () => ({
    type: constants.CANCEL_EXCHANGE_ORDER
  });
  beginRefundPartialOrder = ({ id, customerId, userId }) => ({
    type: constants.BEGIN_PARTIAL_REFUND_ORDER
  });
  cancelRefundPartialOrder = ({ id, customerId, userId }) => ({
    type: constants.CANCEL_PARTIAL_REFUND_ORDER
  });
  refundFullOrder = ({
    id,
    refundProducts,
    details,
    user,
    status,
    order
  }) => ({
    type: constants.REFUND_ORDER,
    promise: (dispatch, getState) => {

      const refundOrderNumber = order.previousOrderId
        ? order.orderNumber
        : generateOrderNumber();

      const refundData = {
        id,
        productsToRefund: refundProducts,
        updateSource: apiUpdateSource,
        refundOrderNumber,
        details,
        status
      };

      return digitalStoreSdk.orders.refundOrder(refundData)
        .then(async (res) => {
          return digitalStoreSdk.orders.fetchOrder({
            id: res.orderNumber,
            includes: ['customer', 'user']
          });
        });
    }
  });
  createCustomer = (params, id, userId) => {
    return {
      type: constants.CREATE_CUSTOMER_FOR_ORDER,
      promise: (dispatch, getState) => {
        const territoryId = authSelectors.getCurrentUser(getState())
          .currentTerritoryId;

        return digitalStoreSdk.customers
          .createCustomer({
            ...params,
            territoryId,
            updateSource: apiUpdateSource
          })
          .then((customer) => {
            dispatch(this.reassignCustomer({ customer, id, userId }));
          });
      }
    };
  };
  cancelOrder = ({ id }) => ({
    type: constants.CANCEL_ORDER,
    promise: () => digitalStoreSdk.orders.cancelOrder({ id })
  });
  updateOrder = ({ id, noSpinner, ...fields }) => ({
    type: constants.UPDATE_ORDER,
    noSpinner,
    promise: (dispatch, getState) => {
      const { connected: isOnline } = getState().network;
      const orderDetails = {
        ...fields,
        id,
        updateSource: apiUpdateSource
      };

      if (!isOnline) {
        return offlineOrdersService.update(orderDetails);
      }

      return digitalStoreSdk.orders.updateOrder(orderDetails);
    }
  });
}

export default new OrderDetailsActions();
