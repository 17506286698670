import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations, userRoles } from '../../config'
import Form from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'
import style from './style'
import currencyFormatter from '../../formatters/currencyFormatter'
import moment from 'moment'

export const formId = 'cash-review-count-modal'
const ThisForm = Form(formId)

class CashReviewCountModal extends Component {

  reviewable = () => {
    const { cashPaymentDeviceCount } = this.props

    if (cashPaymentDeviceCount === null) {
      return null
    }

    if (cashPaymentDeviceCount.signedOffById) {
      return false
    }

    return true
  }

  render() {
    const {
      classes,
      cashPaymentDeviceCount,
      success,
      dismiss,
      onRecount,
      name,
      userRole
    } = this.props

    const reviewable = this.reviewable()
    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })

    actions.push({
      text: translations('Recount'),
      success: true,
      primary: true,
      onClick: () => onRecount()
    })
    actions.push({
      text: translations('Sign off'),
      success: true,
      primary: true,
      disabled: (reviewable !== true)
    })

    const canUserViewTheExpectedAmount = 
      userRole.name !== userRoles.SALES_ASSISTANT

    return (
      <ThisForm onSubmit={success}>
        <BasicModalOverlay
          title={`Review count (${name})`}
          confirmButtonText={translations('Pay')}
          actions={actions}
        >
          {reviewable === null ? (
            <p className={classes.alertText}>Not counted yet.</p>
          ) : (
              <div className={classes.sectionContainer}>
                <div className={classes.section}>
                  {canUserViewTheExpectedAmount && <div>
                    <p className={classes.sectionTitle}>Expected Amount</p>
                    <p className={classes.sectionValue}>{currencyFormatter.format(cashPaymentDeviceCount.expectedCount)}</p>
                  </div>
                  }
                  <div>
                    <p className={classes.sectionTitle}>Counted Amount</p>
                    <p className={classes.sectionValue}>{currencyFormatter.format(cashPaymentDeviceCount.countedValue)}</p>
                  </div>
                  {reviewable === false && (
                    <div>
                      <p className={classes.alertText}>Already Signed off by {cashPaymentDeviceCount.signedOffBy.username}</p>
                    </div>
                  )}
                </div>
                <div className={classes.section}>
                  <div>
                    <p className={classes.sectionTitle}>Counted By</p>
                    <p className={classes.sectionValue}>{cashPaymentDeviceCount.countedBy.username}</p>
                  </div>
                  <div>
                    <p className={classes.sectionTitle}>Count Date</p>
                    <p className={classes.sectionValue}>{moment(cashPaymentDeviceCount.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                  </div>
                </div>
              </div>
            )}
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(CashReviewCountModal)
