import {
  generateUniqueServiceId,
  getTimeInUTCFormat,
  getErrorMessageFromAString,
  makeSaleToAcquirerData
} from './helpers'

import { makeRequest } from '../../adyenCloudPaymentDevice/adyenCloud/makeRequest'

class AdyenGiftCardsService {
  /**
   * Activate a gift card at the point of sale, so a shopper can use it.
   * @param {string} paymentTerminalID selected adyen terminal Id
   * @param {{ cardNumber: string, expiryDate?: string, value: number, currency: string, ean: string }} giftCardDetails gift card details
   * @param {string} orderNumber  unique reference for the request
   * @param {( 'Keyed' | 'Scanned' | 'MagStripe' )} entryMode - entry mode
   * @param {string} saleId - unique Id for the cash register
   */
  static async activateCard (
    paymentTerminalID,
    giftCardDetails,
    orderNumber,
    entryMode,
    saleId
  ) {
    const serviceID = generateUniqueServiceId()
    const timeStamp = getTimeInUTCFormat()
    const body = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: '3.0',
          MessageType: 'Request',
          MessageClass: 'Service',
          MessageCategory: 'StoredValue',
          SaleID: saleId,
          POIID: paymentTerminalID,
          ServiceID: serviceID
        },
        StoredValueRequest: {
          SaleData: {
            SaleTransactionID: {
              TransactionID: orderNumber,
              TimeStamp: timeStamp
            },
            SaleToAcquirerData: makeSaleToAcquirerData([`metadata.ean=${giftCardDetails.ean}`])
          },
          StoredValueData: [
            {
              StoredValueTransactionType: 'Activate',
              StoredValueAccountID: {
                StoredValueAccountType: 'GiftCard',
                StoredValueProvider: 'givex',
                IdentificationType: 'PAN',
                EntryMode: [entryMode],
                StoredValueID:
                  entryMode === 'Scanned' ? giftCardDetails.cardNumber : ''
              },
              ItemAmount: giftCardDetails.value,
              Currency: giftCardDetails.currency
            }
          ]
        }
      }
    }

    try {
      const data = await makeRequest(body)

      if (
        data.SaleToPOIResponse.StoredValueResponse.Response.Result === 'Success'
      ) {
        return {
          status: 'Success',
          currency:
            data.SaleToPOIResponse.StoredValueResponse.StoredValueResult[0]
              .Currency,
          currentBalance:
            data.SaleToPOIResponse.StoredValueResponse.StoredValueResult[0]
              .StoredValueAccountStatus.CurrentBalance,
          ...data
        }
      }

      const errorMessage = getErrorMessageFromAString(
        data.SaleToPOIResponse.StoredValueResponse.Response.AdditionalResponse
      )

      return {
        status: 'Failure',
        errorMessage,
        errorCondition:
          data.SaleToPOIResponse.StoredValueResponse.Response.ErrorCondition,
        ...data
      }
    } catch (error) {
      return {
        status: 'Failure',
        errorMessage: 'Unexpected Error',
        error
      }
    }
  }

  /**
   * Add funds to a gift card at the point of sale.
   * Use this method only to add funds to the existings cards.
   * @param {string} paymentTerminalID selected adyen terminal Id
   * @param {{ cardNumber: string, expiryDate?: string, value: number, currency: string }} giftCardDetails gift card details
   * @param {string} orderNumber  unique reference for the request
   * @param {( 'Keyed' | 'Scanned' | 'MagStripe' )} entryMode - entry mode
   * @param {string} saleId - unique Id for the cash register
   */
  static async loadFunds (
    paymentTerminalID,
    giftCardDetails,
    orderNumber,
    entryMode,
    saleId
  ) {
    const serviceID = generateUniqueServiceId()
    const timeStamp = getTimeInUTCFormat()
    const body = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: '3.0',
          MessageClass: 'Service',
          MessageCategory: 'StoredValue',
          MessageType: 'Request',
          ServiceID: serviceID,
          SaleID: saleId,
          POIID: paymentTerminalID
        },
        StoredValueRequest: {
          SaleData: {
            SaleTransactionID: {
              TransactionID: orderNumber,
              TimeStamp: timeStamp
            },
            SaleToAcquirerData: makeSaleToAcquirerData()
          },
          StoredValueData: [
            {
              StoredValueTransactionType: 'Load',
              StoredValueAccountID: {
                StoredValueAccountType: 'GiftCard',
                StoredValueProvider: 'givex',
                IdentificationType: 'PAN',
                EntryMode: [entryMode],
                StoredValueID:
                  entryMode === 'Scanned' ? giftCardDetails.cardNumber : ''
              },
              ItemAmount: giftCardDetails.value,
              Currency: giftCardDetails.currency
            }
          ]
        }
      }
    }

    try {
      const data = await makeRequest(body)

      if (
        data.SaleToPOIResponse.StoredValueResponse.Response.Result === 'Success'
      ) {
        return {
          status: 'Success',
          currency:
            data.SaleToPOIResponse.StoredValueResponse.StoredValueResult[0]
              .Currency,
          currentBalance:
            data.SaleToPOIResponse.StoredValueResponse.StoredValueResult[0]
              .CurrentBalance
        }
      }

      const errorMessage = getErrorMessageFromAString(
        data.SaleToPOIResponse.StoredValueResponse.Response.AdditionalResponse
      )

      return {
        status: 'Failure',
        errorMessage,
        errorCondition:
          data.SaleToPOIResponse.StoredValueResponse.Response.ErrorCondition
      }
    } catch (error) {
      return {
        status: 'Failure',
        errorMessage: 'Unexpected Error',
        error
      }
    }
  }

  /**
   * Check the funds remaining on an activated gift card.
   * @param {string} paymentTerminalID selected adyen terminal Id
   * @param {{ cardNumber: string, expiryDate?: string }} giftCardDetails gift card details
   * @param {( 'Keyed' | 'Scanned' | 'MagStripe' )} entryMode - entry mode
   * @param {string} saleId - unique Id for the cash register
   */
  static async checkBalance (
    paymentTerminalID,
    giftCardDetails,
    entryMode,
    saleId
  ) {
    const serviceID = generateUniqueServiceId()
    const body = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: '3.0',
          MessageClass: 'Service',
          MessageCategory: 'BalanceInquiry',
          MessageType: 'Request',
          ServiceID: serviceID,
          SaleID: saleId,
          POIID: paymentTerminalID
        },
        BalanceInquiryRequest: {
          PaymentAccountReq: {
            PaymentInstrumentData: {
              PaymentInstrumentType: 'StoredValue',
              StoredValueAccountID: {
                StoredValueAccountType: 'GiftCard',
                StoredValueProvider: 'givex',
                IdentificationType: 'PAN',
                EntryMode: [entryMode],
                StoredValueID:
                  entryMode === 'Scanned' ? giftCardDetails.cardNumber : ''
              }
            }
          }
        }
      }
    }

    try {
      const data = await makeRequest(body)

      if (
        data.SaleToPOIResponse.BalanceInquiryResponse.Response.Result ===
        'Success'
      ) {
        return {
          status: 'Success',
          currency:
            data.SaleToPOIResponse.BalanceInquiryResponse.PaymentAccountStatus
              .Currency,
          currentBalance:
            data.SaleToPOIResponse.BalanceInquiryResponse.PaymentAccountStatus
              .CurrentBalance
        }
      }

      const errorMessage = getErrorMessageFromAString(
        data.SaleToPOIResponse.BalanceInquiryResponse.Response
          .AdditionalResponse
      )

      return {
        status: 'Failure',
        errorMessage,
        errorCondition:
          data.SaleToPOIResponse.BalanceInquiryResponse.Response.ErrorCondition
      }
    } catch (error) {
      return {
        status: 'Failure',
        errorMessage: 'Unexpected Error',
        error
      }
    }
  }

  /**
   * Make a gift card payment at the point of sale.
   * @param {string} paymentTerminalID selected adyen terminal Id
   * @param {{ cardNumber: string, expiryDate?: string }} giftCardDetails gift card details
   * @param {{ value: string, currency: string }} paymentRequestDetails payment request details
   * @param {string} orderNumber  unique reference for the request
   * @param {( 'Keyed' | 'Scanned' | 'MagStripe' )} entryMode - entry mode
   * @param {string} saleId - unique Id for the cash register
   */
  static async makeAPayment (
    paymentTerminalID,
    giftCardDetails,
    paymentRequestDetails,
    entryMode,
    orderNumber,
    saleId
  ) {
    const serviceID = generateUniqueServiceId()
    const timeStamp = getTimeInUTCFormat()
    const body = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: '3.0',
          MessageClass: 'Service',
          MessageCategory: 'Payment',
          MessageType: 'Request',
          ServiceID: serviceID,
          SaleID: saleId,
          POIID: paymentTerminalID
        },
        PaymentRequest: {
          SaleData: {
            SaleTransactionID: {
              TransactionID: orderNumber,
              TimeStamp: timeStamp
            },
            SaleToAcquirerData: makeSaleToAcquirerData()
          },
          PaymentTransaction: {
            AmountsReq: {
              Currency: paymentRequestDetails.currency,
              RequestedAmount: parseFloat(paymentRequestDetails.value)
            }
          },
          PaymentData: {
            PaymentInstrumentData: {
              PaymentInstrumentType: 'StoredValue',
              StoredValueAccountID: {
                StoredValueAccountType: 'GiftCard',
                StoredValueProvider: 'givex',
                IdentificationType: 'PAN',
                EntryMode: [entryMode],
                StoredValueID:
                  entryMode === 'Scanned' ? giftCardDetails.cardNumber : ''
              }
            }
          }
        }
      }
    }

    try {
      const data = await makeRequest(body)

      if (
        data.SaleToPOIResponse.PaymentResponse.Response.Result === 'Success'
      ) {
        return {
          status: 'Success',
          currency:
            data.SaleToPOIResponse.PaymentResponse.PaymentResult.AmountsResp
              .Currency,
          currentBalance:
            data.SaleToPOIResponse.PaymentResponse.PaymentResult.AmountsResp
              .AuthorizedAmount,
          amount:
            data.SaleToPOIResponse.PaymentResponse.PaymentResult.AmountsResp
              .AuthorizedAmount,
          ...data
        }
      }

      const errorMessage = getErrorMessageFromAString(
        data.SaleToPOIResponse.PaymentResponse.Response.AdditionalResponse
      )

      return {
        status: 'Failure',
        errorMessage,
        errorCondition:
          data.SaleToPOIResponse.PaymentResponse.Response.ErrorCondition,
        ...data
      }
    } catch (error) {
      return {
        status: 'Failure',
        errorMessage: 'Unexpected Error',
        error
      }
    }
  }

  /**
   * Reverse a transaction that loaded funds to a gift card.
   * @param {string} paymentTerminalID selected adyen terminal Id
   * @param {string} orderNumber  unique reference for the request
   * @param dateOriginalTransaction date of original transaction
   * @param {string} saleId  unique Id for the cash register
   */
  static async undoTransaction (
    paymentTerminalID,
    orderNumber,
    dateOriginalTransaction,
    saleId
  ) {
    const serviceID = generateUniqueServiceId()
    const timeStamp = getTimeInUTCFormat()
    const body = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: '3.0',
          MessageType: 'Request',
          MessageClass: 'Service',
          MessageCategory: 'StoredValue',
          SaleID: saleId,
          POIID: paymentTerminalID,
          ServiceID: serviceID
        },
        StoredValueRequest: {
          SaleData: {
            SaleTransactionID: {
              TransactionID: orderNumber,
              TimeStamp: timeStamp
            },
            SaleToAcquirerData: makeSaleToAcquirerData()
          },
          StoredValueData: [
            {
              StoredValueTransactionType: 'Reverse',
              OriginalPOITransaction: {
                POITransactionID: {
                  TransactionID: 'A7sM001574852436000.8815748524464271',
                  TimeStamp: dateOriginalTransaction
                }
              }
            }
          ]
        }
      }
    }

    try {
      const data = await makeRequest(body)

      if (
        data.SaleToPOIResponse.StoredValueResponse.Response.Result === 'Success'
      ) {
        return {
          status: 'Success',
          currency:
            data.SaleToPOIResponse.StoredValueResponse.PaymentResult.AmountsResp
              .Currency,
          currentBalance:
            data.SaleToPOIResponse.StoredValueResponse.PaymentResult.AmountsResp
              .AuthorizedAmount,
          amount:
            data.SaleToPOIResponse.StoredValueResponse.PaymentResult.AmountsResp
              .AuthorizedAmount
        }
      }

      const errorMessage = getErrorMessageFromAString(
        data.SaleToPOIResponse.StoredValueResponse.Response.AdditionalResponse
      )

      return {
        status: 'Failure',
        errorMessage,
        errorCondition:
          data.SaleToPOIResponse.StoredValueResponse.Response.ErrorCondition
      }
    } catch (error) {
      return {
        status: 'Failure',
        errorMessage: 'Unexpected Error',
        error
      }
    }
  }
}



export default AdyenGiftCardsService
