import { connect } from 'react-redux'
import _ from 'lodash'
import SelectWoltDeviceModal from './SelectWoltDeviceModal'
import { compose, withHandlers, withState } from 'recompose'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { goBack } from 'connected-react-router'
import { PAY_BY_WOLT } from '../../../services/paymentDeviceService'

const MODAL_VALUE = PAY_BY_WOLT

const mapStateToProps = (state, props) => {
  const selectedPaymentDeviceId = appSelectors.getSelectedPaymentDevices(state)[MODAL_VALUE]
  const paymentDeviceOptions = _.map(props.paymentDevices, obj => ({ value: obj.id, label: obj.name }))

  let onlyPaymentDeviceId
  if (paymentDeviceOptions.length === 1) {
    onlyPaymentDeviceId = paymentDeviceOptions[0].value
  }

  const initialValues = {
    paymentDeviceId: _.chain(paymentDeviceOptions)
      .map(({ value }) => value)
      .includes(selectedPaymentDeviceId)
      .value()
      ? selectedPaymentDeviceId
      : onlyPaymentDeviceId,
    amount: props.leftToPay
  }
  const isNegativeAmount = Boolean(props.leftToPay < 0)

  const isRefund = Boolean(props.paymentsToRefund)

  return {
    onlyPaymentDeviceId,
    currencyCode: authSelectors.getCurrencyCode(state),
    initialValues,
    paymentDeviceOptions,
    isNegativeAmount,
    isRefund
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDefaultPaymentDevice: paymentDeviceId => {
      return dispatch(appActions.setPaymentDevice({ paymentDeviceId, deviceType: MODAL_VALUE }))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('pending', 'setPending', false),
  withState('error', 'setError', null),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    processPayment: ({ success, paymentDevices, setDefaultPaymentDevice }) => (formValues) => {
      const paymentDevice = _.find(paymentDevices, pd => pd.id === formValues.paymentDeviceId)

      setDefaultPaymentDevice(formValues.paymentDeviceId)

      if (!paymentDevice) {
        throw new Error(`No payment device found with id ${formValues.paymentDeviceId}`)
      }

      return success(
        paymentDevice,
        {
          amount: formValues.amount
        }
      )
    }
  })
)(SelectWoltDeviceModal)
