import _ from 'lodash'
import { connect } from 'react-redux'

import RefundOrderScreen from './RefundOrder'
import { selectors as paymentDevicesSelectors } from '../../../store/modules/paymentDevices'
import { actions as checkoutFlowActions } from '../../../store/modules/checkoutFlow'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import {
  selectors as orderDetailsSelectors,
  actions as orderDetailsActions
} from '../../../store/modules/orderDetails'
import {
  selectors as appSelectors,
  actions as appActions
} from '../../../store/modules/app'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { selectors as authSelectors } from '../../../store/modules/auth'
import {
  getSupportedPaymentDevices,
  getSupportedReceiptPrinters
} from '../../../services/paymentDevicesService'
import modalService from '../../../services/modalService'
import currencyFormatter from '../../../formatters/currencyFormatter'
import StarCloudPRNTReceiptPrinter from '../../../services/paymentDevicesService/starCloudPRNTReceiptPrinter'
import { ADYEN_CLOUD_PAYMENT_DEVICE } from '../../../services/paymentDeviceService'
import { goBack, push } from 'connected-react-router'
import { asDropdownOptions, createSupportedDevices, getLeftToPayGroupedByDeviceType } from '../helper'
import StarCloudPRNTService from '../../../services/paymentDeviceService/starCloudPRNTService'
import toastService from '../../../services/toastService'
import { round } from '@redant/digital-store-prices-fiskars'

const allSupportedPaymentDevices = getSupportedPaymentDevices()
const supportedReceiptPrinterTypes = getSupportedReceiptPrinters()

const mapStateToProps = (state) => {
  const allDevices = paymentDevicesSelectors.getAll(state)
  const orderNumber = orderDetailsSelectors.getOrderNumber(state)
  const orderId = orderDetailsSelectors.getOrderId(state)
  const currentUser = authSelectors.getCurrentUser(state)
  const selectedPrinterId = appSelectors.getSelectedPrinterId(state)
  const currencyCode = authSelectors.getCurrencyCode(state)
  const allPrinters = StarCloudPRNTReceiptPrinter.getReceiptPrinters(
    allDevices
  )
  const printerOptions = asDropdownOptions(allPrinters, 'name', 'id')
  const isOnline = networkSelectors.isConnected(state)
  const paymentDevices = paymentDevicesSelectors.getAll(state)
  const currentOrder = orderDetailsSelectors.getOrderRaw(state)
  const supportedPaymentDevices = createSupportedDevices(currentOrder, allSupportedPaymentDevices)

  const isVintagePurchase = (_.get(currentOrder, 'products') || [])
    .some(product =>
      _.get(product, 'details.isVintage', false) &&
          _.get(product, 'price.value', 0) < 0 &&
          !_.get(product, 'refund', false)
    )
  const isRefund = (_.get(currentOrder, 'products') || []).some(products => products.refund)
  const isUnreferencedRefundOrder = isRefund && (_.get(currentOrder, 'payments') || [])
    .filter(payment => payment.success && !payment.isRefund).length === 0 && !isVintagePurchase

  return {
    currencyCode,
    orderId,
    currentUser,
    orderNumber,
    allSupportedPaymentDevices,
    printerOptions,
    currentOrder,
    allDevices,
    isOnline,
    paymentDevices,
    selectedPrinterId,
    allPrinters,
    supportedPaymentDevices,
    isRefund,
    isVintagePurchase,
    isUnreferencedRefundOrder
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedPrinterId: (selectedPrinterId) =>
    dispatch(appActions.setselectedPrinterId(selectedPrinterId)),
  updateOrderDetails: ({ id, ...fields }) =>
    dispatch(orderDetailsActions.updateOrder({ id, ...fields })),
  goToHome: () => dispatch(push('/')),
  goToOrder: (orderNumber) => dispatch(push(`/orders/${orderNumber}`)),
  fail: (obj) => dispatch(checkoutFlowActions.fail(obj)),
  dispatch
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('payments', 'setPayments', []),
  withState('isLoading', 'setIsLoading', false),
  withPropsOnChange(
    ['selectedPrinterId', 'allPrinters'],
    ({ selectedPrinterId, allPrinters }) => {
      const selectedPrinter = allPrinters.find(
        (printer) => printer.id === selectedPrinterId
      )

      return { selectedPrinter }
    }
  ),
  withPropsOnChange(
    ['payments', 'currentOrder'],
    ({ payments, currentOrder }) => {
      const currentProducts = _.chain((_.get(currentOrder, 'products') || []))
      const orderPayments = _.chain((_.get(currentOrder, 'payments') || []).filter((payment) => payment.success))
      const isRefundPaymentChain = orderPayments.filter(
        (payment) => payment.isRefund
      )
      const paymentsMade = isRefundPaymentChain
        .map((payment) => payment.amountPaid)
        .sum()
        .value()
      const total = currentProducts
        .map((product) => Math.abs(product.total.value))
        .defaultTo([])
        .sum()
        .value()
      const refundPayments = isRefundPaymentChain.value()
      const originalPayments = orderPayments
        .filter((payment) => !payment.isRefund)
        .value()

      return {
        paymentsMade,
        paymentOutstanding: {
          code: _.get(currentOrder, 'total.code'),
          value: round(total - paymentsMade)
        },
        paymentTotal: {
          code: _.get(currentOrder, 'total.code'),
          value: _.chain(currentOrder.products)
            .map((payment) => Math.abs(payment.total.value))
            .sum()
            .value()
        },
        refundPayments,
        originalPayments
      }
    }
  ),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    onPaymentOptionClick: ({
      allDevices,
      currentOrder,
      orderNumber,
      setPayments,
      payments,
      orderId,
      updateOrderDetails,
      paymentOutstanding,
      isRefund,
      isVintagePurchase,
      isUnreferencedRefundOrder
    }) => (deviceType) => async () => {
      try {
        const impl = _.find(allSupportedPaymentDevices, { deviceType })

        if (!impl) {
          throw new Error(
            `No payment device implementation found for device type: ${deviceType}`
          )
        }

        const orderPayments = (_.get(currentOrder, 'payments') || []).filter(
          (payment) => payment.success
        )
        const refundPayments = orderPayments.filter(
          (payment) => payment.deviceType === deviceType && !payment.isRefund // removing refund payments
        )

        let leftToPay = 0

        if (refundPayments) {
          const LeftToPayForDeviceType = _.get(getLeftToPayGroupedByDeviceType(currentOrder).find(x => x.deviceType === deviceType), 'amount', null)
          leftToPay = LeftToPayForDeviceType > 0 ? Math.min(paymentOutstanding.value, LeftToPayForDeviceType) : paymentOutstanding.value
        } else {
          leftToPay = paymentOutstanding.value
        }

        // Cards should have a selection option in modal rest will use a combined left to pay
        const res = await impl.processPayment(
          allDevices,
          leftToPay,
          { ...currentOrder, orderNumber },
          refundPayments || []
        )

        if (res) {
          if (isRefund && !isUnreferencedRefundOrder && !isVintagePurchase && deviceType === ADYEN_CLOUD_PAYMENT_DEVICE) { // we are tracking refund status of card refunds only
            const indexOfOriginalPayment = orderPayments.findIndex(
              (item) => item.id === res.result.selectedPaymentToRefund.id
            )
            const originalPayment = _.omit(res.result.selectedPaymentToRefund, ['leftToRefund'])
            orderPayments[indexOfOriginalPayment] = {
              ...originalPayment,
              hasBeenRefunded: true, // TODO: we are ignoring if it is partially or fully refunded
              refundId: [orderPayments[indexOfOriginalPayment], res.id].join(',') // TODO: we need multiple refundIds one payment can have multiple partial refunds
            }
          }

          const updatedPayments = [...payments, res]
          const orderUpdate = {
            id: orderId,
            payments: [...orderPayments, res]
          }
          setPayments(updatedPayments)
          await updateOrderDetails(orderUpdate)
        }

        console.log('success', { res })
      } catch (error) {
        console.log('fail', { error })
        throw error
      }
    },
    onCancelClick: ({
      goToHome,
      goToOrder,
      paymentsMade,
      currencyCode,
      orderNumber
    }) => () => {
      let text = `Are you sure you want to cancel the refund? The consumer has already been refunded ${currencyFormatter.format(
        { code: currencyCode, value: paymentsMade }
      )}. Please go to Order Management to refund the order.`

      if (paymentsMade === 0) {
        text = `Are you sure you want to cancel the refund?`
      }

      modalService.action({
        title: 'Cancel refund',
        text,
        actions: [
          {
            success: true,
            text: 'Yes, Go to dashboard',
            onClick: () => {
              goToHome()
            },
            primary: true
          },
          {
            success: true,
            text: 'Yes, Go to order',
            onClick: () => {
              goToOrder(orderNumber)
            },
            primary: true
          },
          {
            text: 'No, Close',
            onClick: () => modalService.close()
          }
        ]
      })
    },
    onCompleteClick: ({
      updateOrderDetails,
      setIsLoading,
      currentUser,
      selectedPrinter,
      currentOrder,
      allDevices,
      orderNumber,
      dispatch,
      isUnreferencedRefundOrder
      // refundOrderAndPayment
    }) => async () => {
      const update = {
        id: currentOrder.id,
        status: !isUnreferencedRefundOrder ? currentOrder.details.nextStatus : 'full_refund'
      }

      try {
        setIsLoading(true)

        await updateOrderDetails(update)

        const { deviceType } = selectedPrinter
        const impl = _.find(supportedReceiptPrinterTypes, { deviceType })

        if (!impl) {
          throw new Error(
            `No receipt printer implementation found for device type: ${deviceType}`
          )
        }

        // print receipt
        if (selectedPrinter) {
          const content = StarCloudPRNTService.generateContentToPrint({ order: currentOrder, user: currentUser })
          await StarCloudPRNTService.print(selectedPrinter.config.modelNumber, content, 'text/html')

          const isPartialOrFullRefund = currentOrder.products.some((product) => product.refund && product.returnReasonCode)
          if (isPartialOrFullRefund) {
            const customerRefundCopy = StarCloudPRNTService.generateContentToPrint({ order: currentOrder, user: currentUser, printAsCustomerRefundCopy: true })
            await StarCloudPRNTService.print(selectedPrinter.config.modelNumber, customerRefundCopy, 'text/html')
          }

          toastService.action({
            type: 'success',
            message: 'Receipt sent to the printer',
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        }

        setIsLoading(false)

        dispatch(push(`/orders/${orderNumber}`))
      } catch (err) {
        setIsLoading(false)
        console.log(err)
        // fail({ code: 'CHECKOUT_FAILED', errorMessage: 'Checkout failed.' })
      }
    }
  })
)(RefundOrderScreen)
