import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Button from '../../../components/Button'
import SideMenu from '../../../components/SideMenu'
import SafeAreaSpacer from '../../../components/SafeAreaSpacer'
import AppDetails from '../AppDetails'
import style from './style'
import { translations } from '../../../config'

const RootMenu = ({
  handleLogout,
  handleCopyOfflineOrders,
  handleMyAccountClick,
  handleMyTeamClick,
  classes,
  showMyTeam,
  offlineOrderCount,
  ...props
}) => {
  return (
    <SideMenu
      {...props}
      footerElement={
        <div className={classes.footer}>
          <AppDetails />
          <div className={classes.buttonsContainer}>
            {offlineOrderCount
              ? <Button onClick={handleCopyOfflineOrders} buttonType='primary' className={classes.copyOfflineOrdersButton} >{translations('Copy offline orders')}</Button>
              : null}
            <div className={classes.gridButtons}>
              {showMyTeam && (<Button onClick={handleMyTeamClick} buttonType='white'>{translations('My team')}</Button>)}
              <Button onClick={handleMyAccountClick} buttonType='white'>{translations('My account')}</Button>
            </div>
            <Button onClick={handleLogout} buttonType='primary' >{translations('Sign out')}</Button>
          </div>
          <SafeAreaSpacer inset={'bottom'} />
        </div>
      }
    />
  )
}

export default withStyles(style)(RootMenu)
