import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { FormControl, Grid } from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Form from '../../components/Form'
import Button from '../../components/Button'
import modalService from '../../services/modalService'
import ActionModalOverlay from '../../components/ActionModalOverlay'
import media from '../../config/media'
import { translations } from '../../config'


const MobileButton = styled(Button)`
  margin: auto;
`

const StyledFilterFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`

const StyledFormControlContainer = styled.div`
  align-items: flex-start;
  flex-direction: row;
  flex: 1;
  margin: 5px;
  ${media.lessThan('lg')`
    flex-direction: column;
  `}
`

const StyledFormControl = styled(FormControl)`
  display: flex;
`

const renderMobileCTA = ({ totalSelectedFilters, onClick }) => {
  return (
    <MobileButton big buttonType='primary' onClick={onClick}>
      Filter{totalSelectedFilters
        ? ` - (${totalSelectedFilters})Selected`
        : null}
    </MobileButton>
  )
}

const renderFilterForm = ({ onFilterChange, filters, initialValues, FilterForm }) => {
  const fields = filters.map(({ id, title, component: FieldComponent, ...props }, i) => (
    <StyledFormControlContainer key={i}>
      <StyledFormControl>
        <FieldComponent
          key={id}
          name={props.name || id}
          label={title}
          value={null}
          {...props}
        />
      </StyledFormControl>
    </StyledFormControlContainer>
  ))

  return (
    <Grid>
      <FilterForm onChange={onFilterChange} initialValues={initialValues}>
        <StyledFilterFormContainer>
          {fields}
        </StyledFilterFormContainer>
      </FilterForm>
    </Grid>
  )
}

const FiltersInModal = ({ onFilterChange, filters, initialValues, FilterForm }) => (
  <ActionModalOverlay actions={[{
    onClick: () => modalService.close(),
    success: true,
    primary: true,
    text: translations('Close')
  }]}>
    <Fragment>
      <h3 style={{ marginTop: 0 }}>{translations('Filters')}</h3>
      {renderFilterForm({ onFilterChange, filters, initialValues, FilterForm })}
    </Fragment>
  </ActionModalOverlay>
)

export const renderFilters = ({ title, filters = [], onFilterChange, initialValues }) => {
  /**
   * This is here because this file is imported before Form is defined
   * (try console.log({ Form }) at the top of this file). For this reason,
   * FilterForm needs to be created here.
   */
  const [FilterForm] = useState(
    Form(_.kebabCase(title + '-list-page-layout-form'))
  )

  if (!filters.length) {
    return null
  }

  const totalSelectedFilters = 0
  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('lg'))

  if (isMobile) {
    const onClick = () => {
      modalService.open({
        component: FiltersInModal,
        hideBottomBar: true,
        inModal: true,
        onFilterChange,
        filters,
        FilterForm,
        initialValues
      })
    }

    return renderMobileCTA({ onClick, totalSelectedFilters })
  }

  return renderFilterForm({ filters, onFilterChange, initialValues, FilterForm })
}
