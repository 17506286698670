import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import style from './style'
import Form, { FormBody, FormSubmit, FormError } from '../../../components/Form'
import { Dropdown, Counter } from '../../../components/Fields'
import Spacing from '../../../components/Spacing'
import { required } from '../../../validators'
import { translations, formNames } from '../../../config'

import P from '../../../components/P'
import Button from '../../../components/Button'

export const ProductSingleForm = Form(formNames.productDetails, { destroyOnUnmount: false })

const ProductDetailForm = ({
  variantsOptions,
  onSubmit,
  initialValues,
  classes,
  renderSizeGuide,
  onVariantChange,
  isAppointmentActive,
  isSelectedVariantIdInAppointment,
  openStockAdjustModal,
  onVariantAttributeChange,
  availableVariantAttributes = []
}) => {
  const appointmentButtonString = isSelectedVariantIdInAppointment ? translations('Remove from consultation') : translations('Add to consultation')
  const renderVariantAttributes = () => {
    if (availableVariantAttributes.length > 0) {
      return availableVariantAttributes.map(attribute => <>
        <P
          value={attribute.name}
          className={classes.rowLabel}
        />
        <div className={classes.dropdownAndSizeGuideContainer}>
          <FormControl className={cx(classes.field, classes.variantDropdownContainer)}>
            <Dropdown
              options={attribute.values.map(attrVal => ({
                label: attrVal.name,
                value: attrVal.value
              }))}
              name={attribute.id}
              givenClasses={{ field: classes.dropdown }}
              label={translations('Please select')}
              validate={required}
              noErrorTextLabel
              noFloatingLabel
              onChange={(e) => onVariantAttributeChange({ id: attribute.id, value: e.target.value })}
            />
          </FormControl>
        </div>
      </>)
    } else {
      return null
    }
  }

  const variantRow = <>
    <P
      value={translations('Variant') + ':'}
      className={classes.rowLabel}
    />
    <div className={classes.dropdownAndSizeGuideContainer}>
      <FormControl className={cx(classes.field, classes.variantDropdownContainer)}>
        <Dropdown
          options={variantsOptions}
          name='variantId'
          givenClasses={{ field: classes.dropdown }}
          label={translations('Please select')}
          validate={required}
          noErrorTextLabel
          noFloatingLabel
          onChange={(e) => onVariantChange({ variantId: e.target.value })}
        />
      </FormControl>
      <div className={classes.sizeGuideContainer}>
        {renderSizeGuide ? renderSizeGuide() : null}
      </div>
    </div>
  </>

  const quantityRow = <>
    <P
      value={translations('Quantity') + ':'}
      className={classes.rowLabel}
    />
    <div className={cx(classes.field, classes.counterContainer)}>
      <Counter name='quantity' validate={required} noErrorTextLabel />
    </div>
  </>

  return (
    <ProductSingleForm onSubmit={onSubmit} initialValues={initialValues}>
      <FormBody>
        <div className={classes.formGrid}>
          {renderVariantAttributes()}
          {availableVariantAttributes.length === 0 && variantRow}
          {quantityRow}
        </div>
      </FormBody>
      <FormError />
      <Spacing height={20} />
      <FormSubmit
        label={isAppointmentActive ? appointmentButtonString : translations('Add to basket')}
        containerClass={classes.submitButtonContainer}
        buttonClass={classes.submitButton}
        big
      />
      <div className={classes.adjustStockButtonContainer}>
        <Button type='button' buttonType='secondary' onClick={openStockAdjustModal}>
          {translations('Adjust stock')}
        </Button>
      </div>
    </ProductSingleForm>
  )
}

ProductDetailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
}

export default withStyles(style)(ProductDetailForm)
