import styled from 'styled-components'

export const Container = styled.div`
  width: 95%;
  margin: 15px auto;
  font-size: 13px;
`

export const Column = styled.div`
  width: 20%;
  padding: 8px 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 5px 0;

  &.header {
    background-color: #F4F4F4;
    font-weight: bold;
  }
`
