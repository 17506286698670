import { makeRequest } from '../makeRequest'
import { mapPaymentRequest } from './mapRequest'
import { mapPaymentResponse } from './mapResponse'
import {transactionStatusRequest } from '../transactionStatusRequest'


export async function unrefrerencedRefundRequest(paymentDevice, total, orderNumber) {
  const mappedRequest = mapPaymentRequest(paymentDevice, total, orderNumber)
  const response = await makeRequest(mappedRequest)

  // Parse the Adyen SDK response
  let mappedResponse = mapPaymentResponse(response)

  if (mappedResponse === null) {
    /**
     * No response from payment terminal, so
     * wait and send a status request to get the status of the transaction.
     */
    await new Promise(r => setTimeout(r, 5 * 1000))

    // Fetch the status
    mappedResponse = await transactionStatusRequest(
      paymentDevice,
      mappedRequest.saleToPOIRequest.messageHeader.serviceID,
      mappedRequest.saleToPOIRequest.messageHeader.saleID
    )
  }

  return {
    success: mappedResponse.success,
    message: mappedResponse.message,
    error: mappedResponse.error,
    data: mappedResponse.data
  }
}
