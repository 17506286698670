import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { InChart } from '../../../../Reporting/ListItems/Generic';

const getRow = (item) => {
  const bubbleText = `${item.sales} sales @ ${item.salesValue}`

  return (
    <li>
      <ListItem button style = {{paddingTop: 0, paddingBottom: 0}} >
        <InChart mainText={item.user} bubbleText={bubbleText} />
      </ListItem>
    </li>
  );
};

export const ListView = ({ data, timePeriod }) => (
  <List>{data.map((item) => getRow(item, timePeriod))}</List>
);

export default ListView;
