import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import MultiPayment from './MultiPayment'
import { selectors as paymentDevicesSelectors } from '../../../store/modules/paymentDevices'
import {
  selectors as appSelectors,
  actions as appActions
} from '../../../store/modules/app'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { selectors as authSelectors } from '../../../store/modules/auth'
import {
  actions as orderDetailsActions,
  selectors as orderDetailsSelectors
} from '../../../store/modules/orderDetails'
import {
  getSupportedPaymentDevices,
  getSupportedReceiptPrinters
} from '../../../services/paymentDevicesService'
import withCheckoutFlow from '../withCheckoutFlow'
import modalService from '../../../services/modalService'
import toastService from '../../../services/toastService'
import currencyFormatter from '../../../formatters/currencyFormatter'
import StarCloudPRNTReceiptPrinter from '../../../services/paymentDevicesService/starCloudPRNTReceiptPrinter'
import { ADYEN_CLOUD_PAYMENT_DEVICE } from '../../../services/paymentDeviceService'
import ActivateGiftCardModal from '../../../components/ActivateGiftCardModal'
import GiftReceiptModal from '../../../components/GiftReceiptModal'

const handleActivateGiftCards = (
  adyenTerminals,
  giftCards,
  orderNumber,
  paymentDevices
) =>
  new Promise((resolve) => {
    modalService.open({
      component: ActivateGiftCardModal,
      disableBackdropClick: true,
      disableEscapeKeyDown: true,
      adyenTerminals,
      giftCards,
      orderNumber,
      paymentDevices,
      onComplete: (response) => {
        resolve(response)
      }
    })
  })

const asDropdownOptions = (array = [], label, value) =>
  array.map((obj) => ({
    label: _.get(obj, label),
    value: _.get(obj, value)
  }))

const supportedPaymentDeviceTypes = getSupportedPaymentDevices()
const supportedReceiptPrinterTypes = getSupportedReceiptPrinters()

const mapStateToProps = (state) => {
  const allDevices = paymentDevicesSelectors.getAll(state)
  const orderNumber = orderDetailsSelectors.getOrderNumber(state)
  const orderId = orderDetailsSelectors.getOrderId(state)
  const currentUser = authSelectors.getCurrentUser(state)
  const selectedPrinterId = appSelectors.getSelectedPrinterId(state)
  const basketType = currentOrderCombinedSelectors.getBasketType(state)
  const isVintagePurchase = basketType === 'vintage purchase'
  const currentOrder = currentOrderCombinedSelectors.currentOrderSelector(
    state
  )

  const allPrinters = StarCloudPRNTReceiptPrinter.getReceiptPrinters(
    allDevices
  )
  const printerOptions = asDropdownOptions(allPrinters, 'name', 'id')

  return {
    currencyCode: authSelectors.getCurrencyCode(state),
    orderId,
    currentUser,
    orderNumber,
    supportedPaymentDeviceTypes,
    supportedReceiptPrinterTypes,
    printerOptions,
    currentOrder,
    allDevices,
    isOnline: networkSelectors.isConnected(state),
    paymentDevices: paymentDevicesSelectors.getAll(state),
    selectedPrinterId,
    allPrinters,
    basketType,
    isVintagePurchase
  }
}

const mapDispatchToProps = (dispatch) => ({
  setselectedPrinterId: (selectedPrinterId) =>
    dispatch(appActions.setselectedPrinterId(selectedPrinterId)),
  updateOrderDetails: ({ id, ...fields }) =>
    dispatch(orderDetailsActions.updateOrder({ id, ...fields }))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('payments', 'setPayments', []),
  withState('isLoading', 'setIsLoading', false),
  withCheckoutFlow,
  withPropsOnChange(
    ['selectedPrinterId', 'allPrinters'],
    ({ selectedPrinterId, allPrinters }) => {
      const selectedPrinter = allPrinters.find(
        (printer) => printer.id === selectedPrinterId
      )
      return {
        selectedPrinter
      }
    }
  ),
  withPropsOnChange(
    ['payments', 'currentOrder'],
    ({ payments, currentOrder }) => {
      const paymentsMade =
        _.chain(payments)
          .filter((payment) => payment.success)
          .map((payment) => payment.amountPaid)
          .sum()
          .value() || 0

      return {
        paymentsMade,
        amountToPay: {
          code: currentOrder.total.code,
          value: parseFloat(currentOrder.total.value.toFixed(2)) - paymentsMade
        }
      }
    }
  ),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    onPaymentOptionClick: ({
      allDevices,
      currentOrder,
      orderNumber,
      setPayments,
      payments,
      orderId,
      updateOrderDetails
    }) => (deviceType) => async () => {
      try {
        const impl = _.find(supportedPaymentDeviceTypes, { deviceType })

        if (!impl) {
          throw new Error(
            `No payment device implementation found for device type: ${deviceType}`
          )
        }

        const leftToPay =
          currentOrder.total.value -
          _.chain(payments)
            .filter((payment) => payment.success)
            .map((payment) => payment.amountPaid)
            .sum()
            .defaultTo(0)
            .value()

        const res = await impl.processPayment(
          allDevices,
          leftToPay.toFixed(2),
          { ...currentOrder, orderNumber }
        )

        if (res) {
          const updatedPayments = [...payments, res]
          const update = {
            id: orderId,
            payments: updatedPayments
          }

          setPayments(updatedPayments)
          await updateOrderDetails(update)
        }

        console.log('success', { res })
      } catch (error) {
        console.log('fail', { error })
        throw error
      }
    },
    onCancelClick: ({
      goToHome,
      goToOrder,
      paymentsMade,
      currencyCode,
      orderNumber,
      end
    }) => () => {
      let text = `Are you sure you want to cancel the payment? The consumer has already paid ${currencyFormatter.format(
        { code: currencyCode, value: paymentsMade }
      )}. Please go to Order Management to refund the order.`

      if (paymentsMade === 0) {
        text = `Are you sure you want to cancel the payment? The consumer would not be able to pay  for this order.`
      }

      modalService.action({
        title: 'Cancel order',
        text,
        actions: [
          {
            success: true,
            text: 'Yes, Go to dashboard',
            onClick: () => {
              end()
              goToHome()
            },
            primary: true
          },
          {
            success: true,
            text: 'Yes, Go to order',
            onClick: () => {
              end()
              goToOrder(orderNumber)
            },
            primary: true
          },
          {
            text: 'No, Close',
            onClick: () => modalService.close()
          }
        ]
      })
    },
    onCompleteClick: ({
      updateOrderDetails,
      next,
      fail,
      orderId,
      setIsLoading,
      payments,
      currentUser,
      selectedPrinter,
      currentOrder,
      isOnline,
      allDevices,
      orderNumber,
      isVintagePurchase
    }) => async () => {
      const update = {
        id: orderId,
        status: 'complete',
        payments
      }
      const adyenTerminals = allDevices
        .filter((device) => device.deviceType === ADYEN_CLOUD_PAYMENT_DEVICE)
        .map(({ id, name }) => ({ value: id, label: name }))
      const giftCards = currentOrder.products.filter((item) =>
        _.get(item, 'details.isProductAGiftCard')
      )

      if (isVintagePurchase) {
        await handleActivateGiftCards(
          adyenTerminals,
          [{ variant: { ean: 'vintage purchase' }, price: { code: currentOrder.total.code, value: Math.abs(currentOrder.total.value) } }],
          orderNumber,
          allDevices
        )
      }

      if (giftCards.length) {
        await handleActivateGiftCards(
          adyenTerminals,
          giftCards,
          orderNumber,
          allDevices
        )
      }

      try {
        setIsLoading(true)
        const updatedOrder = await updateOrderDetails(update)

        const { deviceType } = selectedPrinter
        const impl = _.find(supportedReceiptPrinterTypes, { deviceType })

        if (!impl) {
          throw new Error(
            `No receipt printer implementation found for device type: ${deviceType}`
          )
        }

        if (isOnline) {
          await impl.print(selectedPrinter, updatedOrder, currentUser)
        }

        setIsLoading(false)
        toastService.action({
          type: 'success',
          message: 'Receipt sent to the printer',
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })

        next()
      } catch (err) {
        setIsLoading(false)
        console.log(err)
        fail({ code: 'CHECKOUT_FAILED', errorMessage: 'Checkout failed.' })
      }
    },
    onGiftReceiptClick: ({
      currentUser,
      selectedPrinter,
      currentOrder
    }) => async () => {
      await modalService.open({
        component: GiftReceiptModal,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        currentUser,
        selectedPrinter,
        currentOrder
      })
    }
  })
)(MultiPayment)
