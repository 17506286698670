import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'
import PromotionList from './PromotionList'
import PromotionDetails from './PromotionDetails'

export default [
  <PrivateRoute
    exact
    key='/promotions'
    path='/promotions'
    component={TimedLogoutComponent(PromotionList)}
  />,
  <PrivateRoute
    exact
    key='/promotions/:id'
    path='/promotions/:id'
    component={TimedLogoutComponent(PromotionDetails)}
  />
]
