import PouchDB from 'pouchdb'
import uuidV4 from 'uuid/v4'
import _ from 'lodash'
import digitalStoreSdk from '../../digitalStoreSdk'
import { createOrderSchema } from './orderSchema'
import { translations } from '../../config'
import toastService from '../toastService'

PouchDB.plugin(require('pouchdb-find').default)

class OfflineOrdersService {
  constructor () {
    this.dataStore = new PouchDB(`offline_orders`)
    this.dataStore.createIndex({ index: { fields: ['_id', 'orderNumber'] } })
    window.offlineOrdersDb = this.dataStore
  }

  async create ({ order: _order }) {
    const validationResult = createOrderSchema.validate(_order, {
      abortEarly: false,
      noDefaults: true
    })

    if (validationResult.error !== null) {
      console.log({ validationResult })
      throw new Error('Order schema validation error.')
    }

    const order = validationResult.value
    const isReferral = order.orderType === 'referral'
    const containsPreviewProducts = order.products.some((p) => p.preview)

    console.log(
      `checking if order can be created isReferral=${isReferral} containsPreviewProducts=${containsPreviewProducts}`
    )
    if (!isReferral && containsPreviewProducts) {
      console.error(`order is not a referral and contains preview products.`, {
        order
      })
      throw new Error(
        'Failed to create order. Order contains preview only products.'
      )
    }

    const databaseId = uuidV4()
    const finalOrder = {
      ...order,
      ...(order.id ? { id: order.id } : { id: databaseId })
    }

    await this.dataStore.put({
      _id: finalOrder.id,
      ...finalOrder
    })
  }

  async fetchOrder ({ orderNumber }) {
    const rows = await this.dataStore.find({
      selector: {
        orderNumber: orderNumber
      }
    })
    return _.get(rows, 'docs[0]')
  }

  async count () {
    const rows = await this.dataStore.find({
      selector: {
        orderNumber: { $exists: true }
      }
    })

    return _.get(rows, 'docs', []).length
  }

  async copyToClipboard () {
    const res = await this.getAllDocs()
    const copyStr = JSON.stringify({ transactional: false, rows: res.items })

    if (window.cordova) {
      await cordova.plugins.clipboard.copy(copyStr)
    } else {
      await window.navigator.clipboard.writeText(copyStr)
    }

    return res.total
  }

  async getAllDocs () {
    const rows = _.get(await this.dataStore.find({
      selector: {
        orderNumber: { $exists: true }
      }
    }), 'docs', [])

    return {
      total: rows.length,
      items: rows
    }
  }

  async update (updatedOrder) {
    const { id, ...fields } = updatedOrder
    const doc = await this.dataStore.get(id).catch((err) => {
      if (err.name === 'not_found') {
        return {
          _id: id,
          ...fields
        }
      }
    })

    await this.dataStore.put({
      _id: id,
      ...doc,
      ...fields
    })

    const finalOrder = await this.dataStore.get(id)

    return { ...finalOrder }
  }

  async upload () {
    const { total, items } = await this.getAllDocs()

    if (total !== 0) {
      console.log('Uploading offline orders to the API server...')

      try {
        await digitalStoreSdk.orders.bulkCreateOrders({
          transactional: false,
          rows: items.map((row) => _.omit(row, ['_id', '_rev']))
        })
        // handle the error somehow - maybe a modal or something in the top offline bar.
        await Promise.all(
          items.map((row) => this.dataStore.remove(row._id, row._rev))
        )
        toastService.action({
          type: 'success',
          message: translations(
            'Successfully uploaded {{offlineOrderCount}} offline orders',
            {
              offlineOrderCount: total
            }
          ),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      } catch (error) {
        console.log('Failed uploading offline orders to API:', { error })
        toastService.action({
          type: 'error',
          message: translations('Failed uploading offline orders.'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      }
    }

    // setTimeout(() => { this.upload() }, 5 * 1000) // attempt a reupload every 5secs
  }
}

export default new OfflineOrdersService()
