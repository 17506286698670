import { colors } from '../../config/theme'
import { zIndexMap } from '../../config'

const styles = theme => ({
  appBar: {
    paddingTop: 'env(safe-area-inset-top)',
    backgroundColor: colors.headerBg,
    position: 'fixed',
    zIndex: zIndexMap.header + 1,
    boxShadow: 'none',
    top: 0
  },
  toolBar: {
    position: 'relative',
    backgroundColor: colors.headerBg,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.headerHeight,
    minHeight: theme.headerHeight
  },
  iconStyleLeft: {
    padding: 0
  },
  basketIcon: {
    padding: 0,
    minWidth: 0,
    display: 'flex',
    color: colors.headerBgIconColor
  },
  whiteLetters: {
    color: 'white',
    maxWidth: '166px'
  },
  logoContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    fontWeight: 100,
    margin: 0,
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  burger: {
    width: '19px',
    display: 'flex',
    color: colors.headerBgIconColor
  },
  burgerBadge: {
    top: '4px'
  }
})

export default styles
