import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import moment from 'moment'
import { dateFormat } from '../../../config'

const PAGE_SIZE = 10

const parseFilters = (filters) => {
  let result = {
    ...filters
  }

  if (filters.fromDate) {
    result['createdAt[$gte]'] = moment.utc(filters.fromDate, dateFormat).toISOString()
  }

  if (filters.toDate) {
    result['createdAt[$lte]'] = moment.utc(filters.toDate, dateFormat).add(1, 'days').toISOString()
  }

  Object.keys(result).forEach((key) => {
    if (!result[key]) {
      delete result[key]
    }
  })

  return result
}
class ResourceActions {

  setFilters = (filters) => ({
    type: constants.SET_FILTERS,
    filters: filters,
  })

  fetchAllFresh = (params) => ({
    type: constants.FETCH_ALL_FRESH,
    promise: (dispatch, getState) => {
      const filters = getState().paymentDevicesHistory.filters
      const parsedFilters = parseFilters(filters)
      
      return digitalStoreSdk.paymentDevices.fetchHistory({
        ...parsedFilters,
        includes: ['user', 'order'],
        sort: '-createdAt',
        ...params,
        offset: 0,
        limit: PAGE_SIZE
      })
    }
  })

  fetchAllNext = (params) => ({
    type: constants.FETCH_ALL_NEXT,
    promise: (dispatch, getState) => {
      const offset = getState().paymentDevicesHistory.results.length
      const filters = getState().paymentDevicesHistory.filters
      const parsedFilters = parseFilters(filters)

      return digitalStoreSdk.paymentDevices.fetchHistory({
        ...parsedFilters,
        includes: ['user', 'order'],
        sort: '-createdAt',
        ...params,
        offset,
        limit: PAGE_SIZE
      })
    }
  })

}

export default new ResourceActions()
