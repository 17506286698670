import React from 'react'
import { translations } from '../../../../config'
import {
  Column,
  Container,
  Row
} from './PromotionConditionList.styles'

const PromotionConditionList = ({ promotionConditions }) => {
  return (
    <Container>
      <Row className='header'>
        <Column>{translations('ID')}</Column>
        <Column>{translations('Description')}</Column>
        <Column>{translations('Condition Type')}</Column>
        <Column>{translations('Value')}</Column>
        <Column>{translations('Items')}</Column>
      </Row>
      {promotionConditions.map((item, index) => (
        <Row key={item.externalPromotionConditionId}>
          <Column>{item.externalPromotionConditionId}</Column>
          <Column>{item.description}</Column>
          <Column>{item.conditionType}</Column>
          <Column>{item.value}</Column>
          <Column>{item.items.join(', ')}</Column>
        </Row>
      ))}
    </Container>
  )
}

export default PromotionConditionList
