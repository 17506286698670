import { connect } from 'react-redux'
import { change, getFormValues } from 'redux-form'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _, { isNull } from 'lodash'
import ProductDetailForm from './ProductDetailForm'
import { formNames } from '../../../config'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as productDetailsSelectors } from '../../../store/modules/productDetails'
import { replace } from 'connected-react-router'
import modalService from '../../../services/modalService'
import StockAdjustmentModal from '../../../components/StockAdjustmentModal'

const pdpProductKeys = ['id', 'name', 'variants', 'brand', 'link', 'price', 'discount', 'details', 'images', 'category', 'categoryId', 'externalProductId', 'preview', 'service', 'clientId', 'vatPercent']

const getCombinedVariantName = (variantAttributes, selectedAttributes) => {
  const names = []
  for (var attr in selectedAttributes) {
    const matchedAttr = _.find(variantAttributes, varAttr => varAttr.id === attr)
    if (matchedAttr) {
      const selected = _.find(matchedAttr.values, values => values.value === selectedAttributes[attr])
      if (selected) {
        names.push(selected.name)
      }
    }
  }
  return names.join(' - ')
}

const mapStateToProps = (state, { overrideProductDetailForm }) => {
  if (overrideProductDetailForm) {
    return overrideProductDetailForm
  }
  const selectedProducts = currentAppointmentSelectors.getAppointmentProducts(state)
  const formValues = getFormValues(formNames.productDetails)(state)
  const selectedVariantId = _.get(formValues, 'variantId')
  const isSelectedVariantIdInAppointment = !!_.find(selectedProducts, selected => selected.variant.id === selectedVariantId)
  return {
    formValues,
    isSelectedVariantIdInAppointment,
    selectedProducts: selectedProducts,
    selectedVariantId: selectedVariantId,
    variants: productDetailsSelectors.getVariants(state),
    variantsOptions: productDetailsSelectors.getVariantsOptions(state),
    product: productDetailsSelectors.getProduct(state),
    isAppointmentActive: currentAppointmentSelectors.getIsAppointmentActive(state),
    variantAttributes: productDetailsSelectors.getVariationAttributes(state),
    variantAttributeNames: productDetailsSelectors.getVariationAttributeNames(state)
  }
}

const getAvailableVariantAttributes = ({
  variantAttributes,
  variantAttributeNames,
  formValues,
  variants
}) => {
  const selectedAttributes = _.chain(formValues)
    .pick(variantAttributeNames)
    .pickBy(_.identity)
    .value()

  const isCleanForm = Object.keys(selectedAttributes).length === 0

  if (isCleanForm) return variantAttributes

  const availableVariantAttributes = []

  const availableVariantsForKey = (keyName) => variants.filter(variant => {
    const variationValues = variant.details.variation_values
    const otherSelectedAttributes = _.omit(selectedAttributes, [keyName])
    let allPositive = true
    _.toPairs(otherSelectedAttributes).forEach(values => {
      const keyName = values[0]
      const keyValue = values[1]
      allPositive = allPositive && keyValue === variationValues[keyName]
    })

    return allPositive
  })

  variantAttributes.forEach(attribute => {
    const attributeId = attribute.id
    const isSelected = !!selectedAttributes[attributeId]
    const isOnlySelected = isSelected && Object.keys(selectedAttributes).length === 1

    if (isOnlySelected) {
      availableVariantAttributes.push(attribute)
    } else {
      const availableVariants = availableVariantsForKey(attributeId)
      availableVariantAttributes.push({
        ...attribute,
        values: attribute.values.filter(attrVals => {
          return !!_.find(availableVariants, available => {
            return available.details.variation_values[attributeId] === attrVals.value
          })
        })
      })
    }
  })

  return availableVariantAttributes
}

export default compose(
  connect(mapStateToProps),
  withPropsOnChange(
    [
      'variantsOptions',
      'variantAttributes',
      'variantAttributeNames',
      'formValues',
      'variants'
    ],
    ({
      variantsOptions,
      variantAttributes,
      formValues,
      variantAttributeNames,
      variants
    }) => ({
      initialValues: {
        quantity: 1,
        variantId: (
          variantsOptions.length === 1
            ? _.get(variantsOptions, '0.value')
            : null
        )
      },
      availableVariantAttributes: getAvailableVariantAttributes({
        variantAttributes,
        variantAttributeNames,
        formValues,
        variants
      })
    })
  ),
  withHandlers({
    openStockAdjustModal: ({
      dispatch,
      product,
      formValues,
    }) => () => {
      modalService.open({
        component: StockAdjustmentModal,
        productId: product.id,
        variantId: formValues.variantId,
        success: () => {
          modalService.close()
        },
        dismiss: () => {
          modalService.close()
        }
      })
    },
    onSubmit: ({
      dispatch,
      product,
      isAppointmentActive,
      isSelectedVariantIdInAppointment,
      variantAttributes,
      variantAttributeNames
    }) => (formValues) => {
      const {
        quantity,
        variantId
      } = formValues

      const selectedAttributes = _.pick(formValues, variantAttributeNames)
      const combinedVariantName = getCombinedVariantName(variantAttributes, selectedAttributes)

      const productObj = _.pick(product, pdpProductKeys)
      const productWithVariant = currentOrderSelectors.attachVariantToProduct({ product: productObj, variantId, variantName: combinedVariantName })

      if (isAppointmentActive) {
        if (isSelectedVariantIdInAppointment) {
          dispatch(currentAppointmentActions.removeContent({ type: 'product', details: productWithVariant, removeVariant: true }))
        } else {
          dispatch(currentAppointmentActions.addContent({ type: 'product', details: productWithVariant }))
        }
      } else {
        dispatch(currentOrderActions.addProduct({ product: productWithVariant, quantity }))
      }
    },
    onVariantChange: ({ dispatch, variants, product }) => ({ variantId }) => {
      if (variantId) {
        const currentProductId = product.id
        const nextProductId = _.chain(variants)
          .find(v => v.id === variantId)
          .get('productId')
          .value()
          
        dispatch(change(formNames.productDetails, 'variantId', variantId))
        // reload the page if the product id is different
        if (nextProductId && nextProductId !== currentProductId) {
          dispatch(replace(`/product/${nextProductId}`))
        }
      }
    },
    onVariantAttributeChange: ({ dispatch, formValues, variantAttributeNames, variants, product }) => ({ id, value }) => {
      const newValues = {
        ...formValues,
        [id]: value
      }
      const currentProductId = product.id

      dispatch(change(formNames.productDetails, id, value))
      const allAttributesSelected = variantAttributeNames.every(item => {
        return Object.prototype.hasOwnProperty.call(newValues, item) && newValues[item] !== null
      })
      if (allAttributesSelected) {
        const variantValues = _.toPairs(_.pick(newValues, variantAttributeNames))
        const match = _.find(variants, variant => {
          let allPositive = true
          const variationValues = variant.details.variation_values
          variantValues.forEach(values => {
            const keyName = values[0]
            const keyValue = values[1]
            allPositive = allPositive && keyValue === variationValues[keyName]
          })

          return allPositive
        })
        const variantId = match.id
        if (currentProductId !== variantId) {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
          dispatch(replace(`/product/${variantId}`))
        } else {
          dispatch(change(formNames.productDetails, 'variantId', variantId))
        }
      }
    }
  })
)(ProductDetailForm)
