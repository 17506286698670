import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Image from '../../../../../components/Image'
import Grid from '@material-ui/core/Grid'
import Button from '../../../../../components/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import P from '../../../../../components/P'
import H2 from '../../../../../components/H2'
import _ from 'lodash'
import { translations, productDetails } from '../../../../../config'
import MarkdownScreen from '../../../../../components/MarkdownScreen'
import StarRating from '../../../../../components/StarRating'
import modalService from '../../../../../services/modalService'
import LargeImageModal from './LargeImageModal'
import { colors } from '../../../../../config/theme'
import SnapshotDetails from './Sections/SnapshotDetails'
import { actions as currentAppointmentActions } from '../../../../../store/modules/currentAppointment'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../../../store/modules/currentOrder'
import toastService from '../../../../../services/toastService/toastService'
import SelectVariant from '../../../../Products/common/Results/ProductsGrid/ProductsGridItem/SelectVariant'

const Wrapper = styled(Grid)`
  background-color: #f9f6f6;
  position: relative;
`

const GridSection = styled(Grid)`
  background: white;
  margin: 1rem;
`

const ReviewsHeadingContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Heading = styled(H2)`
  font-size: 14px;
  padding: 1rem;
  border-bottom: 1px solid ${colors.border};
`

const ReviewsHeading = styled(H2)`
  font-size: 14px;
`

const SectionContent = styled.div`
  padding: 0 1rem 1rem 1rem;
`

const ThumbnailsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const ThumbnailButton = styled(ButtonBase)`
  flex: 0 0 16.93%;
  margin: 5px;
`

const ThumbnailImage = styled(Image)`
  max-width: 100%;
  border: 1px solid ${colors.border};
`

const EmptyContentMessage = styled(P)`
  margin-top: 1rem;
`

const BasketButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${colors.lightGrey};
  padding: 1rem;
  height: fit-content;
  box-shadow: 0 -2px 4px 0 rgba(133, 109, 109, 0.2);
`

const ProductResourceItem = (props) => {
  const {
    type,
    id,
    title,
    name,
    image,
    images = [],
    details = {},
    link,
    latestReview,
    variants
  } = props
  const dispatch = useDispatch()
  const { description, summary } = details

  const basketItems = useSelector(currentOrderSelectors.getCurrentOrderProducts)
  const basketItemIds = _.map(basketItems, (item) => item.id)
  const isItemInBasket = _.includes(basketItemIds, id)

  const addToBasketButtonVariant = isItemInBasket ? 'outlined' : 'contained'

  const handleThumbnailClick = useCallback((src) => {
    return modalService.open({
      component: LargeImageModal,
      fullScreen: true,
      src,
      onClose: modalService.close
    })
  })

  const addToWishlist = useCallback(() => {
    dispatch(currentAppointmentActions.customerToggleContentIsLoved({ type, details: props }))
    toastService.action({
      type: 'success',
      message: translations('Wishlist - update message for customer'),
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  })

  const addToBasket = useCallback(() => {
    if (isItemInBasket) {
      // remove product
      dispatch(currentOrderActions.removeProduct({ product: props }))
    } else {
      // add product
      if (variants && variants.length > 1) {
        modalService.open({
          component: SelectVariant,
          smallModal: true,
          variants: variants,
          title: translations('Select A Variant'),
          modalIndex: 1,
          actions: [
            {
              success: true,
              primary: true,
              text: translations('Done'),
              onClick: (productWithVariant) => dispatch(currentOrderActions.addProduct({ product: productWithVariant, quantity: 1 }))
            },
            {
              text: translations('Cancel'),
              onClick: _.noop
            }
          ]
        })
      } else {
        dispatch(currentOrderActions.addProduct({
          product: props,
          quantity: 1
        }))
      }
    }
  })
  const productReviewsEnabled = _.get(productDetails, 'reviews', false)
  const showLink = _.get(productDetails, 'websiteLink', false)

  return (
    <Wrapper container>
      <Grid item xs={12}>

        <SnapshotDetails {...props} showProductRating={productReviewsEnabled} />

        <Grid container>
          <GridSection item xs={12}>
            <Heading value={translations('About this item')} />
            <SectionContent>

              {description && (<MarkdownScreen markdownData={description} />)}

              {(images && images.length) && (
                <ThumbnailsContainer>
                  {images.map((src) => {
                    return (
                      <ThumbnailButton
                        key={src}
                        onClick={() => handleThumbnailClick(src)}
                      >
                        <ThumbnailImage src={src} />
                      </ThumbnailButton>
                    )
                  })}
                </ThumbnailsContainer>
              )}

            </SectionContent>
          </GridSection>

          {summary && (
            <GridSection item xs={12}>
              <Heading value={translations('Product information')} />
              <SectionContent>
                <MarkdownScreen markdownData={summary} />
              </SectionContent>
            </GridSection>
          )}

          {productReviewsEnabled && (
            <GridSection item xs={12}>
              <ReviewsHeadingContainer>
                <ReviewsHeading value={translations('Latest review')} />
                <StarRating />
              </ReviewsHeadingContainer>
  
              <SectionContent>
                {latestReview
                  ? (<MarkdownScreen markdownData={latestReview} />)
                  : (<EmptyContentMessage value={translations('No reviews')} />)
                }
              </SectionContent>
            </GridSection>
          )}

          {(showLink && link) && (
            <Grid item xs={12}>
              <SectionContent>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  href={link}
                  target={'_blank'}
                >
                  See full details
                </Button>
              </SectionContent>
            </Grid>
          )}
        </Grid>
      </Grid>

      <BasketButtonContainer>
        <Button
          color='primary'
          variant={addToBasketButtonVariant}
          fullWidth
          onClick={addToBasket}
        >
          {isItemInBasket ? translations('Remove from bag') : translations('Add this item to your bag')}
        </Button>
      </BasketButtonContainer>
    </Wrapper>
  )
}

export default ProductResourceItem
