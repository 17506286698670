import styled from 'styled-components'

export const Container = styled.div`
  width: 70%;
  margin: 10px auto;
  border: 1px solid #e9e9e9;
  padding: 8px 5px;
`

export const Column = styled.div`
  width: 33%;
  padding: 8px 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const ColumnHeader = styled.div`
  opacity: 0.5;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ColumnContent = styled.div`
  font-size: 13px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
