import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as authSelectors } from '../../store/modules/auth';
import { translations } from '../../config';
import modalService from '../../services/modalService';
import ListPage from '../../layouts/ListPage';
import NewCountModal from './NewCountModal/';
import sdk from '../../digitalStoreSdk';

const defaultColumns = [
  {
    Header: translations('Date'),
    accessor: 'countDate',
  },
  {
    Header: translations('Visitor Count'),
    accessor: 'visitorCount',
  },
  {
    Header: translations('Connection Count'),
    accessor: 'connectionCount',
  },
];

const VisitorConnectionCountScreen = () => {
  const state = useSelector((state) => state);
  const selectedStore = authSelectors.getSelectedStore(state);
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);

  const fetchStoreVisitorConnectionCount = async () => {
    const results = await sdk.storeVisitorConnectionCount.fetchStoreVisitorConnectionCount(
      { storeId: selectedStore.id, limit: 10, offset: offset }
    );
    const allData = data.concat(results.items)
    setData(allData);
    setTotalItems(results.total || 0);
  };

  const showModal = () => {
    modalService.close();
    return modalService.open({
      component: NewCountModal,
      wideModal: true,
      title: 'New Count',
      onCancel: () => modalService.close(),
      onSubmit: async (item) => {
        const allData = [item, ...data]
        setData(allData)
        modalService.close();
      },
    });
  };

  useEffect(() => {
    fetchStoreVisitorConnectionCount();
  }, []);

  useEffect(() => {
    fetchStoreVisitorConnectionCount();
  }, [offset]);

  return (
    <ListPage
      title={selectedStore.name}
      columns={defaultColumns}
      results={data}
      actionButtons={[{ label: translations('New Count'), onClick: showModal }]}
      hasMore={totalItems > data.length}
      loadMore={() => setOffset(offset + 1)}
    />
  );
};

export default VisitorConnectionCountScreen;
