import * as validators from '../../../validators'
import { countryPhoneCodes, countries } from '../../../config'
import { storeLogos } from '../../../assets'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

export default ({ regions = [], catalogues = [] } = {}) => {
  return {
    schema: [{
      id: 'name',
      field: 'Input',
      props: {
        label: 'Name',
        name: 'name',
        required: true
      }
    }, {
      id: 'externalStoreId',
      field: 'Input',
      props: {
        label: 'External ID',
        name: 'externalStoreId',
        required: true
      }
    }, {
      id: 'regionId',
      field: 'Dropdown',
      props: {
        label: 'Region',
        name: 'regionId',
        options: [regions],
        validate: [smallTextLengthValidator],
        required: true
      }
    }, {
      id: 'receiptLanguage',
      field: 'Dropdown',
      props: {
        label: 'Receipt Language',
        name: 'receiptLanguage',
        options: [
          { label: 'Finnish', value: 'fi' },
          { label: 'Swedish', value: 'sv' },
          { label: 'Danish', value: 'da' },
          { label: 'Dutch', value: 'nl' },
          { label: 'German', value: 'de' },
          { label: 'English', value: 'en' }
        ],
        validate: [smallTextLengthValidator],
        required: true
      }
    }, {
      id: 'catalogue',
      field: 'AutoComplete',
      props: {
        label: 'Catalogue',
        name: 'catalogue',
        required: true,
        autocomplete: true,
        options: catalogues
      }
    }, {
      id: 'contactEmail',
      field: 'Email',
      props: {
        label: 'Contact Email',
        name: 'contactEmail',
        validate: [validators.email, smallTextLengthValidator],
        required: true,
        showEmailButton: false
      }
    }, {
      id: 'phoneNumber',
      field: 'PhoneNumber',
      props: {
        label: 'Phone Number',
        name: 'phoneNumber',
        options: countryPhoneCodes,
        validate: [telephoneLengthValidator, validators.phoneNumber]
      }
    },
    {
      id: 'address1',
      field: 'Input',
      props: {
        label: 'Address 1',
        name: 'address1',
        required: true
      }
    }, {
      id: 'address2',
      field: 'Input',
      props: {
        label: 'Address 2',
        name: 'address2'
      }
    }, {
      id: 'city',
      field: 'Input',
      props: {
        label: 'City',
        name: 'city',
        required: true
      }
    }, {
      id: 'county',
      field: 'Input',
      props: {
        label: 'State',
        name: 'county'
      }
    }, {
      id: 'postCode',
      field: 'Input',
      props: {
        label: 'Postal Code',
        name: 'postCode',
        required: true
      }
    }, {
      id: 'country',
      field: 'Dropdown',
      props: {
        label: 'Country',
        name: 'country',
        options: countries,
        required: true
      }
    },
    {
      id: 'latLng',
      field: 'Input',
      props: {
        label: 'GPS coordinate',
        placeholder: '51.5059656, -0.1036042',
        name: 'latLng'
      }
    },
    {
      id: 'storeLogo',
      field: 'Dropdown',
      props: {
        multiple: true,
        label: 'Store Logo',
        name: 'storeLogo',
        options: storeLogos,
        required: true
      }
    },
    {
      id: 'tacMarkdown',
      field: 'Input',
      props: {
        label: 'Register Customer Terms Markdown',
        placeholder: `I have read and agreed to the **[Terms Of Service](https://www.iittala.com/fi-fi/footer/myiittala-sopimusehdot)** and **[Privacy Policy](https://fiskarsgroup.com/fi/fiskars-groupin-tietosuojakaytannot/tietosuojakaytanto-kuluttajille/)**, and I am over the age of 16. *`,
        name: 'tacMarkdown'
      }
    }
    ],
    layout: [['name']]
  }
}
