import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

import { Table, TableHeader, TableRow, TableCell } from '../../../../../../components/Table'
import Image from '../../../../../../components/Image'
import ButtonLink from '../../../../../../components/ButtonLink'
import P from '../../../../../../components/P'
import { translations, returnReasonCodes, defectCodes } from '../../../../../../config'
import style from './style'
import { Dropdown } from '../../../../../../components/Fields'
import * as validators from '../../../../../../validators'
import { conditionValues } from '../../../../../Basket/BasketItem/BasketItem'

class OrderProduct extends Component {
  renderDataLine = ([key, content]) => {
    const { classes } = this.props
    const keyText = translations(`Order Product ${_.startCase(key)}`)
    let text = (
      keyText
      ? `${keyText}: ${content}`
      : content
    )
    let lineClass = cx(classes.itemDetail, {
      [classes.notPurchasedReasonTextMobile]: key === 'notPurchasedReason'
    })
    return <P className={lineClass} value={text} />
  }

  renderItemDetails = (linesData = []) => {
    const { product, goToProduct, classes } = this.props
    return <div className={classes.itemDetailsContainer}>
      <Image
        src={_.get(product, 'images.0')}
        className={classes.itemDetailsImage}
        box
        contain
        onClick={goToProduct}
      />
      <div className={classes.itemDetailsText}>
        <ButtonLink
          className={classes.itemName}
          onClick={goToProduct}
        >
          {_.get(product, 'name')}
        </ButtonLink>
        {linesData.map(this.renderDataLine)}
      </div>
    </div>
  }

  _getProductLinesForAllScreenSizes = () => {
    const { product, serviceForProduct } = this.props

    const variantSku = _.get(product, 'variant.details.sku')
    const externalProductId = _.get(product, 'externalProductId')
    const lineItemNotes = _.get(product, 'lineItemNotes')

    const lines = []
    lines.push(['externalId', externalProductId])
    if (variantSku) lines.push(['variantSku', variantSku])
    if (serviceForProduct) lines.push(['serviceProductName', serviceForProduct.name])
    if (lineItemNotes) lines.push(['lineItemNotes', lineItemNotes])
    return lines
  }

  renderDesktop = () => {
    const { productInfo, productInfoLayout, faded, classes, product, isPartialRefundState, orderFormValues } = this.props

    const defectCodeCategories = defectCodes.map(defectCode => ({ label: defectCode.label, value: defectCode.value }))
    const defectCodeOptions = _.chain(defectCodes)
      .find(defectCode => defectCode.value === _.get(orderFormValues, `${product.id}_return_defect_code_type`))
      .get('options')
      .defaultTo([])
      .map(defectCode => ({ label: defectCode.label, value: defectCode.value }))
      .value()

    return (
      <>
        <TableRow
          className={faded ? classes.faded : undefined}
        >
          <TableCell sm={4} className={classes.cell}>
            {this.renderItemDetails(this._getProductLinesForAllScreenSizes())}
          </TableCell>
          {productInfoLayout.map(([key, cellWidth]) => {
            return <TableCell
              key={key}
              children={productInfo[key]}
              sm={cellWidth}
              className={classes.cell}
            />
          })}
        </TableRow>
        {isPartialRefundState && !faded ? (<TableRow className={faded ? classes.faded : undefined} >
          <TableCell sm={2} className={classes.cell}>
            <Dropdown
              required
              name={`${product.id}_return_reason_code`}
              label={translations('Reason for Refund')}
              className={classes.refundResonDropdown}
              options={returnReasonCodes.map(obj => ({ label: obj.text, value: obj.code }))}
              // validate={validators.required}
            />
          </TableCell>
          {orderFormValues && orderFormValues[`${product.id}_return_reason_code`] === 'WARRANTY' ? (
            <>
              <TableCell sm={2} className={classes.cell}>
                <Dropdown
                  required
                  name={`${product.id}_return_defect_code_type`}
                  label={translations('Material')}
                  className={classes.refundResonDropdown}
                  options={defectCodeCategories}
                  validate={validators.required}
                />
              </TableCell>
              {defectCodeOptions.length ? (<TableCell sm={2} className={classes.cell}>
                <Dropdown
                  required
                  name={`${product.id}_return_defect_code_value`}
                  label={translations('Defect code')}
                  className={cx(classes.refundResonDropdown, classes.root)}
                  options={defectCodeOptions}
                  validate={validators.required}
                />
              </TableCell>) : null}
            </>
          ) : null}
          {
            orderFormValues && orderFormValues[`${product.id}_quantity`] 
              && orderFormValues[`${product.id}_return_reason_code`] && (
                <TableRow>
                  <TableCell sm={2} className={classes.cell}>
                    <Dropdown
                      required
                      name={`${product.id}_isInSalableCondition`}
                      label={translations('Is the item in salable condition?')}
                      options={conditionValues}
                      validate={validators.required}
                    />
                  </TableCell>
                </TableRow>
              )
          }
        </TableRow>) : null}
      </>
    )
  }

  renderMobile = () => {
    const { productInfo, productInfoLayout, faded, classes, product, isPartialRefundState, orderFormValues } = this.props

    const linesData = [...this._getProductLinesForAllScreenSizes()]

    let rightContent = null

    productInfoLayout.forEach(([key]) => {
      const content = productInfo[key]
      // this is so that stuff like the YesNo picker is pushed to the right
      // but other stuff can just be rendered as lines of text
      if (typeof content === 'string' || typeof content === 'number') {
        linesData.push([key, content])
      } else {
        rightContent = content
      }
    })

    const defectCodeCategories = defectCodes.map(defectCode => ({ label: defectCode.label, value: defectCode.value }))
    const defectCodeOptions = _.chain(defectCodes)
      .find(defectCode => defectCode.value === _.get(orderFormValues, `${product.id}_return_defect_code_type`))
      .get('options')
      .defaultTo([])
      .map(defectCode => ({ label: defectCode.label, value: defectCode.value }))
      .value()

    return (
      <div className={classes.containerMobile}>
        <div className={cx(classes.innerMobile, {
          [classes.faded]: faded
        })}>
          {this.renderItemDetails(linesData)}
          {rightContent}
        </div>
        {isPartialRefundState && !faded ? (
          <>
            <TableRow className={faded ? classes.faded : undefined} >
              <TableCell sm={2} className={classes.cell}>
                <Dropdown
                  required
                  name={`${product.id}_return_reason_code`}
                  label={translations('Reason for Refund')}
                  className={classes.refundResonDropdown}
                  options={returnReasonCodes.map(obj => ({ label: obj.text, value: obj.code }))}
                // validate={validators.required}
                />
              </TableCell>
            </TableRow>
            {orderFormValues && orderFormValues[`${product.id}_return_reason_code`] === 'WARRANTY' ? (
              <TableRow className={faded ? classes.faded : undefined} >
                <TableCell sm={2} className={classes.cell}>
                  <Dropdown
                    required
                    name={`${product.id}_return_defect_code_type`}
                    label={translations('Material')}
                    className={classes.refundResonDropdown}
                    options={defectCodeCategories}
                    validate={validators.required}
                  />
                </TableCell>
                {defectCodeOptions.length ? (<TableCell sm={2} className={classes.cell}>
                  <Dropdown
                    required
                    name={`${product.id}_return_defect_code_value`}
                    label={translations('Defect code')}
                    className={classes.refundResonDropdown}
                    options={defectCodeOptions}
                    validate={validators.required}
                  />
                </TableCell>) : null}
              </TableRow>
            ) : null}
          </>
        ) : null}
        {
          orderFormValues && orderFormValues[`${product.id}_quantity`] 
            && orderFormValues[`${product.id}_return_reason_code`] && (
              <TableRow>
                <TableCell sm={2} className={classes.cell}>
                  <Dropdown
                    required
                    name={`${product.id}_isInSalableCondition`}
                    label={translations('Is the item in salable condition?')}
                    options={conditionValues}
                    validate={validators.required}
                  />
                </TableCell>
              </TableRow>
            )
        }
      </div>
    )
  }

  render () {
    return <div>
      <Hidden smUp>{this.renderMobile()}</Hidden>
      <Hidden xsDown>{this.renderDesktop()}</Hidden>
    </div>
  }
}

export default withStyles(style)(OrderProduct)
