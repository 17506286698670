import React, { PureComponent } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { selectors as orderDetailsSelectors } from '../../../../store/modules/orderDetails'

import { dateFormat } from '../../../../config'

import { formatDate, getDeliveryPrice } from '../../../../helpers'
import OrderDetail from './OrderDetail'

class OrderDetailContainer extends PureComponent {
  _generateProps = () => {
    const { total, subTotal, totalDiscount, tax, orderDate, deliveryOption, status, numberOfPurchaseableProducts } = this.props.order
    const formattedItemString = numberOfPurchaseableProducts === '1' ? 'item' : 'items'

    return {
      ...this.props,
      order: {
        ...this.props.order,
        status,
        total,
        subTotal,
        totalDiscount,
        tax,
        orderDate: formatDate(orderDate, dateFormat),
        numberOfProducts: `${numberOfPurchaseableProducts} ${formattedItemString}`,
        deliveryPrice: getDeliveryPrice(deliveryOption)
      }
    }
  }

  render() {
    const props = this._generateProps()
    return <OrderDetail {...props} />
  }
}

const mapStateToProps = (state) => {
  const isLoading = orderDetailsSelectors.getIsLoading(state) && !orderDetailsSelectors.getNoSpinner(state)
  const failedToLoad = orderDetailsSelectors.getFailedToLoad(state)
  const order = orderDetailsSelectors.getOrder(state)
  const isPartialRefundOrder = orderDetailsSelectors.getIsPartialRefundState(state)

  return {
    order,
    isLoading,
    failedToLoad,
    isPartialRefundOrder
  }
}

const mapDispatchToProps = (dispatch) => ({
  goToOrders: () => dispatch(push(`/orders`)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailContainer)
