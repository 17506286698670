import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import RemoveButton from '../RemoveButton'
import { noPrices } from '../../../../config'

import H2 from '../../../H2'
import P from '../../../P'

import style from './style'
import Image from '../../../Image'


const Product = ({
  id,
  link,
  name,
  brand,
  images,
  price,
  onDelete,
  onProductContainerClick,
  editing,
  width,
  classes
}) => (
  <div
    className={classNames(classes.container, {
      [classes.containerMargin]: width !== 'xs'
    })}
    onClick={onProductContainerClick}
  >
    {editing && (
      <RemoveButton onClick={event => onDelete({ id, event })} />
    )}
    <Image src={_.get(images, '0')} className={classes.image} containerClassName={classes.imageContainer} />
    {brand && <H2 className={classes.productText} value={brand} />}
    <H2 className={`${classes.productText} ${classes.truncatedProductName}`} value={name} />
    {noPrices ? null : <P className={classes.productText} type='textButton' value={price} />}
  </div>
)

Product.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  brand: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  onProductContainerClick: PropTypes.func,
  editing: PropTypes.bool
}

export default withStyles(style)(Product)
