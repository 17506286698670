import {
  ADYEN_CLOUD_PAYMENT_DEVICE,
  STAR_CLOUD_PRNT_DEVICE,
} from '../../../services/paymentDeviceService'
import { getPaymentDeviceOptions } from '../../../services/paymentDevicesService'

// const paymentDeviceOptions = [
//   { value: STAR_CLOUD_PRNT_DEVICE, label: 'Cash Drawer' },
//   { value: ADYEN_CLOUD_PAYMENT_DEVICE, label: 'Payment Terminal' },
// ]
const paymentDeviceOptions = getPaymentDeviceOptions()

export const commonSchemas = [
  {
    id: 'deviceType',
    field: 'Dropdown',
    props: {
      label: 'Device Type',
      name: 'deviceType',
      options: paymentDeviceOptions.filter(item => !item.value.startsWith('internal')),
      required: true,
    },
  },
  {
    id: 'name',
    field: 'Input',
    props: {
      label: 'Device Name',
      name: 'name',
      required: true,
    },
  },
]

export const paymentTerminalSchemas = [
  {
    id: 'modelNumber',
    field: 'Input',
    props: {
      label: 'Model Number',
      name: 'modelNumber',
      required: true,
    },
  },
  {
    id: 'serialNumber',
    field: 'Input',
    props: {
      label: 'Serial Number',
      name: 'serialNumber',
      required: true,
    },
  },
]

export const cashDrawerSchemas = [
  {
    id: 'modelNumber',
    field: 'Input',
    props: {
      label: 'Access Id',
      name: 'modelNumber',
      required: true,
    },
  }
]