import * as constants from './constants'
import * as selectors from './selectors'
import digitalStoreSdk from '../../../digitalStoreSdk'

class UserActions {
  searchUsersFresh = ({ query, roleIds, storeIds, includeDeactivated, departments, size: _size }) => ({
    type: constants.SEARCH_USER_FRESH,
    query,
    roleIds,
    storeIds,
    includeDeactivated,
    departments,
    promise: (dispatch, getState) => {
      const searchUsersStoreIds = storeIds === undefined ? selectors.getStoreIds(getState()) : storeIds
      const searchUsersRoleIds = roleIds === undefined ? selectors.getRoleIds(getState()) : roleIds
      const searchUsersQuery = query === undefined ? selectors.getQuery(getState()) : query
      const searchUsersIncludeDeactivated = includeDeactivated === undefined ? selectors.getIncludeDeactivated(getState()) : includeDeactivated
      const defaultSize = selectors.getSize(getState())
      return digitalStoreSdk.users
        .searchUsers({
          query: searchUsersQuery,
          roleIds: searchUsersRoleIds,
          storeIds: searchUsersStoreIds,
          departmentIds: departments,
          includeDeactivated: searchUsersIncludeDeactivated,
          page: 1,
          size: _size || defaultSize
        })
    }
  })
  searchUsersNext = () => ({
    type: constants.SEARCH_USER_NEXT,
    promise: (dispatch, getState) => {
      const storeIds = selectors.getStoreIds(getState())
      const roleIds = selectors.getRoleIds(getState())
      const query = selectors.getQuery(getState())
      const includeDeactivated = selectors.getIncludeDeactivated(getState())
      const size = selectors.getSize(getState())
      const page = selectors.getPage(getState())
      return digitalStoreSdk.users
        .searchUsers({ query, storeIds, roleIds, includeDeactivated, page: page + 1, size })
        .then(({ total, results }) => ({ total, results, query, storeIds, roleIds, includeDeactivated }))
    }
  })
  updateUserInList = ({ user }) => ({
    type: constants.UPDATE_USER_IN_LIST,
    user
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new UserActions()
