import _ from 'lodash'
import { createSelector } from 'reselect'
import { compose, flatten } from 'lodash/fp'

import { PENDING, FAIL } from '../../middleware/redux-promise'
import orderFormatter from '../../../formatters/orderFormatter'
import { getHasReassignOrder, getHasEditOrder } from '../auth/selectors'

export const getOrderRaw = state => _.get(state, 'orderDetails.order')
export const getOrderId = state => _.get(state, 'orderDetails.order.id')
export const getOrderNumber = state => _.get(state, 'orderDetails.order.orderNumber')
export const getIsLoading = state => _.get(state, 'orderDetails.status') === PENDING
export const getNoSpinner = state => _.get(state, 'orderDetails.noSpinner')
export const getIsPartialRefundState = state => _.get(state, 'orderDetails.orderState') === `PARTIAL_REFUND_${getOrderId(state)}`
export const getIsExchangeState = state => _.get(state, 'orderDetails.orderState') === `EXCHANGE_${getOrderId(state)}`

export const getFailedToLoad = state => _.get(state, 'orderDetails.status') === FAIL
export const hasReassignOrderPermission = state => getHasReassignOrder(state)
export const hasEditOrderPermission = state => getHasEditOrder(state)

export const getProductForService = serviceForProductId => state => {
  const product = _.chain(state)
    .get('orderDetails.order.products')
    .find(product => product.productId === serviceForProductId)
    .value()

  return product || null
}

export const getOrderProducts = state => {
  const products =  _.get(getOrderRaw(state), 'products', [])
  return _.orderBy(products, ['service'], ['desc'])
}

export const getOrder = createSelector([
  getOrderRaw
], (rawOrder) => {
  const order = orderFormatter.format(rawOrder)
  return order
})

export const orderHasPaymentToken = createSelector([getOrder], order => {
  if (!order) return false
  return order.paymentToken !== null
})

export const getOrderHasBeenRefunded = createSelector([getOrder], order => {
  return _.chain(order)
    .get('products')
    .map(orderProduct => orderProduct.hasBeenRefunded)
    .includes(true)
    .value()
})
export const getOrderIsFullyRefunded = createSelector([getOrder], order => {
  const isFullyRefunded = !!order && !!order.products && order.products.length > 0 && _.chain(order)
    .get('products')
    .reject(orderProduct => orderProduct.unsold)
    .map(orderProduct => !!orderProduct.hasBeenRefunded || !!orderProduct.refund)
    .includes(false)
    .eq(false)
    .value()
  return isFullyRefunded
})
export const getOrderStoreId = createSelector([getOrder], order => {
  return _.chain(order)
    .get('storeId')
    .value()
})

export const getOrderIsCancelled = createSelector([getOrderRaw], order => {
  if (!order) return false
  return order.status === 'cancelled'
})

export const orderHasOneOrManyRefundedProducts = createSelector([getOrderProducts], products => {
  if (!products) return false
  const res = _.some(products, product => {
    return product.hasBeenRefunded || product.refund
  })
  return res
})

export const orderHasRefund = createSelector([getOrder], order => {
  if (!order) return false
  const orderProducts = _.get(order, 'products')
  const productKeysWithPrices = ['price', 'total', 'manualDiscount', 'discount', 'nowPrice', 'subTotal', 'priceToDisplay', 'discountToDisplay']

  const mapAndGetNegativeValueTruthyness = (products) => _.map(products, product => {
    const prices = _.pick(product, productKeysWithPrices)
    const res = _.map(prices, ({ value }) => Math.sign(value) === -1 || value === 0) 
    return res
  })

  const isInvertedPricesPredicate = compose(
    _.every,
    flatten,
    mapAndGetNegativeValueTruthyness
  )(orderProducts)
  return isInvertedPricesPredicate
})

export const canReassignCustomer = createSelector([hasReassignOrderPermission, orderHasPaymentToken], (hasReassignOrderPermission, hasPaymentToken) => {
  return hasReassignOrderPermission // && hasPaymentToken // hasPaymentToken no longer necessary
})

export const canEditOrder = createSelector([hasEditOrderPermission, orderHasPaymentToken], (hasEditOrderPermission, hasPaymentToken) => {
  return hasEditOrderPermission && !hasPaymentToken
})

export const canCancelOrder = createSelector([getOrderIsCancelled], orderIsCancelled => {  
  return !orderIsCancelled
})
