import PayByGiftCardModal from '../../../components/PaymentDevices/PayByGiftCardModal/PayByGiftCardModal'
import modalService from '../../modalService'
import AdyenCloudPaymentDevice from '../adyenCloudPaymentDevice'
import AdyenGiftCardsService from './adyenGiftCards/AdyenGiftCardsService'
import uuid from 'uuid/v4'

class AdyenGiftCardPaymentDevice {
  implementation = AdyenGiftCardsService;

  // Constants
  deviceType = 'gift-card:adyen-cloud';
  // deviceType = 'card:adyen-cloud'
  deviceName = 'Adyen Cloud (Giftcard Payments)';
  ctaText = 'Pay by Gift Card (Adyen)';
  nameForReceipt = 'GIFT_CARD'

  async initalizeForCheckoutSession (order) {
    // init Adyen here
    return order
  }

  async processPayment (paymentDevices, leftToPay, order, paymentsToRefund) {
    return new Promise((resolve, reject) => {
      const success = (selectedPaymentDevice, result) => {
        resolve({
          id: uuid(),
          timestamp: new Date().toISOString(),
          paymentDeviceId: selectedPaymentDevice.id,
          amountPaid: parseFloat(result.amount), // the currency input field returns a string,
          success: true,
          name: selectedPaymentDevice.name,
          deviceType: this.deviceType,
          result,
          ...(paymentsToRefund ? { isRefund: true } : {})
        })
        return modalService.close()
      }

      const dismiss = () => {
        resolve(null)
        return modalService.close()
      }

      modalService.open({
        component: PayByGiftCardModal,
        // paymentDevices: paymentDevices.filter(obj => obj.deviceType === this.deviceType), // use card:adyen-cloud
        paymentDevices: paymentDevices.filter(
          (obj) => obj.deviceType === AdyenCloudPaymentDevice.deviceType
        ),
        total: { value: leftToPay, code: order.total.code },
        paymentsToRefund,
        orderNumber: order.orderNumber,
        success
      })
    })
  }
}

export default new AdyenGiftCardPaymentDevice()
