import _ from 'lodash'

import { defaultState } from './store'
import { promiseReducer } from '../../util'

class CheckoutFlowReducers {
  next = (state) => {
    return state
  }

  back = (state) => {
    return state
  }

  start = (state, action) => {
    return {
      ...state,
      checkoutType: action.checkoutType
    }
  }

  end = () => {
    return {
      ...defaultState
    }
  }

  clear = () => {
    return defaultState
  }

  fail = (state) => {
    return state
  }

  setCurrent = (state, action) => {
    if (action.module) {
      return {
        ...state,
        current: action.module
      }
    } else {
      return state
    }
  }

  setLoading = (state, action) => {
    const { isLoading } = action
    return {
      ...state,
      isLoading: !!isLoading
    }
  }

  setOrderNumber = (state, action) => {
    const { orderNumber } = action
    if (orderNumber) {
      return {
        ...state,
        orderNumber
      }
    } else {
      return state
    }
  }

  setOrderId = (state, action) => {
    const { orderId } = action
    if (orderId) {
      return {
        ...state,
        orderId
      }
    } else {
      return state
    }
  }

  fetchDeliveryOptions = promiseReducer((state, action) => {
    return {
      ...state,
      deliveryOptions: action.result
    }
  })

  fetchValueAddedServices = promiseReducer((state, action) => {
    return {
      ...state,
      valueAddedServices: action.result
    }
  })

  setPreviousPath = (state, action) => {
    const { previousPath } = action
    if (previousPath) {
      return {
        ...state,
        previousPath
      }
    } else {
      return state
    }
  }

  setDeferredModule = (state, action) => {
    if (action.module) {
      return {
        ...state,
        deferred: action.module
      }
    } else {
      return state
    }
  }
}

export default new CheckoutFlowReducers()
