import _ from 'lodash'


export const mapReferencedRefundRequest = (paymentDevice, originalTransactionId, originalTransactionTimestamp, total, orderNumber) => {
  const transactionTimestamp = new Date().toISOString()
  // TODO: Check if this is correct
  const saleId = 'RetailOS' // unique to the POS device
  const paymentDeviceId = makePaymentDeviceId(paymentDevice)
  const transactionId = orderNumber

  let refundRequest = {
    SaleToPOIRequest: {
      MessageHeader: {
        ProtocolVersion: '3.0',
        MessageClass: 'Service',
        MessageCategory: 'Reversal',
        MessageType: 'Request',
        SaleID: saleId,
        ServiceID: '010' + getRandomInt(100000, 10000000),
        POIID: paymentDeviceId
      },
      ReversalRequest: {
        OriginalPOITransaction: {
          POITransactionID: {
            TransactionID: originalTransactionId,
            TimeStamp: originalTransactionTimestamp
          }
        },
        ReversalReason: "MerchantCancel"
      }
    }
  }

  // Partial refunds
  if (total) {
    refundRequest.SaleToPOIRequest.ReversalRequest.ReversedAmount = parseFloat(total.value)
    refundRequest.SaleToPOIRequest.ReversalRequest.SaleData = {
      SaleToAcquirerData: `currency=${total.code}&${makeSaleToAcquirerData()}`,
      SaleTransactionID: {
        TimeStamp: transactionTimestamp,
        TransactionID: transactionId
      }
    }
  } else {
    refundRequest.SaleToPOIRequest.ReversalRequest.SaleData = {
      SaleToAcquirerData: makeSaleToAcquirerData(),
      SaleTransactionID: {
        TimeStamp: transactionTimestamp,
        TransactionID: transactionId
      }
    }
  }

  return refundRequest
}

const makeSaleToAcquirerData = () => {
  // https://docs.adyen.com/point-of-sale/add-data/pass-application-information?tab=option_2_key_value_pairs_2

  const versionNo = window.env.REACT_APP_VERSION || process.env.REACT_APP_VERSION

  const ADYEN_APPLICATION_NAME = 'RetailOS'
  const APPLICATION_VERSION = `${versionNo}`
  const INTEGRATOR_COMPANY_NAME = 'RedAnt'
  const PLATFORM_NAME = 'AdyenLocalTerminalApiIntegration'
  const PLATFORM_VERSION = '0.1.0'
  const OS_NAME = _.get(window, 'device.platform',
    _.get(window, 'navigator.platform',
      'Unknown'
    )
  )
  const OS_VERSION = _.get(window, 'device.version',
    'Unknown'
  )
  const DEVICE_REFERENCE = 'DEVICE_REFERENCE'

  const parts = [
    `applicationInfo.merchantApplication.name=${ADYEN_APPLICATION_NAME}`,
    `applicationInfo.merchantApplication.version=${APPLICATION_VERSION}`,
    `applicationInfo.externalPlatform.integrator=${INTEGRATOR_COMPANY_NAME}`,
    `applicationInfo.externalPlatform.name=${PLATFORM_NAME}`,
    `applicationInfo.externalPlatform.version=${PLATFORM_VERSION}`,
    `applicationInfo.merchantDevice.os=${OS_NAME}`,
    `applicationInfo.merchantDevice.osVersion=${OS_VERSION}`,
    `applicationInfo.merchantDevice.reference=${DEVICE_REFERENCE}`,
    `tenderOption=ReceiptHandler`
  ]

  const keyValuePairString = parts.join('&')

  return keyValuePairString
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const makePaymentDeviceId = paymentDevice => `${paymentDevice.config.modelNumber}-${paymentDevice.config.serialNumber}`
