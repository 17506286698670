import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const NewListChart = ({ columns, header, tableProps }) => (
  <TableContainer>
    <Table {...tableProps}>
      <TableHead>
        <TableRow>
          {header.map((headerItem) => (
            <TableCell key={headerItem.id} {...headerItem}>
              {headerItem.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {columns.map((column) => (
          <TableRow>
            {header.map((headerItem) => {
              const value = column[headerItem.id] || '';
              return <TableCell {...headerItem}>{value}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default NewListChart;
