const style = theme => ({
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridRowGap: '10px',
    gridColumnGap: '25px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'auto 1fr'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr auto'
    }
  },
  rowLabel: {
    fontWeight: 'bold',
    lineHeight: '40px'
  },
  dropdownAndSizeGuideContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  adjustStockButtonContainer: {
    margin: '15px 0px'
  },
  variantDropdownContainer: {
    marginTop: -14
  },
  field: {
    width: 160
  },
  submitButtonContainer: {
    width: '100%'
  },
  submitButton: {
    width: '100%',
    maxWidth: '100%'
  }
})

export default theme => style(theme)
