import React from 'react'
import { compose, withState } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import style from './style'
import H3 from '../../../../components/H3'
import Dropdown from '../../../../components/Fields/Dropdown'
import Button from '../../../../components/Button'

const anonymousReasons = [
  'Customer Privacy',
  'Tourist',
  'Client pressed for time',
  'Gifting'
]

const enhancer = compose(
  withState('value', 'onChange', '')
)

const AnonymousReasonModal = enhancer(({ classes, onClick, value, onChange }) => {
  return (
    <div className={classes.container}>
      <H3 value='Please select a reason' />
      <Dropdown
        value={value}
        onChange={(e) => onChange(e.target.value)}
        options={anonymousReasons}
        className={cx(classes.dropdown)}
      />
      <Button
        buttonType='primary'
        onClick={() => onClick(value)}
        disabled={!value}
        className={classes.button}
      >Submit</Button>
    </div>
  )
})

export default withStyles(style)(AnonymousReasonModal)
