import StarCloudPRNTService from '../../paymentDeviceService/starCloudPRNTService'

class AbstractReceiptPrinter {
  getReceiptPrinters (allPaymentDevices) {
    /**
     * FIXME: Fiskars want to use "cash:** as printers". The core implementation should seperate the two.
     */
    // return allPaymentDevices.filter(obj => obj.deviceType.startsWith('printer'))
    return allPaymentDevices.filter(obj => obj.deviceType.startsWith('cash'))
  }
}

class StarCloudPRNTReceiptPrinter extends AbstractReceiptPrinter {
  // Constants
  deviceType = 'cash:star-cloudprnt'
  deviceName = 'Receipt Printer (Star CloudPRNT)'
  ctaText = 'Receipt Printer (Star CloudPRNT)'
  nameForReceipt = 'CASH'

  async initalizeForChecoutSession (order) {
    // init Adyen here
    return order
  }

  async print (selectedPrinter, order, user) {
    const content = StarCloudPRNTService.generateContentToPrint({ order, user })
    await StarCloudPRNTService.print(selectedPrinter.config.modelNumber, content, 'text/html')
  }
}

export default new StarCloudPRNTReceiptPrinter()
