import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { conforms, pick } from 'lodash';

import BasicModalOverlay from '../../../components/BasicModalOverlay';
import Form from '../../../components/Form';
import sdk from '../../../digitalStoreSdk';
import { selectors as authSelectors } from '../../../store/modules/auth';
import { DatePicker, Input } from '../../../components/Fields';

const FORM_ID = 'visitorConnectionCountForm';

const NewCountForm = Form(FORM_ID);

const formInitialValues = {
  countDate: new Date(),
};

const NewCountModal = ({ onCancel, onSubmit }) => {
  const state = useSelector((state) => state);
  const selectedStore = authSelectors.getSelectedStore(state);
  const [error, setError] = useState(null);
  const [warnings, setWarnings] = useState({
    connectionCount: false,
    visitorCount: false,
  });

  const handleChange = (params) => {
    const counts = pick(params, ['visitorCount', 'connectionCount']);
    if (counts.visitorCount === 0 || counts.connectionCount === 0) {
      setWarnings({
        connectionCount: counts.connectionCount === 0 ? true : false,
        visitorCount: counts.visitorCount === 0 ? true : false,
      });
    } else {
      setWarnings({ connectionCount: false, visitorCount: false });
    }
  };

  const handleSubmit = async () => {
    const {
      values: { countDate, visitorCount, connectionCount },
    } = state.form[FORM_ID];
    setError(null);

    if (!visitorCount || !connectionCount) {
      setWarnings({
        connectionCount: !connectionCount,
        visitorCount: !visitorCount,
      });
      return
    }

    const today = new Date();
    const selectedDate = new Date(countDate);
    if (today.getTime() <= selectedDate.getTime()) {
      setError('Future date cannot be selected');
      return;
    }

    const newCountDate = `${selectedDate.getDate()}/${selectedDate.getMonth() +
      1}/${selectedDate.getFullYear()}`;

    const body = {
      countDate: newCountDate,
      visitorCount: visitorCount || 0,
      connectionCount: connectionCount || 0,
      storeId: selectedStore.id,
    };

    try {
      const newCount = await sdk.storeVisitorConnectionCount.createNewVisitorConnectionCount(
        body
      );
      onSubmit(newCount);
    } catch (error) {
      setError('Error submitting the count, please try again later.');
    }
  };

  return (
    <BasicModalOverlay
      title={`Review count`}
      actions={[
        {
          text: 'Submit',
          success: true,
          primary: true,
          onClick: handleSubmit,
        },
        {
          text: 'Cancel',
          success: true,
          primary: true,
          disabled: false,
          onClick: onCancel,
        },
      ]}
    >
      <NewCountForm
        editing
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        onChange={handleChange}
        formId={FORM_ID}
      >
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <FormControl style={{ marginRight: 20 }}>
            <DatePicker name="countDate" label="Date" returnRawDate />
          </FormControl>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl error={warnings.visitorCount}>
              <Input type="integer" name="visitorCount" label="Visitor Count" />
            </FormControl>
            <FormControl error={warnings.connectionCount}>
              <Input
                type="integer"
                name="connectionCount"
                label="Connection Count"
              />
            </FormControl>
          </div>
        </div>
      </NewCountForm>
      {error && (
        <p style={{ fontSize: 14, color: 'red', marginTop: 30 }}>{error}</p>
      )}
    </BasicModalOverlay>
  );
};

export default memo(NewCountModal);
