import * as validators from '../../../validators'
import { countryPhoneCodes, isUserEmailRequired } from '../../../config'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

export const personal = {
  schema: [{
    id: 'title',
    field: 'Dropdown',
    props: {
      label: 'Title',
      name: 'title',
      options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
      validate: [smallTextLengthValidator],
      required: true
    }
  }, {
    id: 'firstName',
    field: 'Input',
    props: {
      label: 'First Name',
      name: 'firstName',
      validate: [smallTextLengthValidator],
      required: true
    }
  }, {
    id: 'lastName',
    field: 'Input',
    props: {
      label: 'Last Name',
      name: 'lastName',
      validate: [smallTextLengthValidator],
      required: true
    }
  }, {
    id: 'email',
    field: 'Email',
    props: {
      label: 'Email',
      name: 'email',
      validate: [validators.email, smallTextLengthValidator],
      required: isUserEmailRequired !== undefined ? isUserEmailRequired : true
    }
  }, {
    id: 'dob',
    field: 'DatePicker',
    props: {
      label: 'Date Of Birth',
      name: 'dob',
      validate: [validators.date]
    }
  }, {
    id: 'telephone',
    field: 'PhoneNumber',
    props: {
      label: 'Phone Number',
      name: 'telephone',
      options: countryPhoneCodes,
      validate: [telephoneLengthValidator, validators.phoneNumber]
    }
  }, {
    id: 'username',
    field: 'Input',
    props: {
      label: 'Username',
      name: 'username',
      required: true
    }
  }],
  layout: [
    [['title', 'firstName']],
    ['lastName', 'email'],
    ['telephone', 'username']
  ]
}
