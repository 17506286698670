import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Product from './Product'
import { componentConfigs, productDetailsTypes } from '../../../../config'

import currencyFormatter from '../../../../formatters/currencyFormatter'
import { subtractPrices } from '../../../../helpers'

class ProductContainer extends PureComponent {
  _generateProps = () => {
    const { pushToProduct, editing, id, link } = this.props
    const { value, code } = subtractPrices(this.props.price, this.props.discount)
    return {
      ...this.props,
      price: currencyFormatter.format(value, code),
      onProductContainerClick: () => {
        if (!editing) {
          if (componentConfigs.selectProduct.productDetailsType === productDetailsTypes.PDP) {
            // push
            pushToProduct(id)
          } else {
            // go to link
            window.open(link, '_blank')
          }
        }
      }
    }
  }

  render () {
    const props = this._generateProps()
    return <Product {...props} />
  }
}

const mapDispatchToProps = dispatch => ({
  pushToProduct: id => dispatch(push(`/product/${id}`))
})

export default connect(null, mapDispatchToProps)(ProductContainer)
