import _ from 'lodash'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import SelectStoreForm from './SelectStoreForm'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'

const mapStateToProps = state => ({
  stores: authSelectors.getUserStoreRegionOptions(state),
  storeId: authSelectors.getUserSelectedStoreId(state)
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch }) => ({ storeId, regionId, catalogue }) => {
      return dispatch(authActions.selectStore({ storeId, regionId, catalogue }))
    }
  })
)(SelectStoreForm)
