import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../config/theme'

const CardWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  background-color: ${colors.whiteBackground};
  margin: 10px auto;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`

const CardMainInfo = styled.div`
  padding: 10px 14px;
  border-bottom: 2px solid ${colors.lightGrey};
  display: flex;
`

const CardTitle = styled.p`
  margin: 5px 0;
  font-weight: bold;
`

const CardTitles = styled.div`
  display: inline-block;
  flex: 1;
`

const CardInfoText = styled.p`
  margin: 5px 10px 5px 0;
`

const CardType = styled.p`
  display: inline-block;
  text-align: right;
  font-weight: bold;
  flex: 0.8;
  margin-top: 0px;
`

const CardExtraInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
`

const ProductListCard = ({
  id,
  title,
  subTitle,
  type,
  info = [],
  onClick
}) => {
  return (
    <CardWrapper onClick={() => onClick && onClick(id)}>
      <CardMainInfo>
        <CardTitles>
          <CardTitle>{title}</CardTitle>
          <CardInfoText>{subTitle}</CardInfoText>
        </CardTitles>
        { type && <CardType>{type}</CardType> }
      </CardMainInfo>
      <CardExtraInfo>
        {info.map(i => <p style={{ margin: '0 10px 0 0' }}>{i}</p>)}
      </CardExtraInfo>
    </CardWrapper>
  )
}

ProductListCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func
}

export default ProductListCard
