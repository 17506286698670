import React, { useEffect, useMemo } from 'react'
import { translations } from '../../../config'
import ProductListDetailsScreen from '../../../layouts/ProductListDetailsScreen'
// import dataWedgeService from '../../../services/dataWedgeService'
import ProductCard from './ProductCard'

import PropTypes from 'prop-types'

const ReceiveGoodsDetailsScreen = ({
  list,
  user,
  products = [],
  error,
  isLoading,
  isUpdating,
  fetchData,
  match,
  searchQuery = '',
  updateQuery,
  onClickDelete,
  onClickAdd,
  receiveCompleteModal,
  setOrderByOption,
  activeOrderByOption,
  openReceiveProductsModal,
  siteId,
  onReviewedProduct,
  hasReviewPermissions,
  isSearchLoading,
  currentStore,
  rawProducts
}) => {

  useEffect(() => {
    fetchData(match.params.id)
  }, [fetchData])

  const footerButtons = useMemo(() => {
    return [
      {
        onClick: receiveCompleteModal,
        label: translations('Receive goods details - complete button'),
        disabled: list.status === 'checked' || products.some(product => typeof product.receivedQuantity !== 'number')
      }
    ]

    return []
  }, [receiveCompleteModal, products])

  const listCreatedMessage = {
    message: translations('Capacity fill - list created label'),
    icon: 'checkIcon'
  }

  const orderByOptions = [
    { name: 'location', activeIcon: 'locationIcon', inactiveIcon: 'locationGreyIcon' },
    { name: 'scannedTime', activeIcon: 'menuIcon', inactiveIcon: 'menuGreyIcon' }
  ]

  const renderProduct = (product, index) => {
    return (
      <ProductCard
        index={index}
        list={list}
        product={product}
        onClickDelete={onClickDelete}
        activeOrderByOption={activeOrderByOption}
        openReceiveProductsModal={openReceiveProductsModal}
        siteId={siteId}
        onSelect={onReviewedProduct}
        hasReviewPermissions={hasReviewPermissions}
      />
    )
  }

  const listMessage = useMemo(() => {
    if (list.status === 'picking' || list.status === 'filling') {
      return rawProducts.length === 0 ? translations(`Product lists - empty list message`) : translations(`Product lists - list continue compiling message`)
    } else {
      return translations(`Product lists - empty search message`)
    }
  }, [list.status, rawProducts])

  const searchDisabled = (list.status === 'picking' || list.status === 'filling') ? searchQuery.length < 4 : searchQuery.length < 1

  const headerMessage = list.status === 'checked'
    ? { message: 'Shipment checked', icon: 'greenTickIcon' }
    : null

  return (list &&
    <ProductListDetailsScreen
      detailsTitle={translations('Receive goods - title', { number: list.details ? list.details.deliveryNumber : null })}
      detailsSubTitle={translations(`Receive goods - subtitle`, { storeName: currentStore.name })}
      detailsStatus={translations(`Receive Goods - ${list.status} status label`)}
      emptyListMessage={listMessage}
      list={list}
      error={error}
      isLoading={isLoading}
      isSearchLoading={isSearchLoading}
      isUpdating={isUpdating}
      searchQuery={searchQuery}
      // updateQuery={(e) => updateQuery(e)} // hide the search bar for now
      searchInputPlaceholder={translations('Receive goods details - search bar placeholder')}
      footerButtons={footerButtons}
      headerMessage={headerMessage}
      // orderByOptions={orderByOptions}
      // activeOrderByOption={activeOrderByOption}
      // orderByTitle={translations('Product lists - order by')}
      // setOrderByOption={setOrderByOption}
      products={products}
      renderProduct={renderProduct}
      searchDisabled={searchDisabled}
    />
  )
}

ReceiveGoodsDetailsScreen.propTypes = {
  user: PropTypes.object,
  list: PropTypes.object,
  products: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  error: PropTypes.bool,
  updateQuery: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  receiveCompleteModal: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  onReviewedProduct: PropTypes.func.isRequired,
  hasReviewPermissions: PropTypes.bool,
  rawProducts: PropTypes.array.isRequired
}

ReceiveGoodsDetailsScreen.defaultProps = {
  list: {},
  show: false,
  error: false,
  hasReviewPermissions: false
}

export default ReceiveGoodsDetailsScreen
