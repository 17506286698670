import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import RootMenu from './RootMenu'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { selectors as offlineSelectors } from '../../../store/modules/offline'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../store/modules/currentOrder'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { menu, translations } from '../../../config'
import digitalStoreSdk from '../../../digitalStoreSdk'
import modalService from '../../../services/modalService'
import analyticsService from '../../../services/analyticsService'
import offlineOrdersService from '../../../services/offlineOrdersService'
import toastService from '../../../services/toastService'

const mapStateToProps = state => {
  const isOpen = appSelectors.getIsMenuOpen(state)
  const items = menu
  const productsInBasket = currentOrderCombinedSelectors.getCurrentOrderProducts(state)
  const currentOrder = state.currentOrder
  const currentUser = authSelectors.getCurrentUser(state)
  const loggedInStoreId = authSelectors.getUserSelectedStoreId(state)
  const offlineOrderCount = offlineSelectors.getOfflineOrderCount(state)
  const isUserManagementAllowed = authSelectors.getAppsAllowedForRole(state).USER_MANAGEMENT
  const isUserManagementEnabled = !_.isUndefined(_.find(menu, { id: 'USER_MANAGEMENT' }))

  return {
    isOpen,
    items,
    productsInBasket,
    offlineOrderCount,
    currentOrder,
    currentUser,
    loggedInStoreId,
    showMyTeam: isUserManagementAllowed && isUserManagementEnabled
  }
}

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onRequestClose: ({ dispatch }) => () => {
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    onMenuItemClick: ({ dispatch }) => id => {
      const item = menu.find(menuItem => menuItem.id === id)
      if (id === 'USER_MANAGEMENT') {
        analyticsService.sendCustomEvent({ type: 'userAccountsVisit', route: 'burgerMenu' })
      }
      dispatch(item.action)
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    handleLogout: ({ dispatch, productsInBasket, currentOrder, loggedInStoreId }) => () => {
      if (productsInBasket && productsInBasket.length) {
        modalService.action({
          title: translations('Save Basket Title'),
          actions: [
            {
              success: true,
              text: translations('Save Basket Confirm'),
              onClick: () => {
                digitalStoreSdk.users.setCurrentOrder({ currentOrder, loggedInStoreId })
                dispatch(push('/'))
                dispatch(authActions.logout())
              },
              primary: true
            },
            {
              text: translations('Save Basket Cancel'),
              onClick: () => {
                // the clearOrder middleware handles sdk setCurrentOrder
                analyticsService.sendCustomEvent({ type: 'clearBasket' })
                dispatch(currentOrderActions.clearOrder())
                dispatch(push('/'))
                dispatch(authActions.logout())
              }
            }
          ]
        })
      } else {
        dispatch(push('/'))
        dispatch(authActions.logout())
      }
    },
    handleCopyOfflineOrders: ({ }) => () => {
      offlineOrdersService.copyToClipboard()
        .then(total => {
          toastService.action({
            type: 'success',
            message: translations('Successfully coped {{offlineOrderCount}} offline orders to the clipboard', { offlineOrderCount: total }),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        })
        .catch(error => {

          console.log('Failed copying offline orders to clipboard:', { error })
          toastService.action({
            type: 'error',
            message: translations('Failed copying offline orders to clipboard.'),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        })
    },
    handleMyAccountClick: ({ dispatch, currentUser }) => () => {
      dispatch(push(`/users/${currentUser.id}`))
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    },
    handleMyTeamClick: ({ dispatch }) => () => {
      dispatch(push(`/users`))
      dispatch(appActions.toggleSideMenu({ isOpen: false }))
    }
  })
)(RootMenu)
