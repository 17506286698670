import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, push } from 'connected-react-router'

import AccessoryDetailsForm from '../AccessoryDetailsForm'
import BackBar from '../../../components/BackBar'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import H2 from '../../../components/H2'
import LoaderContainer from '../../../components/LoaderContainer'
import SubHeader from '../../../components/SubHeader'

import toastService from '../../../services/toastService/toastService'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as regionsSelectors } from '../../../store/modules/regions'
import { actions as accessoryDetailsActions } from '../../../store/modules/accessoryDetails'
import { actions as cashPaymentDeviceCountDetailsActions, selectors as cashPaymentDeviceCountDetailsSlectors } from '../../../store/modules/cashPaymentDeviceCountDetails'
import { useResults as useAccessoryDetailsResults, useIsFetchPaymentDeviceLoading } from '../../../hooks/accessoryDetailsHooks'
import { translations } from '../../../config'
import modalService from '../../../services/modalService'
import CashFloatModal from '../../../components/CashFloatModal'
import CashReviewCountModal from '../../../components/CashReviewCountModal'
import { getPaymentDeviceType, STAR_CLOUD_PRNT_DEVICE } from '../../../services/paymentDeviceService'
import CountCashModal from '../../../components/CountCashModal'
import { addPrices } from '@redant/digital-store-prices-fiskars/dist/handlePrices'
import starCloudPRNTService from "../../../services/paymentDeviceService/starCloudPRNTService";

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledButton = styled(Button)`
  margin-left: 10px;
`

const AccessoryDetailsScreen = ({ match }) => {
  const dispatch = useDispatch()
  const paymentDeviceId = _.get(match, 'params.id')
  const formMode = paymentDeviceId ? 'edit' : 'create'
  const accessoryDetails = paymentDeviceId ? useAccessoryDetailsResults() : {}
  const isPaymentDeviceLoading = useIsFetchPaymentDeviceLoading()
  const regionId = useSelector(authSelectors.getUserSelectedRegionId)
  const loggedInUserRole = useSelector(authSelectors.getLoggedInUserRole)
  const regions = useSelector(regionsSelectors.getRegions)
  const cashPaymentDeviceCount = useSelector(cashPaymentDeviceCountDetailsSlectors.getCashPaymentDeviceCountDetails)
  const isLoading = paymentDeviceId ? isPaymentDeviceLoading : false
  const title = paymentDeviceId ? _.get(accessoryDetails, 'name') : translations('Add Device')

  const pickAccessoryDetailsValues = (values) => {
    return {
      ..._.pick(values, [
        'name',
        'deviceType',
        'externalId',
        'lastTransaction',
        'enabled',
        'values',
        'connection'
      ]),
      config: {
        modelNumber: values ? values.modelNumber : '',
        serialNumber: values
          ? values.deviceType === STAR_CLOUD_PRNT_DEVICE
            ? values.modelNumber
            : values.serialNumber
          : ''
      }
    }
  }

  const pickInitialValues = (values) => {
    return {
      ...pickAccessoryDetailsValues(values),
      modelNumber: _.has(values, 'config') ? values.config.modelNumber : '',
      serialNumber: _.has(values, 'config') ? values.config.serialNumber : ''
    }
  }

  const initialValues = useMemo(() => ({
    ...pickInitialValues(accessoryDetails)
  }), [accessoryDetails])

  const submitCreate = (values) => {
    const requestBody = {
      ..._.pick(values, ['deviceType', 'externalId', 'name']),
      config: {
        modelNumber: values.modelNumber,
        serialNumber:
          values.deviceType === STAR_CLOUD_PRNT_DEVICE
            ? values.modelNumber
            : values.serialNumber
      }
    }

    return dispatch(accessoryDetailsActions.createPaymentDevice(requestBody))
      .then(() => {
        dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Payment device successfully created'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const onActionsClick = () => {
    const cashOrCard = getPaymentDeviceType(accessoryDetails)

    let modalActions = [
      {
        text: translations(accessoryDetails.enabled ? 'Disable' : 'Enable'),
        onClick: () => submitEdit({ enabled: !accessoryDetails.enabled }, false),
        primary: true,
        fullWidth: true
      }
    ]

    if (cashOrCard === 'cash') {
      modalActions = [
        ...modalActions,
        {
          text: translations('Add float'),
          onClick: () => openFloatModal(),
          primary: true,
          fullWidth: true
        },
        {
          text: translations('Open Drawer'),
          onClick: () => openDrawer(),
          primary: true,
          fullWidth: true
        },
        {
          text: translations('Remove float'),
          onClick: () => openFloatModal(true),
          primary: true,
          fullWidth: true
        },
        {
          text: translations('Count cash'),
          onClick: () => openCountCashModal(),
          primary: true,
          fullWidth: true
        }
      ]

      const isManager = loggedInUserRole.value < 4

      if (isManager) {
        modalActions = [...modalActions, {
          text: translations('Review count'),
          onClick: () => openReviewCountModal(),
          primary: true,
          fullWidth: true
        }]
      }
    }

    modalService.action({
      title: 'Actions',
      buttonDirection: 'column',
      actions: modalActions
    })
  }

  const openReviewCountModal = () => {
    modalService.close()
    return modalService.open({
      component: CashReviewCountModal,
      wideModal: true,
      onSubmit: ({ userId }) => {
        updateCashCount({
          id: cashPaymentDeviceCount.id,
          signedOffById: userId
        })
        modalService.close()
      },
      cashPaymentDeviceCount: cashPaymentDeviceCount,
      name: _.get(accessoryDetails, 'name', null),
      onRecount: () => openCountCashModal()
    })
  }

  const openFloatModal = (removeFloat) => {
    modalService.close()
    return modalService.open({
      component: CashFloatModal,
      wideModal: true,
      onSubmit: (param) => {
        submitEdit({ values: { float: param.totalFloat, floatComment: param.floatComment } }, false)
        modalService.close()
      },
      removeFloat,
      currentFloat: _.get(accessoryDetails, 'values.float', null)
    })
  }

  const openCountCashModal = () => {
    modalService.close()

    const currentFloat = _.get(accessoryDetails, 'values.float', {})
    const expectedAmount = addPrices(
      _.get(accessoryDetails, 'values.float', {}),
      _.get(accessoryDetails, 'values.totalValue', {})
    )

    const countCashConfig = _.chain(regions)
      .find(region => region.id === regionId)
      .get('details.countCashConfig', {})
      .value()

    return modalService.open({
      component: CountCashModal,
      wideModal: true,
      onSubmit: (params) => {
        const cashPaymentDeviceCount = {
          paymentDeviceId: _.get(accessoryDetails, 'id'),
          countedValue: { code: params.total.code, value: params.total.value },
          expectedCount: { code: expectedAmount.code, value: expectedAmount.value }
        }

        submitCashCount(cashPaymentDeviceCount)
        modalService.close()
      },
      currentFloat: currentFloat,
      expectedAmount: expectedAmount,
      countCashConfig: countCashConfig
    })
  }

  const submitEdit = (values, shouldGoBack = true) => {
    const detailsValues = pickAccessoryDetailsValues(values)

    if (!detailsValues.values) {
      detailsValues.values = {}
    }

    return dispatch(accessoryDetailsActions.updatePaymentDevice({ id: paymentDeviceId, fields: detailsValues }))
      .then(() => {
        dispatch(cashPaymentDeviceCountDetailsActions.getLatestCashCount({ paymentDeviceId }))
        shouldGoBack && dispatch(goBack())
        toastService.action({
          type: 'success',
          message: translations('Payment device successfully updated'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const submitCashCount = (values) => {
    return dispatch(cashPaymentDeviceCountDetailsActions.create(values))
      .then(() => {
        dispatch(cashPaymentDeviceCountDetailsActions.getLatestCashCount({ paymentDeviceId }))
        toastService.action({
          type: 'success',
          message: translations('Successfullly submitted cash count.'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const updateCashCount = (values) => {
    return dispatch(cashPaymentDeviceCountDetailsActions.patch(values))
      .then(() => {
        dispatch(cashPaymentDeviceCountDetailsActions.getLatestCashCount({ paymentDeviceId }))
        toastService.action({
          type: 'success',
          message: translations('Successfullly updated cash count.'),
          verticalPosition: 'top',
          horizontalPosition: 'right'
        })
      })
  }

  const openDrawer = () => {
    starCloudPRNTService.openDrawer(_.get(accessoryDetails, 'config.serialNumber'))
  }

  useEffect(() => {
    if (paymentDeviceId) {
      dispatch(accessoryDetailsActions.fetchPaymentDevice({ id: paymentDeviceId }))
      dispatch(cashPaymentDeviceCountDetailsActions.getLatestCashCount({ paymentDeviceId }))

      return () => {
        dispatch(accessoryDetailsActions.clear())
      }
    }
  }, [paymentDeviceId])

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <H2 value={_.toUpper(title)} />
        )}
      />
      <Container>
        <LoaderContainer isLoading={isLoading}>
          <SubHeaderContainer>
            <p>{translations('required fields')}</p>
            {paymentDeviceId && (
              <div>
                <StyledButton onClick={() => dispatch(push(`/accessories/${paymentDeviceId}/history`))}
                  color='primary'>
                  Device History
                </StyledButton>
                <StyledButton onClick={() => onActionsClick()}
                  color='primary'>
                  Actions
                </StyledButton>
              </div>
            )}
          </SubHeaderContainer>
          <AccessoryDetailsForm
            initialValues={initialValues}
            onSubmit={paymentDeviceId ? submitEdit : submitCreate}
            mode={formMode}
          />
        </LoaderContainer>
      </Container>
    </div>
  )
}

export default AccessoryDetailsScreen
