import * as validators from '../../../validators'

export default ({ calendars = [] } = {}) => {
  return {
    schema: [{
      id: 'calendarIds',
      field: 'Dropdown',
      props: {
        label: 'Calendars',
        name: 'calendarIds',
        options: [calendars],
        multiple: true
      }
    }],
    layout: [
      ['calendarIds'],
    ]
  }
}
