import { connect } from 'react-redux'
import { withHandlers, compose, withState } from 'recompose'
import _ from 'lodash'

import BasketButtons from './BasketButtons'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { actions as appActions, selectors as appSelectors } from '../../../store/modules/app'
import { actions as currentOrderActions, selectors as currentOrderSelectors, constants as currentOrderConstants } from '../../../store/modules/currentOrder'
import { actions as currentAppointmentActions, selectors as currentAppointmentSelectors } from '../../../store/modules/currentAppointment'
import { selectors as checkoutFlowSelectors, actions as checkoutFlowActions } from '../../../store/modules/checkoutFlow'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { actions as customerDetailsActions } from '../../../store/modules/customerDetails'
import { validateRefundReason } from '../../../validators'
import * as currentOrderCombinedSelectors from '../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import analyticsService from '../../../services/analyticsService'

const mapStateToProps = state => ({ state })

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const order = currentOrderCombinedSelectors.currentOrderSelector(state)
  const isOpen = appSelectors.getIsBasketOpen(state)
  const canSendEmailBasket = !order.numberOfProducts || currentOrderSelectors.getCurrentOrderEditMode(state)
  const isEmpty = currentOrderSelectors.getCurrentOrderProductTotal(state) === 0
  const isCheckoutLoading = checkoutFlowSelectors.getIsLoading(state)
  const editType = currentOrderSelectors.getEditType(state)
  const exchangeMode = editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
  const isConsultationMode = !!currentAppointmentSelectors.getAppointmentCustomerId(state)
  const isOffline = !networkSelectors.isConnected(state)
  const canCheckout = validateRefundReason(state.currentOrder.products)

  const lovedProducts = _.get(order, 'products', []).filter((product) => product.isLoved)
  const customer = _.get(order, 'customer', {})
  const currentWishlist = _.get(customer, 'details.wishlist', [])

  const addLovedToCustomerWishlist = () => {
    if (!customer) return
    const wishlistProductsToAdd = []
    for (const product of lovedProducts) {
      if (!currentWishlist.find(wishlistProduct => wishlistProduct.id === product.id)) {
        wishlistProductsToAdd.push({
          id: product.id,
          brand: product.brand,
          images: product.images,
          link: product.link,
          name: product.name,
          price: product.price,
          type: 'product'
        })
      }
    }
    if (wishlistProductsToAdd.length > 0) {
      const wishlistUpdated = wishlistProductsToAdd.concat(currentWishlist)

      dispatch(customerDetailsActions.fetchFullCustomer(customer.id)).then(() => {
        return dispatch(customerDetailsActions.updateCustomerWishlist({
          id: customer.id,
          wishlist: wishlistUpdated
        }))
      }).catch(e => {
        console.log(e)
      })
    }
  }

  return {
    ...ownProps,
    isOpen,
    isEmpty,
    canSendEmailBasket,
    isCheckoutLoading,
    exchangeMode,
    isConsultationMode,
    isOffline,
    canCheckout,
    ..._.pick(order, [
      'editMode',
      'groupedProducts',
      'subTotal',
      'numberOfPurchaseableProducts',
      'orderNumber',
      'storeroomOrderId',
      'customer'
    ]),
    appointmentAnalyticsEvent: () => {
      dispatch(currentAppointmentActions.appointmentAnalyticsEvent())
    },
    onClose: () => {
      dispatch(appActions.toggleBasket({ isOpen: false }))
    },
    dispatch,
    checkout: () => {
      analyticsService.sendCustomEvent({ type: 'startCheckout' })
      addLovedToCustomerWishlist()
      dispatch(appActions.toggleBasket({ isOpen: false }))
      dispatch(checkoutFlowActions.start({ checkoutType: 'checkout' }))
    },
    consultation: () => {
      analyticsService.sendCustomEvent({ type: 'startCheckout' })
      addLovedToCustomerWishlist()
      dispatch(appActions.toggleBasket({ isOpen: false }))
      dispatch(checkoutFlowActions.start({ checkoutType: 'consultation' }))
    },
    referral: () => {
      analyticsService.sendCustomEvent({ type: 'startReferral' })
      dispatch(appActions.toggleBasket({ isOpen: false }))
      dispatch(checkoutFlowActions.start({ checkoutType: 'referral' }))
    },
    storeroomRequest: () => {
      analyticsService.sendCustomEvent({ type: 'startStoreroomRequest' })
      dispatch(appActions.toggleBasket({ isOpen: false }))
      dispatch(checkoutFlowActions.start({ checkoutType: 'storeroom' }))
    },
    stopEditingOrder: () => {
      dispatch(currentOrderActions.stopEditingOrder())
    },
    saveToProfile: () => {
      addLovedToCustomerWishlist()
      dispatch(currentOrderActions.saveToProfile())
        .then(() => {
          modalService.action({
            title: translations('Consultation Saved'),
            text: translations('Your consultation has been saved to the customer profile'),
            actions: [
              {
                success: true,
                text: translations('OK'),
                onClick: () => {
                  dispatch(currentOrderActions.clearOrder({ clearAppointment: true }))
                },
                primary: true
              }
            ]
          })
        })
        .catch(e => {
          console.error(e)
          modalService.action({
            title: translations('Error'),
            text: translations('Failed saving consultation.'),
            actions: [
              {
                success: true,
                text: translations('OK'),
                primary: true,
                onClick: () => null
              }
            ]
          })
        })
    }
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  withState('tabValue', 'changeTabValue', 'basket'),
  withHandlers({
    clearOrder: ({ dispatch }) => () => {
      analyticsService.sendCustomEvent({ type: 'clearBasket' })
      dispatch(currentOrderActions.clearOrder({ 
        doNotRemoveEditMode: true, 
        showToast: true, 
        clearAppointment: false 
      }))
    },
    handleTabChange: ({ changeTabValue }) => (event, value) => {
      return changeTabValue(value)
    }
  })
)(BasketButtons)
