import uuid from 'uuid/v4'
import _ from 'lodash'
import moment from 'moment'
import { customAlphabet } from 'nanoid'
import { amendOrder, round } from '@redant/digital-store-prices-fiskars'

import offlineOrdersService from '../../services/offlineOrdersService'
import digitalStoreSdk from '../../digitalStoreSdk'
import { constants as currentOrderConstants } from '../../store/modules/currentOrder'

import { dateFormat, apiUpdateSource } from '../../config'
import { addPrices } from '@redant/digital-store-prices-fiskars/dist/handlePrices'

const ALLOWED_CHARS =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
const ORDER_NUMBER_LENGTH = 16

const nanoid = customAlphabet(ALLOWED_CHARS, ORDER_NUMBER_LENGTH)

export const generateOrderNumber = () => nanoid()

const uploadOrder = async ({ params, isOnline = true }) => {
  params = amendOrder(params)

  const products = _.chain(params)
    .get('products', [])
    .map((product) => ({
      ..._.pick(product, [
        'brand',
        'category',
        'categoryId',
        'discount',
        'externalProductId',
        'vatPercent',
        'images',
        'link',
        'manualDiscount',
        'name',
        'preview',
        'price',
        'subTotal',
        'total',
        'tax',
        'unsold',
        'service',
        'clientId',
        'refund',
        'hasBeenRefunded',
        'returnReasonCode',
        'defectCodeCategory',
        'defectCode',
        'isInSalableCondition',
        'lineItemNotes',
        'serviceForProductId',
        'promotions',
        'isPromotionExtraItem',
        'details'
      ]),
      id: uuid(),
      productId: product.id,
      variant: _.pick(product.variant, ['name', 'ean', 'details', 'id'])
    }))
    .value()

  const status = params.status || 'pending'

  const salesChannel = 'Store'

  let orderDateISOString = moment(params.orderDate, dateFormat).toISOString(true)
  // if orderDate is today, set it to the exact time of right NOW
  if (moment(orderDateISOString).isSame(moment(), 'day')) {
    orderDateISOString = moment().toISOString(true)
  } else if (!params.orderDate) {
    orderDateISOString = moment().toISOString(true) // default to now
  }
  // anon
  if (!_.get(params, 'customerId')) {
    params = {
      ...params,
      deliveryType: null,
      deliveryOption: null,
      deliveryDetails: null,
      deliveryAddress: null,
      deliveryPrice: null
    }
  }

  const details = { ...params.details }
  if (params.storeroomOrderId) { details.storeroomOrderId = params.storeroomOrderId }

  const uploadParams = {
    ..._.pick(params, [
      'customerId',
      'deliveryAddress',
      'deliveryDetails',
      'deliveryOption',
      'paymentDeviceId',
      'deliveryType',
      'id',
      'orderDate',
      'orderType',
      'paymentToken',
      'storeId',
      'subTotal',
      'tax',
      'total',
      'totalDiscount',
      'userId'
    ]),
    products,
    status,
    salesChannel,
    orderDate: orderDateISOString,
    details,
    subTotal: addPrices(params.subTotal, params.totalDiscount)
  }

  if (params.editMode) {
    const editOrder =
      params.editType === currentOrderConstants.EDIT_ORDER_TYPES.EXCHANGE
        ? digitalStoreSdk.orders.exchangeOrder
        : digitalStoreSdk.orders.editOrder
    return editOrder({
      ...uploadParams,
      orderNumber: params.orderNumber,
      updateSource: apiUpdateSource
    }).then((res) =>
      digitalStoreSdk.orders.fetchOrder({
        id: res.orderNumber,
        includes: ['customer', 'user']
      })
    )
  }

  const order = {
    ...uploadParams,
    tax: {
      ...uploadParams.tax,
      value: round(uploadParams.tax.value, 5)
    },
    subTotal: {
      ...uploadParams.subTotal,
      value: round(uploadParams.subTotal.value)
    },
    total: {
      ...uploadParams.total,
      value: round(uploadParams.total.value)
    },
    totalDiscount: {
      ...uploadParams.totalDiscount,
      value: round(uploadParams.totalDiscount.value)
    },
    orderNumber: params.orderNumber,
    updateSource: apiUpdateSource
  }

  if (!isOnline) {
    const offlineOrder = {
      ...order,
      id: order.id ? order.id : uuid()
    }

    await offlineOrdersService.create({ order: offlineOrder })
    return offlineOrder
  }

  return digitalStoreSdk.orders.createOrder(order)
}

export default uploadOrder
