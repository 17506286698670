import { makeRequest } from '../makeRequest'
import { mapTransactionStatusRequest } from './mapRequest'
import { mapTransactionStatusResponse } from './mapResponse'


export async function transactionStatusRequest(paymentDevice, serviceID, saleID) {
  const mappedRequest = mapTransactionStatusRequest(
    paymentDevice, serviceID, saleID
  )
  const response = await makeRequest(mappedRequest)

  // Parse the Adyen SDK response
  const mappedResponse = mapTransactionStatusResponse(response)

  return {
    success: mappedResponse.success,
    message: mappedResponse.message,
    error: mappedResponse.error,
    data: mappedResponse.data
  }
}
