import { mapConnectedTerminalsResponse } from './mapResponse'
import sdk from '../../../../../digitalStoreSdk'

export async function connectedTerminalsRequest() {
  const response = await sdk.proxyEndpoints.adyenGetConnectedTerminals()
  // Parse the Adyen SDK response
  let mappedResponse = mapConnectedTerminalsResponse(response)

  return {
    success: mappedResponse.success,
    message: mappedResponse.message,
    error: mappedResponse.error,
    data: mappedResponse.data
  }
}
