import arabia from './arabia'
import littala from './littala'
import rorstrand from './rorstrand'
import royalCopenhagen from './royalCopenhagen'
import outletWedgwoodIIttala from './outletWedgwoodIIttala'
import outletRorstrandFiskarsIIttala from './outletRorstrandFiskarsIIttala'
import iadc from './iadc'

export const storeLogos = [
  {
    value: 'ARABIA',
    label: 'Arabia',
    imageAsBase64: arabia
  },
  {
    value: 'LITTALA',
    label: 'Littala',
    imageAsBase64: littala
  },
  {
    value: 'IADC',
    label: 'Iittala & Arabia Design Centre (IADC)',
    imageAsBase64: iadc
  },
  {
    value: 'RORSTRAND',
    label: 'Rorstrand',
    imageAsBase64: rorstrand
  },
  {
    value: 'ROYAL_COPENHAGEN',
    label: 'Royal Copenhagen',
    imageAsBase64: royalCopenhagen
  },
  {
    value: 'OUTLET_WEDGWOOD_IITTALA',
    label: 'Outlet Wedgwood IIttala',
    imageAsBase64: outletWedgwoodIIttala
  },
  {
    value: 'OUTLET_RORSTRAND_FISKARS_IITTALA',
    label: 'Outlet Rorstrand Fiskars IIttala',
    imageAsBase64: outletRorstrandFiskarsIIttala
  }
]
