import _ from 'lodash'
import countriesConfig from './configFiles/countries'
import { sortWithPriority } from '../helpers'

const { allCountries, prioritized } = countriesConfig

const orderedNumByNameList = sortWithPriority(allCountries, prioritized, 'name')
const orderedNumByCodeList = sortWithPriority(allCountries, prioritized, 'code')

export const countryPhoneCodes = _.chain(orderedNumByCodeList)
  .map(country => `${country.code} ${country.dialCode}`)
  .value()
export const countryPhoneDictionary = orderedNumByNameList.reduce((memo, next) => ({ ...memo, [next.dialCode]: next }), {})
// export const countries = orderedNumByNameList.map(country => country.name)

// Fiskars CR
export const countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Cote d\'Ivoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Cura&ccedil;ao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'INT', label: 'International' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao People\'s Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PS', label: 'Palestine' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barth&eacute;lemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
].map(obj => obj.label)

// Fiskars CR
export const stateOptions = [
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Northern Territory', value: 'NT' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Tocantins', value: 'TO' },
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon Territories', value: 'YT' },
  { label: 'Beijing', value: '11' },
  { label: 'Tianjin', value: '12' },
  { label: 'Hebei', value: '13' },
  { label: 'Shanxi', value: '14' },
  { label: 'Nei Mongol', value: '15' },
  { label: 'Liaoning', value: '21' },
  { label: 'Jilin', value: '22' },
  { label: 'Heilongjiang', value: '23' },
  { label: 'Shanghai', value: '31' },
  { label: 'Jiangsu', value: '32' },
  { label: 'Zhejiang', value: '33' },
  { label: 'Anhui', value: '34' },
  { label: 'Fujian', value: '35' },
  { label: 'Jiangxi', value: '36' },
  { label: 'Shandong', value: '37' },
  { label: 'Henan', value: '41' },
  { label: 'Hubei', value: '42' },
  { label: 'Hunan', value: '43' },
  { label: 'Guangdong', value: '44' },
  { label: 'Guangxi', value: '45' },
  { label: 'Hainan', value: '46' },
  { label: 'Chongqing', value: '50' },
  { label: 'Sichuan', value: '51' },
  { label: 'Guizhou', value: '52' },
  { label: 'Yunnan', value: '53' },
  { label: 'Xizang', value: '54' },
  { label: 'Shaanxi', value: '61' },
  { label: 'Gansu', value: '62' },
  { label: 'Qinghai', value: '63' },
  { label: 'Ningxia', value: '64' },
  { label: 'Xinjiang', value: '65' },
  { label: 'Taiwan', value: '71' },
  { label: 'Hong Kong', value: '91' },
  { label: 'Macao', value: '92' },
  { label: 'Clare', value: 'CE' },
  { label: 'Cavan', value: 'CN' },
  { label: 'Cork', value: 'CO' },
  { label: 'Carlow', value: 'CW' },
  { label: 'Dublin', value: 'D' },
  { label: 'Donegal', value: 'DL' },
  { label: 'Galway', value: 'G' },
  { label: 'Kildare', value: 'KE' },
  { label: 'Kilkenny', value: 'KK' },
  { label: 'Kerry', value: 'KY' },
  { label: 'Longford', value: 'LD' },
  { label: 'Louth', value: 'LH' },
  { label: 'Limerick', value: 'LK' },
  { label: 'Leitrim', value: 'LM' },
  { label: 'Laois', value: 'LS' },
  { label: 'Meath', value: 'MH' },
  { label: 'Monaghan', value: 'MN' },
  { label: 'Mayo', value: 'MO' },
  { label: 'Offaly', value: 'OY' },
  { label: 'Roscommon', value: 'RN' },
  { label: 'Sligo', value: 'SO' },
  { label: 'Tipperary', value: 'TA' },
  { label: 'Waterford', value: 'WD' },
  { label: 'Westmeath', value: 'WH' },
  { label: 'Wicklow', value: 'WW' },
  { label: 'Wexford', value: 'WX' },
  { label: 'Andaman and Nicobar Islands', value: 'AN' },
  { label: 'Andhra Pradesh', value: 'AP' },
  { label: 'Arunachal Pradesh', value: 'AR' },
  { label: 'Assam', value: 'AS' },
  { label: 'Bihar', value: 'BR' },
  { label: 'Chandigarh', value: 'CH' },
  { label: 'Chhattisgarh', value: 'CT' },
  { label: 'Daman and Diu', value: 'DD' },
  { label: 'Delhi', value: 'DL' },
  { label: 'Dadra and Nagar Haveli', value: 'DN' },
  { label: 'Goa', value: 'GA' },
  { label: 'Gujarat', value: 'GJ' },
  { label: 'Himachal Pradesh', value: 'HP' },
  { label: 'Haryana', value: 'HR' },
  { label: 'Jharkhand', value: 'JH' },
  { label: 'Jammu and Kashmir', value: 'JK' },
  { label: 'Karnataka', value: 'KA' },
  { label: 'Kerala', value: 'KL' },
  { label: 'Lakshadweep', value: 'LD' },
  { label: 'Maharashtra', value: 'MH' },
  { label: 'Meghalaya', value: 'ML' },
  { label: 'Manipur', value: 'MN' },
  { label: 'Madhya Pradesh', value: 'MP' },
  { label: 'Mizoram', value: 'MZ' },
  { label: 'Nagaland', value: 'NL' },
  { label: 'Odisha', value: 'OR' },
  { label: 'Punjab', value: 'PB' },
  { label: 'Puducherry', value: 'PY' },
  { label: 'Rajasthan', value: 'RJ' },
  { label: 'Sikkim', value: 'SK' },
  { label: 'Tamil Nadu', value: 'TN' },
  { label: 'Tripura', value: 'TR' },
  { label: 'Uttar Pradesh', value: 'UP' },
  { label: 'Uttarakhand', value: 'UT' },
  { label: 'West Bengal', value: 'WB' },
  { label: 'Agrigento', value: 'AG' },
  { label: 'Alessandria', value: 'AL' },
  { label: 'Ancona', value: 'AN' },
  { label: 'Aosta', value: 'AO' },
  { label: 'Ascoli Piceno', value: 'AP' },
  { label: 'L\'Aquila', value: 'AQ' },
  { label: 'Arezzo', value: 'AR' },
  { label: 'Asti', value: 'AT' },
  { label: 'Avellino', value: 'AV' },
  { label: 'Bari', value: 'BA' },
  { label: 'Bergamo', value: 'BG' },
  { label: 'Biella', value: 'BI' },
  { label: 'Belluno', value: 'BL' },
  { label: 'Benevento', value: 'BN' },
  { label: 'Bologna', value: 'BO' },
  { label: 'Brindisi', value: 'BR' },
  { label: 'Brescia', value: 'BS' },
  { label: 'Barletta-Andria-Trani', value: 'BT' },
  { label: 'Bolzano', value: 'BZ' },
  { label: 'Cagliari', value: 'CA' },
  { label: 'Campobasso', value: 'CB' },
  { label: 'Caserta', value: 'CE' },
  { label: 'Chieti', value: 'CH' },
  { label: 'Carbonia-Iglesias', value: 'CI' },
  { label: 'Caltanissetta', value: 'CL' },
  { label: 'Cuneo', value: 'CN' },
  { label: 'Como', value: 'CO' },
  { label: 'Cremona', value: 'CR' },
  { label: 'Cosenza', value: 'CS' },
  { label: 'Catania', value: 'CT' },
  { label: 'Catanzaro', value: 'CZ' },
  { label: 'Enna', value: 'EN' },
  { label: 'Forlì-Cesena', value: 'FC' },
  { label: 'Ferrara', value: 'FE' },
  { label: 'Foggia', value: 'FG' },
  { label: 'Florence', value: 'FI' },
  { label: 'Fermo', value: 'FM' },
  { label: 'Frosinone', value: 'FR' },
  { label: 'Genoa', value: 'GE' },
  { label: 'Gorizia', value: 'GO' },
  { label: 'Grosseto', value: 'GR' },
  { label: 'Imperia', value: 'IM' },
  { label: 'Isernia', value: 'IS' },
  { label: 'Crotone', value: 'KR' },
  { label: 'Lecco', value: 'LC' },
  { label: 'Lecce', value: 'LE' },
  { label: 'Livorno', value: 'LI' },
  { label: 'Lodi', value: 'LO' },
  { label: 'Latina', value: 'LT' },
  { label: 'Lucca', value: 'LU' },
  { label: 'Monza and Brianza', value: 'MB' },
  { label: 'Macerata', value: 'MC' },
  { label: 'Messina', value: 'ME' },
  { label: 'Milan', value: 'MI' },
  { label: 'Mantua', value: 'MN' },
  { label: 'Modena', value: 'MO' },
  { label: 'Massa and Carrara', value: 'MS' },
  { label: 'Matera', value: 'MT' },
  { label: 'Naples', value: 'NA' },
  { label: 'Novara', value: 'NO' },
  { label: 'Nuoro', value: 'NU' },
  { label: 'Ogliastra', value: 'OG' },
  { label: 'Oristano', value: 'OR' },
  { label: 'Olbia-Tempio', value: 'OT' },
  { label: 'Palermo', value: 'PA' },
  { label: 'Piacenza', value: 'PC' },
  { label: 'Padua', value: 'PD' },
  { label: 'Pescara', value: 'PE' },
  { label: 'Perugia', value: 'PG' },
  { label: 'Pisa', value: 'PI' },
  { label: 'Pordenone', value: 'PN' },
  { label: 'Prato', value: 'PO' },
  { label: 'Parma', value: 'PR' },
  { label: 'Pistoia', value: 'PT' },
  { label: 'Pesaro and Urbino', value: 'PU' },
  { label: 'Pavia', value: 'PV' },
  { label: 'Potenza', value: 'PZ' },
  { label: 'Ravenna', value: 'RA' },
  { label: 'Reggio Calabria', value: 'RC' },
  { label: 'Reggio Emilia', value: 'RE' },
  { label: 'Ragusa', value: 'RG' },
  { label: 'Rieti', value: 'RI' },
  { label: 'Rome', value: 'RM' },
  { label: 'Rimini', value: 'RN' },
  { label: 'Rovigo', value: 'RO' },
  { label: 'Salerno', value: 'SA' },
  { label: 'Siena', value: 'SI' },
  { label: 'Sondrio', value: 'SO' },
  { label: 'La Spezia', value: 'SP' },
  { label: 'Syracuse', value: 'SR' },
  { label: 'Sassari', value: 'SS' },
  { label: 'Savona', value: 'SV' },
  { label: 'Taranto', value: 'TA' },
  { label: 'Teramo', value: 'TE' },
  { label: 'Trento', value: 'TN' },
  { label: 'Turin', value: 'TO' },
  { label: 'Trapani', value: 'TP' },
  { label: 'Terni', value: 'TR' },
  { label: 'Trieste', value: 'TS' },
  { label: 'Treviso', value: 'TV' },
  { label: 'Udine', value: 'UD' },
  { label: 'Varese', value: 'VA' },
  { label: 'Verbano-Cusio-Ossola', value: 'VB' },
  { label: 'Vercelli', value: 'VC' },
  { label: 'Venice', value: 'VE' },
  { label: 'Vicenza', value: 'VI' },
  { label: 'Verona', value: 'VR' },
  { label: 'Medio Campidano', value: 'VS' },
  { label: 'Viterbo', value: 'VT' },
  { label: 'Vibo Valentia', value: 'VV' },
  { label: 'Aguascalientes', value: 'AG' },
  { label: 'Baja California', value: 'BC' },
  { label: 'Baja California Sur', value: 'BS' },
  { label: 'Chihuahua', value: 'CH' },
  { label: 'Colima', value: 'CL' },
  { label: 'Campeche', value: 'CM' },
  { label: 'Coahuila', value: 'CO' },
  { label: 'Chiapas', value: 'CS' },
  { label: 'Federal District', value: 'DF' },
  { label: 'Durango', value: 'DG' },
  { label: 'Guerrero', value: 'GR' },
  { label: 'Guanajuato', value: 'GT' },
  { label: 'Hidalgo', value: 'HG' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'Mexico State', value: 'ME' },
  { label: 'Michoacán', value: 'MI' },
  { label: 'Morelos', value: 'MO' },
  { label: 'Nayarit', value: 'NA' },
  { label: 'Nuevo León', value: 'NL' },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PB' },
  { label: 'Querétaro', value: 'QE' },
  { label: 'Quintana Roo', value: 'QR' },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'San Luis Potosí', value: 'SL' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Tamaulipas', value: 'TM' },
  { label: 'Veracruz', value: 'VE' },
  { label: 'Yucatán', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
  { label: 'Armed Forces Americas', value: 'AA' },
  { label: 'Armed Forces Europe', value: 'AE' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Armed Forces Pacific', value: 'AP' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Federated Micronesia', value: 'FM' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Montana', value: 'MT' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New York', value: 'NY' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Utah', value: 'UT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'US Virgin Islands', value: 'VI' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wyoming', value: 'WY' },
].map(obj => obj.label)
