import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { translations } from '../../../config'
import ProductListScreen from '../../../layouts/ProductListScreen'

const ReceiveGoods = ({
  config,
  lists,
  isLoading,
  error,
  getProductLists,
  getList,
  setMobileSelectedColumn,
  mobileSelectedColumn
}) => {
  useEffect(() => {
    getProductLists()
  }, [getProductLists])

  return (
    <ProductListScreen
      moduleConfig={config}
      lists={lists}
      isLoading={isLoading}
      error={error}
      getList={getList}
      getProductLists={getProductLists}
      setMobileSelectedColumn={setMobileSelectedColumn}
      mobileSelectedColumn={mobileSelectedColumn}
      // footerButtons={[
      //   {
      //     label: translations('Capacity fill - start picking button'),
      //     onClick: () => createCapacityFillList()
      //   }
      // ]}
    />
  )
}

ReceiveGoods.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getProductLists: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  createCapacityFillList: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  mobileSelectedColumn: PropTypes.string.isRequired,
  setMobileSelectedColumn: PropTypes.func.isRequired
}

ReceiveGoods.defaultProps = {
  isLoading: false,
  error: false
}

export default ReceiveGoods
