import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_PAYMENT_DEVICE:
      return reducers.fetchPaymentDevice(state, action)
    case constants.CREATE_PAYMENT_DEVICE:
      return reducers.createPaymentDevice(state, action)
    case constants.UPDATE_PAYMENT_DEVICE:
      return reducers.updateOne(state, action)
    default:
      return state
  }
}
