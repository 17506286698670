import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ReceiveGoodsDetailsScreen from './ReceiveGoodsDetailsScreen'
import ReceiveProducts from '../Modals/ReceiveProducts'
import {
  fetchProductsList,
  updateProductList,
  setOrderBy,
  setSearchQuery
} from '../../../store/modules/productListDetails/actions'
import * as productListDetailsSelectors from '../../../store/modules/productListDetails/selectors'
import { selectors as authSelectors } from '../../../store/modules/auth'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'

const mapStateToProps = (state) => {
  const list = productListDetailsSelectors.getProductList(state)
  const currentStore = authSelectors.getSelectedStore(state)
  return {
    list: list,
    id: list
      ? list.id
      : '',
    products: productListDetailsSelectors.getProducts(state),
    currentStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (id) => {
      dispatch(fetchProductsList({ id }))
    },
    updateList: (params) => {
      dispatch(updateProductList(params))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    updateQuery: ({ dispatch }) => (query) => {
      dispatch(setSearchQuery(query))
    },
    onClickDelete: ({ id, rawProducts, updateList, dispatch }) => (productId) => {
      const newList = rawProducts
      rawProducts.forEach((product, index) => {
        if (product.id === productId) newList.splice(index, 1)
      })
      updateList({ id, products: newList })
    },
    onReviewedProduct: ({ rawProducts, dispatch, updateList, id }) => (selectedProduct, index) => {
      const newList = rawProducts.map((product, i) => {
        if (product.id === selectedProduct.id && i === index) {
          return {
            ...product,
            reviewed: !product.reviewed
          }
        }
        return product
      })
      updateList({ id, products: newList })
    },
    receiveCompleteModal: ({ id, updateList }) => () => {
      modalService.action({
        title: translations('Receive goods details - complete modal title'),
        text: translations('Receive goods details - complete modal description'),
        actions: [
          { text: translations('Cancel'), onClick: () => null, success: false },
          { success: true, text: translations('Submit'), primary: true, onClick: () => updateList({ id, status: 'checked' }) }
        ]
      })
    },
    setOrderByOption: ({ dispatch }) => (option) => {
      dispatch(setOrderBy(option))
    },
    openReceiveProductsModal: () => ({ product, list, index }) => {
      return modalService.open({
        product,
        index,
        location,
        component: ReceiveProducts,
        status: list.status,
        modalIndex: 1
      })
    }
  })
)(ReceiveGoodsDetailsScreen)
