import { SUCCESS, FAIL, PENDING } from '../../middleware/redux-promise'

class PromotionReducers {
  fetchPromotionsAndPromotionConditions = (state, action) => {
    switch (action.status) {
      case SUCCESS:
        return {
          ...state,
          isLoading: false,
          promotions: action.result,
          error: null
        }
      case PENDING:
        return {
          ...state,
          isLoading: true,
          promotions: null
        }
      case FAIL:
        return {
          ...state,
          promotions: null,
          isLoading: false,
          error: action.error
        }
      default:
        return state
    }
  }

  setRemovePromotionFromOrder = (state, action) => {
    return {
      ...state,
      removePromotionFromOrder: action.removePromotionFromOrder
    }
  }

  setHasPromoCode = (state, action) => {
    return {
      ...state,
      hasPromoCode: action.hasPromoCode
    }
  }

  setPromoCode = (state, action) => {
    return {
      ...state,
      promoCode: action.promoCode
    }
  }

  setHasValidPromoCode = (state, action) => {
    return {
      ...state,
      hasValidPromoCode: action.hasValidPromoCode
    }
  }

  resetPromotionState = (state) => {
    return {
      ...state,
      promoCode: '',
      hasPromoCode: false,
      promoCodeError: null,
      removePromotionFromOrder: false,
    }
  }
}

export default new PromotionReducers()
