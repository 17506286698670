import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ReceiveProducts from './ReceiveProducts'
import {
  actions as productListDetailsActions,
  selectors as productListDetailsSelectors
} from '../../../../store/modules/productListDetails'
import modalService from '../../../../services/modalService'

const mapStateToProps = (state, ownProps) => {
  const productList = productListDetailsSelectors.getProductList(state)

  return { productList }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onClose: () => {
      return modalService.close({ modalIndex: 1 })
    },
    onSubmit: ({ dispatch, productList, product }) => (receivedQuantity, index) => {
      const updatedProducts = productList.products.map((p, i) => {
        if (p.externalProductId === product.externalProductId && index === i) {
          return { ...p, receivedQuantity }
        }
        return p
      })

      dispatch(productListDetailsActions.updateProductList({
        id: productList.id,
        products: updatedProducts
      }))

      return modalService.close({ modalIndex: 1 })
    }
  })
)(ReceiveProducts)
