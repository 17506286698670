import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from 'styled-components'

import H2 from '../../components/H2'
import BackBar from '../../components/BackBar'
import Button from '../../components/Button'
import SubHeader from '../../components/SubHeader'
import LoaderContainer from '../../components/LoaderContainer'
import ResponsiveTable from '../../components/ResponsiveTable'
import BottomBar from '../../components/BottomBar'
import TabbedContent from '../../components/TabbedContent'

import media from '../../config/media'
import LoadMoreButton from '../../components/LoadMoreButton'
import { renderFilters } from './Filters'
import { translations } from '../../config'

const TabsContainer = styled.div`
  margin: 20px 30% 0 30%;
  ${media.lessThan('lg')`
    margin: 20px 15% 0 15%;
  `}
  ${media.lessThan('md')`
    margin: 20px auto 0 auto;
  `}
`

const NoResultsContainer = styled.div`
  margin: 50px;
  text-align: center;
  text-transform: uppercase;
`

const ResultsContainer = styled.div`
  margin: 20px auto 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  ${media.greaterThan('md')`
    padding-left: 20px;
    padding-right: 20px;
  `}
},
`

const LoadMoreContainer = styled.div`
  text-align: center;
  padding: 20px;
},
`

const renderActionButtons = ({ actionButtons }) => {
  return (
    <BottomBar>
      {actionButtons.map(button => {
        return <Button big key={button.label} buttonType='primary' onClick={button.onClick}>{button.label}</Button>
      })}
    </BottomBar>
  )
}

const renderTabs = ({ tabs, selectedTab, handleTabChange }) => {
  return (
    <TabsContainer>
      <TabbedContent
        tabs={tabs}
        tabValue={selectedTab}
        handleTabChange={handleTabChange}
        mediumTabs
        noMaxWidth
        noDivider
      />
    </TabsContainer>
  )
}

const renderResultsTable = ({ results, columns, onRowClick }) => {
  return (
    <div>
      <ResponsiveTable data={results} columns={columns} onRowClick={onRowClick} />
    </div>
  )
}

const renderLoadMore = ({ hasMore, loadMore, isLoading }) => {
  return (
    <LoadMoreContainer>
      <LoadMoreButton
        hasMore={hasMore}
        isLoading={isLoading}
        loadNext={loadMore}
      />
    </LoadMoreContainer>
  )
}

const ListPage = ({
  results = [],
  columns = [],
  isLoading,
  onRowClick,
  hasMore,
  loadMore,
  onBackClick,
  actionButtons = [],
  title,
  noResultsFoundText = 'No results found',

  filters = [],
  onFilterChange,
  filterInitalValues,

  tabs,
  selectedTab,
  handleTabChange,

}) => {
  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={onBackClick} />
        )}
        centerContent={!!title && <H2 value={title} />}
      />
      {tabs && renderTabs({ tabs, selectedTab, handleTabChange })}
      <ResultsContainer>
        {onFilterChange && renderFilters({ title, filters, onFilterChange, initialValues: filterInitalValues })}
        {
          results.length ? (
            renderResultsTable({ results, columns, onRowClick })
          ) : (
              <NoResultsContainer>
                <h3>{translations(noResultsFoundText)}</h3>
              </NoResultsContainer>
            )
        }
        <LoaderContainer isLoading={isLoading} />
      </ResultsContainer>
      {loadMore && renderLoadMore({ hasMore, loadMore, isLoading })}
      {!!actionButtons.length && renderActionButtons({ actionButtons })}
    </div>
  )
}

ListPage.propTypes = {
  isLoading: PropTypes.bool,
  results: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  onBackClick: PropTypes.func,

  // Pagination
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,

  // Filtering
  filter: PropTypes.object,
  onFilterChange: PropTypes.func,
  filterInitalValues: PropTypes.object,

  actionButtons: PropTypes.array,
  title: PropTypes.string,
  noResultsFoundText: PropTypes.string,
}

export default ListPage
