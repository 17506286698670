export const SEARCH_PRODUCTSLISTS = 'ROS/productLists/SEARCH_PRODUCTSLISTS'
export const SET_FILTER_DATE_RANGE_FROM = 'ROS/productLists/SET_FILTER_DATE_RANGE_FROM'
export const SET_FILTER_DATE_RANGE_TO = 'ROS/productLists/SET_FILTER_DATE_RANGE_TO'
export const SET_LISTS_ERROR = 'ROS/productLists/SET_LISTS_ERROR'
export const SET_LISTS_LOADING = 'ROS/productLists/SET_LISTS_LOADING'
export const SET_MOBILE_SELECTED_COLUMN = 'ROS/productLists/SET_MOBILE_SELECTED_COLUMN'
export const SET_FILTER_TYPE = 'digitalStore/productLists/SET_FILTER_TYPE'

export const GET_CAP_FILL_ALL_LISTS = 'ROS/productLists/GET_CAP_FILL_ALL_LISTS'
export const GET_CAP_FILL_REVIEW_LIST = 'ROS/productLists/GET_CAP_FILL_REVIEW_LIST'
export const GET_CAP_FILL_COMPLETE_LIST = 'ROS/productLists/GET_CAP_FILL_COMPLETE_LIST'
export const GET_CAP_FILL_PICKING_LIST = 'ROS/productLists/GET_CAP_FILL_PICKING_LIST'
export const GET_CAP_FILL_FILLING_LIST = 'ROS/productLists/GET_CAP_FILL_FILLING_LIST'

export const GET_STOCK_COUNT_ALL_LISTS = 'ROS/productLists/GET_STOCK_COUNT_ALL_LISTS'
export const GET_STOCK_COUNT_INVESTIGATIONS_LIST = 'ROS/productLists/GET_STOCK_COUNT_INVESTIGATIONS_LIST'
export const GET_STOCK_COUNT_COMPLETE_LIST = 'ROS/productLists/GET_STOCK_COUNT_COMPLETE_LIST'
export const GET_STOCK_COUNT_REVIEW_LIST = 'ROS/productLists/GET_STOCK_COUNT_REVIEW_LIST'

export const GET_STOCK_MOVEMENT_ALL_LISTS = 'ROS/productLists/GET_STOCK_MOVEMENT_ALL_LISTS'
export const GET_STOCK_MOVEMENT_PREADVICE_LIST = 'ROS/productLists/GET_STOCK_MOVEMENT_PREADVICE_LIST'
export const GET_STOCK_MOVEMENT_UNPOSTED_LIST = 'ROS/productLists/GET_STOCK_MOVEMENT_UNPOSTED_LIST'
export const GET_STOCK_MOVEMENT_POSTED_LIST = 'ROS/productLists/GET_STOCK_MOVEMENT_POSTED_LIST'

export const GET_GAP_SCAN_ALL_LISTS = 'ROS/productLists/GET_GAP_SCAN_ALL_LISTS'
export const GET_GAP_SCAN_SCANNING_LIST = 'ROS/productLists/GET_GAP_SCAN_SCANNING_LIST'
export const GET_GAP_SCAN_REVIEW_LIST = 'ROS/productLists/GET_GAP_SCAN_REVIEW_LIST'
export const GET_GAP_SCAN_COMPLETE_LIST = 'ROS/productLists/GET_GAP_SCAN_COMPLETE_LIST'
