
import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Form, { FormBody, FormSubmit } from '../../../components/Form'
import { Dropdown } from '../../../components/Fields'
import { useResults } from '../../../hooks/storesHooks'
import { actions as storesActions } from '../../../store/modules/stores'

import * as validators from '../../../validators'

import style from './style'
import { translations } from '../../../config'

const SelectStoreForm = Form('select-store')

const autocompleteDropdownProps = {
  autocomplete: true,
  listWidth: 330,
  maxListHeight: 144
}

const SelectStoreFormWrapper = ({
  onSubmit,
  storeId,
  stores,
  classes
}) => {

  const dispatch = useDispatch()
  const results = useResults()

  useEffect(() => {
    if (!results || !results.length) {
      dispatch(storesActions.fetchAllStores())
    }
  }, [])

  const handleSubmit = (values) => {
    const { storeRegionId } = values
    const [storeId, regionId] = _.split(storeRegionId, '_')

    const store = results.find((store) => store.id === storeId) || {}
    const { catalogue } = store

    onSubmit({ storeId, regionId, catalogue })
  }

  return (
    <div className={classes.formContainer}>
      <SelectStoreForm onSubmit={handleSubmit} submitLabel={translations('Continue')} initialValues={{ storeId }}>
        <FormBody>
          <div>
            <FormControl className={classes.field}>
              <Dropdown
                options={stores}
                name='storeRegionId'
                validate={validators.required}
                autocomplete
                strict={'true'}
                {...(stores.length > 10 ? autocompleteDropdownProps : {})}
              />
            </FormControl>
          </div>
        </FormBody>
        <FormSubmit containerClass={classes.centerButtonContainer} label={translations('Continue')} buttonClass={classes.fullWidthButton} />
      </SelectStoreForm>
    </div>
  )
}

SelectStoreFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default withStyles(style)(SelectStoreFormWrapper)
