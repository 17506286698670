import moment from 'moment'
import { get, isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { translations } from '../../../config'

export const getMobileSelectedColumn = listType => state => get(state.productLists, `allLists.mobileSelectedColumn.${listType}`) || 'all'

const getFilterType = state => {
  const values = get(state, 'productLists.filters.type')
  if (values && !isEmpty(values)) {
    const keys = Object.keys(values).filter((key) => values[key] === true)
    return keys.map(k => {
      if (k === 'Urgent Returns') k = 'returns'
      if (k === 'Uplift') k = 'uplifts'
      return k.toLowerCase().replace(' ', '')
    })
  }
  return []
}

const getCapFillAllList = state => state.productLists.allLists.capacityFill.all
const getCapFillCompleteList = state => state.productLists.allLists.capacityFill.complete
const getCapFillReviewList = state => state.productLists.allLists.capacityFill.review
const getCapFillPickingList = state => state.productLists.allLists.capacityFill.picking
const getCapFillFillingList = state => state.productLists.allLists.capacityFill.filling

const getStockCountAllList = state => state.productLists.allLists.stockCount.all
const getStockCountInvestigationsList = state => state.productLists.allLists.stockCount.investigations
const getStockCountCompleteList = state => state.productLists.allLists.stockCount.complete
const getStockCountReviewList = state => state.productLists.allLists.stockCount.review

const getStockMovementAllList = state => state.productLists.allLists.stockMovement.all
const getStockMovementPreadviceList = state => state.productLists.allLists.stockMovement.preadvice
const getStockMovementUnpostedList = state => state.productLists.allLists.stockMovement.unposted
const getStockMovementPostedList = state => state.productLists.allLists.stockMovement.posted

const getGapScanAllList = state => state.productLists.allLists.gapScan.all
const getGapScanScanningList = state => state.productLists.allLists.gapScan.scanning
const getGapScanReviewList = state => state.productLists.allLists.gapScan.review
const getGapScanCompleteList = state => state.productLists.allLists.gapScan.complete

const getCapacityFillLists = (productList) => {
  return createSelector([
    productList
  ], (productLists = []) => productLists.map(productList => {
    return {
      id: productList.id,
      status: productList.status,
      createdAt: productList.createdAt,
      updatedAt: productList.updatedAt,
      title: translations('Product lists - list number', { listNumber: productList.listNumber }),
      subTitle: `${translations('Product lists - created', {
        username: `${productList.user.firstName}`
      })}, ${moment(moment(productList.createdAt).format('YYYYMMDDkkmmss'), 'YYYYMMDDkkmmss').fromNow()}`,
      info: [
        translations('Capacity fill - products', { productsCount: productList.products.length })
      ]
    }
  }))
}

const getGapScanLists = (productList) => {
  return createSelector([
    productList
  ], (productLists = []) => productLists.map(productList => {
    return {
      id: productList.id,
      status: productList.status,
      createdAt: productList.createdAt,
      updatedAt: productList.updatedAt,
      title: translations('Product lists - list number', { listNumber: productList.listNumber }),
      subTitle: `${translations('Product lists - created', {
        username: `${productList.user.firstName}`
      })}, ${moment(moment(productList.createdAt).format('YYYYMMDDkkmmss'), 'YYYYMMDDkkmmss').fromNow()}`,
      info: [
        translations('Capacity fill - products', { productsCount: productList.products.length })
      ]
    }
  }))
}

const getStockCountLists = (productList, v2 = false) => {
  return createSelector([
    productList
  ], (productLists = []) => productLists.map(productList => {
    return {
      id: productList.id,
      status: productList.status,
      createdAt: productList.createdAt,
      updatedAt: productList.updatedAt,
      actionDate: productList.actionDate,
      title: translations('Product lists - list number', { listNumber: productList.listNumber }),
      type:
        productList.details && productList.details.previousListType && productList.details.previousListType == 'gap-scan'
          ? translations('Stock count - is gap scan list') : undefined,
      subTitle: v2
        ? `${translations('Stock count - count date', {
          date: moment(productList.actionDate).format('MMM Do YYYY')
        })}`
        : `${translations('Stock count - created date', {
          date: moment(productList.createdAt).format('MMM Do YYYY')
        })}`,
      info: [
        `${translations('Stock count - investigations', {
          investigationsCount: productList.products.length
        })}`
      ]
    }
  }))
}

const getStockMovementLists = (productList) => {
  return createSelector([
    productList,
    getFilterType
  ],
  (productLists = [], filters) => productLists
    .filter(productList => filters.length !== 0 ? filters.includes(productList.details.type) : productList)
    .map(
      productList => {
        const extraInfo = () => {
          const info = []

          if (((productList && productList.details) && productList.details.type === 'ibtin') || productList.details.type === 'ibtout') {
            info.push(`${translations('Stock movement - store', {
              direction: productList.details.type === 'ibtin' ? 'From' : 'To',
              store: productList.details.type === 'ibtin'
                ? `${productList.details.senderBranchName} (${productList.details.senderBranchNumber})`
                : `${productList.details.receiverBranchName} (${productList.details.receiverBranchNumber})`
            })}`)
          }

          info.push(`${translations('Stock movement - eaches', {
            eaches: productList.products.length
          })}`)

          return info
        }

        const titleType = (productList.details.type === 'ibtin' || productList.details.type === 'delivery') ? 'order' : 'form'

        const updatedStoreList = {
          id: productList.id,
          status: productList.status,
          createdAt: productList.createdAt,
          updatedAt: productList.updatedAt,
          actionDate: productList.actionDate,
          type: translations(`Stock movement details - ${productList.details.type} label`),
          title: translations(`Stock movement - ${titleType} number`, { number: productList.id.slice(0, 8) }),
          subTitle: `${translations('Stock movement - delivery date', {
            date: moment(productList.actionDate).format('MMM Do YYYY')
          })}`,
          info: extraInfo()
        }

        return updatedStoreList
      }
    )
  )
}

export const capFillAllList = getCapacityFillLists(getCapFillAllList)
export const capFillReviewList = getCapacityFillLists(getCapFillReviewList)
export const capFillCompleteList = getCapacityFillLists(getCapFillCompleteList)
export const capFillPickingList = getCapacityFillLists(getCapFillPickingList)
export const capFillFillingList = getCapacityFillLists(getCapFillFillingList)
export const stockCountAllList = getStockCountLists(getStockCountAllList, true)
export const stockCountInvestigationsList = getStockCountLists(getStockCountInvestigationsList, true)
export const stockCountCompleteList = getStockCountLists(getStockCountCompleteList, true)
export const stockCountReviewList = getStockCountLists(getStockCountReviewList, true)
export const stockCountAllListV1 = getStockCountLists(getStockCountAllList)
export const stockCountInvestigationsListV1 = getStockCountLists(getStockCountInvestigationsList)
export const stockCountCompleteListV1 = getStockCountLists(getStockCountCompleteList)
export const stockMovementAllList = getStockMovementLists(getStockMovementAllList)
export const stockMovementPreadviceList = getStockMovementLists(getStockMovementPreadviceList)
export const stockMovementUnpostedList = getStockMovementLists(getStockMovementUnpostedList)
export const stockMovementPostedList = getStockMovementLists(getStockMovementPostedList)
export const gapScanAllList = getGapScanLists(getGapScanAllList)
export const gapScanScanningList = getGapScanLists(getGapScanScanningList)
export const gapScanReviewList = getGapScanLists(getGapScanReviewList)
export const gapScanCompleteList = getGapScanLists(getGapScanCompleteList)
