import React from 'react'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

import OrdersSearchScreen from './OrdersSearchScreen'
import ViewOrderScreen from './ViewOrderScreen'
import RefundOrderScreen from './RefundOrderScreen'

export default [
  <PrivateRoute key='/orders/:id/refund' path='/orders/:id/refund' component={TimedLogoutComponent(RefundOrderScreen)} allowOffline />,
  <PrivateRoute key='/orders/:id' path='/orders/:id' component={TimedLogoutComponent(ViewOrderScreen)} allowOffline />,
  <PrivateRoute key='/orders' path='/orders' exact component={TimedLogoutComponent(OrdersSearchScreen)} allowOffline />
]
