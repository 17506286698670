import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import ListPage from '../../../layouts/ListPage'
import currencyFormatter from '../../../formatters/currencyFormatter'
import { actions, selectors } from '../../../store/modules/paymentDevicesHistory'
import { actions as paymentDevicesActions, selectors as paymentDevicesSelectors } from '../../../store/modules/paymentDevices'
import { actions as usersActions, selectors as usersSelectors } from '../../../store/modules/users'
import { DatePicker, Dropdown } from '../../../components/Fields'
import { translations } from '../../../config'
import { STAR_CLOUD_PRNT_DEVICE } from '../../../services/paymentDeviceService'

const AccessoryViewHistoryScreen = ({ match }) => {
  const results = useSelector(selectors.results)
  const paymentDevices = useSelector(paymentDevicesSelectors.getAll)
  const filterInitalValues = useSelector(selectors.filters)
  const hasMore = useSelector(selectors.hasMore)
  const userOptions = useSelector(usersSelectors.getAllAsOptions)
  const isLoading = useSelector(selectors.loading)
  const error = useSelector(selectors.error)
  const dispatch = useDispatch()
  const paymentDeviceId = _.get(match, 'params.id')
  const paymentDevice = paymentDevices.filter((device) => device.id === paymentDeviceId)[0]
  const tableHeader = paymentDevice ? `${paymentDevice.name} ${translations('History')}` : translations('Payment Device History')

  React.useEffect(() => {
    dispatch(paymentDevicesActions.fetchAll())
    dispatch(actions.setFilters({ paymentDeviceId }))
    dispatch(actions.fetchAllFresh())
    dispatch(usersActions.searchUsersFresh({ size: 500 }))
  }, [])

  const mappedResults = React.useMemo(() => {
    return results.map(result => ({
      ...result,
      total: result.total ? currencyFormatter.format({ ...result.total, options: { format: '%s%v' }}) : undefined,
      tendered: result.tendered ? currencyFormatter.format(result.tendered) : undefined,
      change: result.change ? currencyFormatter.format(result.change) : undefined,
      user: result.user && result.user.username,
      createdAt: moment(result.createdAt).format('DD.MM.YYYY HH:mm')
    }))
  }, [results])

  const columns = [
    { Header: translations('Event'), accessor: 'event' },
    { Header: translations('Total'), accessor: 'total' },
    { Header: translations('Tendered'), accessor: 'tendered' },
    { Header: translations('Change'), accessor: 'change' },
    { Header: translations('User'), accessor: 'user' },
    { Header: translations('Date / Time'), accessor: 'createdAt' },
  ]

  if(paymentDevice && paymentDevice.deviceType  === STAR_CLOUD_PRNT_DEVICE) {
    columns.splice(5, 0, { Header: translations('Comment'), accessor: 'details.floatComment' })
  }

  const actionButtons = [
    // {
    // label: translations('Create Store'),
    // onClick: onClickAddStore
    // }
  ]

  const loadMore = () => dispatch(actions.fetchAllNext())

  const onFilterChange = (filters) => {
    // FIXME: it triggers this cb twice, first with a react synthetic event and then with the plain object
    if (_.isPlainObject(filters)) {
      dispatch(actions.setFilters({ ...filters, paymentDeviceId }))
      dispatch(actions.fetchAllFresh())
    }
  }

  const onRowClick = () => null

  const filters = [
    {
      id: 'userId',
      title: translations('User'),
      options: userOptions,
      component: Dropdown,
      value: ''
    },
    {
      id: 'fromDate',
      title: translations('Date from'),
      component: DatePicker
    },
    {
      id: 'toDate',
      title: translations('Date to'),
      component: DatePicker
    }
  ]

  return (
    <ListPage
      title={tableHeader}
      onRowClick={onRowClick}

      onFilterChange={onFilterChange}
      filters={filters}
      filterInitalValues={filterInitalValues}

      isLoading={isLoading}
      hasMore={hasMore}
      loadMore={loadMore}

      columns={columns}
      results={mappedResults}
      error={error}
      actionButtons={actionButtons}
    />
  )
}

export default AccessoryViewHistoryScreen
