import { translations } from '../../../config'
import * as validators from "../../../validators";
import {brandRequired} from "../../../validators";

const brands = [
  {
    label: 'MyIittala',
    value: 'MyIittala'
  },
  {
    label: 'Royal Copenhagen',
    value: 'Royal Copenhagen'
  }
]

export default [
  {
    id: 'companyName',
    field: 'Input',
    props: {
      label: translations('Company Name'),
      name: 'companyName',
      required: true
    }
  },
  {
    id: 'companyVAT',
    field: 'Input',
    props: {
      label: translations('Company VAT Code'),
      name: 'companyVAT',
      required: true
    }
  },
  {
    id: 'storeBrand',
    field: 'Dropdown',
    props: {
      label: translations('Store Brand'),
      name: 'storeBrand',
      multiple: false,
      options: brands,
      required: true,
      validate: validators.brandRequired
    }
  },
  {
    id: 'termsAndCondition',
    field: 'Input',
    props: {
      label: translations('Terms And Condition'),
      name: 'termsAndCondition'
    }
  }
]
