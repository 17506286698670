import Joi from '@hapi/joi'

const abstractEntitySchema = Joi.object().keys({
  updateSource: Joi.string().required().min(3).allow(null),
  createdAt: Joi.string().isoDate().optional(),
  updatedAt: Joi.string().isoDate().optional(),
  deletedAt: Joi.string().isoDate().optional().allow(null),
})

const priceSchema = Joi.object().keys({
  code: Joi.string(),
  value: Joi.number()
})

const discountSchema = Joi.object().keys({
  code: Joi.string(),
  value: Joi.number(),
  type: Joi.string(),
  percent: Joi.string().optional(),
  reason: Joi.string().optional()
})

const orderStatusSchema = Joi.string().valid(
  'pending',
  'complete',
  'void',
  'exchange',
  'partial_refund',
  'full_refund',
  'edit',
  'cancelled',
  'picking_in_progress',
  'awaiting_picking',
  'ready_for_collection',
  'awaiting_payment',
  'awaiting_refund'
)

const orderTypeSchema = Joi.string().valid(
  'standard',
  'referral',
  'consultation',
  'storeroom'
)

const deliveryTypeSchema = Joi.string().valid(
  'home',
  'store'
)

const productSchema = Joi.object({
  brand: Joi.string().allow(''),
  categoryId: Joi.string().allow(null).optional(),
  discount: priceSchema.allow(null),
  externalProductId: Joi.string(),
  id: Joi.string().required(),
  vatPercent: Joi.number().min(0).max(100).optional().allow(null),
  link: [Joi.string().uri().allow(null), Joi.string().uri().allow('')],
  manualDiscount: discountSchema,
  name: Joi.string().required(),
  preview: Joi.bool().default(false),
  service: Joi.bool().default(false),
  unsold: Joi.bool().default(false),
  isLoved: Joi.bool().default(false),
  price: priceSchema.required(),
  nowPrice: priceSchema.optional(),
  priceToDisplay: priceSchema.optional(),
  discountToDisplay: priceSchema.optional(),
  productId: Joi.string().required(),
  clientId: Joi.string().optional().allow(null).default(null),
  images: Joi.array().items(Joi.string()).optional().default([]),
  subTotal: priceSchema.required(),
  tax: priceSchema.required(),
  total: priceSchema.required(),
  variant: Joi.object({
    name: Joi.string().required(),
    ean: Joi.string()
  }).unknown().required(),
  refund: Joi.bool(),
  hasBeenRefunded: Joi.bool().optional(),
  picked: Joi.bool().optional(),
  notPickedReason: Joi.string().optional()
}).unknown(true)

const baseSchema = abstractEntitySchema.keys({
  id: Joi.string().optional(),
  tax: priceSchema.optional(),
  total: priceSchema.optional(),
  subTotal: priceSchema.optional(),
  totalDiscount: priceSchema.optional(),
  status: orderStatusSchema.optional(),
  orderNumber: Joi.string().optional(),
  externalOrderId: Joi.string().optional().allow(null),
  salesChannel: Joi.string().optional(),
  orderDate: Joi.string().isoDate().optional(),
  products: Joi.array().items(productSchema).optional(),
  customerId: Joi.string().uuid().optional().allow(null),
  paymentDeviceId: Joi.string().uuid().optional(),
  userId: Joi.string().uuid().optional(),
  storeId: Joi.string().uuid().optional(),
  orderType: orderTypeSchema.optional(),
  paymentToken: Joi.string().optional().allow(null),
  deliveryType: deliveryTypeSchema.optional().allow(null),
  deliveryOption: Joi.object().optional().allow(null),
  deliveryAddress: Joi.object().optional().allow(null),
  deliveryDetails: Joi.object().optional().allow(null),
  details: Joi.object().optional(),
  returningCustomer: Joi.boolean().optional(),
  previousOrderId: Joi.string().uuid().optional().allow(null)
})

export const createOrderSchema = baseSchema.keys({
  tax: priceSchema.required(),
  total: priceSchema.required(),
  subTotal: priceSchema.required(),
  totalDiscount: priceSchema.required(),
  status: orderStatusSchema.required(),
  orderNumber: Joi.string().optional(),
  salesChannel: Joi.string().required(),
  orderDate: Joi.string().isoDate().optional(),
  products: Joi.array().items(productSchema).required(),
  storeId: Joi.string().uuid().required(),
  orderType: orderTypeSchema.required()
})