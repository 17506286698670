import {
  SEARCH_PRODUCTSLISTS,
  GET_CAP_FILL_ALL_LISTS,
  GET_CAP_FILL_REVIEW_LIST,
  GET_CAP_FILL_COMPLETE_LIST,
  GET_CAP_FILL_PICKING_LIST,
  GET_CAP_FILL_FILLING_LIST,
  GET_STOCK_COUNT_ALL_LISTS,
  GET_STOCK_COUNT_INVESTIGATIONS_LIST,
  GET_STOCK_COUNT_COMPLETE_LIST,
  GET_STOCK_COUNT_REVIEW_LIST,
  GET_STOCK_MOVEMENT_ALL_LISTS,
  GET_STOCK_MOVEMENT_PREADVICE_LIST,
  GET_STOCK_MOVEMENT_UNPOSTED_LIST,
  GET_STOCK_MOVEMENT_POSTED_LIST,
  GET_GAP_SCAN_SCANNING_LIST,
  GET_GAP_SCAN_ALL_LISTS,
  GET_GAP_SCAN_REVIEW_LIST,
  GET_GAP_SCAN_COMPLETE_LIST,
  SET_FILTER_DATE_RANGE_FROM,
  SET_FILTER_DATE_RANGE_TO,
  SET_LISTS_LOADING,
  SET_LISTS_ERROR,
  SET_MOBILE_SELECTED_COLUMN,
  SET_FILTER_TYPE
} from './constants'
import {
  searchProductLists,
  getCapFillAllList,
  getCapFillReviewList,
  getCapFillCompleteList,
  getCapFillPickingList,
  getCapFillFillingList,
  getStockCountAllList,
  getStockCountInvestigationsList,
  getStockCountCompleteList,
  getStockCountReviewList,
  getStockMovementAllList,
  getStockMovementPreadviceList,
  getStockMovementUnpostedList,
  getStockMovementPostedList,
  getGapScanAllList,
  getGapScanScanningList,
  getGapScanReviewList,
  getGapScanCompleteList,
  setFilterDateRangeTo,
  setFilterDateRangeFrom,
  setListsLoading,
  setListsError,
  setMobileSelectedColumn,
  setFilterType
} from './reducers'

const defaultState = {
  results: [],
  filters: {
    dateRange: {
      to: null,
      from: null
    },
    type: {}
  },
  allLists: {
    capacityFill: {
      all: [],
      review: [],
      complete: [],
      picking: [],
      filling: []
    },
    stockCount: {
      all: [],
      investigations: [],
      complete: []
    },
    stockMovement: {
      all: [],
      preadvice: [],
      unposted: [],
      posted: []
    },
    gapScan: {
      all: [],
      scanning: [],
      review: [],
      complete: []
    },
    mobileSelectedColumn: {},
    loading: {
      'capacity-fill': {},
      'stock-count': {},
      'stock-movement': {},
      'gap-scan': {}
    },
    error: {
      'capacity-fill': {},
      'stock-count': {},
      'stock-movement': {},
      'gap-scan': {}
    }
  },
  error: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCTSLISTS:
      return searchProductLists(state, action)
    case GET_CAP_FILL_ALL_LISTS:
      return getCapFillAllList(state, action)
    case GET_CAP_FILL_REVIEW_LIST:
      return getCapFillReviewList(state, action)
    case GET_CAP_FILL_COMPLETE_LIST:
      return getCapFillCompleteList(state, action)
    case GET_CAP_FILL_PICKING_LIST:
      return getCapFillPickingList(state, action)
    case GET_CAP_FILL_FILLING_LIST:
      return getCapFillFillingList(state, action)
    case GET_STOCK_COUNT_ALL_LISTS:
      return getStockCountAllList(state, action)
    case GET_STOCK_COUNT_INVESTIGATIONS_LIST:
      return getStockCountInvestigationsList(state, action)
    case GET_STOCK_COUNT_COMPLETE_LIST:
      return getStockCountCompleteList(state, action)
    case GET_STOCK_COUNT_REVIEW_LIST:
      return getStockCountReviewList(state, action)
    case GET_STOCK_MOVEMENT_ALL_LISTS:
      return getStockMovementAllList(state, action)
    case GET_STOCK_MOVEMENT_PREADVICE_LIST:
      return getStockMovementPreadviceList(state, action)
    case GET_STOCK_MOVEMENT_UNPOSTED_LIST:
      return getStockMovementUnpostedList(state, action)
    case GET_STOCK_MOVEMENT_POSTED_LIST:
      return getStockMovementPostedList(state, action)
    case GET_GAP_SCAN_ALL_LISTS:
      return getGapScanAllList(state, action)
    case GET_GAP_SCAN_SCANNING_LIST:
      return getGapScanScanningList(state, action)
    case GET_GAP_SCAN_REVIEW_LIST:
      return getGapScanReviewList(state, action)
    case GET_GAP_SCAN_COMPLETE_LIST:
      return getGapScanCompleteList(state, action)
    case SET_FILTER_DATE_RANGE_FROM:
      return setFilterDateRangeFrom(state, action)
    case SET_FILTER_DATE_RANGE_TO:
      return setFilterDateRangeTo(state, action)
    case SET_LISTS_LOADING:
      return setListsLoading(state, action)
    case SET_LISTS_ERROR:
      return setListsError(state, action)
    case SET_MOBILE_SELECTED_COLUMN:
      return setMobileSelectedColumn(state, action)
    case SET_FILTER_TYPE:
      return setFilterType(state, action)
    default:
      return state
  }
}
