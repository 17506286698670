import * as validators from '../../../validators'
import {
  countryPhoneCodes,
  translations,
  auth,
  loginTypes,
  isUserEmailRequired
} from '../../../config'

const usernameRequired = auth.loginType === loginTypes.USERNAME

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const telephoneLengthValidator = validators.maxLength(25, 'Phone Number')

const schema = [{
  id: 'title',
  field: 'Dropdown',
  props: {
    label: translations('Title'),
    name: 'title',
    options: ['Mr', 'Mrs', 'Ms', 'Dr', 'Sir', 'Madam'],
    validate: [smallTextLengthValidator]
  }
}, {
  id: 'firstName',
  field: 'Input',
  props: {
    label: translations('First Name'),
    name: 'firstName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'lastName',
  field: 'Input',
  props: {
    label: translations('Last Name'),
    name: 'lastName',
    validate: [smallTextLengthValidator],
    required: true
  }
}, {
  id: 'email',
  field: 'Email',
  props: {
    label: translations('Email'),
    name: 'email',
    validate: [validators.email, smallTextLengthValidator],
    required: isUserEmailRequired !== undefined ? isUserEmailRequired : true,
    showEmailButton: false
  }
}, {
  id: 'dob',
  field: 'DatePicker',
  props: {
    label: translations('Date Of Birth'),
    name: 'dob',
    validate: [validators.date]
  }
}, {
  id: 'telephone',
  field: 'PhoneNumber',
  props: {
    label: translations('Phone Number'),
    name: 'telephone',
    options: countryPhoneCodes,
    validate: [telephoneLengthValidator, validators.phoneNumber],
    showSmsButton: false
  }
}]

if (usernameRequired) {
  schema.push({
    id: 'username',
    field: 'Input',
    props: {
      label: translations('Registration Username'),
      name: 'username',
      required: true
    }
  })
}

export default schema
