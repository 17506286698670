import { createSelector } from 'reselect';
import _ from 'lodash';

import { connect } from 'react-redux';
import { compose, withPropsOnChange, branch } from 'recompose';
import { getFormValues, isValid } from 'redux-form';
import { getPriceValue } from '@redant/digital-store-prices-fiskars';
import withWidth from '@material-ui/core/withWidth';

import { selectors as paymentDevicesSelectors } from '../../../../../store/modules/paymentDevices';
import { selectors as authSelectors } from '../../../../../store/modules/auth';
import { withOrderActionHandlers } from './actions.js';

import { selectors as rolesSelectors } from '../../../../../store/modules/roles';
import { selectors as orderDetailsSelectors } from '../../../../../store/modules/orderDetails';
import { selectors as currentOrderSelectors } from '../../../../../store/modules/currentOrder';

import {
  translations,
  editOrderEnabled,
  refundOrderEnabled,
  exchangeOrderEnabled,
  reassignOrderEnabled,
  cancelOrderEnabled
} from '../../../../../config';

import formId from '../formId';
import OrderActions from './OrderActions';
import { STAR_CLOUD_PRNT_DEVICE } from '../../../../../services/paymentDeviceService';
import { getCurrentUser } from '../../../../../store/modules/auth/selectors';

// REDUX STUFF

// this fixes the issue of redux-form.getFormValues not returning
// untouched fields
const getOrderFormValues = createSelector(
  [
    (state) => _.get(state, ['form', formId, 'registeredFields'], {}),
    getFormValues(formId)
  ],
  (registeredFields, values) => {
    return {
      ..._.transform(
        registeredFields,
        (result, value, key) => {
          result[key] = null;
        },
        {}
      ),
      ...values
    };
  }
);

// this fixes the issue of redux form validation messing up when we
// switch from desktop to mobile and back, unregistering fields and
// making sync errors not update properly
const getOrderFormIsValid = createSelector(
  [isValid(formId), orderDetailsSelectors.getOrder],
  (doesReduxFormSayItIsValid, order) => {
    let isValid = true;
    let nothingPicked = true;
    _.forEach(order.products, (product) => {
      if (product.picked !== true && product.picked !== false) {
        isValid = false;
      } else if (product.picked === true) {
        nothingPicked = false;
      }
    });
    return isValid && doesReduxFormSayItIsValid && !nothingPicked;
  }
);

const checkAreReasonCodesValid = (orderFormValues) => {
  if (!orderFormValues) {
    return false;
  }

  const lineItemIds = _.chain(orderFormValues)
    .keys()
    .map((key) => _.split(key, '_')[0])
    .uniq()
    .value();

  const invalid = _.chain(lineItemIds)
    .map((id) => {
      const quantity = _.get(orderFormValues, `${id}_quantity`);
      const returnReasonCode = _.get(
        orderFormValues,
        `${id}_return_reason_code`
      );
      const returnDefectCodeType = _.get(
        orderFormValues,
        `${id}_return_defect_code_type`
      );
      const returnDefectCode = _.get(
        orderFormValues,
        `${id}_return_defect_code_value`
      );
      const isItemInSalableCondition = _.get(
        orderFormValues,
        `${id}_isInSalableCondition`
      );

      if (quantity && !isItemInSalableCondition) {
        return { id, valid: false };
      }

      if (
        !quantity &&
        (returnReasonCode || returnDefectCodeType || returnDefectCode)
      ) {
        return { id, valid: false };
      }

      if (
        !quantity &&
        !returnReasonCode &&
        !returnDefectCodeType &&
        !returnDefectCode
      ) {
        return { id, valid: true };
      }

      if (returnReasonCode === 'WARRANTY') {
        return {
          id,
          valid: returnDefectCodeType && returnDefectCode ? true : false
        };
      }

      return {
        id,
        valid: returnReasonCode ? true : false
      };
    })
    .some((obj) => !obj.valid)
    .value();

  return !invalid;
};

const mapStateToProps = (state, ownProps) => {
  const hasBeenRefunded = orderDetailsSelectors.getOrderHasBeenRefunded(state);
  const isFullyRefunded = orderDetailsSelectors.getOrderIsFullyRefunded(state);
  const isLoading = orderDetailsSelectors.getIsLoading(state);
  const failedToLoad = orderDetailsSelectors.getFailedToLoad(state);
  const order = orderDetailsSelectors.getOrder(state);
  const hasRefundPermission = authSelectors.getHasRefund(state);
  const hasExchangePermission = authSelectors.getHasExchange(state);
  const hasReassignOrderPermission = authSelectors.getHasReassignOrder(state);
  const hasCancelOrder = authSelectors.getHasCancelOrder(state);
  const isCancelledOrder = orderDetailsSelectors.getOrderIsCancelled(state);
  const rolesStoreIsNotLoaded = rolesSelectors.getIsInitial(state);
  const rolesStoreHasError = rolesSelectors.getHasError(state);
  const orderFormIsValid = getOrderFormIsValid(state);
  const orderFormValues = getOrderFormValues(state);

  const orderHasRefund = orderDetailsSelectors.orderHasRefund(state);
  const orderHasOneOrManyRefundedProducts = orderDetailsSelectors.orderHasOneOrManyRefundedProducts(
    state
  );
  const orderHasPaymentToken = orderDetailsSelectors.orderHasPaymentToken(
    state
  );
  const loggedInStoreId = authSelectors.getUserSelectedStoreId(state);
  const orderStoreId = orderDetailsSelectors.getOrderStoreId(state);

  const isValidRefund = !!orderFormValues && _.some(orderFormValues);
  const orderIsFromCurrentStoreOrAnonymous =
    !orderStoreId || orderStoreId === loggedInStoreId;
  const isUserAdmin = authSelectors.getIsUserAdmin(state);

  const hasPositiveOrderTotal = getPriceValue(_.get(order, 'subTotal')) > 0;
  const isQuantitySelected = !!orderFormValues && _.some(orderFormValues);
  const areReasonCodesValid = checkAreReasonCodesValid(orderFormValues);
  const currentUser = getCurrentUser(state);
  const printers = paymentDevicesSelectors
    .getAll(state)
    .filter((device) => device.deviceType === STAR_CLOUD_PRNT_DEVICE);

  const mappedState = {
    printers,
    isFullyRefunded,
    hasBeenRefunded,
    order,
    isLoading,
    failedToLoad,
    inEditingStateForThisOrder: !!order.editMode,
    canRefund:
      !isCancelledOrder && hasRefundPermission && hasPositiveOrderTotal,
    canExchange: !isCancelledOrder && hasExchangePermission,
    canEditOrder:
      !isCancelledOrder && orderDetailsSelectors.canEditOrder(state),
    canReassignOrder: !isCancelledOrder && hasReassignOrderPermission,
    rolesStoreIsNotLoaded,
    rolesStoreHasError,
    canReassignCustomer:
      !isCancelledOrder && orderDetailsSelectors.canReassignCustomer(state),
    isPartialRefundState: orderDetailsSelectors.getIsPartialRefundState(state),
    isExchangeState: orderDetailsSelectors.getIsExchangeState(state),
    canCancelOrder:
      orderDetailsSelectors.canCancelOrder(state) &&
      hasCancelOrder &&
      !hasBeenRefunded,
    orderHasRefund,
    orderHasOneOrManyRefundedProducts,
    isValidRefund,
    isQuantitySelected,
    areReasonCodesValid,
    isInEditMode: currentOrderSelectors.getCurrentOrderEditMode(state),
    orderHasPaymentToken,
    orderFormValues,
    orderFormIsValid,
    isUserAdmin,
    orderIsFromCurrentStoreOrAnonymous,
    currentUser,
    isAwaitingRefund: order.status === 'awaiting_refund'
  };

  return mappedState;
};

const withStandardOrderButtons = withPropsOnChange(
  [
    'canCancelOrder',
    'canEditOrder',
    'canReassignOrder',
    'canRefund',
    'canExchange',
    'inEditingStateForThisOrder',
    'isInEditMode',
    'isPartialRefundState',
    'isValidRefund',
    'isExchangeState',
    'isQuantitySelected',
    'areReasonCodesValid',
    'orderHasRefund',
    'hasBeenRefunded',
    'isFullyRefunded'
  ],
  (props) => {
    // from state
    const {
      canCancelOrder,
      canEditOrder,
      canReassignOrder,
      canRefund,
      canExchange,
      inEditingStateForThisOrder,
      isInEditMode,
      isPartialRefundState,
      isValidRefund,
      isExchangeState,
      isQuantitySelected,
      areReasonCodesValid,
      orderHasRefund,
      hasBeenRefunded,
      isFullyRefunded,
      order,
      isAwaitingRefund,
      // handlers
      cancelRefundOrderState,
      cancelExchangeOrderState,
      editOrder,
      onCancelOrderClick,
      onExchangeOrderClick,
      performRefund,
      performExchange,
      reassignOrder,
      generateReceipt,
      refundOrder,
      stopEditingOrder
    } = props;

    let buttonsProps = [];

    if (isExchangeState) {
      // buttons for when order is in exchange state
      buttonsProps = [
        {
          children: translations('Exchange'),
          onClick: performExchange,
          disabled: !canExchange || !isQuantitySelected || !areReasonCodesValid
        },
        {
          children: translations('Cancel Exchange'),
          onClick: cancelExchangeOrderState,
          disabled: !canExchange
        }
      ];
    } else if (isPartialRefundState) {
      // buttons for when order is in partial refund state
      buttonsProps = [
        {
          children: translations('Refund'),
          onClick: performRefund,
          disabled:
            !canRefund && !isAwaitingRefund||
            !isValidRefund && !isAwaitingRefund||
            !isQuantitySelected && !isAwaitingRefund||
            !areReasonCodesValid
        },
        {
          children: translations('Cancel Refund'),
          onClick: cancelRefundOrderState,
          disabled: !canRefund && !isAwaitingRefund
        }
      ];
    } else {
      // buttons for when a Standard order is not in partial refund state
      // TODO: stop functionality from existing depending on config,
      // (aka, don't just hide buttons lol)
      if (reassignOrderEnabled) {
        buttonsProps.push({
          children: translations('Reassign'),
          onClick: reassignOrder,
          disabled: !canReassignOrder || isInEditMode
        });
      }
      if (editOrderEnabled) {
        buttonsProps.push({
          children: translations(
            inEditingStateForThisOrder ? 'Stop Edit' : 'Edit'
          ),
          onClick: inEditingStateForThisOrder ? stopEditingOrder : editOrder,
          disabled: !canEditOrder || isInEditMode || hasBeenRefunded
        });
      }
      if (refundOrderEnabled) {
        buttonsProps.push({
          children: translations('Refund'),
          onClick: refundOrder,
          disabled:
            isInEditMode ||
            isFullyRefunded && !isAwaitingRefund||
            !canRefund && !isAwaitingRefund ||
            orderHasRefund && !isAwaitingRefund ||
            order.status === 'awaiting_payment' && !order.payments
        });
      }

      buttonsProps.push({
        children: translations('Generate Receipt'),
        onClick: generateReceipt,
        disabled: !['complete', 'full_refund', 'partial_refund'].includes(order.status)
      });

      if (exchangeOrderEnabled) {
        buttonsProps.push({
          children: translations('Exchange'),
          onClick: onExchangeOrderClick,
          disabled: isInEditMode || isFullyRefunded || !canExchange
        });
      }
      if (cancelOrderEnabled) {
        buttonsProps.push({
          children: translations('Cancel'),
          onClick: onCancelOrderClick,
          disabled: !canCancelOrder || isInEditMode
        });
      }
    }

    return { buttonsProps };
  }
);

const withStoreroomOrderButtons = withPropsOnChange(
  ['order', 'orderFormIsValid'],
  (props) => {
    // from state
    const { order, orderFormIsValid, config } = props;
    // handlers
    const { updateOrder } = props;

    if (config && config.actions) {
      const actionsForStatus = config.actions[_.get(order, 'status')] || [];
      const buttonsProps = actionsForStatus.map((action) => ({
        children: translations(action.name),
        onClick: () => {
          return (action.status
            ? updateOrder(action.status)
            : Promise.resolve()
          ).then(
            Promise.all(
              (action.handlers || []).map((handlerName) => props[handlerName]())
            )
          );
        },
        disabled: action.validateForm ? !orderFormIsValid : false
      }));
      return { buttonsProps };
    }
    return {};
  }
);

// ENHANCER

const enhance = compose(
  connect(mapStateToProps),
  withWidth(),
  compose(
    withOrderActionHandlers,
    withStandardOrderButtons,
    withStoreroomOrderButtons
  )
);

export default enhance(OrderActions);
