import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import LockForm from './LockForm'
import { actions as appActions } from '../../../store/modules/app'
import { actions as authActions, selectors as authSelectors } from '../../../store/modules/auth'

const enhancer = compose(
  connect(
    (state) => ({
      user: authSelectors.getCurrentUser(state)
    }),
    {
      lockScreen: appActions.lockScreen,
      login: authActions.login
    }
  ),
  withPropsOnChange(['user'], ({ user = {} }) => {
    return {
      initialValues: { username: user.username, email: user.email }
    }
  }),
  withHandlers({
    unlockScreen: ({ lockScreen, login }) => ({ email, username, password }) => {
      // Login and unlock
      return login({
        email,
        username,
        password,
        isCustomerMode: true // this ensures a bunch of auth middlewares dont run
      }).then(() => (
        lockScreen({ isLocked: false })
      ))
    }
  })
)

export default enhancer(LockForm)
