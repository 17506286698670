import { connect } from 'react-redux'

import AccessoriesSearchScreen from './AccessoriesSearchScreen'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'

const mapStateToProps = (state) => ({
  selectedTab: appSelectors.getAccessoryManagementTab(state)
})

const mapDispatchToProps = (dispatch) => ({
  changeAccessoryManagementTab: (e, tab) => dispatch(appActions.changeAccessoryManagementTab({ tab }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccessoriesSearchScreen)
