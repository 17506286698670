import da from './da.json'
import de from './de.json'
import en from './en.json'
import fi from './fi.json'
import nl from './nl.json'
import sv from './sv.json'

const translation = (key, lang = 'en') => {
  switch (lang) {
    case 'da':
      return da[key] || key
    case 'de':
      return de[key] || key
    case 'en':
      return en[key] || key
    case 'fi':
      return fi[key] || key
    case 'nl':
      return nl[key] || key
    case 'sv':
      return sv[key] || key
    default:
      return en[key] || key
  }
}

export default translation
