import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getImage } from '../../Images'
import { useIsLoading } from '../../../hooks/productListDetailsHooks'
import LoaderContainer from '../../LoaderContainer'

const DropdownBar = styled.div`
  font-size: 14px;
  color: black;
  font-weight: bold;
  text-align: left;
  &:hover { cursor: pointer; }
  padding: 14px 10px;
`

const DropdownSubTitle = styled.div`
  font-size: 14px;
  color: black;
  font-weight: normal;
  text-align: left;
  &:hover { cursor: pointer; }
  padding: 5px 0px;
`

const Chevron = styled.img`
  margin-right: 0.5em;
  transform: rotate(90deg);
  ${({ dropdown }) => dropdown &&
    'transform: rotate(270deg);'}
`

const ProductListDetailsDropdown = ({
  title,
  subtitle,
  openByDefault,
  total,
  children
}) => {
  const [dropdown, toggleDropdown] = useState(openByDefault)
  const isLoading = useIsLoading()

  const ChevronWrapper = (
    <div style={{ float: 'right', display: 'inline' }}>
      <Chevron dropdown={dropdown} src={getImage('iconChevronLeft')}/>
    </div>
  )

  return (
    <>
      <DropdownBar onClick={() => toggleDropdown(!dropdown)}>
        {title} ({total}){ChevronWrapper}
        {subtitle && <DropdownSubTitle>{ subtitle }</DropdownSubTitle>}
      </DropdownBar>
      <LoaderContainer isLoading={isLoading}>
        {dropdown && (
          <>{children}</>
        )}
      </LoaderContainer>
    </>
  )
}

ProductListDetailsDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  openByDefault: PropTypes.string,
  total: PropTypes.number.isRequired
}

ProductListDetailsDropdown.defaultProps = {
  openByDefault: true
}

export default ProductListDetailsDropdown
