import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import { Dropdown, Input } from '../../Fields'
import Form from '../../Form'
import BasicModalOverlay from '../../BasicModalOverlay'

import style from './style'
import * as validators from '../../../validators'
import currencyFormatter from '../../../formatters/currencyFormatter'

export const formId = 'checkout-card-payment-modal'
const ThisForm = Form(formId)

class SelectCashPaymentDeviceModal extends Component {
  renderAbsoluteRefundAmount = () => {
    const {
      classes,
      total,
      error,
      dismiss,
      leftToPay,
      complete
    } = this.props

    const actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })

    actions.push({
      text: translations('Complete'),
      success: false,
      primary: true,
      onClick: () => complete(true)
    })

    return (
      <BasicModalOverlay title={translations('Refund due')} confirmButtonText={translations('Pay')} actions={actions}>
        <div className={classes.formContainer}>
          <div className={classes.changeContainer}>
            {/* <p>Paid: {currencyFormatter.format(paymentAmount)}</p>
            <p>-{currencyFormatter.format(total)}</p>
            <hr></hr> */}
            <h4 className={classes.changeDueText}>Refund due: {currencyFormatter.format({
              code: total.code,
              value: leftToPay
            })}</h4>
          </div>
          {error && (
            <p className={classes.errorText}>{error.message}</p>
          )}
        </div>
      </BasicModalOverlay>
    )
  }

  renderChangeDue = () => {
    const {
      classes,
      error,
      dismiss,
      paymentAmount,
      cashPaid,
      changeDue,
      complete
    } = this.props

    const actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })

    actions.push({
      text: translations('Complete'),
      success: false,
      primary: true,
      onClick: () => complete(true)
    })

    return (
      <BasicModalOverlay title={translations('Change due')} confirmButtonText={translations('Pay')} actions={actions}>
        <div className={classes.formContainer}>
          <div className={classes.changeContainer}>
            <p>Paid: {currencyFormatter.format(cashPaid)}</p>
            <p>-{currencyFormatter.format(paymentAmount)}</p>
            <hr></hr>
            <h4 className={classes.changeDueText}>Change due: {currencyFormatter.format(changeDue)}</h4>
          </div>
          {error && (
            <p className={classes.errorText}>{error.message}</p>
          )}
        </div>
      </BasicModalOverlay>
    )
  }

  validateAmount = amount => {
    const { isNegativeAmount, outstandingAmount } = this.props

    if (!isNegativeAmount && outstandingAmount < parseFloat(amount)) {
      return `Amount must not be more than ${outstandingAmount}.`
    }

    if (isNegativeAmount && outstandingAmount > parseFloat(amount)) {
      return `Amount must not be less than ${outstandingAmount}.`
    }

    return validators.required(amount)
  }

  validateAmountIsMoreThanLeftToPay = amount => {
    const { isNegativeAmount, formValues } = this.props

    if (isNegativeAmount) {
      return true
    } else {
      if (parseFloat(amount) < formValues.paymentAmount) {
        return `Cash received from customer should be equal or more than ${formValues.paymentAmount}.`
      }
      return validators.required(amount)
    }
  }

  render () {
    const {
      classes,
      paymentDeviceOptions,
      processPayment,
      error,
      dismiss,
      currencyCode,
      showChangeDue,
      validateAmount,
      initialValues,
      leftToPay,
      isPartialRefund,
      paymentsToRefund,
      complete,
      isNegativeAmount,
      isRefund
    } = this.props

    const actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })

    actions.push({
      text: translations('Submit'),
      success: true,
      primary: true,
      disabled: !paymentDeviceOptions.length,
      ...(paymentsToRefund ? {
        onClick: () => {
          setTimeout(() => { try { complete(true) } catch {} })
        }
      } : {})
    })

    if (isPartialRefund && showChangeDue) {
      return this.renderAbsoluteRefundAmount()
    }

    return showChangeDue && !paymentsToRefund ? (
      this.renderChangeDue()
    ) : (
      <ThisForm onSubmit={processPayment} initialValues={initialValues}>
        <BasicModalOverlay
          title={translations(isRefund ? 'Cash refund' : 'Cash payment')}
          confirmButtonText={translations(isRefund ? 'Refund' : 'Pay')}
          actions={actions}
        >
          <div className={classes.formContainer}>
            <FormControl className={classes.formControl}>
              <Dropdown
                required
                name='paymentDeviceId'
                label={translations('Payment Device')}
                options={paymentDeviceOptions}
                validate={validators.required}
              />
            </FormControl>
            {!paymentDeviceOptions.length && (
              <p className={classes.errorText}>No payment devices enabled for the current store.</p>
            )}
            {error && (
              <p className={classes.errorText}>{error.message}</p>
            )}
            <FormControl className={classes.formControl}>
              <Input
                currencyCode={currencyCode}
                name='paymentAmount'
                label={translations(isRefund ? 'Amount to Refund' : 'Amount to Pay')}
                type='price'
                validate={this.validateAmount}
                negative={isNegativeAmount}
              />
            </FormControl>
            {
              !isPartialRefund && !isRefund && leftToPay > 0 && (
                <FormControl className={classes.formControl}>
                  <Input
                    type='price'
                    currencyCode={currencyCode}
                    name='cashPaid'
                    label={translations('Cash received from Customer')}
                    validate={[validators.required, validateAmount, this.validateAmountIsMoreThanLeftToPay]}
                  />
                </FormControl>
              )}
          </div>
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(SelectCashPaymentDeviceModal)
