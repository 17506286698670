import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../config'
import { Input } from '../Fields'
import Form from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'

import style from './style'
import currencyFormatter from '../../formatters/currencyFormatter'
import * as validators from '../../validators'

export const formId = 'cash-float-modal'
const ThisForm = Form(formId)

class SelectCashPaymentDeviceModal extends Component {

  render() {
    const {
      classes,
      removeFloat,
      currentFloat,
      totalFloat,
      success,
      dismiss,
      totalFloatError,
    } = this.props

    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })


    actions.push({
      text: translations('Confirm'),
      success: true,
      primary: true,
      disabled: totalFloatError
        ? true
        : false
    })

    return (
      <ThisForm onSubmit={success} >
        <BasicModalOverlay
          title={translations(removeFloat ? 'Remove Float' : 'Add Float')}
          confirmButtonText={translations('Pay')}
          actions={actions}
        >
          <div className={classes.floatSectionContainer}>
            <div className={classes.floatSection}>
              <h4>Current Float:</h4>
              <h4>{currencyFormatter.format(currentFloat)}</h4>
            </div>
            <div>
              <FormControl className={classes.floatSection}>
                <Input
                  type='price'
                  currencyCode={currentFloat.code}
                  name='float'
                  label={translations(removeFloat ? 'Remove Float' : 'Add Float')}
                  validate={[validators.required]}
                />
              </FormControl>
              <FormControl className={classes.floatSection}>
                <Input
                  type='text'
                  name='floatComment'
                  label={translations('Comment')}
                />
              </FormControl>
            </div>
            <div className={classes.floatSection}>
              <h4>Total Float:</h4>
              <h4>{currencyFormatter.format(totalFloat)}</h4>
            </div>
          </div>
          {totalFloatError && (
              <p className={classes.errorText}>{totalFloatError}</p>
            )}
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(SelectCashPaymentDeviceModal)
