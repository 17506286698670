import { promiseReducer } from '../../util'
import { compactObject } from '../../../helpers'

export const searchProductLists = promiseReducer(
  // SUCCESS
  (state, action) => {
    const { result = {} } = action
    const { results, total } = result
    return {
      ...state,
      total,
      results
    }
  },
  // PENDING
  (state, action) => {
    const { payload = {} } = action
    return {
      ...state,
      ...compactObject(payload)
    }
  }
)

export const setListsError = (state, action) => {
  const { allLists } = state
  const params = action.params
  allLists.error[params.listType][params.status] = params.value
  return {
    ...state
  }
}

export const setFilterDateRangeFrom = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateRange: {
        ...state.filters.dateRange,
        from: action.params
      }
    }
  }
}

export const setFilterDateRangeTo = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      dateRange: {
        ...state.filters.dateRange,
        to: action.params
      }
    }
  }
}

export const setListsLoading = (state, action) => {
  const { allLists } = state
  const params = action.params
  allLists.loading[params.listType][params.status] = params.value
  return {
    ...state
  }
}

export const setMobileSelectedColumn = (state, action) => {
  const listType = action.listType
  const mobileSelectedColumn = action.mobileSelectedColumn
  return {
    ...state,
    allLists: {
      ...state.allLists,
      mobileSelectedColumn: {
        ...state.allLists.mobileSelectedColumn,
        [listType]: mobileSelectedColumn
      }
    }
  }
}

const getList = (listType, status) => (state, action) => {
  const { allLists } = state
  allLists[listType][status] = action.result
  return {
    ...state
  }
}

export const getCapFillAllList = (state, action) => getList('capacityFill', 'all')(state, action)
export const getCapFillCompleteList = (state, action) => getList('capacityFill', 'complete')(state, action)
export const getCapFillPickingList = (state, action) => getList('capacityFill', 'picking')(state, action)
export const getCapFillFillingList = (state, action) => getList('capacityFill', 'filling')(state, action)
export const getCapFillReviewList = (state, action) => getList('capacityFill', 'review')(state, action)

export const getStockCountAllList = (state, action) => getList('stockCount', 'all')(state, action)
export const getStockCountInvestigationsList = (state, action) => getList('stockCount', 'investigations')(state, action)
export const getStockCountCompleteList = (state, action) => getList('stockCount', 'complete')(state, action)
export const getStockCountReviewList = (state, action) => getList('stockCount', 'review')(state, action)

export const getStockMovementAllList = (state, action) => getList('stockMovement', 'all')(state, action)
export const getStockMovementPreadviceList = (state, action) => getList('stockMovement', 'preadvice')(state, action)
export const getStockMovementUnpostedList = (state, action) => getList('stockMovement', 'unposted')(state, action)
export const getStockMovementPostedList = (state, action) => getList('stockMovement', 'posted')(state, action)

export const getGapScanAllList = (state, action) => getList('gapScan', 'all')(state, action)
export const getGapScanScanningList = (state, action) => getList('gapScan', 'scanning')(state, action)
export const getGapScanReviewList = (state, action) => getList('gapScan', 'review')(state, action)
export const getGapScanCompleteList = (state, action) => getList('gapScan', 'complete')(state, action)

export const setFilterType = (state, action) => ({
  ...state,
  filters: {
    ...state.filters,
    type: action.filterTypes
  }
})
