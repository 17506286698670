import styled from 'styled-components'

export const CardContainer = styled.div`
  width: 80%;
  margin: 20px auto;
  border: 2px solid #e9e9e9;
`

export const Divider = styled.hr`
  border: 1px solid #e9e9e9;
`

export const Header = styled.div`
  font-size: 19px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #e9e9e9;
`

export const ContentContainer = styled.div`
  padding: 20px 15px;
`

export const Column = styled.div`
  width: 50%;
  padding: 8px 5px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
`

export const ColumnHeader = styled.div`
  opacity: 0.5;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`

export const ColumnContent = styled.div`
  width: 80%;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
