import { connect } from 'react-redux'
import _ from 'lodash'
import SelectCardPaymentDeviceModal from './SelectCardPaymentDeviceModal'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import {
  selectors as appSelectors,
  actions as appActions
} from '../../../store/modules/app'
import { isConnected } from '../../../store/modules/network/selectors'
import { selectors as authSelectors } from '../../../store/modules/auth'
import AdyenCloudPaymentDevice from '../../../services/paymentDevicesService/adyenCloudPaymentDevice'
import { getRefundablePaymentsWithLeftToRefundForOrder } from '../../../containers/Orders/helper'
import currencyFormatter from '../../../formatters/currencyFormatter'
import { ADYEN_CLOUD_PAYMENT_DEVICE } from '../../../services/paymentDeviceService'

const mapStateToProps = (state, props) => {
  const isOnline = isConnected(state)
  const selectedPaymentDeviceId = appSelectors.getSelectedPaymentDevices(state).card
  const paymentDeviceOptions = _.map(props.paymentDevices, (obj) => ({
    value: obj.id,
    label: obj.name
  }))

  const refundablePaymentsWithLeftToRefundForOrder = getRefundablePaymentsWithLeftToRefundForOrder(props.order).filter(payment => payment.deviceType === ADYEN_CLOUD_PAYMENT_DEVICE)
  const paymentOptions = _.map(refundablePaymentsWithLeftToRefundForOrder, (payment) => {
    const cardDetail = _.get(payment, 'result.apiResponse.data.SaleToPOIResponse.PaymentResponse.PaymentResult.PaymentInstrumentData.CardData.MaskedPan', 'Card')
    const currency = _.get(payment, 'result.apiResponse.data.SaleToPOIResponse.PaymentResponse.PaymentResult.AmountsResp.Currency', _.get(props, 'order.total.code'))
    const amount = currencyFormatter.format({
      code: currency,
      value: payment.amountPaid
    })
    const label = `${cardDetail} ${amount}`
    return {
      value: payment.id,
      label: label,
      disabled: payment.leftToRefund <= 0
    }
  })

  const initialValues = {
    paymentDeviceId: _.chain(paymentDeviceOptions)
      .map(({ value }) => value)
      .includes(selectedPaymentDeviceId)
      .value()
      ? selectedPaymentDeviceId
      : undefined,
    amount: props.leftToPay
  }

  const isNegativeAmount = Boolean(props.leftToPay < 0)

  return {
    currencyCode: authSelectors.getCurrencyCode(state),
    isOnline,
    initialValues,
    paymentDeviceOptions,
    paymentOptions,
    refundablePaymentsWithLeftToRefundForOrder,
    isNegativeAmount
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDefaultPaymentDevice: (paymentDeviceId) =>
    dispatch(
      appActions.setPaymentDevice({ paymentDeviceId, deviceType: 'card' })
    )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('pending', 'setPending', false),
  withState('error', 'setError', null),
  withState('manualEntry', 'setManualEntry', false),
  withState('selectedPaymentToRefund', 'setSelectedPaymentToRefund', null),
  withPropsOnChange(['paymentsToRefund'], ({ paymentsToRefund, setError }) => {
    const refundOrderManualEntry = _.some(
      paymentsToRefund,
      payment => _.get(payment, 'result.manualEntry', false)
    )

    if (refundOrderManualEntry) {
      setError({
        message: `Cannot refund this order because it was paid for using manual card entry. You must create a new unreferenced refund order.`,
        code: `ERR_MANUAL_ENTRY_REFUND`,
        details: null
      })
    }

    return { refundOrderManualEntry }
  }),
  withHandlers({
    doPaymentRequest: ({
      selectedPaymentToRefund,
      isPartialRefund,
      setError,
      orderNumber,
      currencyCode
    }) => async (paymentDevice, amount) => {
      const isUnreferencedRefund = amount < 0 // an unreferenced refund is building a basket with a negative value
      if (isUnreferencedRefund) {
        return AdyenCloudPaymentDevice.implementation.makeUnreferencedRefundRequest(
          paymentDevice,
          { value: amount },
          orderNumber
        )
      }

      if (selectedPaymentToRefund) {
        const originalTransactionId = _.get(
          selectedPaymentToRefund,
          'result.apiResponse.data.SaleToPOIResponse.PaymentResponse.POIData.POITransactionID.TransactionID'
        )
        const originalTransactionTimestamp = _.get(
          selectedPaymentToRefund,
          'result.apiResponse.data.SaleToPOIResponse.PaymentResponse.POIData.POITransactionID.TimeStamp'
        )
        if (originalTransactionId && originalTransactionTimestamp) {
          const isPartial = selectedPaymentToRefund.amount !== amount
          if (isPartial) {
            return AdyenCloudPaymentDevice.implementation.makeReferencedRefundRequest(
              paymentDevice,
              originalTransactionId,
              originalTransactionTimestamp,
              { value: amount, code: currencyCode },
              orderNumber
            )
          }

          return AdyenCloudPaymentDevice.implementation.makeReferencedRefundRequest(
            paymentDevice,
            originalTransactionId,
            originalTransactionTimestamp,
            undefined,
            orderNumber
          )
        } else {
          throw new Error(
            `No originalTransactionId or originalTransactionTimestamp found for order ${selectedPaymentToRefund.id}`
          )
        }
      }

      return AdyenCloudPaymentDevice.implementation.makePaymentRequest(
        paymentDevice,
        { value: amount, code: currencyCode },
        orderNumber
      )
    }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    processPayment: ({
      paymentDevices,
      setPending,
      setError,
      success,
      error,
      doPaymentRequest,
      setDefaultPaymentDevice
    }) => (formValues) => {
      const paymentDevice = _.find(
        paymentDevices,
        (pd) => pd.id === formValues.paymentDeviceId
      )

      if (!paymentDevice) {
        throw new Error(
          `No payment device found with id ${formValues.paymentDeviceId}`
        )
      }

      if (formValues.merchantReference) {
        return success(paymentDevice, {
          merchantReference: formValues.merchantReference,
          amount: formValues.amount,
          manualEntry: true,
          apiResponse: error
        })
      }

      setPending(true)
      setDefaultPaymentDevice(paymentDevice.id)
      doPaymentRequest(paymentDevice, formValues.amount)
        .then((result) => {
          if (result.success) {
            setPending(false)
            return success(paymentDevice, {
              merchantReference: null, // TODO: why null?
              amount: formValues.amount,
              apiResponse: result,
              selectedPaymentToRefund: formValues.selectedPaymentToRefund,
              manualEntry: false
            })
          }

          setPending(false)
          return setError(result)
        })
        .catch((error) => {
          setPending(false)
          console.error(error)
          return setError({
            message: `There was a technical error whilst attempting to process the payment.`,
            code: error.code,
            details: error.message
          })
        })
    }
  }),
  withHandlers({
    onFormChange: ({ refundablePaymentsWithLeftToRefundForOrder, setSelectedPaymentToRefund }) => ({ selectedPaymentIdToRefund }, dispatch, props, prev) => {
      const { change } = props || {}
      const selected = refundablePaymentsWithLeftToRefundForOrder.find(x => x.id === selectedPaymentIdToRefund)
      if (selected) {
        setSelectedPaymentToRefund(selected)
        dispatch(change('paymentDeviceId', selected.paymentDeviceId))
        dispatch(change('selectedPaymentToRefund', selected))
        if (prev && selectedPaymentIdToRefund !== prev.selectedPaymentIdToRefund) {
          dispatch(change('amount', selected.leftToRefund))
        }
      }
    }
  })
)(SelectCardPaymentDeviceModal)
