import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import BackBar from '../../../components/BackBar'
import BottomBar from '../../../components/BottomBar'
import Button from '../../../components/Button'
import ButtonsContainer from '../../../components/ButtonsContainer'
import ContentBox from '../../../components/ContentBox'
import ContentBoxBody from '../../../components/ContentBox/ContentBoxBody'
import ContentBoxHeader from '../../../components/ContentBox/ContentBoxHeader'
import H2 from '../../../components/H2'
import P from '../../../components/P'
import SubHeader from '../../../components/SubHeader'
import TextContent from '../../../components/Form/TextContent'
import { translations } from '../../../config'
import { getFullName } from '../../../helpers'

import style from './style'

const _getRecipientText = isTelephone => recipient => {
  return `${getFullName(recipient)} (${isTelephone ? recipient.telephone : recipient.email})`
}

const MessagePreviewScreen = props => {
  const { classes, messageDetails, onSubmit, recipients, closePreviewScreen } = props
  const { communicationType, subject, text, content } = (messageDetails || {})
  const isTelephone = communicationType === 'sms' || communicationType === 'whatsapp'
  const hasContent = content && content.length
  const previewInjection = { __html: props.preview }
  const getRecipientText = _getRecipientText(isTelephone)
  const toText = recipients.map(getRecipientText).join(', ')
  // I foresee constant flipflops on decision about whether preview links should work, hence [class]: true
  const previewBoxClass = classNames(classes.previewBox, { [classes.previewBoxUnclickable]: true })

  return (
    <div>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={(
          <H2 value={_.toUpper(translations('Customer Communications'))} />
        )}
      />
      <div className={classes.container}>
        {
          messageDetails && messageDetails.text
            ? (
              <Fragment>
                <ContentBox givenContentClass={classes.contentBoxBody}>
                  <ContentBoxHeader>
                    <TextContent>{translations(`${isTelephone ? 'SMS' : 'Email'} Preview`)}</TextContent>
                  </ContentBoxHeader>
                  <ContentBoxBody>
                    <div className={classNames(classes.content, classes.meta)}>
                      <P value={`${translations('to')}:`} />
                      <P value={toText} />
                      {
                        !isTelephone
                        ? <Fragment>
                          <P value={`${translations('subject')}:`} />
                          <P value={subject} />
                        </Fragment>
                        : null
                      }
                    </div>
                    {
                      isTelephone
                        ? (
                          <div className={classes.content}>
                            {text && text.split('\n').map(line => <P value={line} />)}
                          </div>
                        )
                        : null
                    }
                    {
                      (isTelephone && hasContent)
                        ? (
                          <ContentBoxHeader className={classes.previewHeader}>
                            <TextContent>Link Preview</TextContent>
                          </ContentBoxHeader>
                        )
                        : null
                    }
                    {
                      (!isTelephone || hasContent)
                        ? (
                          <div className={previewBoxClass}>
                            <div className={classes.emailBox}>
                              <div dangerouslySetInnerHTML={previewInjection} />
                            </div>
                          </div>
                        )
                        : null
                    }
                  </ContentBoxBody>
                </ContentBox>
                <BottomBar>
                  <ButtonsContainer align='center'>
                    <Button
                      buttonType='white'
                      onClick={closePreviewScreen}
                      big
                    >
                      {translations('Back To Editor')}
                    </Button>
                    <Button
                      buttonType='primary'
                      big
                      onClick={onSubmit}
                    >
                      {translations('Send Now')}
                    </Button>
                  </ButtonsContainer>
                </BottomBar>
              </Fragment>
            )
            : null
        }
      </div>
    </div>
  )
}

export default withStyles(style)(MessagePreviewScreen)
