export default () => ({
  buttonsContainerInner: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  printerDropdownContainer: {
    maxWidth: 380,
    margin: '0 auto',
    marginBottom: 20
  }
})
