import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import ContentBoxHeader from '../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../components/ContentBox/ContentBoxBody'
import TextContent from '../../components/Form/TextContent'
import H2 from '../../components/H2'
import ButtonsContainer from '../../components/ButtonsContainer'
import { FormBody } from '../../components/Form'
import { translations } from '../../config'

import AddressList from './AddressList'
import AddressListError from './AddressListError'
import AddressFull from './AddressFull'

import style from './style'

const addressSearchSchema = [{
  id: 'addressSearch',
  field: 'Input',
  props: {
    label: translations('Address Search'),
    name: `addressSearch`
  }
}]

const Address = props => {
  const {
    addressList,
    alwaysShowFields,
    align,
    classes,
    closeList,
    formName,
    fullWidth,
    inputValue,
    invalidPostcodeError,
    noContentBox,
    onClickEnterAddress,
    onClickFindAddress,
    onClickOutsideError,
    required,
    searchFullAddress,
    searchPostcodeError,
    searchPostcodePending,
    showError,
    showFields,
    showList
  } = props
  const formClass = classNames(
    classes.positionRelative,
    { [classes.formContainer]: !fullWidth }
  )

  const inner = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={formClass}>
          <div className={classes.inputContainer}>
            <FormBody
              schema={addressSearchSchema}
              editing
              fullWidthFields
            />
          </div>
          <ButtonsContainer
            foldOnMobile
            align={align}
            className={classes.buttonsContainer}
          >
            <Button
              disabled={!inputValue || searchPostcodePending}
              buttonType='primary'
              onClick={onClickFindAddress}
            >
              {translations('Find Address')}
            </Button>
            {/* {
              alwaysShowFields
                ? null
                : <Button
                  buttonType='white'
                  onClick={onClickEnterAddress}
                >
                  {translations('Enter Address')}
                </Button>
            } */}
          </ButtonsContainer>
          {
            searchPostcodeError && showError
              ? <AddressListError
                invalidPostcodeError={invalidPostcodeError}
                searchPostcodeError={searchPostcodeError}
                onClickOutsideError={onClickOutsideError}
              />
              : null
          }
          {
            addressList && showList
              ? <AddressList
                addressList={addressList}
                closeList={closeList}
                searchFullAddress={searchFullAddress}
                formName={formName}
              />
              : null
          }
        </div>
      </Grid>
      <Grid item xs={12}>

      </Grid>
      {/* this has a visible prop instead of just not being included
      because it needs to always exist in dom for form validation */}
      <Grid item xs={12}>
        <AddressFull
          fullWidth={fullWidth}
          required={required}
          visible={showFields || alwaysShowFields}
        />
      </Grid>
    </Grid>
  )

  return (
    noContentBox
      ? inner
      : <ContentBox givenContentClass={classes.addressContent}>
        <ContentBoxHeader>
          <TextContent><H2 className={classes.contentBoxHeader} value={translations('Address')} /></TextContent>
        </ContentBoxHeader>
        <ContentBoxBody>
          {inner}
        </ContentBoxBody>
      </ContentBox>
  )
}

Address.propTypes = {
  inputValue: PropTypes.string,
  searchPostcodePending: PropTypes.bool,
  searchPostcode: PropTypes.func.isRequired,
  addressList: PropTypes.arrayOf(PropTypes.object),
  searchPostcodeError: PropTypes.bool,
  searchFullAddress: PropTypes.func.isRequired
}

export default withStyles(style)(Address)
