import moment from 'moment'
import React from 'react'
import { translations } from '../../../config'
import {
  Column,
  ColumnContent,
  ColumnHeader,
  Container,
  Row,
} from './PromotionCard.styles'
import { get } from 'lodash'

const PromotionCard = ({ promotion, onClick }) => {
  const _startDate = moment(promotion.startDate).format('DD/MM/YYYY')
  const _endDate = moment(promotion.endDate).format('DD/MM/YYYY')

  const handleClick = () => {
    onClick(promotion.id)
  }

  return (
    <Container onClick={handleClick}>
      <Row>
        <Column>
          <ColumnHeader>{translations('Promotion Description')}</ColumnHeader>
          <ColumnContent style = {{width: '80%'}}>{promotion.description}</ColumnContent>
        </Column>
        <Column>
          <ColumnHeader>{translations('Conditions No.')}</ColumnHeader>
          <ColumnHeader>{get(promotion,'promotionConditions', []).length}</ColumnHeader>
        </Column>
        <Column>
          <ColumnHeader>{translations('Promotion Group')}</ColumnHeader>
          <ColumnContent>{promotion.promotionGroup}</ColumnContent>
        </Column>
      </Row>
      <Row>
        <Column>
          <ColumnHeader>Start Date</ColumnHeader>
          <ColumnContent>{_startDate}</ColumnContent>
        </Column>
        <Column>
          <ColumnHeader>End Date</ColumnHeader>
          <ColumnContent>{_endDate}</ColumnContent>
        </Column>
        <Column>
          <ColumnHeader>Enabled</ColumnHeader>
          <ColumnContent>
            {promotion.enabled ? translations('True') : translations('False')}
          </ColumnContent>
        </Column>
      </Row>
    </Container>
  )
}

export default PromotionCard
