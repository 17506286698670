import { useSelector } from 'react-redux'

// import { getIsLoading } from '../store/modules/productListDetails/selectors'

/**
 * @description returns if the store is currently loading list details
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}
