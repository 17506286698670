const styles = (theme) => ({
  scanButtonContainer:{
    display: 'flex',
    alignItems: 'self-end'
  },
  scanButton: {
    borderRadius: 0
  },
  scanInput: {
    minWidth: '200px'
  }
})

export default styles
