const styles = theme => ({
  messageOptionsContainer: {
    marginTop: 10,
    [theme.breakpoints.up('lg')]: {
      marginTop: 20
    }
  },
  dropDownTemplatesContainer: {
    marginTop: 10,
    [theme.breakpoints.up('lg')]: {
      marginTop: 20
    }
  },
  dropDownStyle: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 210
    },
    minWidth: 245,
    [theme.breakpoints.up('lg')]: {
      minWidth: 270
    }
  },
  inputInkbar: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 210
    },
    minWidth: 245,
    [theme.breakpoints.up('lg')]: {
      minWidth: 270
    }
  },
  formControl: {
    display: 'block'
  }
})

export default styles
