import customerService from './services'
import * as constants from './constants'
import * as selectors from './selectors'
import { selectors as authSelectors } from '../auth'

import digitalStoreSdk from '../../../digitalStoreSdk'

import { customerRegionEnabled } from '../../../config'

class CustomerActions {
  searchPostcode = searchTerm => ({
    type: constants.SEARCH_POSTCODE,
    promise: () => {
      return customerService.searchPostcode(searchTerm)
    }
  })

  searchFullAddress = id => ({
    type: constants.SEARCH_FULL_ADDRESS,
    promise: () => customerService.searchFullAddress(id)
  })

  searchCustomerFresh = ({ query }) => ({
    type: constants.SEARCH_CUSTOMER_FRESH,
    query,
    promise: (dispatch, getState) => {
      const state = getState()
      const brands = authSelectors.getCurrentStoreBrands(state)
      const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined
      
      //Dont search less than 3 chars - very inefficient
      if (query.length < 3 || !brands) {
        return Promise.resolve({ total : 0, results: [], query})
      }

      return digitalStoreSdk.customers.searchCustomers({ query, page: 1, size: 30, loggedInStoreId, extras: { brands } })
        .then(({ total, items }) => ({ total, results: items, query }))

    }
  })
  searchCustomerNext = () => ({
    type: constants.SEARCH_CUSTOMER_NEXT,
    promise: (dispatch, getState) => {
      const state = getState()
      const page = selectors.getCustomerListPage(state)
      const query = selectors.getCustomerListQuery(state)
      const size = selectors.getCustomerListSize(state)
      const brands = authSelectors.getCurrentStoreBrands(state)
      const loggedInStoreId = customerRegionEnabled ? authSelectors.getUserSelectedStoreId(state) : undefined
      return digitalStoreSdk.customers
        .searchCustomers({ query, page: page + 1, size, loggedInStoreId, extras: { brands } })
        .then(({ total, items }) => ({ total, results: items, query }))
    }
  })

  updateCustomerInList = ({ customer }) => ({
    type: constants.UPDATE_CUSTOMER_IN_LIST,
    customer
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new CustomerActions()
