import SelectCashPaymentDeviceModal from '../../../components/PaymentDevices/SelectCashPaymentDeviceModal'
import modalService from '../../modalService'
import uuid from 'uuid/v4'

class CashStarCloudPRNTPaymentDevice {
  // Constants
  deviceType = 'cash:star-cloudprnt';
  deviceName = 'Pay by Cash (Star CloudPRNT)';
  ctaText = 'Pay by Cash (Star CloudPRNT)';
  nameForReceipt = 'CASH'

  async initalizeForCheckoutSession (order) {
    // init Adyen here
    return order
  }

  async processPayment (paymentDevices, leftToPay, order, paymentsToRefund) {
    const payments = _.chain(order.payments)
    const paymentsMade = payments
      .filter((payment) => payment.isRefund)
      .map((payment) => payment.amountPaid)
      .sum()
      .value()

    const products = _.chain(order.products)
    const total = products
      .map((payment) => Math.abs(payment.total.value))
      .defaultTo([])
      .sum()
      .value()

    const outstandingAmount = Number((total - paymentsMade).toFixed(2))

    return new Promise((resolve, reject) => {
      const success = (selectedPaymentDevice, result) => {
        const data = {
          id: uuid(),
          timestamp: new Date().toISOString(),
          paymentDeviceId: selectedPaymentDevice.id,
          amountPaid: parseFloat(result.amount), // the currency input field returns a string,
          success: true,
          name: selectedPaymentDevice.name,
          deviceType: this.deviceType,
          result,
          ...(paymentsToRefund ? { isRefund: true } : {})
        }

        resolve(data)
        return modalService.close()
      }

      const dismiss = () => {
        resolve(null)
        return modalService.close()
      }

      modalService.open({
        component: SelectCashPaymentDeviceModal,
        success,
        dismiss,
        leftToPay,
        paymentsToRefund,
        orderTotal: order.total,
        outstandingAmount,
        paymentDevices: paymentDevices.filter(
          (obj) => obj.deviceType === this.deviceType
        ),
        disableBackdropClick: true,
        disableEscapeKeyDown: true
      })
    })
  }
}

export default new CashStarCloudPRNTPaymentDevice()
