import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withStyles } from '@material-ui/core/styles'
import AlertBadge from '../AlertBadge'
import Button from '../../components/Button'
import style from './style'
import { assets, a11y, translations } from '../../config'

const Header = ({
  children,
  rightElement: RightElement,
  openMenu,
  classes,
  customerModeUpdate,
  networkNotification,
  isAppointmentActive,
  isInsideConsultation,
  isAppointmentComplete,
  isInsideCheckout
}) => {
  const hideBasket = customerModeUpdate || isAppointmentComplete || isInsideCheckout
  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      {networkNotification}
      <Toolbar className={classes.toolBar}>
        <div className={classes.logoContainer}>
          {/* <img className={classes.whiteLetters} src={assets.headerLogo} alt={a11y.mainLogoAltText} /> */}
        </div>
        {!customerModeUpdate && !isInsideCheckout && !(isAppointmentActive && isInsideConsultation) ? (
          <IconButton color='inherit' aria-label='Menu' onClick={openMenu} className={classes.burger}>
            <AlertBadge type='notifications' classes={{ badge: classes.burgerBadge }}>
              <MenuIcon />
            </AlertBadge>
          </IconButton>
        ) : <div />
        }
        {(!hideBasket && RightElement) &&
          <Button aria-label={translations('Basket')} className={classes.basketIcon}><RightElement /></Button>
        }
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  RightElement: PropTypes.element
}

export default withStyles(style)(Header)
