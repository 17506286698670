import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { actions as customersActions, selectors as customersSelectors } from '../../store/modules/customers'
import Address from './Address'

export * from './util'

class AddressContainer extends React.Component {
  state = {
    // Always show fields for fiskars as
    // the address info has a required field
    // so we dont want to have it hidden.
    // showFields: false,
    // showError: false,
    // showList: false
    showFields: true,
    showError: false,
    showList: false
  }

  onClickEnterAddress = () => {
    this.setState({
      showFields: true,
      showError: false,
      showList: false
    })
  }

  onClickFindAddress = () => {
    const { inputValue, searchPostcode } = this.props
    searchPostcode(inputValue)
      .then(() => {
        this.setState({
          showFields: false,
          showError: false,
          showList: true
        })
      })
      .catch(() => {
        this.setState({
          showFields: false,
          showError: true,
          showList: false
        })
      })
  }

  onClickOutsideError = () => {
    const { showError } = this.state
    if (showError) {
      this.onClickEnterAddress()
    }
  }

  closeList = () => {
    this.setState({
      showFields: true,
      showError: false,
      showList: false
    })
  }

  render () {
    return <Address
      {...this.props}
      {...this.state}
      onClickEnterAddress={this.onClickEnterAddress}
      onClickFindAddress={this.onClickFindAddress}
      onClickOutsideError={this.onClickOutsideError}
      closeList={this.closeList}
    />
  }
}

const mapStateToProps = (state, ownProps) => {
  const valueSelector = formValueSelector(ownProps.formName)
  return {
    inputValue: valueSelector(state, 'addressSearch'),
    searchPostcodePending: customersSelectors.getIsSearchingPostcode(state),
    addressList: customersSelectors.getAddressList(state),
    searchPostcodeError: customersSelectors.getSearchPostcodeError(state),
    invalidPostcodeError: customersSelectors.getInvalidPostcodeError(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    searchPostcode: postcode => {
      return dispatch(customersActions.searchPostcode(postcode))
    },
    searchFullAddress: id => {
      return dispatch(customersActions.searchFullAddress(id))
    }
  }
}

const ConnectedAddress = connect(mapStateToProps, mapDispatchToProps)(AddressContainer)

export default ConnectedAddress
