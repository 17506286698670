import React, { Fragment, useState } from "react";
import classNames from "classnames";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";

import { translations } from "../../../../config";
import H2 from "../../../../components/H2";
import P from "../../../../components/P";

import BasketList from "../../../Basket/BasketList";
import style from "./style";
import Button from "../../../../components/Button";
import ValueAddedServicesOrderModal from "../../../../components/ValueAddedServicesOrderModal";
import modalService from "../../../../services/modalService";

// preserve product ordering when making changes to discounts
function sortById(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

const BasketSection = (props) => {
  const {
    groupedProducts,
    products: ungroupedProducts,
    editable,
    basketType,
    addProduct,
    classes,
    isOrderWithPromotion,
    discountMode,
    toggleDiscountMode,
    addSelection,
    removeSelection,
    toggleSelectAll,
    selectedLines,
    applyDiscount,
    numSelected,
    discountAll,
    addDiscountItems,
    hideUnpurchasedItems,
    hideUndo,
    promoCode,
    removePromotionFromOrder,
    hasValidPromoCode
  } = props;

  const isRefundOrExchange = basketType !== "default";
  const showHeader = isRefundOrExchange;
  const title = isRefundOrExchange ? basketType : "Consultation";
  const productList = (discountMode ? ungroupedProducts : groupedProducts).sort(
    sortById
  );
  const ungroupedProductsSanitized = _.filter(ungroupedProducts, (product) => {
    return !product.service && !product.refund;
  });
  const allSelected = numSelected === ungroupedProductsSanitized.length;

  const openOrderValueAddedServicesModal = () => {
    const onSuccess = (params) => {
      addProduct({
        product: {
          ...params.selectedService,
          service: params.selectedService.service,
          id: params.selectedService.id,
          name: params.selectedService.name,
          categoryId: params.selectedService.categoryId,
          brand: params.selectedService.brand,
          link: params.selectedService.link,
          price: {
            code: params.selectedService.price.code,
            value: params.price,
          },
          discount: params.selectedService.discount,
          images: params.selectedService.images,
          externalProductId: params.selectedService.externalProductId,
          preview: params.selectedService.preview,
          service: params.selectedService.service,
          clientId: params.selectedService.clientId,
          serviceForProductId: null,
          lineItemNotes: params.notes || null,
          vatPercent: params.selectedService.vatPercent,
          variant: params.selectedService.variants[0],
        },
        quantity: 1,
        silent: false,
      });
      modalService.close();
    };

    const onDismiss = () => null;

    modalService.open({
      component: ValueAddedServicesOrderModal,
      success: onSuccess,
      dismiss: onDismiss,
    });
  };

  return (
    <div className={classNames(classes.section, classes.basketSection)}>
      <div className={classNames(classes.inner, classes.basketInner)}>
        <H2 value={translations("My Basket")} className={classes.title} />
        {
          isOrderWithPromotion ? (
            <div className={classes.discountButtonContainer}>
              <Button
                buttonType='primary'
                className={classes.discountButton}
                onClick={removePromotionFromOrder}
              >
                {translations('Remove Promotion')}
              </Button>
            </div>
          ) : null
        }
        <div className={classes.discountButtonContainer}>
          {editable ? (
            discountMode ? (
              <Button
                buttonType="primary"
                className={classes.discountButton}
                onClick={discountAll}
              >
                {translations("Discount whole order total")}
              </Button>
            ) : (
              <Button
                buttonType="primary"
                className={classes.discountButton}
                onClick={() => toggleDiscountMode(true)}
              >
                {translations("Add Discount")}
              </Button>
            )
          ) : null}
        </div>
        {editable && (
          <div className={classes.discountButtonContainer}>
            <Button
              buttonType="primary"
              className={classes.discountButton}
              onClick={() => openOrderValueAddedServicesModal()}
            >
              {translations("Order-level Value Adding Services")}
            </Button>
          </div>
        )}
        <div className={classes.basketList}>
          {discountMode ? (
            <Fragment>
              <div className={classes.discountModeSubheader}>
                <p>Discount Mode</p>
                <Button hyperlink onClick={() => toggleDiscountMode(false)}>
                  {translations("Back to full basket")}
                </Button>
              </div>
              <div className={classes.discountModeButtonsContainer}>
                <Button
                  hyperlink
                  className={classes.discountModeSelectAll}
                  onClick={toggleSelectAll}
                >
                  {translations(allSelected ? "Deselect all" : "Select all")}
                </Button>
                <Button
                  buttonType="primary"
                  className={classes.discountModeApply}
                  onClick={() => applyDiscount()}
                  disabled={numSelected === 0}
                >
                  {translations("Discount") + ` (${numSelected})`}
                </Button>
              </div>
            </Fragment>
          ) : null}
          {showHeader ? (
            <div className={classes.basketTypeHeader}>
              <P
                type={"promoText"}
                value={translations(title)}
                className={classes.basketTypeTitle}
              />
            </div>
          ) : null}
          <BasketList
            products={productList}
            editableQuantities={editable}
            editablePrices={editable}
            discountMode={discountMode}
            addSelection={addSelection}
            removeSelection={removeSelection}
            selectedLines={selectedLines}
            addDiscountItems={addDiscountItems}
            hideUnpurchasedItems={hideUnpurchasedItems}
            hideUndo={hideUndo}
          />
        </div>
        {hasValidPromoCode && promoCode && isOrderWithPromotion ? (
            <div className={classes.promoCodeContainer}>
              <div className={classes.promoCodes}>{promoCode}</div>
            </div>
          )
          : ''
        }
      </div>
    </div>
  );
};

export default withStyles(style)(BasketSection);
