import * as helpers from './helpers'
import sdk from '../../../digitalStoreSdk'
import _ from 'lodash'

class StarCloudPRNTService {
  static generateContentToPrint = ({ order, user, printAsCustomerRefundCopy, gifts }) => {
    let content = ''
    const isGiftReceipt = _.isArray(gifts) && gifts.length > 0
    const isPartialOrFullRefund = order.products.find((product) => product.refund && product.returnReasonCode)
    const isVintagePurchase = order.products
      .some(product =>
        _.get(product, 'details.isVintage', false) &&
            _.get(product, 'price.value', 0) < 0 &&
            !_.get(product, 'refund', false)
      )
    const store = user.stores.find((store) => store.id === order.storeId)
    const receiptLanguage = _.get(store, 'details.receiptLanguage', 'en')

    content += helpers.generateLogoSection(user)
    content += helpers.generateReceiptIdentifier(order, user, receiptLanguage, isGiftReceipt)

    if (isGiftReceipt) { content += helpers.generateGiftReceiptHeaderSection(receiptLanguage) }
    if (isPartialOrFullRefund) { content += helpers.generateReturnHeaderSection(receiptLanguage) }
    if (isVintagePurchase) { content += helpers.generateVintagePurchaseHeaderSection(receiptLanguage) }

    if (isGiftReceipt) {
      content += helpers.generateGiftDetailSection(order, gifts, receiptLanguage)
    }

    if (!isGiftReceipt) {
      content += helpers.generateProductDetailSection(order, receiptLanguage)
      content += helpers.generateSummarySection(order, receiptLanguage)
      if (!isVintagePurchase) { content += helpers.generateVatSection(order, receiptLanguage) }
      content += helpers.generatePaymentSection(order, isPartialOrFullRefund, receiptLanguage)
      if (isPartialOrFullRefund && !printAsCustomerRefundCopy) { content += helpers.generateReturnSection(receiptLanguage) }
    }

    content += helpers.generateStoreAddressSection(store, receiptLanguage)
    content += helpers.generateTermAndConditionSection(store)

    return helpers.getFullHTMLContent(content)
  }

  static print = async (accessId, contentToPrint, contentType) => {
    try {
      const response = await sdk.proxyEndpoints.cloudPrntPrintReceipt({
        accessId,
        contentToPrint,
        contentType
      })
      return { data: response }
    } catch (error) {
      return { error }
    }
  }

  static openDrawer = async (accessId) => {
    try {
      const response = await sdk.proxyEndpoints.cloudPrntOpenDrawer({
        accessId
      })

      return { data: response }
    } catch (error) {
      return { error }
    }
  }
}

export default StarCloudPRNTService
