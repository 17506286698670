import _ from 'lodash'
import { createSelector } from 'reselect'

import { selectors as authSelectors } from '../auth'
import { getFullName } from '../../../helpers'
import { FETCH_STORE } from './constants'

export const getStore = state => _.get(state, 'storeDetails.result')
export const getStoreId = state => _.get(state, 'storeDetails.result.id')
export const getStoreUsers = state => _.get(state, 'storeDetails.result.users', [])
export const getIsFetchStoreLoading = state => _.get(state, 'storeDetails.status') === 'PENDING' && _.get(state, 'storeDetails.action') === FETCH_STORE
export const getView = state => _.get(state, 'storeDetails.view')
export const getError = state => _.get(state, 'storeDetails.error')
export const getStoreCalendars = state => _.get(getStore(state), 'calendars')

export const getStoreCalendarOptions = createSelector(
  [getStoreCalendars],
  (storeCalendars = []) => {
    return storeCalendars.map(calendar => ({
      value: calendar.id,
      label: calendar.name
    }))
  }
)

export const getStoreROSCalendarOptions = createSelector(
  [getStoreCalendars],
  (storeCalendars = []) => {
    const rosCalendars = _.chain(storeCalendars)
      .filter(calendar => !calendar.externalCalendarId)
      .map(calendar => ({
        value: calendar.id,
        label: calendar.name
      }))
      .value()
    return rosCalendars
  }
)

const optionFromUser = (user, me) => ({
  label: getFullName(user),
  value: user.id
})
const optionFromMe = user => {
  const option = optionFromUser(user)
  option.label = `ME (${option.label})`
  return option
}

export const getViewOptions = createSelector(
  [getStoreUsers, authSelectors.getActiveUserId],
  (storeUsers, activeUserId) => {
    const optionGroups = []
    if (!storeUsers) return optionGroups

    optionGroups.push([{ label: 'STORE VIEW', value: 'store' }])
    
    const activeUserIndex = storeUsers.findIndex(user => user.id === activeUserId)
    const hasActiveUser = activeUserIndex > -1
    if (hasActiveUser) {
      optionGroups.push([optionFromMe(storeUsers[activeUserIndex])])
    }

    let otherUsers = [...storeUsers]
    if (hasActiveUser) otherUsers.splice(activeUserIndex, 1)
    optionGroups.push(otherUsers.map(optionFromUser))

    return optionGroups
  }
)

export const getViewValue = createSelector(
  [getView],
  (view) => (
    view.user
    ? _.get(view, 'user.id')
    : view.type
  )
)

export const getViewUser = createSelector(
  [getView],
  (view) => _.get(view, 'user')
)

export const getTimePeriod = state => _.get(state, 'storeDetails.timePeriod')
