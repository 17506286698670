import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { getImage } from '../../Images'

const Product = styled.div`
  padding: 0.8em;
  font-size: 14px;
  border-bottom: 0.5px solid #B9B9B9;
  text-align: left;
`

const Add = styled.img`
  float: right;
  &:hover { cursor: pointer; }
`

const ProductListDetailsAdd = ({
  label,
  onClick
}) => {
  return (
    <Product>
      <div style={{ width: '90%', display: 'inline-block' }}>{label}</div>
      <div style={{ width: '10%', float: 'right', display: 'inline-block' }}><Add src={getImage('plusIcon')} onClick={onClick}/></div>
    </Product>
  )
}

ProductListDetailsAdd.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ProductListDetailsAdd
