const __RESOURCE_NAME = 'paymentDevices'

import _ from 'lodash'
import { createSelector } from 'reselect'
import { PENDING } from '../../middleware/redux-promise'

export const getAll = state => _.get(state, `${__RESOURCE_NAME}.result.items`) || []
export const getError = state => _.get(state, `${__RESOURCE_NAME}.error`)
export const getIsLoading = state => _.get(state, `${__RESOURCE_NAME}.status`) === PENDING
export const getConnectedTerminals = state => _.get(state, `${__RESOURCE_NAME}.connectedTerminals`)

export const getAllAsOptions = createSelector([
  getAll
], (items) => {
  return items.map(obj => ({ value: obj.id, label: obj.name }))
})
