import React from 'react'

import AccessoryViewHistoryScreen from './AccessoryViewHistoryScreen'
import AccessoriesSearchScreen from './AccessoriesSearchScreen'
import AccessoryDetailsScreen from './AccessoryDetailsScreen'
import PrivateRoute from '../../components/PrivateRoute'
import TimedLogoutComponent from '../../components/TimedLogout'

export default [
  <PrivateRoute key='/accessories' path='/accessories' exact component={TimedLogoutComponent(AccessoriesSearchScreen)} />,
  <PrivateRoute key='/accessories/new' path='/accessories/new' exact component={TimedLogoutComponent(AccessoryDetailsScreen)} />,
  <PrivateRoute key='/accessories/:id' path='/accessories/:id' exact component={TimedLogoutComponent(AccessoryDetailsScreen)} />,
  <PrivateRoute key='/accessories/:id/history' path='/accessories/:id/history' exact component={TimedLogoutComponent(AccessoryViewHistoryScreen)} />,
]
