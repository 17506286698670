import React from 'react'
import { pure } from 'recompose'

import ChartTile from '../../../../components/Charts/ChartTile'
import ReportChart from '../../../Reporting/ReportChart'
import SalesByPerson from '../SalesByPerson'
import { slugify } from '../../../../helpers'
import { storeReportingSchema as schema } from '../../../../config'

const StoreCharts = ({
  store,
  timePeriod
}) => {
  return (
    schema.map((reportSchema, i) => {
      if(reportSchema.title === 'Sales per Person'){
        return (
          <SalesByPerson
            storeId={store.id}
            reportSchema={reportSchema}
            timePeriod={timePeriod}
          />
        );
      }

      const link = `/retail-analytics/store/${store.id}/${timePeriod}/${i}-${slugify(reportSchema.title)}`
      const to = reportSchema.fullList && !(reportSchema.type === 'LIST') && link
      const seeAllLink = reportSchema.fullList && (reportSchema.type === 'LIST') && link

      return (
        <ChartTile to={to || undefined} key={link}>
          <ReportChart
            items={[store]}
            reportSchema={reportSchema}
            seeAllLink={seeAllLink}
            timePeriod={timePeriod}
          />
        </ChartTile>
      )
    })
  )
}

export default pure(StoreCharts)
