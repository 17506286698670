import _ from 'lodash'
import { makeRequest } from '../makeRequest'
import { mapReferencedRefundRequest } from './mapRequest'
import { mapReferencedRefundResponse } from './mapResponse'
import { transactionStatusRequest } from '../transactionStatusRequest'

export async function refrerencedRefundRequest(paymentDevice, originalTransactionId, originalTransactionTimestamp, total, orderNumber) {
  const mappedRequest = mapReferencedRefundRequest(paymentDevice, originalTransactionId, originalTransactionTimestamp, total, orderNumber)
  const response = await makeRequest(mappedRequest)

  // Parse the Adyen SDK response
  let mappedResponse = mapReferencedRefundResponse(response)

  if (mappedResponse === null) {
    /**
     * No response from payment terminal, so
     * wait and send a status request to get the status of the transaction.
     */
    await new Promise(r => setTimeout(r, 5 * 1000))

    // Fetch the status
    mappedResponse = await transactionStatusRequest(
      paymentDevice,
      mappedRequest.saleToPOIRequest.messageHeader.serviceID,
      mappedRequest.saleToPOIRequest.messageHeader.saleID
    )
  }

  _.set(mappedResponse, 'data.apiRequest', mappedRequest)

  return {
    success: mappedResponse.success,
    message: mappedResponse.message,
    error: mappedResponse.error,
    data: mappedResponse.data
  }
}
