import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import ContentBox from '../../../../../components/ContentBox'
import ContentBoxHeader from '../../../../../components/ContentBox/ContentBoxHeader'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import TextContent from '../../../../../components/Form/TextContent'
import H2 from '../../../../../components/H2'
import P from '../../../../../components/P'
import { SelectStore, Dropdown } from '../../../../../components/Fields'
import * as validators from '../../../../../validators'

import { translations } from '../../../../../config'
import style from './style'

const Preferences = ({ classes, brandOptions }) => {
  return (
    <ContentBox givenContentClass={classes.communicationContentContainer}>
      <ContentBoxHeader>
        <TextContent><H2 className={classes.contentBoxHeader} value={translations('Preferences')} /></TextContent>
      </ContentBoxHeader>
      <ContentBoxBody>
        <div className={classes.storesContainer}>
          <FormControl className={classes.fullWidth}>
            <P value={translations('Favourite Store')} />
            <SelectStore name={'details.favouriteStore'} />
          </FormControl>
          <FormControl className={classes.fullWidth}>
            <P value={translations('Brand *')} />
            <Dropdown name={'details.brands'}
              multiple
              validate={validators.minArrayLength(1)}
              options={brandOptions}>
            </Dropdown>
          </FormControl>
        </div>
      </ContentBoxBody>
    </ContentBox>
  )
}

Preferences.propTypes = {
  classes: PropTypes.shape({}),
  brandOptions: PropTypes.arrayOf(PropTypes.shape({})),
  storesOptions: PropTypes.arrayOf(PropTypes.shape({}))
}

Preferences.defaultProps = {
  brandOptions: []
}
export default withStyles(style)(Preferences)
