import { colors } from '../../../config/theme'


const styles = {
  footer: {
    backgroundColor: colors.lightGrey
  },
  buttonsContainer: {
    padding: 9,
    width: '100%',
    display: 'grid',
    gridGap: '9px'
  },
  safeAreaSpacer: {
    paddingBottom: 'env(safe-area-inset-bottom)'
  },
  copyOfflineOrdersButton: {
    // backgroundColor: 'red',
  },
  gridButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > button:only-child': {
      flex: '1 0 auto'
    },
    '& > button': {
      flex: '0 1 48%'
    }
  }
}

export default theme => styles
