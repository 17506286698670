import { LOCATION_CHANGE } from 'connected-react-router'
import { matchPath } from 'react-router'
import _ from 'lodash'

// import { pathsForCheckoutModule } from '../../../helpers'
import * as authSelectors from '../auth/selectors'
import resourceActions from './actions'

class ResourceMiddleware {
  // fetchResourceMiddleware = ({ dispatch, getState }) => next => action => {
  //   next(action)
  //   if (action.type === LOCATION_CHANGE) {
  //     const path = action.payload.location.pathname
  //     if (
  //       authSelectors.getIsLoggedIn(getState()) &&
  //       (
  //         matchPath(path, { path: `/checkout/paypal-here`, exact: true })
  //         || matchPath(path, { path: `/orders/:orderNumber`, exact: true })
  //       )
  //     ) {
  //       dispatch(resourceActions.fetchAll())
  //     }
  //   }
  // }
}

export default new ResourceMiddleware()
