import { PENDING, SUCCESS, FAIL } from '../../middleware/redux-promise'

class ResourceReducers {
  fetchAll = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.requestPending(state, action)
      case SUCCESS:
        return this.fetchAllSuccess(state, action)
      case FAIL:
        return this.requestFail(state, action)
      default:
        return state
    }
  }

  fetchAllSuccess = (state, action) => ({
    ...state,
    result: action.result,
    status: SUCCESS,
    action: undefined,
    error: null
  })

  fetchConnectedTerminals = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.requestPending(state, action)
      case SUCCESS:
        return this.fetchConnectedTerminalsSuccess(state, action)
      case FAIL:
        return this.requestFail(state, action)
      default:
        return state
    }
  }

  fetchConnectedTerminalsSuccess = (state, action) => {
    const { uniqueTerminalIds } = action.result.data || []

    return {
      ...state,
      connectedTerminals: uniqueTerminalIds,
      status: SUCCESS,
      action: undefined,
      error: null
    }
  }

  requestPending = (state, action) => ({
    ...state,
    status: PENDING,
    action: action.type
  })

  requestFail = (state, action) => ({
    ...state,
    status: FAIL,
    action: undefined,
    error: action.error.message
  })
}

export default new ResourceReducers()
