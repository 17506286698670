import { connect } from 'react-redux'
import { compose } from 'redux'
import { isInvalid } from 'redux-form'
import _ from 'lodash'

import CheckoutWrapper from './CheckoutWrapper'
import withCheckoutFlow from '../../withCheckoutFlow'
import { getModulesFromCheckoutType } from '../../../../helpers'
import { selectors as currentOrderSelectors } from '../../../../store/modules/currentOrder'
import { selectors as promotionSelectors } from '../../../../store/modules/promotions'
import * as currentOrderCombinedSelectors from '../../../../store/modules/combinedSelectors/currentOrderCombinedSelectors'
import { withDiscountMode } from './discountMode'
import { mapProps } from 'recompose'
import { addPrices } from '@redant/digital-store-prices-fiskars/dist/handlePrices'

export default compose(
  withCheckoutFlow,
  withDiscountMode,
  connect((state, props) => {
    const current = _.get(props, 'current')
    const checkoutType = _.get(props, 'checkoutType')
    const basketType = currentOrderCombinedSelectors.getBasketType(state)
    const currentModule = getModulesFromCheckoutType(checkoutType).find(m => m.name === current)
    const isOrderWithPromotion = currentOrderSelectors.getIsOrderWithPromotions(state) && 
      !promotionSelectors.getRemovePromotionFromOrder(state)
    const hasPromoCode = promotionSelectors.getHasPromoCode(state)
    const promoCode = promotionSelectors.getPromoCode(state)
    const hasValidPromoCode = promotionSelectors.getHasValidPromoCode(state)

    return {
      basketType,
      invalid: !!(props.formId && isInvalid(props.formId)(state)),
      displayOrderDate: _.get(currentModule, 'orderDate'),
      displayBasketSection: _.get(currentModule, 'name') !== 'PAYMENT_SALESFORCE',
      submitText: _.get(currentModule, 'submitButtonText'),
      isOrderWithPromotion,
      hasPromoCode,
      promoCode,
      hasValidPromoCode
    }
  }),
  mapProps(props => {
    const { subTotal, totalDiscount } = props
    const subtotalBeforeDiscount = addPrices(subTotal, totalDiscount)

    return { ...props, subtotalBeforeDiscount }
  })
)(CheckoutWrapper)
