import React, { useState } from 'react'
import { translations } from '../../config'
import BasicModalOverlay from '../BasicModalOverlay'
import Dropdown from '../Fields/Dropdown'
import sdk from '../../digitalStoreSdk'
import StarCloudPRNTService from '../../services/paymentDeviceService/starCloudPRNTService'
import toastService from '../../services/toastService'
import styled from 'styled-components'

const Error = styled.p`
  color: red;
`;

const PrintReceiptModal = ({ order, printers, onCancel }) => {
  const [selectedPrinterId, setSelectedPrinterid] = useState(null)
  const [error, setError] = useState(null)

  const handlePrint = async () => {
    setError(null)
    try {
      const { content } = await sdk.archiveReceipt.getReceipt({
        orderNumber: order.orderNumber,
        orderDate: order.orderDate
      })
      const selectedPrinter = printers.find(
        (printer) => printer.id === selectedPrinterId
      )

      StarCloudPRNTService.print(
        selectedPrinter.config.modelNumber,
        content,
        'text/html'
      )

      toastService.action({
        type: 'success',
        message: translations('Receipt sent to printer'),
        verticalPosition: 'top',
        horizontalPosition: 'right'
      })

      onCancel() // close modal
    } catch (error) {
      setError(translations('Receipt not found'))
    }
  }

  const handleChange = ({ target: { value } }) => {
    setSelectedPrinterid(value)
  }

  return (
    <BasicModalOverlay
      actions={[
        {
          disabled: !selectedPrinterId,
          primary: true,
          text: translations('Print Receipt'),
          onClick: () => {
            handlePrint()
          }
        },
        {
          primary: true,
          text: translations('Cancel'),
          onClick: () => {
            onCancel()
          }
        }
      ]}
    >
      <p>
        Please select the printer you would like to use to print the receipt
      </p>
      <Dropdown
        value={selectedPrinterId}
        label='Printer'
        options={printers.map((printer) => ({
          value: printer.id,
          label: printer.name
        }))}
        onChange={handleChange}
      />

      {error && <Error>{error}</Error>}
    </BasicModalOverlay>
  )
}

export default PrintReceiptModal
