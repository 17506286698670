import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../config/theme'
import { translations } from '../../config'
import ProductListCard from './ProductListCard'
import EmptyListMessage from './EmptyListMessage'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorMessage from './ErrorMessage'

const Container = styled.div`
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: top;
  overflow-x: auto;
`

const ListWrapper = styled.div`
  margin: 10px 5px;
  min-height: 650px;
  min-width: 400px;
  width: 20%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: 1440px) {
    min-width: 300px;
  }
`

const ListListWrapper = styled.li`
  list-style: none;
  margin: 0 auto 0 auto;
`

const ListTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  padding: 20px 0;
  margin: 0;
  border-bottom: 1px solid ${colors.lightGreyBorderBackground};
  text-transform: capitalize;
  background-color: ${colors.whiteBackground};
`

const LoaderWrapper = styled.div`
  text-align: center;
  margin-top: 50%;
`

const ErrorWrapper = styled.div`
  margin: 0 auto;
  width: 500px;
`

const ProductListDesktop = ({ config, lists, isLoading, error, onListItemClick }) => {
  const { columns } = config
  const listColumns = columns.filter(({ status }) => status !== 'all')

  return (
    <>
      <ErrorWrapper>{error[status] && <ErrorMessage content={translations('503 Error')} />}</ErrorWrapper>
      <Container>
        {listColumns.map(({ status, title }) => (
          <ListListWrapper key={status}>
            <ListWrapper>
              <ListTitle>{title}</ListTitle>
              <div style={{ padding: '0 3%' }}>
                {isLoading[status] ? (
                  <LoaderWrapper>
                    <CircularProgress size={50} color='secondary' />
                  </LoaderWrapper>
                ) : (
                  <>
                    {lists[status].length === 0 && <EmptyListMessage status={title} />}
                    {lists[status].length > 0 &&
                      lists[status]
                        .map(
                          ({
                            id,
                            title,
                            subTitle,
                            type,
                            info
                          }) => (
                            <ListListWrapper key={id}>
                              <ProductListCard
                                id={id}
                                title={title}
                                subTitle={subTitle}
                                type={type}
                                info={info}
                                onClick={onListItemClick}
                              />
                            </ListListWrapper>
                          )
                        )}
                  </>
                )}
              </div>
            </ListWrapper>
          </ListListWrapper>
        ))}
      </Container>
    </>
  )
}

ProductListDesktop.propTypes = {
  config: PropTypes.shape({}).isRequired,
  lists: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.object,
  error: PropTypes.bool,
  onListItemClick: PropTypes.func.isRequired
}

ProductListDesktop.defaultProps = {
  lists: [],
  isLoading: false,
  error: false
}

export default ProductListDesktop
