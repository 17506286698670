import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import _ from 'lodash'

import ListPage from '../../../layouts/ListPage'
import { translations, userRoles } from '../../../config'
import { useResults, useIsLoading, useError, useUserStores } from '../../../hooks/storesHooks'
import { actions as storesActions } from '../../../store/modules/stores'
import { getIsLoggedInBase } from '../../../store/modules/auth/selectors'

const StoresSearchScreen = ({ onStoreClick, onBackClick }) => {
  let onRowClick
  const user = useSelector(getIsLoggedInBase)
  const results = useResults()
  const userStores = useUserStores()
  const isLoading = useIsLoading()
  const error = useError()
  const dispatch = useDispatch()

  if (onStoreClick) {
    onRowClick = onStoreClick
  } else {
    onRowClick = React.useCallback((cell) => {
      const store = cell.row.original
      dispatch(push(`/stores/${store.id}`))
    }, [])
  }

  const onClickAddStore = React.useCallback(() => {
    dispatch(push(`/stores/new`))
  }, [])

  React.useEffect(() => {
    if (!results || !results.length) {
      if (
        user.role.name === userRoles.STORE_MANAGER ||
        user.role.name === userRoles.AREA_MANAGER
      ) {
        dispatch(
          storesActions.fetchUserStores(user.stores, {
            includes: ['region'],
            sort: 'name'
          })
        )
      } else {
        dispatch(
          storesActions.fetchAllStores({ includes: ['region'], sort: 'name' })
        )
      }
    }
  }, [])

  const columns = React.useMemo(() => ([
    {
      Header: translations('Name'),
      accessor: 'name',
      onClick: onRowClick
    },
    {
      Header: translations('External ID'),
      accessor: 'externalStoreId'
    },
    {
      Header: translations('Region'),
      accessor: 'regionName'
    },
    {
      Header: translations('Catalogue'),
      accessor: 'catalogue'
    }
  ]), [])

  const mappedResults = React.useMemo(() => {
    let _results = user.role.name === userRoles.STORE_MANAGER ? userStores : results;
    return _results.map(result => ({
      ...result,
      regionName: _.get(result, 'region.name')
    }))
  }, [results])

  const actionButtons = [
    {
      label: translations('Create Store'),
      onClick: onClickAddStore
    }
  ]

  return (
    <ListPage
      title={translations('Page Title - Stores')}
      onRowClick={onRowClick}
      onBackClick={onBackClick}
      columns={columns}
      isLoading={isLoading}
      results={mappedResults}
      error={error}
      actionButtons={
        user.role.permissions.createNewStore ? actionButtons : []
      }
    />
  )
}

export default StoresSearchScreen
