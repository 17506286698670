import React, { useCallback, useEffect, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import ListPage from '../../../layouts/ListPage'
import { useResults, useIsLoading, useError, useConnectedTerminals } from '../../../hooks/paymentDevicesHooks'
import { actions as paymentDevicesActions } from '../../../store/modules/paymentDevices'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { ADYEN_CLOUD_PAYMENT_DEVICE, STAR_CLOUD_PRNT_DEVICE } from '../../../services/paymentDeviceService'
import { ACCESSORY_MANAGEMENT_CASH_DRAWERS, ACCESSORY_MANAGEMENT_PAYMENT_TERMINALS } from '../../../store/modules/app/constants'
import { translations } from '../../../config'
import currencyFormatter from '../../../formatters/currencyFormatter'

const mapStateToProps = (state) => ({
  loggedInUserRole: authSelectors.getLoggedInUserRole(state)
})

const AccessoriesSearchScreen = (props) => {
  const results = useResults()
  const connectedTerminals = useConnectedTerminals()
  const isLoading = useIsLoading()
  const error = useError()
  const dispatch = useDispatch()

  const { selectedTab, changeAccessoryManagementTab } = props
  const isAuthorisedToAddNewDevice = props.loggedInUserRole.value < 4

  const generateTableColumns = (tab) => {
    const currentFloatColumn = {
      Header: translations('Current Float'),
      accessor: 'currentFloat'
    }

    const defaultColumns = [
      {
        Header: translations('Name'),
        accessor: 'name',
        onClick: onRowClick
      },
      {
        Header: translations('External ID'),
        accessor: 'externalStoreId'
      },
      {
        Header: translations('Last Transaction'),
        accessor: 'lastTransaction'
      },
      {
        Header: translations('State'),
        accessor: 'enabled'
      },
      {
        Header: translations('Connection'),
        accessor: 'connection'
      }
    ]

    return tab === ACCESSORY_MANAGEMENT_PAYMENT_TERMINALS
      ? defaultColumns
      : [ ...defaultColumns.slice(0, 2), currentFloatColumn, ...defaultColumns.slice(2) ]
  }

  const onClickAddDevice = useCallback(() => {
    dispatch(push(`/accessories/new`))
  }, [])

  const onRowClick = useCallback((cell) => {
    const accessory = cell.row.original
    dispatch(push(`/accessories/${accessory.id}`))
  }, [])

  const actionButtons = isAuthorisedToAddNewDevice
    ? [{ label: translations('Add Device'), onClick: onClickAddDevice }]
    : []

  const tabs = [
    {
      label: translations('Cash Drawers'),
      content: null,
      value: ACCESSORY_MANAGEMENT_CASH_DRAWERS
    },
    {
      label: translations('Payment Terminals'),
      content: null,
      value: ACCESSORY_MANAGEMENT_PAYMENT_TERMINALS
    }
  ]

  const columns = useMemo(() => generateTableColumns(selectedTab), [selectedTab])

  const mappedResults = useMemo(() => {
    const filteredResults = selectedTab === ACCESSORY_MANAGEMENT_CASH_DRAWERS
      ? results.filter((result) => result.deviceType === STAR_CLOUD_PRNT_DEVICE)
      : results.filter((result) => result.deviceType === ADYEN_CLOUD_PAYMENT_DEVICE)

    const updatedResults = filteredResults.map((result) => ({
      ...result,
      lastTransaction: result.lastTransaction ? moment(result.lastTransaction).format('DD.MM.YYYY HH:mm') : '-',
      enabled: result.enabled ? 'Enabled' : 'Disabled',
      currentFloat: result.values && result.values.float
        ? currencyFormatter.format(result.values.float)
        : null
    }))

    return selectedTab === ACCESSORY_MANAGEMENT_CASH_DRAWERS
      ? updatedResults
      : updatedResults.map((result) => {
        const { modelNumber, serialNumber } = result.config
        const terminalId = `${modelNumber}-${serialNumber}`
        const connection = connectedTerminals.includes(terminalId) ? 'Connected' : 'Disconnected'

        return {
          ...result,
          connection
        }
      })
  }, [results, selectedTab])

  useEffect(() => {
    dispatch(paymentDevicesActions.fetchAll())
    dispatch(paymentDevicesActions.fetchConnectedTerminals())
  }, [])

  return (
    <ListPage
      title={translations('Page Title - Accessory Management')}
      onRowClick={onRowClick}
      columns={columns}
      isLoading={isLoading}
      results={mappedResults}
      error={error}
      actionButtons={actionButtons}
      tabs={tabs}
      selectedTab={selectedTab}
      handleTabChange={changeAccessoryManagementTab}
    />
  )
}

export default connect(mapStateToProps)(AccessoriesSearchScreen)
