export default theme => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionTitle: {
    fontWeight: 'bold',
    margin: '10px 0px',
  },
  alertText: {
    fontWeight: 'bold',
    color: 'darkred',
    margin: '10px 0px',
  },
  sectionValue: {
    margin: '10px 0px',
  },
})
