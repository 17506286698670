import { connect } from 'react-redux'
import _ from 'lodash'
import CountCashModal from './CountCashModal'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { getFormValues, change } from 'redux-form'
import { formId } from './CountCashModal'
import { selectors as authSelectors } from '../../store/modules/auth'
import { currencyThreshold } from '../../config'

const mapStateToProps = (state, props) => {
  const formValues = getFormValues(formId)(state)
  const currencyCode = authSelectors.getCurrencyCode(state)
  const loggedInUserRole = authSelectors.getLoggedInUserRole(state)

  return {
    currencyCode,
    loggedInUserRole,
    formValues: formValues || {},
  }
}

// const countCashConfig = {
//   "warningThresholdPercentage": 10,
//   "denominations": [
//     { "denomination": "1c", "unitValue": 0.01 },
//     { "denomination": "2c", "unitValue": 0.02 },
//     { "denomination": "5c", "unitValue": 0.05 },
//     { "denomination": "10c", "unitValue": 0.10 },
//     { "denomination": "20c", "unitValue": 0.20 },
//     { "denomination": "50c", "unitValue": 0.50 },
//     { "denomination": "C1", "unitValue": 1.00 },
//     { "denomination": "C2", "unitValue": 2.00 },
//     { "denomination": "C5", "unitValue": 5.00 },
//     { "denomination": "C10", "unitValue": 10.00 },
//     { "denomination": "C20", "unitValue": 20.00 },
//     { "denomination": "C50", "unitValue": 50.00 },
//     { "denomination": "C100", "unitValue": 100.00 },
//     { "denomination": "C200", "unitValue": 200.00 },
//     { "denomination": "C500", "unitValue": 500.00 },
//   ]
// }

export default compose(
  connect(mapStateToProps, dispatch => ({ dispatch })),
  withState('showWarning', 'setShowWarning', false),
  withPropsOnChange(['formValues', 'expectedAmount'], ({ formValues, currencyCode, expectedAmount: _expectedAmount }) => {
    let expectedAmount = _expectedAmount
    if (!expectedAmount || !expectedAmount.code || !expectedAmount.value) {
      expectedAmount = { code: currencyCode, value: 0 }
    }

    const total = _.chain(formValues)
      .keys()
      .filter(k => k.includes('_total'))
      .map(k => formValues[k])
      .sum()
      .defaultTo(0.00)
      .thru(value => ({ code: currencyCode, value }))
      .value()

    return { total, expectedAmount }
  }),
  withHandlers({
    onQuantityChange: ({ dispatch, countCashConfig }) => (event) => {
      const quantityFieldName = event.target.name
      const quantity = event.target.value
      const denomination = quantityFieldName.split('_')[0]
      const denominationTotal = _.chain(countCashConfig)
        .get('denominations')
        .find(obj => obj.denomination === denomination)
        .get('unitValue')
        .multiply(quantity)
        .round(2)
        .value()

      dispatch(change(formId, quantityFieldName, quantity))
      dispatch(change(formId, `${denomination}_total`, denominationTotal))
    },
    success: ({ expectedAmount, total, onSubmit, showWarning, setShowWarning, currencyCode }) => () => {
      const threshold = currencyThreshold.find(
        (currencyItem) => currencyItem.currencyCode === currencyCode
      )

      const isTooLow = expectedAmount.value > (total.value + threshold.value)
      const isTooHigh = expectedAmount.value < (total.value - threshold.value)

      if (!showWarning && (isTooLow || isTooHigh)) {
        return setShowWarning(true)
      }

      return onSubmit({ total })
    }
  }),
)(CountCashModal)
