import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import { Dropdown, Input } from '../../Fields'
import Form from '../../Form'
import BasicModalOverlay from '../../BasicModalOverlay'

import style from './style'
import LoaderContainer from '../../LoaderContainer'
import * as validators from '../../../validators'
export const formId = 'checkout-card-payment-modal'
const ThisForm = Form(formId)

class SelectCardPaymentDeviceModal extends Component {
  validateAmount = (amount, values) => {
    const { leftToPay, outstandingAmount, isNegativeAmount } = this.props
    const _leftToPay = Math.min(...([leftToPay, outstandingAmount, Number(_.get(values, 'selectedPaymentToRefund.leftToRefund', 0))].filter(x => x > 0)))

    if (!isNegativeAmount && _leftToPay < parseFloat(amount)) {
      return `Amount must not be more than ${_leftToPay}.`
    }

    if (isNegativeAmount && _leftToPay > parseFloat(amount)) {
      return `Amount must not be less than ${_leftToPay}.`
    }

    if (parseFloat(amount) === 0) {
      return `Amount must  be more than 0.`
    }

    return validators.required(amount)
  }

  render () {
    const {
      classes,
      paymentDeviceOptions,
      paymentOptions,
      processPayment,
      pending,
      currencyCode,
      error,
      manualEntry,
      setManualEntry,
      dismiss,
      paymentsToRefund,
      refundOrderManualEntry,
      initialValues,
      isNegativeAmount,
      onFormChange
    } = this.props

    const isRefund = Boolean(paymentsToRefund)
    const isReferenced = (paymentsToRefund || []).length > 0

    const actions = []


    let isButtonDisabled = !error || manualEntry

    actions.push({
      text: translations('Manual Entry'),
      disabled: isButtonDisabled,
      primary: false,
      onClick: () => setManualEntry(true)
    })

    actions.push({
      text: translations('Cancel'),
      success: false,
      disabled: pending,
      primary: false,
      onClick: () => dismiss(true)
    })

    if (!refundOrderManualEntry) {
      actions.push({
        text: translations('Submit'),
        success: true,
        disabled: pending || !paymentDeviceOptions.length,
        primary: true
      })
    }

    return (
      <ThisForm onSubmit={processPayment} initialValues={initialValues} onChange={onFormChange}>
        <BasicModalOverlay
          title={isRefund ? translations('Card refund') : translations('Card payment')}
          confirmButtonText={isRefund ? translations('Refund') : translations('Pay') }
          actions={actions}
        >
          <LoaderContainer isLoading={pending}>
            <div className={classes.formContainer}>
              {(!isRefund || !isReferenced) && (
                <FormControl className={classes.formControl}>
                  <>
                    <Dropdown
                      required
                      disabled={manualEntry}
                      name='paymentDeviceId'
                      label={translations('Payment Device')}
                      options={paymentDeviceOptions}
                      validate={validators.required}
                    />
                    <FormControl className={classes.formControl}>
                      <Input
                        currencyCode={currencyCode}
                        name='amount'
                        label={isRefund ? translations('Amount to Refund') : translations('Amount to Pay')}
                        type='price'
                        validate={this.validateAmount}
                        negative={isNegativeAmount}
                      />
                    </FormControl>
                  </>
                </FormControl>
              )}

              {(isRefund && isReferenced) && (
                <FormControl className={classes.formControl}>
                    <>
                      <Dropdown
                        required
                        disabled={manualEntry}
                        name='selectedPaymentIdToRefund'
                        label={translations('Payment To Refund')}
                        options={paymentOptions}
                        validate={validators.required}
                      />
                      <FormControl className={classes.formControl}>
                        <Input
                          currencyCode={currencyCode}
                          name='amount'
                          label={translations('Amount to Refund')}
                          type='price'
                          validate={this.validateAmount}
                          negative={isNegativeAmount}
                        />
                      </FormControl>
                    </>
                </FormControl>
              )}

              {!paymentDeviceOptions.length && (
                <p className={classes.errorText}>
                  No payment devices enabled for the current store.
                </p>
              )}
              {error && <p className={classes.errorText}>{error.message}</p>}
              {manualEntry && (
                <FormControl className={classes.formControl}>
                  <Input
                    name='merchantReference'
                    label={translations('Merchant reference')}
                    validate={validators.required}
                  />
                </FormControl>
              )}
            </div>
          </LoaderContainer>
        </BasicModalOverlay>
      </ThisForm>
    )
  }
}

export default withStyles(style)(SelectCardPaymentDeviceModal)
