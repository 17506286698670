import { connect } from 'react-redux'
import _ from 'lodash'
import { getFormValues } from 'redux-form'
import SelectCashPaymentDeviceModal, { formId } from './SelectCashPaymentDeviceModal'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as checkoutFlowSelectors } from '../../../store/modules/checkoutFlow'
import { subtractPrices } from '@redant/digital-store-prices-fiskars'
import * as validators from '../../../validators'

const CASH_PAYMENT_MODAL_VALUE = 'SelectCashPaymentDeviceModal'

const mapStateToProps = (state, props) => {
  const paymentDeviceOptions = _.map(props.paymentDevices, obj => ({ value: obj.id, label: obj.name }))
  const currencyCode = authSelectors.getCurrencyCode(state)
  const selectedPaymentDeviceId = appSelectors.getSelectedPaymentDevices(state)[CASH_PAYMENT_MODAL_VALUE]
  const checkoutFlowType = checkoutFlowSelectors.getCheckoutFlowType(state)
  const initialValues = {
    paymentDeviceId: _.chain(paymentDeviceOptions)
      .map(({ value }) => value)
      .includes(selectedPaymentDeviceId)
      .value()
      ? selectedPaymentDeviceId
      : undefined,
    paymentAmount: props.leftToPay
  }
  const formValues = getFormValues(formId)(state)
  const isNegativeAmount = Boolean(props.leftToPay < 0)
  const isRefund = Boolean(props.paymentsToRefund)

  return {
    formValues,
    checkoutFlowType,
    initialValues,
    currencyCode,
    paymentDeviceOptions,
    isNegativeAmount,
    isRefund
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDefaultPaymentDevice: paymentDeviceId => {
      return dispatch(appActions.setPaymentDevice({
        paymentDeviceId,
        deviceType: CASH_PAYMENT_MODAL_VALUE
      }))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('showChangeDue', 'setShowChangeDue', false),
  withState('error', 'setError', null),

  withState('paymentAmount', 'setPaymentAmount', null),
  withState('cashPaid', 'setCashPaid', null),
  withState('changeDue', 'setChangeDue', null),
  withState('paymentDevice', 'setPaymentDevice', null),
  withState('selectedPrinter', 'setSelectedPrinter', null),

  withPropsOnChange(['total', 'formValues'], ({ currencyCode, formValues = {} }) => {
    const validateAmount = validators.min(parseFloat(formValues.amount), currencyCode)
    return { validateAmount }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    complete: ({ success, paymentAmount, changeDue, paymentDevice }) => () => {
      return success(
        paymentDevice,
        {
          tenderedAmount: paymentAmount,
          changeDue: changeDue,
          amount: paymentAmount.value
        }
      )
    },
    processPayment: ({
      paymentDevices,
      setShowChangeDue,
      setPaymentAmount,
      setChangeDue,
      setCashPaid,
      currencyCode,
      setPaymentDevice,
      setDefaultPaymentDevice,
      isNegativeAmount,
      isRefund
    }) => (formValues) => {
      const paymentDevice = _.find(paymentDevices, { id: formValues.paymentDeviceId })
      if (!paymentDevice) {
        throw new Error(`No payment device found with id ${formValues.paymentDeviceId}`)
      }

      // Round to 2dp
      const _paymentAmount = parseFloat(`${formValues.paymentAmount}`).toFixed(2)
      const _cashPaid = formValues.cashPaid ? parseFloat(`${formValues.cashPaid}`).toFixed(2) : _paymentAmount

      setPaymentAmount({ code: currencyCode, value: _paymentAmount })
      setCashPaid({ code: currencyCode, value: _cashPaid })
      let changeDue = subtractPrices(
        { code: currencyCode, value: isRefund ? -1 * _cashPaid : _cashPaid },
        { code: currencyCode, value: _paymentAmount }
      )
      // Round to 2dp
      changeDue.value = parseFloat(`${changeDue.value}`).toFixed(2)
      setChangeDue(changeDue)
      setPaymentDevice(paymentDevice)
      setShowChangeDue(true)
      setDefaultPaymentDevice(paymentDevice.id)
    }
  })
)(SelectCashPaymentDeviceModal)
