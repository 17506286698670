import React from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles'

import Checkbox from '../Checkbox'
import styles from './style'

export const Checklist = ({
  label,
  onChange,
  givenClass,
  options,
  classes
}) => ([
  <FormLabel
    key='label'
    // FormControlClasses
    classes={{
      focused: classes.floatingLabelFocusStyle
    }}
  >{label}</FormLabel>,
  <FormGroup key='group' row onChange={onChange}>
    {options.map(option => (
      <Checkbox key={option.label} {...option} />
    ))}
  </FormGroup>
])

Checklist.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  })).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

export default withStyles(styles)(Checklist)
