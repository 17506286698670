import * as constants from './constants'
import reducers from './reducers'

export const defaultState = {
  valueAddedServices: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.NEXT:
      return reducers.next(state, action)
    case constants.START:
      return reducers.start(state, action)
    case constants.END:
      return reducers.end(state, action)
    case constants.CLEAR:
      return reducers.clear(state, action)
    case constants.FAIL:
      return reducers.fail(state, action)
    case constants.SET_CURRENT:
      return reducers.setCurrent(state, action)
    case constants.SET_LOADING:
      return reducers.setLoading(state, action)
    case constants.SET_ORDER_NUMBER:
      return reducers.setOrderNumber(state, action)
    case constants.FETCH_DELIVERY_OPTIONS:
      return reducers.fetchDeliveryOptions(state, action)
    case constants.FETCH_VALUE_ADDED_SERVICES:
      return reducers.fetchValueAddedServices(state, action)
    case constants.SET_PREVIOUS_PATH:
      return reducers.setPreviousPath(state, action)
    case constants.SET_DEFERRED_MODULE:
      return reducers.setDeferredModule(state, action)
    case constants.SET_ORDER_ID:
      return reducers.setOrderId(state, action)
    default:
      return state
  }
}
