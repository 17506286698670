import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { goBack } from 'connected-react-router'
import { connect } from 'react-redux'

import _ from 'lodash'

import { getCheckoutModuleOptions } from '../../../helpers'
import withCheckoutFlow from '../withCheckoutFlow'
import PayPalHere from './PayPalHere'
import modalService from '../../../services/modalService'
import SelectCardPaymentDeviceModal from '../../../components/PaymentDevices/SelectCardPaymentDeviceModal'
import SelectCashPaymentDeviceModal from '../../../components/PaymentDevices/SelectCashPaymentDeviceModal'
import PayByLinkModal from '../../../components/PayByLink/PayByLinkModal'
import { selectors as paymentDevicesSelectors } from '../../../store/modules/paymentDevices'
import { selectors as appSelectors, actions as appActions } from '../../../store/modules/app'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as networkSelectors } from '../../../store/modules/network'
import { generateOrderNumber } from '../uploadOrder'
import { ADYEN_CLOUD_PAYMENT_DEVICE, PAY_BY_LINK, STAR_CLOUD_PRNT_DEVICE } from '../../../services/paymentDeviceService'
import { getCurrentOrderCustomer, getCurrentOrderDetails, getCurrentOrderProducts } from '../../../store/modules/currentOrder/selectors'

const mapStateToProps = (state) => {
  const allDevices = paymentDevicesSelectors.getAll(state)
  const allPrinters = allDevices.filter(device => device.deviceType === STAR_CLOUD_PRNT_DEVICE)
  const adyenTerminals = allDevices
    .filter((device) => device.deviceType === ADYEN_CLOUD_PAYMENT_DEVICE)
    .map((item) => ({ value: item.id, label: item.name }))
  const isAnonymousOrder = getCurrentOrderCustomer(state) ? false : true
  const selectedPrinterId = appSelectors.getSelectedPrinterId(state)
  const giftCards = getCurrentOrderProducts(state).filter((item) =>
    _.get(item, 'details.isProductAGiftCard')
  )
  
  return {
    cardPaymentAvailable: true,
    cashPaymentAvailable: true,
    isLocked: appSelectors.getIsScreenLocked(state),
    isOnline: networkSelectors.isConnected(state),
    userNumber: authSelectors.getActiveUserNumber(state),
    paymentDevices: paymentDevicesSelectors.getAll(state),
    isAnonymousOrder,
    adyenTerminals,
    giftCards,
    selectedPrinterId,
    allPrinters
  };
}

const mapDispatchToProps = dispatch => ({
  setselectedPrinterId: selectedPrinterId => dispatch(appActions.setselectedPrinterId(selectedPrinterId)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCheckoutFlow,
  withPropsOnChange(
    ['checkoutType', 'cardPaymentAvailable', 'cashPaymentAvailable'],
    ({ checkoutType }) => {
      return _.pick(
        getCheckoutModuleOptions('PAYPAL_HERE', checkoutType || 'checkout'),
        'paymentRequired'
      );
    }
  ),
  withPropsOnChange(['isLocked'], ({ isLocked }) => {
    if (isLocked) {
      modalService.close();
    }
  }),
  withPropsOnChange(['selectedPrinterId'], ({ selectedPrinterId, allPrinters }) => {
    const selectedPrinter = allPrinters.find(printer => printer.id === selectedPrinterId);
    return {
      selectedPrinter
    }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack());
    },
    payByCard: ({ updateOrder, next, total, selectedPrinter }) => () => {
      const orderNumber = generateOrderNumber();
      
      const onSuccess = async (paymentDevice, paymentResult) => {
        updateOrder({
          details: { paymentResult, paymentDevice },
          paymentDeviceId: paymentDevice.id,
          orderNumber,
        });
        next();
        return modalService.close();
      };
      const onDismiss = () => {
        return modalService.close();
      };

      return modalService.open({
        component: SelectCardPaymentDeviceModal,
        success: onSuccess,
        dismiss: onDismiss,
        orderNumber: orderNumber,
        total: total,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      });
    },
    payByCash: ({ updateOrder, next, total, giftCards, selectedPrinter }) => () => {
      const orderNumber = generateOrderNumber();
      const onSuccess = async (paymentDevice, paymentResult) => {
        updateOrder({
          details: { paymentResult, paymentDevice },
          paymentDeviceId: paymentDevice.id,
          orderNumber
        })    
        modalService.close()
        next()
      }
      const onDismiss = () => {
        return modalService.close();
      };

      const isPartialRefund = total.value <= 0;

      return modalService.open({
        component: SelectCashPaymentDeviceModal,
        success: onSuccess,
        dismiss: onDismiss,
        giftCards,
        total: total,
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        isPartialRefund: isPartialRefund,
      });
    },
    payByLink: ({ updateOrder, next, paymentDevices, total, selectedPrinter }) => async () => {
      const payByLinkDevice = paymentDevices.find(
        ({ deviceType }) => deviceType === PAY_BY_LINK
      );

      modalService.close()
      return modalService.open({
        component: PayByLinkModal,
        onCancel: () => {
          modalService.close()
        },
        onSuccess: (refenceNumber) => {
          const orderNumber = generateOrderNumber()
          updateOrder({
            details: {
              paymentDevice: payByLinkDevice,
              payByLinkReferenceNumber: refenceNumber
            },
            paymentDeviceId: payByLinkDevice.id,
            orderNumber
          })
          next()
        }
      })
    },
  })
)(PayPalHere);