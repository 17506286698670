import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import _ from 'lodash'
import StockAdjustmentModal, { formId } from './StockAdjustmentModal'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import { selectors as productDetailsSelectors, actions as productDetailsActions } from '../../store/modules/productDetails'
import modalService from '../../services/modalService'
import toastService from '../../services/toastService'
import { defectCodes, translations } from '../../config'

const mapStateToProps = (state, props) => {
  const product = productDetailsSelectors.getProduct(state)
  const formValues = getFormValues(formId)(state)

  return {
    product,
    formValues,
    initialValues: {
      quantity: 1
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createStockAdjustment: (...params) => {
      return dispatch(productDetailsActions.createStockAdjustment(...params))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('error', 'setError', null),
  withState('loading', 'setLoading', false),
  withState('canSubmit', 'setCanSubmit', false),
  withState('reasonCode', 'setReasonCode', ''),
  withState('defectCode', 'setDefectCode', ''),
  withState('defectCategory', 'setDefectCategory', ''),
  withState('defectCategories', 'setDefectCategories', []),
  withState('defectCodeOptions', 'setDefectCodeOptions', []),
  withPropsOnChange(['productId', 'variantId'], ({ product, variantId }) => {
    const selectedVariant = _.chain(product)
      .get('variants')
      .find(({ id }) => id === variantId)
      .value()

    return { selectedVariant }
  }),
  withHandlers({
    goBack: ({ dispatch }) => () => {
      dispatch(goBack())
    },
    handleReasonCodeChange: ({ setReasonCode, setDefectCategories, setCanSubmit }) => (reasonCode) => {
      if(reasonCode === 'QUALITY_ISSUE'){
        const defectCodeCategories = 
          defectCodes.map(defectCode => ({ label: defectCode.label, value: defectCode.value }));
        setDefectCategories(defectCodeCategories)
        setCanSubmit(false)
      }else {
        setCanSubmit(true)
        setDefectCategories([])
      }
      setReasonCode(reasonCode)
    },
    handleDefectCategoryChange: ({ setDefectCategory, setDefectCodeOptions, setCanSubmit, setDefectCode }) => (defectCategory) => {
      const category = defectCodes.find(defectCode => defectCode.value === defectCategory)
      if(category) {
        const defectCodeOptions = category.options.map((defectCode) => ({
          label: defectCode.label,
          value: defectCode.value
        }))
        setDefectCodeOptions(defectCodeOptions)
        setCanSubmit(false)
      }else {
        setDefectCode('')
        setCanSubmit(true)
        setDefectCodeOptions([])
      }
      setDefectCategory(defectCategory)
    },
    handleDefectCodeChange: ({ setDefectCode, setCanSubmit }) => (defectCode) => {
      setDefectCode(defectCode)
      setCanSubmit(true)
    },
    handleSubmit: ({ product, selectedVariant, formValues, createStockAdjustment, setLoading, setError, reasonCode, defectCode, defectCategory }) => () => {
      const stockAdjustment = formValues.quantity
      const reason = formValues.reason
      const obj = {
        productId: product.id,
        variantId: selectedVariant.id,
        stockAdjustment: -Math.abs(stockAdjustment), // Fiskars only allow us to decrement stock
        reasonCode,
        reason,
        defectCategory,
        defectCode,
      }

      setLoading(true)
      return createStockAdjustment(obj)
        .then(() => {
          modalService.close()
          return toastService.action({
            type: 'success',
            message: translations('Successfully submitted stock adjustment'),
            verticalPosition: 'top',
            horizontalPosition: 'right'
          })
        })
        .catch((error) => {
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  })
)(StockAdjustmentModal)
