import * as constants from './constants'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { apiUpdateSource } from '../../../config'

class UserDetailsActions {
  createUser = (fields) => ({
    type: constants.CREATE_USER,
    promise: async () => {
      const createUserParams = _.omit(fields, ['storeIds', 'departmentIds'])
      const storeIds = _.get(fields, 'storeIds', [])
      const departmentIds = _.get(fields, 'departmentIds', [])

      const { id } = await digitalStoreSdk.users
        .createUser({ ...createUserParams, updateSource: apiUpdateSource })

      await Promise.all([
        await digitalStoreSdk.users
          .setStoreRelationshipsForUser({ userId: id, storeIds: _.castArray(storeIds) }),
        await digitalStoreSdk.users
          .setDepartmentRelationshipsForUser({ userId: id, departmentIds: _.castArray(departmentIds) })
      ])

      const userFull = await digitalStoreSdk.users
        .fetchUserDetails({ id, includes: 'role,stores,departments' })

      return userFull
    }
  })

  fetchUserDetails = (id) => {
    return {
      type: id === '@me' ? constants.FETCH_CURRENT_USER_DETAILS : constants.FETCH_USER_DETAILS,
      promise: () => digitalStoreSdk.users
        .fetchUserDetails({ id, includes: 'role,stores,departments' })
    }
  }

  updateUser = (params) => {
    return {
      type: constants.UPDATE_USER,
      promise: async () => {
        const updateUserParams = _.omit(params, ['storeIds', 'departmentIds'])
        const storeIds = _.get(params, 'storeIds')
        const departmentIds = _.get(params, 'departmentIds')

        const { id } = await digitalStoreSdk.users
          .updateUser({ ...updateUserParams, updateSource: apiUpdateSource })

        await Promise.all([
          _.isArray(storeIds)
            ? digitalStoreSdk.users.setStoreRelationshipsForUser({ userId: id, storeIds })
            : Promise.resolve(),
          _.isArray(departmentIds)
            ? digitalStoreSdk.users.setDepartmentRelationshipsForUser({ userId: id, departmentIds })
            : Promise.resolve()
        ])

        const userFull = await digitalStoreSdk.users
          .fetchUserDetails({ id, includes: 'role,stores,departments' })

        return userFull
      }
    }
  }

  changeUserPassword = ({ id, password }) => {
    return {
      type: constants.CHANGE_USER_PASSWORD,
      promise: () => digitalStoreSdk.auth
        .changePasswordByUserId({ id, password })
    }
  }

  changeCurrentUserPassword = ({ id, password }) => {
    return {
      type: constants.CHANGE_CURRENT_USER_PASSWORD,
      promise: () => digitalStoreSdk.auth
        .changePasswordByUserId({ id, password })
    }
  }

  setDeactivated = (id, deactivated) => {
    return {
      type: constants.USER_ACCOUNT_STATUS_TOGGLE,
      promise: () => digitalStoreSdk.users
        .updateUser({ id, accountDeactivated: deactivated, updateSource: apiUpdateSource })
        .then(({ id }) => digitalStoreSdk.users.fetchUserDetails({ id, includes: 'role,stores,departments' }))
    }
  }
}

export default new UserDetailsActions()
