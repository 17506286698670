import { colors } from '../../../config/theme'

export default theme => ({
  container: {

  },
  header: {
    border: '2px solid rgba(125,125,125,0.1)',
    padding: '10px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  listContainer: {
    border: '2px solid rgba(125,125,125,0.1)',
    borderTop: '0px solid rgba(125,125,125,0.1)',
  },
  listItem: {
    padding: '10px',
    fontSize: '12px',
    display: 'flex',
  },
  listItemName: {
    flex: '4',
  },
  listItemValue: {
    flex: '1',
    textAlign: 'right',
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '-17px',
    right: '0px',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
})
