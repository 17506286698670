import React, { useState } from 'react'
import BasicModalOverlay from '../BasicModalOverlay'
import Form from '../../components/Form'
import { Input } from '../Fields'
import { translations } from '../../config'

const FormWrapper = Form('ScanGiftCardForm')

const PayByLinkModal = ({ onSuccess, onCancel }) => {
  const [refenceNumber, setReferenceNumber] = useState()

  const actions = [
    {
      text: translations('Cancel'),
      onClick: () => {
        onCancel()
      }
    },
    {
      disabled: !refenceNumber,
      text: translations('Pay By Link'),
      primary: true,
      onClick: () => {
        onSuccess(refenceNumber)
      }
    }
  ]

  const handleInputChange = ({ target: { value } }) => {
    setReferenceNumber(value)
  }

  return (
    <BasicModalOverlay actions={actions} title={translations('Pay By Link')}>
      <FormWrapper>
        <Input
          type='text'
          label='Reference'
          value={refenceNumber}
          onChange={handleInputChange}
        />
      </FormWrapper>
    </BasicModalOverlay>
  )
}

export default PayByLinkModal
