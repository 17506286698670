import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { selectors as currentAppointmentSelectors } from '../../../../../store/modules/currentAppointment'
import { useMediaQuery } from 'react-responsive'
import { theme, translations } from '../../../../../config'
import ProductResourceItem from '../ProductResourceItem'
import P from '../../../../../components/P'
import ReviewConsultationPanel from '../ReviewConsultationPanel'

// animate height transistion on the drawer
const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden'
  }
}))

const ActiveResourcePanelContainer = styled.div`
`

const EmptyContentText = styled(P)`
  margin: 1rem;
  text-align: center;
`

const ResourceDrawer = styled(Drawer)`
  & > div {
    top: auto;
    bottom: 0;
    height: ${({ open, anchor }) => {
    const portraitHeight = open ? `auto` : `250px`
    return anchor === 'bottom' ? portraitHeight : `calc(100% - ${theme.headerHeight}px)`
  }};
    max-height: 90vh;
  }
`

const ToggleDrawerButton = styled(Button)`
  border-bottom: 1px solid #D1D1D1;
  text-transform: none;
  padding-bottom: 0;
  padding-top: 0;
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  & > span:first-child {
    display: flex;
    flex-direction: column;

    & svg {
      width: 18px;
      height: 18px;
    }
  }
`

const ToggleDrawerButtonLabel = styled.span`
  margin-top: -4px;
`

const DrawerContentsWrapper = styled.div`
  width: ${({ isPortrait }) => isPortrait ? 'auto' : '360px'};
`

const renderEmpty = ({ user }) => {
  const userFirstName = _.get(user, 'firstName')

  if (userFirstName) {
    return (
      <EmptyContentText value={_.template(translations('Consultation - no active resource message {userFirstName}'))({ userFirstName })} />
    )
  } else {
    return null
  }
}

const renderResource = ({ selectedResource, user, appointmentStage }) => {
  if (
    appointmentStage === 'REVIEW' ||
    appointmentStage === 'CHECKOUT' ||
    appointmentStage === 'COMPLETE' ||
    appointmentStage === 'PAYMENT_PENDING' ||
    appointmentStage === 'ORDER_COMPLETION_FAILED'
  ) {
    return <ReviewConsultationPanel appointmentStage={appointmentStage} />
  } else if (selectedResource) {
    switch (selectedResource.type) {
      case 'product':
        return <ProductResourceItem type={selectedResource.type} {...selectedResource.details} />
      default:
        return renderEmpty({ user })
    }
  } else {
    return renderEmpty({ user })
  }
}

const ActiveResourcePanel = () => {
  const user = useSelector(currentAppointmentSelectors.getAppointmentUser)
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const drawerAnchor = isPortrait ? 'bottom' : 'right'
  const toggleDrawerButtonLabel = drawerOpen ? translations('Less information') : translations('More information')
  const selectedResource = useSelector(currentAppointmentSelectors.getAppointmentShowingContent)
  const isAppointmentStatusComplete = useSelector(currentAppointmentSelectors.getAppointmentStatus) === 'COMPLETE'

  let appointmentStage = useSelector(currentAppointmentSelectors.getAppointmentStage)
  if (
    isAppointmentStatusComplete &&
    appointmentStage !== 'PAYMENT_PENDING' &&
    appointmentStage !== 'ORDER_COMPLETION_FAILED'
  ) {
    appointmentStage = 'COMPLETE'
  }

  return (
    <ActiveResourcePanelContainer>
      <ResourceDrawer
        variant={'permanent'}
        anchor={drawerAnchor}
        open={drawerOpen}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen
          })
        }}
      >
        <DrawerContentsWrapper isPortrait={isPortrait}>
          {isPortrait && (
            <ToggleDrawerButton onClick={() => setDrawerOpen(!drawerOpen)}>
              {drawerOpen ? (<ExpandMoreIcon />) : <ExpandLessIcon />}
              <ToggleDrawerButtonLabel>{toggleDrawerButtonLabel}</ToggleDrawerButtonLabel>
            </ToggleDrawerButton>
          )}
          {renderResource({ selectedResource, user, appointmentStage })}
        </DrawerContentsWrapper>
      </ResourceDrawer>
    </ActiveResourcePanelContainer>
  )
}

export default ActiveResourcePanel