import { push, LOCATION_CHANGE, replace } from 'connected-react-router'
import { matchPath } from 'react-router'

import { selectors as authSelectors } from '../auth'
import { SUCCESS } from '../../middleware/redux-promise'

import * as constants from './constants'
import orderDetailsActions from './actions'
import modalService from '../../../services/modalService'
import { actions as currentOrderActions } from '../currentOrder'

class OrderDetailsMiddleware {
  loadOrderMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const isRoutingToOrderDetailsView = matchPath(action.payload.location.pathname, { path: '/orders/:orderNumber', exact: true })
      const isRoutingToOrderDetailsRefundView = matchPath(action.payload.location.pathname, { path: '/orders/:orderNumber/refund', exact: true })
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())

      if (isRoutingToOrderDetailsView && isLoggedIn) {
        const { orderNumber } = isRoutingToOrderDetailsView.params
        dispatch(orderDetailsActions.fetchOrder({ orderNumber }))
      }

      if (isRoutingToOrderDetailsRefundView && isLoggedIn) {
        const { orderNumber } = isRoutingToOrderDetailsRefundView.params
        dispatch(orderDetailsActions.fetchOrder({ orderNumber }))
      }
    }
  }

  updateCurrentOrderAfterUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.UPDATE_ORDER && action.status === SUCCESS) {
      const shouldClearOrder = ['full_refund', 'partial_refund'].includes(action.result.status)
      shouldClearOrder
        ? dispatch(currentOrderActions.clearOrder({}))
        : dispatch(currentOrderActions.updateOrder(action.result))
    }
    next(action)
  }

  createCustomerModalCloseOnSuccessMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.CREATE_CUSTOMER_FOR_ORDER && action.status === SUCCESS) {
      modalService.close()
    }
    next(action)
  }

  redirectAfterRefundToRefundOrderMiddleware = ({ dispatch, getState }) => next => (action) => {
    if (action.type === constants.REFUND_ORDER && action.status === SUCCESS) {
      const orderNumber = action.result.orderNumber
      dispatch(replace(`/orders/${orderNumber}/refund`))
    }
    next(action)
  }
}

export default new OrderDetailsMiddleware()
