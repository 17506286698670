import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { push } from 'connected-react-router'
import CustomerSnapshotHeader from './CustomerSnapshotHeader'
import { selectors as customerDetailSelectors } from '../../../../../store/modules/customerDetails'
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { actions as createMessageActions } from '../../../../../store/modules/createMessage'
import { actions as currentOrderActions, selectors as currentOrderSelectors } from '../../../../../store/modules/currentOrder'
import handleFollow from '../../handleFollow'
import handleNotifyMe from '../../handleNotifyMe'
import { apps, translations } from '../../../../../config'
import modalService from '../../../../../services/modalService'
import handleAnonymiseCustomer from '../../handleAnonymiseCustomer'

const mapStateToProps = (state) => {
  const customer = customerDetailSelectors.getCustomer(state)
  const isCustomerAnonymised = customerDetailSelectors.getIsCustomerAnonymised(state)
  const isFollowing = customerDetailSelectors.getIsCustomerFollowed(state)
  const isMessagingAllowedForRole = authSelectors.getIsMessagingAllowedForRole(state)
  const currentOrderCustomer = currentOrderSelectors.getCurrentOrderCustomer(state)
  const shouldShowFollowButton = (!isCustomerAnonymised) || (isCustomerAnonymised && isFollowing)
  const shouldShowMessageButton = apps.MESSAGING && isMessagingAllowedForRole && !isCustomerAnonymised
  const customerCurrentlyInBasket = currentOrderCustomer && customer && currentOrderCustomer.id === customer.id
  const isUserAdmin = authSelectors.getIsUserAdmin(state)
  const shouldShowAnonymiseButton = isUserAdmin && !isCustomerAnonymised
  return {
    customer,
    currentOrderCustomer,
    customerCurrentlyInBasket,
    tier: customerDetailSelectors.getCustomerLoyaltyType(state),
    isDatesVisible: false, // TODO show once the data is made available
    shouldShowFollowButton,
    shouldShowMessageButton,
    shouldShowAnonymiseButton
  }
}

const handleAddCustomerToOrder = ({ dispatch, customer, currentOrderCustomer }) => {
  if (currentOrderCustomer) {
    modalService.action({
      title: translations('Add Customer To Basket Confirm Customer Overwrite Title'),
      text: translations('Add Customer To Basket Confirm Customer Overwrite Text', {
        customer: `${currentOrderCustomer.firstName} ${currentOrderCustomer.lastName}`
      }),
      actions: [
        {
          text: translations('Cancel')
        },
        {
          text: translations('Replace'),
          primary: true,
          onClick: () => dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
        }
      ]
    })
  } else {
    dispatch(currentOrderActions.updateOrder({ customer, showToast: true }))
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    goToFullProfile: ({ dispatch, customer }) => () => dispatch(push(`/customers/${customer.id}/full`)),
    goToMessages: ({ dispatch, customer }) => () => dispatch(createMessageActions.updateReceipient(customer)),
    handleFollow: ({ dispatch, customer }) => () => {
      handleFollow({ ...customer, dispatch })
    },
    handleNotifyMe: ({ dispatch, customer }) => () => {
      handleNotifyMe({ dispatch, customer })
    },
    onActionsClick: ({
      dispatch,
      customer,
      shouldShowFollowButton,
      shouldShowMessageButton,
      currentOrderCustomer,
      customerCurrentlyInBasket,
      shouldShowAnonymiseButton
    }) => () => {
      modalService.action({
        title: 'Customer Actions',
        buttonDirection: 'column',
        actions: [
          ...shouldShowFollowButton ? [{
            text: translations(customer.following ? 'Unfollow' : 'Follow'),
            onClick: () => handleFollow({ ...customer, dispatch }),
            primary: true,
            fullWidth: true
          }] : [],
          ...shouldShowMessageButton ? [{
            text: translations('Message'),
            onClick: () => dispatch(createMessageActions.updateReceipient(customer)),
            primary: true,
            fullWidth: true
          }] : [],
          ...shouldShowFollowButton ? [{
            text: translations('Follow Up'),
            onClick: () => handleNotifyMe({ dispatch, customer }),
            primary: true,
            fullWidth: true
          }] : [],
          // ...shouldShowAnonymiseButton ? [{
          //   text: translations('Forget Me'),
          //   onClick: () => handleAnonymiseCustomer({ ...customer, dispatch }),
          //   primary: true,
          //   fullWidth: true
          // }] : [],
          {
            text: translations('Add Customer To Basket'),
            onClick: () => handleAddCustomerToOrder({ dispatch, customer, currentOrderCustomer }),
            primary: true,
            fullWidth: true,
            disabled: customerCurrentlyInBasket
          }
        ]
      })
    }
  }),
  withPropsOnChange(
    ['customer'],
    ({ customer }) => ({
      initials: customer.firstName ? `${customer.firstName.charAt(0)}${customer.lastName.charAt(0)}` : ''
    })
  )
)(CustomerSnapshotHeader)
