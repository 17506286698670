import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SubHeader from '../../components/SubHeader'
import BackBar from '../../components/BackBar'
import SearchInput from '../../components/SearchInput'
import Hidden from '@material-ui/core/Hidden'
import ProductListMobile from '../../components/ProductLists/ProductListMobile'
import ProductListDesktop from '../../components/ProductLists/ProductListDesktop'
import BottomBar from '../../components/BottomBar'
import ButtonsContainer from '../../components/ButtonsContainer'
import { colors } from '../../config/theme'
import { translations } from '../../config'
import { ProductListDateRangePicker } from './ProductListDateRangePicker'
import ProductListFilter from '../../components/Filter'
import modalService from '../../services/modalService'

const FooterButton = styled.button`
  width: 100%;
  max-width: 380px;
  height: 45px;
  background-color: ${colors.primary};
  border: none;
  border-radius: 2px;
  color: ${colors.whiteText};
  font-weight: bold;
  font-size: 16px;
  outline: none;
  font-family: 'Tahoma';
  &:hover {
    cursor: pointer;
  }
`

const Filter = styled.button`
  transform: translateY(-25%);
  width: 100%;
  max-width: 100px;
  height: 30px;
  background-color: ${colors.primary};
  border: none;
  border-radius: 2px;
  color: ${colors.whiteText};
  font-weight: bold;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  font-family: 'Tahoma';
`

const FilterList = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5em;
  text-decoration: underline;
`

const FilterWrapper = styled.div`
  margin-top: 1em;
  margin-left: 1em;
`

const ProductListScreen = ({
  lists,
  isLoading,
  error,
  getList,
  getProductLists,
  setDateFrom,
  setDateTo,
  searchPlaceholder,
  footerButtons,
  moduleConfig,
  listInfo,
  setMobileSelectedColumn,
  mobileSelectedColumn,
  showDateRangePicker,
  dateRange,
  filters
}) => {
  return (
    <>
      <SubHeader
        leftContent={<BackBar />}
        centerContent={searchPlaceholder &&
          <SearchInput
            placeholder={searchPlaceholder}
            autoFocus={false}
          />
        }
      />
      {
        showDateRangePicker && 
        <FilterWrapper>
          <Filter onClick={() => {
            return modalService.open({
              component: ProductListDateRangePicker,
              modalIndex: 1,
              toLabel: translations('Stock count - date to label'),
              fromLabel: translations('Stock count - date from label'),
              setDateFrom: setDateFrom,
              setDateTo: setDateTo,
              dateRange
            })
          }}>
            {translations('Product lists - filter', { amount: 1 })}
          </Filter>
          <FilterList>{showDateRangePicker}</FilterList>
        </FilterWrapper>
      }
      {filters && filters.filterList.length > 0 &&
        <FilterWrapper>
          <Filter onClick={() => {
            return modalService.open({
              formName: filters.formName,
              component: ProductListFilter,
              modalIndex: 1,
              filters: filters.filterList,
              title: filters.title,
              type: filters.type,
              text: filters.text,
              actions: filters.actions
            })
          }}>
            {translations('Product lists - filter', { amount: filters.amount })}
          </Filter>
          {filters.list && <FilterList>{filters.list}</FilterList>}
        </FilterWrapper>
      }
      <Hidden xsDown>
        <ProductListDesktop
          config={moduleConfig}
          lists={lists}
          isLoading={isLoading}
          error={error}
          onListItemClick={getList}
        />
      </Hidden>
      <Hidden smUp>
        <ProductListMobile
          mobileSelectedColumn={mobileSelectedColumn}
          setMobileSelectedColumn={setMobileSelectedColumn}
          config={moduleConfig}
          lists={lists}
          listInfo={listInfo}
          isLoading={isLoading}
          getProductLists={getProductLists}
          error={error}
          onListItemClick={getList}
        />
      </Hidden>
      {footerButtons.length > 0 && <BottomBar>
        <ButtonsContainer align='center'>
          {footerButtons.map((footerButton) => {
            return (
              <FooterButton onClick={() => footerButton.onClick()}>
                {footerButton.label}
              </FooterButton>
            )
          })}
        </ButtonsContainer>
      </BottomBar>}
    </>
  )
}

ProductListScreen.propTypes = {
  lists: PropTypes.object.isRequired,
  getProductLists: PropTypes.func.isRequired,
  isLoading: PropTypes.object,
  error: PropTypes.object,
  setDateFrom: PropTypes.func,
  setDateTo: PropTypes.func,
  getList: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  footerButtons: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  })),
  moduleConfig: PropTypes.shape({}).isRequired,
  setMobileSelectedColumn: PropTypes.func.isRequired,
  mobileSelectedColumn: PropTypes.string.isRequired,
  showDateRangePicker: PropTypes.string,
  dateRange: PropTypes.object,
  filters: PropTypes.objectOf({
    formName: PropTypes.string.isRequired,
    filterList: PropTypes.array.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    actions: PropTypes.array,
    list: PropTypes.string,
    amount: PropTypes.number.isRequired
  })
}

ProductListScreen.defaultProps = {
  isLoading: {},
  error: {},
  footerButtons: []
}

export default ProductListScreen
