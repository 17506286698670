import React, { Component } from 'react'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

import { stockAdjustmentReasonCodes, translations } from '../../config'
import { Counter, Input } from '../Fields'
import Form from '../Form'
import BasicModalOverlay from '../BasicModalOverlay'

import style from './style'
import * as validators from '../../validators'
import LoaderContainer from '../LoaderContainer'
import Dropdown from '../Fields/Dropdown'

export const formId = 'adjust-stock-modal'
const ThisForm = Form(formId)

class StockAdjustmentModal extends Component {
  render() {
    const {
      classes,
      handleSubmit,
      error,
      dismiss,
      initialValues,
      product,
      selectedVariant,
      loading,
      canSubmit,
      reasonCode,
      defectCategory,
      defectCode,
      defectCodeOptions,
      defectCategories,
      handleReasonCodeChange,
      handleDefectCodeChange,
      handleDefectCategoryChange,
      formValues
    } = this.props

    let actions = []

    actions.push({
      text: translations('Cancel'),
      success: false,
      primary: false,
      onClick: () => dismiss(true)
    })


    actions.push({
      text: translations('Submit'),
      success: true,
      primary: true,
      disabled: !canSubmit || !reasonCode,
    })

    return (
      <LoaderContainer
        isLoading={loading}
        loadingText={translations('Loading')}
      >
        <ThisForm onSubmit={handleSubmit} initialValues={initialValues}>
          <BasicModalOverlay
            title={translations('Adjust stock')}
            confirmButtonText={translations('submit')}
            actions={actions}
          >
            <div className={classes.formContainer}>
              <h4>{product.name}</h4>
              <h4>{selectedVariant.name}</h4>
              <h4>Current Stock: {selectedVariant.variantStoreStock.stock}</h4>
              <h4>
                Decrease stock by:{' '}
                {_.has(formValues, 'quantity') ? formValues.quantity : 0}
              </h4>
              <FormControl className={classes.formControl}>
                <Counter
                  name='quantity'
                  validate={validators.isTruthy}
                  // allowNegative
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Dropdown
                  name={'reasonCode'}
                  value={reasonCode}
                  label={translations('Reason')}
                  options={stockAdjustmentReasonCodes.map((obj) => ({
                    label: obj.text,
                    value: obj.code
                  }))}
                  onChange={({ target: { value } }) =>
                    handleReasonCodeChange(value)
                  }
                  validate={validators.required}
                />
              </FormControl>
              {defectCategories.length ? (
                <>
                  <FormControl className={classes.formControl}>
                    <Dropdown
                      value={defectCategory}
                      name={'defectCodeCategory'}
                      label={translations('Material')}
                      options={defectCategories}
                      validate={validators.required}
                      onChange={({ target: { value } }) =>
                        handleDefectCategoryChange(value)
                      }
                    />
                  </FormControl>
                  {defectCodeOptions.length ? (
                    <FormControl className={classes.formControl}>
                      <Dropdown
                        value={defectCode}
                        name={'defectCode'}
                        label={translations('Defect code')}
                        options={defectCodeOptions}
                        validate={validators.required}
                        onChange={({ target: { value } }) =>
                          handleDefectCodeChange(value)
                        }
                      />
                    </FormControl>
                  ) : null}
                </>
              ) : null}
              <FormControl className={classes.formControl}>
                <Input
                  type='text'
                  name='reason'
                  label={translations('Add more detail')}
                />
              </FormControl>
              {error && <p className={classes.errorText}>{error.message}</p>}
            </div>
          </BasicModalOverlay>
        </ThisForm>
      </LoaderContainer>
    )
  }
}

export default withStyles(style)(StockAdjustmentModal)
