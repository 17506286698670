import moment from 'moment'
import { get, groupBy, has, omit } from 'lodash'
import currencyFormatter from '../../formatters/currencyFormatter'
import {
  promotionConditionsTypes,
  promotionDiscountTypes,
  promotionTargets
} from './constants'

class PromotionService {
  /**
   * Filter and return only valid promotions
   * @param {*} promotion
   * @returns
   */
  isPromotionEnabled (promotion) {
    const today = moment()
    const startDate = promotion.startDate ? moment(promotion.startDate) : moment().add(-1, 'days')
    const endDate = promotion.endDate ? moment(promotion.endDate) : moment().add(1, 'days')
    const isPromotionWithinStartEndDate = today.isBetween(startDate, endDate)
    return promotion.enabled && isPromotionWithinStartEndDate
  }

  /**
   * Loops through the promotiond and returns the promotion descriptions
   * if the product/product category is part of any promotion
   * @param {*} promotions
   * @param {*} product
   * @returns
   */
  getProductsPromotionsText (promotions = [], product = {}) {
    if (!Array.isArray(promotions)) {
      return
    }

    const promotionTexts = []
    promotions.forEach((promotion) => {
      get(promotion, 'promotionConditions', []).forEach((condition) => {
        if (
          (condition.conditionType === promotionConditionsTypes.PRODUCTS ||
            condition.conditionType === promotionConditionsTypes.CATEGORIES) &&
          (condition.items.includes(product.externalProductId) ||
            condition.items.includes(product.categoryId)) &&
          !promotionTexts.includes(promotion.description)
        ) {
          promotionTexts.push(promotion.description)
        }
      })
    })

    return promotionTexts
  }

  /**
   * Filter and returns promotions with the highest promotion group priority
   * @param {*} promotion
   * @returns
   */
  getPromotionsByGroupPriority (promotions) {
    const groupedPromotions = groupBy(promotions, 'promotionGroup')
    const groupedPromotionWithPriorities = groupedPromotions['null'] || []

    for (const promotionGroup in groupedPromotions) {
      if (promotionGroup !== 'null') {
        const promotion = groupedPromotions[promotionGroup].reduce(
          (prev, curr) => {
            if (
              !prev ||
              get(prev, 'promotionGroupPriority') <
                get(curr, 'promotionGroupPriority')
            ) {
              return curr
            }
            return prev
          },
          null
        )

        groupedPromotionWithPriorities.push(promotion)
      }
    }

    return groupedPromotionWithPriorities
  }

  /**
   * returns all valid promotion
   * @param {*} promotions
   */
  getAllActivePromotions (promotions) {
    const activePromotions = promotions.filter(this.isPromotionEnabled)
    return this.getPromotionsByGroupPriority(activePromotions)
  }

  /**
   * Adds promotion to products
   * @param {*} promotion
   * @param {*} order
   */
  handleAddPromotionToOrder (promotion, order) {
    if (get(promotion, 'extraProductVariants', []).length) {
      const orderProductsWithoutExtraProducts = order.products.filter(
        (product) => !product.isPromotionExtraItem
      )

      const extraProducts = get(promotion, 'extraProductVariants', []).map((product) => ({
        ...product,
        isPromotionExtraItem: true
      }))

      order.products = [...orderProductsWithoutExtraProducts, ...extraProducts]
    }

    switch (promotion.applyTo) {
      case promotionTargets.ALL_PRODUCTS:
        order.products = this.addPromotionToAllProducts(order, promotion)
        break

      case promotionTargets.CHEAPEST_PRODUCT:
        order.products = this.addPromotionToCheapestProduct(order, promotion)
        break

      case promotionTargets.EXTRA_PRODUCTS:
        order.products = this.addPromotionToExtraProduct(order, promotion)
        break

      case promotionTargets.SHIPPING:
        if (
          promotion.applyTo === promotionTargets.SHIPPING &&
          has(order, 'deliveryOption.price')
        ) {
          if (!Array.isArray(order.promotions)) {
            order.promotions = []
          }

          if (
            !order.promotions.find(
              (_promotion) => promotion.id === _promotion.promotionId
            )
          ) {
            order.promotions = [
              ...order.promotions,
              this.addPromotionToShippings(order, promotion)
            ]
          }
        }
        break

      default:
        console.warn('Unknown promotion target')
    }
  }

  /**
   * removes promotion from order
   * @param {*} order
   * @param {*} promotion
   * @returns
   */
  removePromotionFromProducts (products) {
    return products
      .map((product) => {
        return omit(product, ['promotions', 'manualDiscount'])
      })
      .filter((product) => !product.isPromotionExtraItem)
  }

  /**
   * adds promotion (discounttype) to the product
   * @param {*} promotion
   * @param {*} product
   * @returns
   */
  addPromotionDiscountType (promotion, product) {
    // exlude giftcards, value added services
    if (
      get(product, 'service', false) ||
      get(product, 'serviceForProductId', '') ||
      get(product, 'details.isProductAGiftCard', false)
    ) {
      return product
    }

    if (!Array.isArray(product.promotions)) {
      product.promotions = []
    }

    if (
      product.promotions.find(
        (_promotion) => promotion.id === _promotion.promotionId
      )
    ) {
      return product
    }

    switch (promotion.discountType) {
      case promotionDiscountTypes.FIXED_DISCOUNT:
        return {
          ...product,
          promotions: [
            ...product.promotions,
            this.addFixedDiscount(product.price, promotion)
          ]
        }

      case promotionDiscountTypes.FIXED_PRICE:
        return {
          ...product,
          promotions: [
            ...product.promotions,
            this.addFixedPrice(product.price, promotion)
          ]
        }

      case promotionDiscountTypes.PERCENT:
        return {
          ...product,
          promotions: [
            ...product.promotions,
            this.addPercentage(product.price, promotion)
          ]
        }
      default:
        return product
    }
  }

  // #region
  /**
   * Adds fixed discount
   * @param {*} price
   * @param {*} promotion
   */
  addFixedDiscount (price, promotion) {
    return {
      value: promotion.discountValue,
      code: get(price, 'code'),
      promotionId: promotion.id,
      promotionDiscountType: promotion.discountType,
      promotionDiscountValue: promotion.discountValue,
      promotionExternalId: promotion.externalPromotionId,
      promotionTargets: promotion.applyTo
    }
  }

  /**
   * Add fixed price discount
   * @param {*} price
   * @param {*} promotion
   */
  addFixedPrice (price, promotion) {
    return {
      value: Math.abs(promotion.discountValue - price.value),
      code: get(price, 'code'),
      promotionId: promotion.id,
      promotionDiscountType: promotion.discountType,
      promotionDiscountValue: promotion.discountValue,
      promotionExternalId: promotion.externalPromotionId,
      promotionTargets: promotion.applyTo
    }
  }

  /**
   * Adds fixed price discount
   * @param {*} price
   * @param {*} promotion
   */
  addPercentage (price, promotion) {
    return {
      value: currencyFormatter.truncateValue(
        price.value *
          currencyFormatter.truncateValue(promotion.discountValue / 100)
      ),
      code: get(price, 'code'),
      promotionId: promotion.id,
      promotionDiscountType: promotion.discountType,
      promotionDiscountValue: promotion.discountValue,
      promotionExternalId: promotion.externalPromotionId,
      promotionTargets: promotion.applyTo
    }
  }
  // #endregion

  // #region
  /**
   * Finds the cheapest product and applies the discount to it
   * @param {*} order
   * @param {*} promotion
   */
  addPromotionToCheapestProduct (order, promotion) {
    const cheapestProduct = order.products.reduce((prev, curr) => {
      if (!prev) return curr

      return Number(prev.price.value) > Number(curr.price.value) ? curr : prev
    }, null)

    if (cheapestProduct) {
      let isFound = false

      for (let index = 0; index < order.products.length && !isFound; index++) {
        if (order.products[index].id === cheapestProduct.id) {
          order.products[index] = this.addPromotionDiscountType(
            promotion,
            order.products[index]
          )
          isFound = true
        }
      }
    }

    return order.products
  }

  /**
   * Add discount to the extra product(s)
   * @param {*} promotion
   */
  addPromotionToExtraProduct (order, promotion) {
    return order.products.map((product) => {
      if (product.isPromotionExtraItem) {
        const extraProductWithPromotion = this.addPromotionDiscountType(
          promotion,
          product
        )

        return extraProductWithPromotion
      }

      return product
    })
  }

  addPromotionToAllProducts (order, promotion) {
    return order.products.map((product) => {
      return this.addPromotionDiscountType(promotion, product)
    })
  }

  /**
   * Add discount to shipping
   * @param {*} order : ;
   * @param {*} promotion
   * @returns
   */
  addPromotionToShippings (order, promotion) {
    switch (promotion.discountType) {
      case promotionDiscountTypes.FIXED_DISCOUNT:
        return {
          ...this.addFixedDiscount(order.deliveryOption.price, promotion)
        }

      case promotionDiscountTypes.FIXED_PRICE:
        return {
          ...this.addFixedPrice(order.deliveryOption.price, promotion)
        }

      case promotionDiscountTypes.PERCENT:
        return {
          ...this.addPercentage(order.deliveryOption.price, promotion)
        }
      default:
        return order
    }
  }

  // #endregion

  validatePromotionConditions (promotion, order, promoCode, storeId) {
    const validateConditions = []
    let hasIncorrectPromoCode = false
    let hasPromoCode = false
    const promotionConditions = get(promotion, 'promotionConditions', [])

    promotionConditions.forEach((promotionCondition) => {
      switch (promotionCondition.conditionType) {
        case promotionConditionsTypes.BASKET_QTY:
          const orderHasRequiredQty =
            order.products.length > promotionCondition.value
          validateConditions.push(orderHasRequiredQty)
          break

        case promotionConditionsTypes.CATALOGUE: {
          const productGroupedByCatalogue = groupBy(
            order.products,
            'variant.catalogue'
          )

          for (const [catalogue, products] of Object.entries(
            productGroupedByCatalogue
          )) {
            if (promotionCondition.items.includes(catalogue)) {
              if (promotionCondition.value <= products.length) {
                validateConditions.push(true)
              } else {
                validateConditions.push(false)
              }
            }
          }
          break
        }

        case promotionConditionsTypes.SPEND:
          const orderTotal = order.products.reduce((total, product) => {
            // exlude giftcards, value added services
            if (
              get(product, 'service', false) ||
              get(product, 'serviceForProductId', '') ||
              get(product, 'details.isProductAGiftCard', false)
            ) {
              return total
            }

            if (has(product, 'promotions')) {
              const totalProductDiscount = product.promotions.reduce(
                (total, promotion) => {
                  return (total += promotion.value)
                },
                0
              )

              return (total += Number(
                product.price.value - totalProductDiscount
              ))
            }

            return (total += Number(product.price.value))
          }, 0)

          const orderHasMinimumSpend = orderTotal > promotionCondition.value
          validateConditions.push(orderHasMinimumSpend)
          break

        case promotionConditionsTypes.PRODUCTS:
          const productGroupedByExternalId = groupBy(
            order.products,
            'externalProductId'
          )

          for (const [externalProductId, products] of Object.entries(
            productGroupedByExternalId
          )) {
            if (promotionCondition.items.includes(externalProductId)) {
              if (promotionCondition.value <= products.length) {
                validateConditions.push(true)
              } else {
                validateConditions.push(false)
              }
            }
          }
          break

        case promotionConditionsTypes.CATEGORIES:
          const productGroupedByCategory = groupBy(order.products, 'categoryId')
          for (const [categoryId, products] of Object.entries(
            productGroupedByCategory
          )) {
            if (promotionCondition.items.includes(categoryId)) {
              if (promotionCondition.value <= products.length) {
                validateConditions.push(true)
              } else {
                validateConditions.push(false)
              }
            }
          }
          break

        case promotionConditionsTypes.PROMO_CODE:
          if (
            promoCode.length &&
            promotionCondition.items.includes(promoCode)
          ) {
            validateConditions.push(true)
          } else {
            hasIncorrectPromoCode = true
            validateConditions.push(false)
          }
          hasPromoCode = true
          break

        case promotionConditionsTypes.CUSTOMER_GROUP:
          // NOTE: not supported for first phase rollout
          break

        case promotionConditionsTypes.STORE:
          if (promotionCondition.items.includes(storeId)) {
            validateConditions.push(true)
          } else {
            validateConditions.push(false)
          }
          break
        default:
          break
      }
    })

    return { hasIncorrectPromoCode, hasPromoCode, validateConditions }
  }

  applyPromotionsToOrder (order) {
    order.products = order.products.map((product) => {
      if (Array.isArray(product.promotions) && product.promotions.length) {
        const price = product.promotions.reduce((total, promotion) => {
          return (total += promotion.value)
        }, 0)

        return {
          ...product,
          manualDiscount: {
            value: price,
            code: product.price.code
          },
          details: {
            ...product.details,
            promotions: product.promotions
          }
        }
      }

      return product
    })

    if (has(order, 'promotions') && has(order, 'deliveryOption.price')) {
      const discount = order.promotions.reduce((total, promotion) => {
        if (promotion.promotionTargets === promotionTargets.SHIPPING) {
          return (total += promotion.value)
        }
      }, 0)

      const value = order.deliveryOption.price.value - discount

      order.deliveryOption.price = {
        ...order.deliveryOption.price,
        value: value > 0 ? value : 0
      }

      order.details = {
        promotions: order.promotions
      }
    }

    order.hasPromotions = true

    return order
  }

  /**
   * adds promotions to order
   * @param {*} promotions
   * @param {*} order
   * @param {*} storeId
   * @param {*} promoCode
   * @returns
   */
  addPromotionsToOrder (promotions, order, storeId, promoCode) {
    const hasPromotions = []
    const hasPromoCode = []
    const hasIncorrectPromoCode = []

    const orderLevelPromotions = (promotions || []).filter(
      (promotion) => promotion.applyTo === promotionTargets.ALL_PRODUCTS
    )
    const lineItemPromotions = (promotions || []).filter(
      (promotion) => promotion.applyTo !== promotionTargets.ALL_PRODUCTS
    )

    lineItemPromotions.forEach((promotion) => {
      const validateConditions = []
      const {
        hasIncorrectPromoCode: _hasIncorrectPromoCode,
        hasPromoCode: _hasPromoCode,
        validateConditions: _validateConditions
      } = this.validatePromotionConditions(
        promotion,
        order,
        promoCode,
        storeId,
        hasIncorrectPromoCode,
        hasPromoCode,
        validateConditions
      )

      const canPromotionBeAdded =
          _validateConditions.length > 0 &&
          _validateConditions.every(
            (condition) => condition === true
          )

      hasIncorrectPromoCode.push(_hasIncorrectPromoCode)
      hasPromoCode.push(_hasPromoCode)
      hasPromotions.push(canPromotionBeAdded)

      if (canPromotionBeAdded) {
        this.handleAddPromotionToOrder(promotion, order)
      }
    })

    orderLevelPromotions.forEach((promotion) => {
      const validateConditions = []
      const {
        hasIncorrectPromoCode: _hasIncorrectPromoCode,
        hasPromoCode: _hasPromoCode,
        validateConditions: _validateConditions
      } = this.validatePromotionConditions(
        promotion,
        order,
        promoCode,
        storeId,
        hasIncorrectPromoCode,
        hasPromoCode,
        validateConditions
      )

      const canPromotionBeAdded =
          _validateConditions.length > 0 &&
          _validateConditions.every(
            (condition) => condition === true
          )

      hasIncorrectPromoCode.push(_hasIncorrectPromoCode)
      hasPromoCode.push(_hasPromoCode)
      hasPromotions.push(canPromotionBeAdded)

      if (canPromotionBeAdded) {
        this.handleAddPromotionToOrder(promotion, order)
      }
    })

    const canPromotionBeApplied = !!hasPromotions.find((item) => item === true)

    return { order, canPromotionBeApplied, hasPromoCode, hasIncorrectPromoCode }
  }

  removePromotionFromOrder (order, deliveryOption) {
    order.hasPromotions = false
    order.products = this.removePromotionFromProducts(order.products)

    if (has(order, 'shippingPromotion') && has(order, 'deliveryOption.price')) {
      order.deliveryOption = {
        ...order.deliveryOption,
        price: {
          price: deliveryOption.price.value,
          code: deliveryOption.price.code
        }
      }

      omit(order.details, 'promotions')
    }

    return order
  }
}

export default new PromotionService()
