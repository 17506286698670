import React from 'react'
import { translations } from '../../config'
import BasicModalOverlay from '../BasicModalOverlay'
import { EMAIL_RECEIPT, PRINT_RECEIPT } from './constants'

const ReceiptTypeModal = ({ onTypeClick, onCancel }) => {
  return (
    <BasicModalOverlay
      actions={[
        {
          primary: true,
          text: translations('Print Receipt'),
          onClick: () => {
            onTypeClick(PRINT_RECEIPT)
          }
        },
        // not needed for popup
        // {
        //   primary: true,
        //   text: translations('Email Receipt'),
        //   onClick: () => {
        //     onEmailReceipt(EMAIL_RECEIPT)
        //   }
        // },
        {
          primary: true,
          text: translations('Cancel'),
          onClick: () => {
            onCancel()
          }
        }
      ]}
    >
      {translations(
        'Select the type of the receipt you would like to generate'
      )}
    </BasicModalOverlay>
  )
}

export default ReceiptTypeModal
